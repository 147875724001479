import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Step1, Step2, Step3 } from "./forgetPasswordSteps";
import { TfiWorld } from "react-icons/tfi";
import { PrimaryModal } from "../common";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

interface ForgetPasswordProps {
  visible: boolean;
  onClose: () => void;
  onOk?: () => void;
}

const ForgetPassword: React.FC<ForgetPasswordProps> = ({
  visible,
  onClose,
}) => {
  const [select, setSelect] = useState<number>(1);
  const [contactInfo, setContactInfo] = useState<string>(""); // New state for contactInfo
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);

  const handleCloseSuccessModal = () => {
    setIsSuccessModalVisible(false);
    onClose(); // Close the parent ForgetPassword modal
  };

  const handleShowSuccessModal = () => {
    setIsSuccessModalVisible(true);
  };

  useEffect(() => {
    if (visible) {
      setSelect(1); // Reset to Step 1 when the modal becomes visible
      setIsSuccessModalVisible(false); // Ensure success modal is hidden
    }
  }, [visible]);

  return (
    <>
      <Modal
        title={<TfiWorld className="text-sky-700" size={24} />}
        visible={visible}
        onCancel={onClose}
        footer={null}
        closable={true}
        maskClosable={false}
        centered={true}
      >
        <div className="flex flex-col items-center justify-center h-full">
          {select === 1 ? (
            <Step1 setSelect={setSelect} setContactInfo={setContactInfo} /> // Pass setContactInfo to Step1
          ) : select === 2 ? (
            <Step2 setSelect={setSelect} contactInfo={contactInfo} /> // Pass contactInfo to Step2
          ) : select === 3 ? (
            <Step3
              setSelect={setSelect}
              onShowSuccessModal={handleShowSuccessModal}
              onCloseModal={handleCloseSuccessModal}
              contactInfo={contactInfo}
            />
          ) : null}
        </div>
      </Modal>
      <PrimaryModal
        icon={
          <IoMdCheckmarkCircleOutline className="mx-auto text-center text-sky-700" />
        }
        visible={isSuccessModalVisible}
        onClose={() => {}}
        mainTitle="Password Reset Successful"
        content="Your password has been successfully reset."
        primaryBtnLabel="Login"
        primaryOnClick={handleCloseSuccessModal}
        closeIcon={null}
      />
    </>
  );
};

export default ForgetPassword;
