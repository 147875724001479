import React from "react";

interface CalendarButtonProps {
  label: string;
  currentView: string;
  onClick: () => void;
}

const CalendarButton: React.FC<CalendarButtonProps> = ({
  label,
  currentView,
  onClick,
}) => {
  const isSelected = label === currentView;
  return (
    <button
      onClick={onClick}
      className={`px-4 py-[2px] rounded-lg font-semibold ${
        isSelected ? "bg-primaryColor-500 text-white" : "bg-  text-primaryColor-300 "
      } rounded`}
    >
      {label}
    </button>
  );
};

export default CalendarButton;
