import React, { useEffect, useRef, useState } from "react";

import { FaRegWindowClose } from "react-icons/fa";

import { FiEdit } from "react-icons/fi";

import Button from "../buttons/Button";
import toast from "react-hot-toast";

interface ImageOverviewModalProps {
  isOpen: boolean;
  disableUpload?: boolean;
  onClose: () => void;
  imageUrl: string;
  buttonText?: string;
  onFileSelect?: (file: File | null) => void;
}

const ImageOverviewModal: React.FC<ImageOverviewModalProps> = ({
  isOpen,
  onClose,
  buttonText,
  imageUrl,
  onFileSelect,
  disableUpload,
}) => {
  const [, setSelectedFile] = useState<File | null>(null);
  const [uploadedImage, setUploadedImage] = useState<string>(imageUrl);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (imageUrl) {
      setUploadedImage(imageUrl);
    }
  }, [imageUrl]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Check if file type is not png or jpg
    if (!file.type.includes("image/png") && !file.type.includes("image/jpeg")) {
      // Display toast and return
      toast.error("Please upload a PNG or JPEG file.");
      return;
    }

    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      setUploadedImage(base64String);
      if (onFileSelect) {
        onFileSelect(file);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ">
      <div className="relative overflow-hidden bg-[#E7F0EE] w-[100%] sm:max-w-[80%]  md:w-[500px] xl:w-[600px] shadow-3xl rounded-lg">
        <div className="absolute flex gap-5 top-3 right-3">
          {/* upload Icon */}
          {!disableUpload && (
            <button className="text-[#0A6C55]" onClick={handleEditClick}>
              <FiEdit className="w-5 h-5 md:w-6 md:h-6" />
            </button>
          )}

          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            className="hidden"
            onChange={handleFileChange}
          />

          {/* Close Icon */}
          <button className="text-[#0A6C55] text-xl" onClick={onClose}>
            <FaRegWindowClose className="w-5 h-5 rounded-md md:w-6 md:h-6" />
          </button>
        </div>
        <div className="px-4 py-2 mt-10 mb-2 ">
          {uploadedImage ? (
            <div className="flex justify-center ">
              <div className="relative  w-auto h-[300px]  sm:h-[350px] ">
                <img
                  src={uploadedImage}
                  alt="Modal Content"
                  className="w-full h-full mx-auto mb-4 "
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-center ">
              <div className="relative  w-auto h-[300px]  sm:h-[350px] ">
                <img
                  src={"/images/imageUpload.png"}
                  alt="Modal Content"
                  className="w-full h-full mx-auto mb-4 "
                />
              </div>
            </div>
          )}

          {/* Conditionally render button only if buttonText is provided */}
          {buttonText && (
            <div className="flex justify-center mt-3 ">
              <Button text={buttonText} onClick={onClose} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageOverviewModal;
