import React from "react";
import { CiEdit } from "react-icons/ci";

const RateSettingsCard: React.FC<{
  mainTitle: string;
  content?: string;
  keyValue?: string;
  primaryBtnLabel?: string;

  primaryOnClick: () => void;
}> = ({ content, keyValue = "Days", primaryOnClick }) => {
  return (
    <div className="flex items-center w-full overflow-hidden font-semibold rounded-lg shadow-md bg-primaryColor-100 ">
      <div className="relative flex items-center w-full p-4 text-center bg-primaryColor-100">
        <div className="flex gap-3 mr-4 text-balance">
          <h3 className="text-lg text-black text-balance">
            {content} {keyValue}
          </h3>
        </div>

        <button
          onClick={primaryOnClick}
          className="absolute border-white rounded cursor-pointer text-primaryColor-500 text-pri right-2"
        >
          <CiEdit className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default RateSettingsCard;
