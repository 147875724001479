import React from 'react';
import { Title } from '../../../common/';
import { Line } from 'react-chartjs-2';

interface LineChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
    fill: boolean;
  }[];
}

interface LineChartProps {
  title: string;
  timeframe: string;
  lineData: (timeframe: string) => LineChartData;
  setTimeframe: (timeframe: string) => void;
  options: Record<string, unknown>;
}

const A_LineChartSection: React.FC<LineChartProps> = ({
  title,
  timeframe,
  lineData,
  setTimeframe,
  options,
}) => {
  const timeframes = ['12 months', '30 days', '7 days', '24 hours'];

  return (
    <div className="bg-primaryColor-50 p-5 rounded-md mt-7 mb-7">
      <div className="mt-6 text-center">
        <Title
          title={title}
          textColor="text-black"
          fontWeight="font-semibold"
          bgStyle={false}
        />
      </div>
      <div className="relative flex justify-center gap-4 mb-4 top-3">
        {timeframes.map((option) => (
          <button
            key={option}
            type="button"
            onClick={() => setTimeframe(option)}
            className={`px-2 md:px-4 py-2 rounded text-xs md:text-base drop-shadow-lg ${
              timeframe === option
                ? 'bg-[#ffffff] text-primaryColor-600'
                : 'bg-gray-200'
            }`}
          >
            {option}
          </button>
        ))}
      </div>
      <div className="mt-8">
        <Line data={lineData(timeframe)} height={400} options={options} />
      </div>
    </div>
  );
};

export default A_LineChartSection;
