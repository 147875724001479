import React from "react";
import { Main, Title } from "../../common";
import { M_BlacklistTable } from "../../Tables";

const M_BlacklistPage: React.FC = () => {
  return (
    <div>
      <Title title={"Blacklist"} />
      {/* main container  */}
      <Main>
        {/* all Blacklist table  */}
        <M_BlacklistTable />
      </Main>
    </div>
  );
};

export default M_BlacklistPage;
