import React, { useState } from "react";
import {
  AssignEmployeDropdown,
  AssignEmployeeButton,
  Main,
  Title,
} from "../../common";
import {
  AssignCollector,
  AssignCollectorManager,
  AssignManager,
} from "./views";

const A_AssignEmployeePage: React.FC = () => {
  const [view, setView] = useState<
    "Assign Collector" | "Assign Manager" | "Assign Collector & Manager"
  >("Assign Collector");

  return (
    <div>
      {/* main title  */}
      <Title title={"Assign Employee"} />

      {/* main content  */}
      <Main>
        {/* navigate dropdown for mobile view */}
        <div className="-mb-5 sm:hidden">
          <AssignEmployeDropdown
            options={[
              "Assign Collector",
              "Assign Manager",
              "Assign Collector & Manager",
            ]}
            selectedOption={view} // Set selected option to the current view
            onOptionSelect={(option: string) => setView(option as typeof view)} // Update view when option is selected
          />
        </div>

        {/* navigate buttons for larger screens */}
        <div className="flex justify-center max-sm:hidden">
          <AssignEmployeeButton
            label="Assign Collector"
            value="Assign Collector"
            currentView={view}
            onClick={() => setView("Assign Collector")}
          />
          <AssignEmployeeButton
            label="Assign Manager"
            value="Assign Manager"
            currentView={view}
            onClick={() => setView("Assign Manager")}
          />
          <AssignEmployeeButton
            label="Assign Collector & Manager"
            value="Assign Collector & Manager"
            currentView={view}
            onClick={() => setView("Assign Collector & Manager")}
          />
        </div>

        {/* Content */}
        <div className="mt-5">
          {view === "Assign Collector" ? (
            <AssignCollector />
          ) : view === "Assign Manager" ? (
            <AssignManager />
          ) : view === "Assign Collector & Manager" ? (
            <AssignCollectorManager />
          ) : null}
        </div>
      </Main>
    </div>
  );
};

export default A_AssignEmployeePage;
