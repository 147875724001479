import React, { useEffect, useState } from "react";
import {
  Description,
  Dropdown,
  Input,
  LoanAmountDetails,
  RadioButton,
} from "../../../common";
import { loanUpdateTypes } from "../../../../types";
import { CiEdit } from "react-icons/ci";
import { MdDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import {
  getIntrestRates,
  getLoanDurations,
  updateIntrestRate,
} from "../../../../api/loan";
import { calculateInterest } from "../../../../api/calculation";
import { LoanDetailsSchema } from "../../../../lib/validation/loanSchema";

const LoanDetails: React.FC = () => {
  const [loanDetails, setLoanDetails] = useState<loanUpdateTypes>();
  const [inputs, setInputs] = useState({
    netMonthlySalary: {
      value: "",
      disabled: true,
      error: "",
    },
    loanAmount: {
      value: "",
      disabled: true,
      error: "",
    },
    repaymentFrequency: {
      value: "",
      disabled: true,
      error: "",
    },
    numberOfInstallments: {
      value: "",
      disabled: true,
      error: "",
    },
    startDate: {
      value: "",
      disabled: true,
      error: "",
    },
    endDate: {
      value: "",
      disabled: true,
      error: "",
    },
  });
  const [activeField, setActiveField] = useState<keyof typeof inputs | null>(
    null
  );
  const [previousValue, setPreviousValue] = useState<string | null>(null);
  const [isRadioEditMode, setIsRadioEditMode] = useState(false);
  const [loanDurations, setLoanDurations] = useState({
    day: "",
    week: "",
    month: "",
  });
  const [getTotalInterestAmount, setGetTotalInterestAmount] =
    useState<string>();
  const [getInstallmentAmount, setGetInstallMentAmount] = useState<string>();
  const [getNewEndDate, setGetNewEndDate] = useState<string>();
  const [interestRates, setInterestRates] = useState<{ rate: number }[]>([]);
  const [slectedIntrestRate, setSelectedIntrestRate] = useState(0);
  const [slectedIntrest, setSelectedIntrest] = useState("");

  // Fetch customer data from localStorage and combine when the component mounts
  useEffect(() => {
    const storedData1 = localStorage.getItem("customerRegisterFormData3");
    const newInterestRate = localStorage.getItem("newInterestRate");

    setSelectedIntrestRate(Number(newInterestRate) || 0);

    if (storedData1) {
      const parsedData1 = storedData1 ? JSON.parse(storedData1) : {};

      // Merge both objects into a single customerData object
      const combinedData = { ...parsedData1 };
      setLoanDetails(combinedData);
    }
  }, []);

  // Update inputs state when collateralDetails changes
  useEffect(() => {
    if (loanDetails) {
      setInputs({
        netMonthlySalary: {
          value: loanDetails.netMonthlySalary || "",
          disabled: true,
          error: "",
        },
        loanAmount: {
          value: loanDetails.loanAmount || "",
          disabled: true,
          error: "",
        },
        repaymentFrequency: {
          value: loanDetails.repaymentFrequency || "",
          disabled: true,
          error: "",
        },
        numberOfInstallments: {
          value: loanDetails.numberOfInstallments || "",
          disabled: true,
          error: "",
        },
        startDate: {
          value: loanDetails.startDate || "",
          disabled: true,
          error: "",
        },
        endDate: {
          value: loanDetails.endDate || "",
          disabled: true,
          error: "",
        },
      });
    }
  }, [loanDetails]);

  // Handle input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof typeof inputs
  ) => {
    const { value } = e.target;

    // Validate the specific field using Zod schema
    const fieldSchema = LoanDetailsSchema.shape[field];
    const result = fieldSchema.safeParse(value);

    setInputs((prev) => ({
      ...prev,
      [field]: {
        value,
        error: result.success
          ? "" // No error
          : result.error.issues[0]?.message || "Invalid input",
      },
    }));
  };

  const handleEditClick = (field: keyof typeof inputs) => {
    if (activeField && activeField !== field) {
      handleSaveOrCancel(activeField, false);
    }

    // Set previousValue to the current value before editing
    setPreviousValue(inputs[field].value);
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: { ...prevInputs[field], disabled: false },
    }));
    setActiveField(field);
  };

  const handleSaveOrCancel = (field: keyof typeof inputs, save: boolean) => {
    if (save) {
      handleSave(field);
    } else {
      handleCancel(field);
    }
  };

  const handleSave = (field: keyof typeof inputs) => {
    handleLoanCulculation();
    const fieldSchema = LoanDetailsSchema.shape[field];
    const validationResult = fieldSchema.safeParse(inputs[field].value);

    if (!validationResult.success) {
      // If validation fails, show the error
      setInputs((prevInputs) => ({
        ...prevInputs,
        [field]: {
          ...prevInputs[field],
          error: validationResult.error.issues[0]?.message,
        },
      }));
      return;
    }

    // If validation passes, save the data
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: { ...prevInputs[field], disabled: true, error: "" },
    }));

    const existingData = localStorage.getItem("customerRegisterFormData3");
    let updatedData = existingData ? JSON.parse(existingData) : {};

    updatedData = {
      ...updatedData,
      netMonthlySalary: inputs.netMonthlySalary.value,
      loanAmount: inputs.loanAmount.value,
      repaymentFrequency: inputs.repaymentFrequency.value,
      numberOfInstallments: inputs.numberOfInstallments.value,
      startDate: inputs.startDate.value,
      endDate: inputs.endDate.value,
    };

    localStorage.setItem(
      "customerRegisterFormData3",
      JSON.stringify(updatedData)
    );

    setActiveField(null);
    setPreviousValue(null);
  };

  const handleCancel = (field: keyof typeof inputs) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: {
        ...prevInputs[field],
        value: previousValue || loanDetails?.[field] || "",
        disabled: true,
        error: "",
      },
    }));

    setActiveField(null);
    setPreviousValue(null);
  };

  const handleRadioChange = (newValue: string) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      repaymentFrequency: {
        ...prevInputs.repaymentFrequency,
        value: newValue,
      },
    }));
  };

  const handleRadioEditClick = () => {
    setIsRadioEditMode(true);
  };

  const handleRadioSaveClick = () => {
    // Get the updated number of installments based on the selected repayment frequency
    let newInstallments = "";
    if (inputs.repaymentFrequency.value === "DAILY") {
      newInstallments = loanDurations.day;
    } else if (inputs.repaymentFrequency.value === "WEEKLY") {
      newInstallments = loanDurations.week;
    } else if (inputs.repaymentFrequency.value === "MONTHLY") {
      newInstallments = loanDurations.month;
    }

    // Retrieve existing data from localStorage
    const existingData = localStorage.getItem("customerRegisterFormData3");
    const parsedData = existingData ? JSON.parse(existingData) : {};

    // Ensure all fields have default values if they are undefined
    const updatedData = {
      ...parsedData,
      repaymentFrequency: inputs.repaymentFrequency.value,
      numberOfInstallments: String(newInstallments),
    };

    // Save the updated data back to localStorage
    localStorage.setItem(
      `customerRegisterFormData3`,
      JSON.stringify(updatedData)
    );

    setLoanDetails(updatedData);
    setIsRadioEditMode(false);
  };

  const handleRadioCancelClick = () => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      repaymentFrequency: {
        ...prevInputs.repaymentFrequency,
        value: loanDetails?.repaymentFrequency || "",
      },
    }));
    setIsRadioEditMode(false);
  };

  // get loan durations
  useEffect(() => {
    const handleGetLoanDurations = async () => {
      const token = localStorage.getItem("token") || "";
      try {
        const response = await getLoanDurations(token);
        setLoanDurations(response[0]);
      } catch (error) {
        console.log(error);
      }
    };
    handleGetLoanDurations();
  }, []);

  const handleLoanCulculation = async () => {
    const requestBody = {
      repaymentFrequency: loanDetails?.repaymentFrequency || "",
      loanAmount: Number(inputs.loanAmount.value || loanDetails?.loanAmount),
      duration: Number(loanDetails?.numberOfInstallments),
    };
    try {
      const response = await calculateInterest(requestBody);

      const existingData = localStorage.getItem("customerRegisterFormData3");
      const parsedData = existingData ? JSON.parse(existingData) : {};

      const updatedData = {
        ...parsedData,
        endDate: response.data.endDate,
        startDate: response.data.startDate,
        getInterestAmount:
          String(response.data.totalInterest) || loanDetails?.getInterestAmount,
        getinstallmentAmount:
          String(response.data.installmentAmount) ||
          loanDetails?.getinstallmentAmount,
      };
      setGetTotalInterestAmount(String(response.data.totalInterest.toFixed(2)));
      setGetInstallMentAmount(
        String(response.data.installmentAmount.toFixed(2))
      );
      setGetNewEndDate(response.data.endDate);
      localStorage.setItem(
        `customerRegisterFormData3`,
        JSON.stringify(updatedData)
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Function to fetch interest rates from API
  useEffect(() => {
    const handleGetInterestRates = async () => {
      const token = localStorage.getItem("token") || "";
      try {
        const response = await getIntrestRates(token);
        if (response) {
          setInterestRates(response.data);
        } else {
          console.log("No interest rates available");
        }
      } catch (error) {
        console.error("Failed to fetch interest rates", error);
      }
    };

    handleGetInterestRates();
  }, []);

  useEffect(() => {
    handleLoanCulculation();
  }, [loanDetails]);

  const handleInterestRateSelect = (selectedRate: string) => {
    setSelectedIntrestRate(Number(selectedRate));
    setSelectedIntrest(selectedRate);
    localStorage.setItem("newInterestRate", selectedRate);
  };

  // handle update interest rate
  const handleUpdateIntrestRate = async () => {
    const token = localStorage.getItem("token") || "";
    const newRate = slectedIntrest || "";

    console.log("slectedIntrestRate :", Number(newRate));

    try {
      const resposne = await updateIntrestRate(token, Number(newRate));
      if (resposne === "Regular rate updated successfully.") {
        handleLoanCulculation();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (slectedIntrest) {
      handleUpdateIntrestRate();
    }
  }, [slectedIntrestRate]);

  return (
    <div>
      {/*Gross Monthly Income  */}
      <Input
        id={"netMonthlySalary"}
        name={"netMonthlySalary"}
        type="number"
        placeholder={"දළ මාසික ආදායම (රු) "}
        label="දළ මාසික ආදායම (රු)*"
        width="sm:w-[50%]"
        disabled={inputs.netMonthlySalary.disabled}
        value={inputs.netMonthlySalary.value}
        onChange={(e) => handleInputChange(e, "netMonthlySalary")}
        backIcon={
          <CiEdit
            className="mt-5 cursor-pointer lg:mt-6"
            onClick={() => handleEditClick("netMonthlySalary")}
          />
        }
        saveCancel={!inputs.netMonthlySalary.disabled}
        saveOnclick={() => handleSave("netMonthlySalary")}
        cancelOnclick={() => handleCancel("netMonthlySalary")}
        errorMessage={inputs.netMonthlySalary.error}
        restrictSpaces
      />
      {/* job  */}
      <Input
        id={"loanAmount"}
        name={"loanAmount"}
        type="number"
        placeholder={"ඉල්ලුම් කරන ණය මුදල (රු) "}
        label="ඉල්ලුම් කරන ණය මුදල (රු)*"
        width="sm:w-[50%]"
        disabled={inputs.loanAmount.disabled}
        value={inputs.loanAmount.value}
        onChange={(e) => handleInputChange(e, "loanAmount")}
        backIcon={
          <CiEdit
            className="mt-5 cursor-pointer lg:mt-6"
            onClick={() => handleEditClick("loanAmount")}
          />
        }
        saveCancel={!inputs.loanAmount.disabled}
        saveOnclick={() => handleSave("loanAmount")}
        cancelOnclick={() => handleCancel("loanAmount")}
        errorMessage={inputs.loanAmount.error}
        restrictSpaces
      />

      {/* interest rate  */}
      <div className="sm:w-[50%]  ">
        <Dropdown
          label="පොලී අනුපාතය*"
          placeholder="පොලී අනුපාතය තෝරන්න"
          options={interestRates.map((rateObj) => `${rateObj.rate}`)}
          selectedOption={slectedIntrestRate ? `${slectedIntrestRate}%` : ""}
          onOptionSelect={handleInterestRateSelect}
          fontWeight="font-bold"
        />
      </div>

      {/* select payment plan  */}
      <div>
        <div className="flex">
          <div className="mr-3">
            <Description content="ගෙවීම් සැලසුම*" fontWeight="font-bold" />
          </div>{" "}
          {/* edit button  */}
          <div>
            {!isRadioEditMode && (
              <CiEdit
                className="mt-[2px] cursor-pointer text-h5 text-primaryColor-500"
                onClick={handleRadioEditClick}
              />
            )}
            {isRadioEditMode && (
              <div className={` flex  gap-5 text-h4`}>
                <span
                  className={`text-green-600 cursor-pointer`}
                  onClick={handleRadioSaveClick}
                >
                  <MdDone />
                </span>
                <span
                  className={`text-red-600 cursor-pointer`}
                  onClick={handleRadioCancelClick}
                >
                  <RxCross2 />
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-wrap gap-5 mt-3 lg:gap-10">
          <RadioButton
            id={"dailyInstallments"}
            label={"දින වාරික"}
            checked={inputs.repaymentFrequency.value === "DAILY"}
            disabled={!isRadioEditMode}
            onChange={() => handleRadioChange("DAILY")}
          />
          <RadioButton
            id={"weeklyInstallments"}
            label={"සති වාරික"}
            checked={inputs.repaymentFrequency.value === "WEEKLY"}
            disabled={!isRadioEditMode}
            onChange={() => handleRadioChange("WEEKLY")}
          />
          <RadioButton
            id={"monthlyInstallments"}
            label={"මාසික වාරික"}
            checked={inputs.repaymentFrequency.value === "MONTHLY"}
            disabled={!isRadioEditMode}
            onChange={() => handleRadioChange("MONTHLY")}
          />
        </div>
      </div>

      {/* loan Number of installments */}
      <div className="mt-3 ">
        <Input
          id={"numberOfInstallments"}
          name={"numberOfInstallments"}
          placeholder={`ණය ගෙවිය යුතු වාරික ගණන`}
          label={`ණය ගෙවිය යුතු වාරික ගණන`}
          width="sm:w-[50%]"
          value={loanDetails?.numberOfInstallments}
          disabled
        />
      </div>

      <div className="grid mt-5 md:gap-3 md:grid-cols-2">
        {/* loan Start Date  */}
        <Input
          type="text"
          id={"loanStartDate"}
          name={"loanStartDate"}
          placeholder={"ගෙවීම් ආරම්භක දිනය"}
          label="ගෙවීම් ආරම්භක දිනය*"
          value={loanDetails?.startDate}
          disabled
        />
        {/* loan end  Date  */}
        <Input
          type="text"
          id={"loanEndDate"}
          name={"loanEndDate"}
          placeholder={"ගෙවීම් අවසාන දිනය"}
          label="ගෙවීම් අවසාන දිනය*"
          value={getNewEndDate || loanDetails?.endDate}
          disabled
        />
      </div>
      {/* loan amount details  */}
      <div>
        <div className="grid sm:gap-3 sm:grid-cols-2">
          {/* Number of installments */}
          <LoanAmountDetails
            content={"වාරික ප්‍රමාණය"}
            number={loanDetails?.numberOfInstallments || ""}
          />
          {/* Total Interest (Rs.) */}
          <LoanAmountDetails
            content={"මුලු පොලිය"}
            number={getTotalInterestAmount || ""}
          />
        </div>
        {/* installments Amount (Rs.) */}
        <LoanAmountDetails
          content={"වාරික මුදල (රු)"}
          number={getInstallmentAmount || ""}
        />
      </div>
    </div>
  );
};

export default LoanDetails;
