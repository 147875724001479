import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa"; // Importing an icon from react-icons
import Description from "../text/Description";

interface DropdownProps {
  options: string[];
  label?: string;
  width?: string;
  selectedOption: string;
  placeholder?: string;
  onOptionSelect: (option: string) => void;
  errorMessage?: string;
  disabled?: boolean;
  buttonIcon?: React.ReactNode;
  onEnableDropdown?: (isEnabled: boolean) => void;
  fontSize?: string;
  fontColor?:
    | "text-primaryColor-900"
    | "text-primaryColor-200"
    | "text-gray-400";
}

const AssignEmployeDropdown: React.FC<DropdownProps> = ({
  options,
  label,
  width = "w-[100%]",
  selectedOption,
  placeholder,
  onOptionSelect,
  errorMessage,
  disabled = false,
  buttonIcon,
  onEnableDropdown,
  fontSize,
  fontColor,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDisabled, setIsDisabled] = useState(disabled);
  const inputRef = useRef<HTMLInputElement>(null);

  // Sync isDisabled state with disabled prop
  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option: string) => {
    onOptionSelect(option);
    setIsOpen(false);
  };

  const handleEnableButtonClick = () => {
    if (onEnableDropdown) {
      onEnableDropdown(false);
    }
    setIsDisabled(false);

    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isDisabled) {
        if (
          inputRef.current &&
          !inputRef.current.contains(event.target as Node)
        ) {
          setIsDisabled(true);
          if (onEnableDropdown) {
            onEnableDropdown(true);
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDisabled, onEnableDropdown]);

  return (
    <div
      className={`relative inline-block mb-4 text-left font-poppins ${width}`}
      ref={dropdownRef}
    >
      {label && (
        <Description
          content={label}
          fontSize={fontSize}
          fontColor={fontColor}
        />
      )}

      <div className="flex flex-row items-center justify-between rounded-md shadow-sm mt-[3px] bg-primaryColor-500 text-sm font-normal text-white focus:outline-none focus:ring-2 focus:ring-primaryColor-300">
        <button
          type="button"
          className={`inline-flex justify-between items-center ${width} px-4 py-[7px] lg:py-[12px] ${
            isDisabled ? "cursor-not-allowed " : ""
          }`}
          onClick={() => !isDisabled && setIsOpen(!isOpen)}
          disabled={isDisabled}
        >
          <span
            className={`font-normal ${
              selectedOption
                ? "text-white"
                : "text-primaryColor-200 "
            }`}
          >
            {selectedOption || placeholder}
          </span>

          <div className="flex items-center">
            <FaChevronDown
              className={`w-3 h-3 text-white duration-300 ${
                isOpen && "rotate-180"
              }`}
            />
          </div>
        </button>
        {isDisabled && buttonIcon && (
          <button
            type="button"
            onClick={handleEnableButtonClick}
            className="z-50 w-3 h-3 mr-6 -mt-2 text-2xl text-primaryColor-300"
          >
            {buttonIcon}
          </button>
        )}
      </div>

      {isOpen && (
        <div
          className={`absolute left-0 z-40 ${width} max-h-40 overflow-y-auto mt-2 origin-top-right bg-white rounded-md shadow-lg ring-2 ring-primaryColor-900 ring-opacity-5 focus:outline-none`}
        >
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map((option) => (
              <button
                key={option}
                className={`block w-full text-left px-4 py-2 text-h8 font-semibold ${
                  selectedOption === option
                    ? "bg-gray-100 text-primaryColor-700"
                    : "text-primaryColor-900 hover:bg-gray-100 "
                }`}
                onClick={() => handleOptionClick(option)}
                role="menuitem"
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* error message  */}
      {errorMessage && (
        <div className="font-normal text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[1px] ">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default AssignEmployeDropdown;
