import { useEffect, useState } from "react";
import { getCustomerByNic } from "../api/customer";
import { CustomerDataNew } from "../types";

export const useGetCustomerDetails = () => {
  const [customerDetails, setCustomerDetails] = useState<CustomerDataNew>();
  const [responseSuccess, setResponseSuccess] = useState(false);

  //   get customer Details using api
  const getCustomerDetails = async () => {
    const token = localStorage.getItem("token") || "";
    const nic = localStorage.getItem("nic") || "";
    if (nic) {
      try {
        const response = await getCustomerByNic(token, nic);
        setCustomerDetails(response.data);
        setResponseSuccess(response.success);
      } catch {
        console.log("error fetching customer details");
      }
    }
  };

  useEffect(() => {
    getCustomerDetails();
  }, [responseSuccess]);

  return { customerDetails, responseSuccess, getCustomerDetails };
};
