import React, { useState, useRef, useEffect } from "react";

interface OtpInputProps {
  otpLength: number;
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string;
}

const OtpInput: React.FC<OtpInputProps> = ({ otpLength, value, onChange, errorMessage }) => {
  const [otp, setOtp] = useState<string[]>(Array.from({ length: otpLength }, () => ""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(otpLength).fill(null));

  useEffect(() => {
    // Update the internal OTP state when the external value changes
    if (value.length === otpLength) {
      setOtp(value.split(""));
    }
  }, [value, otpLength]);

  const handleChange = (element: HTMLInputElement, index: number) => {
    if (isNaN(Number(element.value))) return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);
    onChange(newOtp.join("")); 

    // Move focus to the next input field if available
    if (index < otp.length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace") {
      e.preventDefault();

      // Clear current input and move focus to the previous input field if available
      if (index > 0 && inputRefs.current[index - 1]) {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        onChange(newOtp.join("")); 
        inputRefs.current[index - 1]?.focus();
      } else if (index === 0) {
        // Clear the first input field
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        onChange(newOtp.join("")); 
      }
    }
  };

  return (
    <div className="flex flex-col items-center space-y-2">
      <div className="flex space-x-2">
        {otp.map((data, index) => (
          <input
            key={index}
            className={`w-10 rounded-lg h-10 border-2 bg-primaryColor-50 outline-primaryColor-200 focus:outline-primaryColor-300 ${
              errorMessage ? "border-red-500" : ""
            } text-center text-lg`}
            type="text"
            name="otp"
            maxLength={1}
            value={data}
            onChange={(e) => handleChange(e.target as HTMLInputElement, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={(el) => (inputRefs.current[index] = el)}
          />
        ))}
      </div>
      {errorMessage && (
        <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
      )}
    </div>
  );
};

export default OtpInput;
