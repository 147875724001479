import React, { useEffect, useState } from "react";
import {
  A_AnaliticsOverview,
  C_AnaliticsOverview,
  Loading,
  M_AnaliticsOverview,
  NotFound,
} from "../components/page";
import { getCurrentUser } from "../api/auth";

const AnaliticsDasboard: React.FC = () => {
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token") || "";
      try {
        const userData = await getCurrentUser(token);

        // get user role
        setUserRole(userData.data.role);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUserRole(""); // Reset userRole to trigger NotFound component
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div>
        {/* loading screen */}
        <Loading />
      </div>
    );
  }

  switch (userRole) {
    case "ADMIN":
      return <A_AnaliticsOverview />;
    case "MANAGER":
      return <M_AnaliticsOverview />;
    case "COLLECTOR":
      return <C_AnaliticsOverview />;
    default:
      return <NotFound />;
  }
};

export default AnaliticsDasboard;
