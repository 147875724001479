import React, { useEffect, useRef, useState } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import Button from "../buttons/Button";
import toast from "react-hot-toast";

// Import Swiper and required modules
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { IoMdCloseCircle } from "react-icons/io";

interface ImageOverviewModalProps {
  isOpen: boolean;
  disableUpload?: boolean;
  onClose: () => void;
  imageUrl?: string[] | null | string;
  buttonText?: string;
  onFileSelect?: (imagesUrl: string[] | null) => void;
}

const CollateralImageOverviewModal: React.FC<ImageOverviewModalProps> = ({
  isOpen,
  onClose,
  buttonText,
  imageUrl,
  onFileSelect,
  disableUpload = false,
}) => {
  const [uploadedImage, setUploadedImage] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (typeof imageUrl === "string") {
      setUploadedImage([imageUrl]); // Convert string to an array of strings
    } else if (Array.isArray(imageUrl)) {
      setUploadedImage(imageUrl);
    }
  }, [imageUrl]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (!files.length) return;

    // Check if file type is not png or jpg
    const invalidFiles = files.some(
      (file) =>
        !file.type.includes("image/png") && !file.type.includes("image/jpeg")
    );
    if (invalidFiles) {
      // Display toast and return
      toast.error("Please upload only PNG or JPEG files.");
      return;
    }

    if (uploadedImage.length + files.length > 5) {
      toast.error("You can upload up to 5 images.");
      return;
    }

    const newPreviews: string[] = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        newPreviews.push(base64String);

        // Once all files have been read
        if (newPreviews.length === files.length) {
          const updatedPreviews = [...uploadedImage, ...newPreviews];
          setUploadedImage(updatedPreviews);

          // Pass all preview images to onFileSelect
          if (onFileSelect) {
            onFileSelect(updatedPreviews);
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveFile = (index: number) => {
    const newPreviewImages = uploadedImage.filter((_, i) => i !== index);

    setUploadedImage(newPreviewImages);
    if (onFileSelect) {
      onFileSelect(newPreviewImages);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative overflow-hidden bg-[#E7F0EE] w-[100%] sm:max-w-[80%] md:w-[500px] xl:w-[600px] shadow-3xl rounded-lg">
        <div className="absolute flex gap-5 top-3 right-3">
          {!disableUpload && (
            <button className="text-[#0A6C55]" onClick={handleEditClick}>
              <FiEdit className="w-5 h-5 md:w-6 md:h-6" />
            </button>
          )}
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            className="hidden"
            onChange={handleFileChange}
          />
          <button className="text-[#0A6C55] text-xl" onClick={onClose}>
            <FaRegWindowClose className="w-5 h-5 rounded-md md:w-6 md:h-6" />
          </button>
        </div>
        <div className="px-4 py-2 mt-10 mb-2">
          {uploadedImage.length ? (
            <div className="flex justify-center">
              <div className="relative w-[300px] h-[310px] sm:h-[350px] flex items-center justify-center  ">
                {/* Swiper for displaying multiple images */}
                <Swiper
                  spaceBetween={5}
                  navigation={true}
                  modules={[Navigation]}
                  className="self-center custom-swiper"
                >
                  {uploadedImage.map((imgUrl, index) => (
                    <SwiperSlide key={index} className="my-auto ">
                      <img
                        src={imgUrl}
                        alt={`Uploaded ${index}`}
                        className="object-cover w-full h-full   max-h-[300px]"
                      />
                      {!disableUpload && (
                        <button
                          className="absolute top-[2px] -right-[2px] flex items-center justify-center text-[#0A6C55]"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveFile(index);
                          }}
                        >
                          <IoMdCloseCircle className="w-5 h-5" />
                        </button>
                      )}
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <style>{`
        /* Custom styles for Swiper arrows */
        .custom-swiper .swiper-button-next,
        .custom-swiper .swiper-button-prev {
          color: #0A6C55; /* Set the color to red */
          
        }
      .custom-swiper .swiper-button-next::after,
  .custom-swiper .swiper-button-prev::after {
    font-size: 30px; /* Adjust size of the arrow itself */
  }

        .custom-swiper .swiper-button-next:hover,
        .custom-swiper .swiper-button-prev:hover {
          color: #0A6C55; /* Change to green on hover */
          
        }
      `}</style>
            </div>
          ) : (
            <div className="flex justify-center">
              <div className="relative w-auto h-[300px] sm:h-[350px]">
                <img
                  src={"/images/imageUpload.png"}
                  alt="Placeholder"
                  className="w-full h-full mx-auto mb-4"
                />
              </div>
            </div>
          )}
          {buttonText && (
            <div className="flex justify-center mt-3">
              <Button text={buttonText} onClick={onClose} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollateralImageOverviewModal;
