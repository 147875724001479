import React, { useRef, useState } from "react";
import { Modal } from "antd";
import html2canvas from "html2canvas";
import { Button, Description, Logo } from "../common";
import { FiDownload } from "react-icons/fi";

interface CustomerPasswordProps {
  visible: boolean;
  onClose: () => void;
  closeModal: () => void;
  customerNIC: string;
  customerUserNameValue: string;
  customerPassword: string;
}

const CustomerPassword: React.FC<CustomerPasswordProps> = ({
  visible,
  customerUserNameValue,
  onClose,
  closeModal,
  customerNIC,
  customerPassword,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const downloadModalImage = async () => {
    setIsLoading(true);
    if (modalRef.current) {
      try {
        const canvas = await html2canvas(modalRef.current, {
          scale: 3,
          windowWidth: 600,
          windowHeight: 700,
          allowTaint: true,
          useCORS: true,
        });
        const imgData = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.download = `${customerNIC}-QR.png`;
        link.href = imgData;
        link.click();
      } catch (error) {
        console.error("Download failed:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      closable={true}
      maskClosable={false}
      centered={true}
    >
      <div ref={modalRef} className="p-4 px-3 mt-3">
        <div className="p-5 bg-gray-200 w-[100%]" />
        <div className="">
          {/* company details  */}
          <div className="">
            <div className="-mb-2 -ml-2 ">
              <Logo width="w-52 " />
            </div>
            {/* phone number  */}
            <Description
              content="+94742678920, "
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor={"text-gray-400"}
            />
            {/* mail address  */}
            <Description
              content="info@credimanage.com, "
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor={"text-gray-400"}
            />
            {/* address  */}
            <Description
              content="26, ඉසුරු නිවස, "
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor={"text-gray-400"}
            />
            <Description
              content="කෝන්කැටියාර සූරියවැව"
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor={"text-gray-400"}
            />
          </div>
          <div className="border border-gray-100 w-[100%] my-3" />
          {/* customer user name  */}
          <div className="flex justify-between">
            <Description
              content={"පරිශීලක නාමය"}
              fontColor="text-black"
              fontWeight="font-bold"
              fontSize="text-h6"
            />
            <Description
              content={customerUserNameValue}
              fontColor="text-black"
              fontWeight="font-bold"
              fontSize="text-h6"
            />
          </div>

          {/* customer password  */}
          <div className="flex justify-between">
            <Description
              content={"මුරපදය"}
              fontColor="text-black"
              fontWeight="font-bold"
              fontSize="text-h6"
            />
            <Description
              content={customerPassword}
              fontColor="text-black"
              fontWeight="font-bold"
              fontSize="text-h6"
            />
          </div>
        </div>
        <div className="p-5 bg-gray-200 w-[100%] mt-4" />
      </div>

      <div className="flex items-center justify-center w-full gap-3 mt-3">
        <Button
          text="Download"
          onClick={downloadModalImage}
          iconPosition="after"
          icon={<FiDownload />}
          isLoading={isLoading}
          disabled={isLoading}
          width="w-36 lg:w-44"
        />
        <Button
          text="Done"
          variant="secondary"
          onClick={closeModal}
          disabled={isLoading}
          width="w-36 lg:w-44"
        />
      </div>
    </Modal>
  );
};

export default CustomerPassword;
