import { z } from "zod";

export const customerUpdateSchema = z.object({
  fullName: z
    .string()
    .min(3, "Must be at least 3 characters long")
    .nonempty("Required.")
    .regex(/^[A-Za-z\u0D80-\u0DFF\s]+$/, "Only letters are allowed"),
  address: z.string().nonempty("Required."),
  nic: z
    .string()
    .min(10, "Must be at least 10 characters long")
    .max(12, "Must be at most 12 characters long")
    .nonempty("Required."),
  policeStation: z.string().nonempty("Required."),
  gramaDivision: z.string().nonempty("Required."),
  provincialOffice: z.string().nonempty("Required."),
  companyName: z.string().nonempty("Required."),
  occupation: z.string().nonempty("Required."),
  companyAddress: z.string().nonempty("Required."),
  email: z.string().email("Invalid format").nonempty("Required."),
  phoneNumber: z
    .string()
    .min(10, "Must be a valid 10-digit number")
    .max(10, "Must be a valid 10-digit number")
    .nonempty("Required."),
  whatsAppNumber: z
    .string()
    .min(10, "Must be a valid 10-digit number")
    .max(10, "Must be a valid 10-digit number")
    .nonempty("Required."),
  optionalContactNumber: z
    .string()
    .min(10, "Must be a valid 10-digit number")
    .max(10, "Must be a valid 10-digit number")
    .nonempty("Required."),
  netMonthlySalary: z.string().nonempty("Required."),
  frontImageURL: z.string().nonempty("Required."),
  backImageURL: z.string().nonempty("Required."),
  customerImageURL: z.string().nonempty("Required."),
  electricityBillImageURL: z.string().nonempty("Required."),
});
