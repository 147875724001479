import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { LuLayoutDashboard } from 'react-icons/lu';
import { FiUserPlus, FiMenu } from 'react-icons/fi';
import { MdLogout } from 'react-icons/md';
import { CollapsibleButton, CustomButton, Logo } from '../../common';
import { useNavigate } from 'react-router-dom';
import { GoPeople } from 'react-icons/go';
import { MdOutlinePayment } from 'react-icons/md';
import { IoAnalyticsOutline } from 'react-icons/io5';
import { RiCalendarScheduleLine } from 'react-icons/ri';
import { VscDebugConsole } from 'react-icons/vsc';

const A_Sidebar: React.FC = () => {
  const navigate = useNavigate(); // Hook to navigate between routes

  // State to control sidebar open/close status
  const [isOpen, setIsOpen] = useState(false);
  // State to track the active button in the sidebar
  const [activeButton, setActiveButton] = useState<string | null>(null);
  // State to manage the open/close status of collapsible sections
  const [openSection, setOpenSection] = useState<string | null>(null);
  // Reference to the sidebar element for detecting clicks outside
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  // Effect to set the default active button and section from localStorage when component mounts
  useEffect(() => {
    const storedActiveButton = localStorage.getItem('activeButton');
    const storedOpenSection = localStorage.getItem('openSection');

    // If there's no stored active button, set the default as "Quick actions"
    if (storedActiveButton) {
      setActiveButton(storedActiveButton);
    } else {
      setActiveButton('Quick actions'); // Default active button
      localStorage.setItem('activeButton', 'Quick actions');
    }

    if (storedOpenSection) {
      setOpenSection(storedOpenSection);
    } else {
      setOpenSection('Dashboard'); // Default open section
      localStorage.setItem('openSection', 'Dashboard');
    }
  }, []);

  // Effect to handle scrolling on body element
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  // Function to handle navigation and set active button
  const handleNavigation = (route: string, button: string) => {
    navigate(route);
    setIsOpen(false);
    setActiveButton(button);
    localStorage.setItem('activeButton', button); // Store active button in localStorage
  };

  // Function to toggle sidebar open/close status
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle sign out logic
  const handleLogOut = () => {
    // Clear localStorage
    localStorage.clear();

    // Navigate to the home page and clear the history stack
    navigate('/', { replace: true });
    // Force a page reload to reset the history
    window.location.reload();
  };

  // Function to handle clicks on collapsible sections
  const handleCollapsibleClick = (section: string) => {
    const newOpenSection = openSection === section ? null : section;
    setOpenSection(newOpenSection);
    localStorage.setItem('openSection', newOpenSection || ''); // Store open section in localStorage
  };

  // Effect to handle closing the sidebar when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle navigate dashboard
  const navigateAdminDashboard = () => {
    navigate('/dashboard');
    setIsOpen(false);
    setActiveButton('Quick actions');
    localStorage.setItem('activeButton', 'Quick actions');
  };

  const navigateAnalytics = () => {
    navigate('/analitics-dashboard');
    setIsOpen(false);
    setActiveButton('Analytics');
    localStorage.setItem('activeButton', 'Analytics');
  };

  // Handle navigation functions for each route
  const navigateEmployeeReg = () =>
    handleNavigation('/user-register', 'Add Employee');
  const navigateAssignEmployee = () =>
    handleNavigation('/assign-employee', 'Assign Employee');
  const navigateCustomerReg = () =>
    handleNavigation('/customer-register', 'Customer Registration');
  const navigateCustomerlist = () =>
    handleNavigation('/customer-list', 'Customer List');
  const navigateEmployeeList = () =>
    handleNavigation('/employee-list', 'Employees List');
  const navigateAddPayment = () =>
    handleNavigation('/add-payment', 'Add Payments');
  const navigatePaymentList = () =>
    handleNavigation('/payment-list', 'View Payments');
  const navigateEmployeePerformance = () =>
    handleNavigation('/system-settings', 'System Settings');
  const navigateGuaranteeList = () =>
    handleNavigation('/guarantee-list', 'Guarantee List');
  const navigateCollateralList = () =>
    handleNavigation('/collateral-list', 'Collateral List');
  const navigateLoanList = () => handleNavigation('/loan-list', 'Loan List');
  const navigateBlacklist = () => handleNavigation('/blacklist', 'Blacklist');
  const navigateConsoleLog = () => handleNavigation('/console-log', 'console');
  const navigateScheduleList = () =>
    handleNavigation('/schedule-list', 'Schedule List');
  const navigateCalendar = () =>
    handleNavigation('/schedule-calender', 'Calander');
  const navigateApproveRelease = () =>
    handleNavigation('/approve-release', 'Approve And Release');

  return (
    <div className='flex'>
      {/* Button to toggle sidebar */}
      <button onClick={toggleSidebar} className='p-3 text-h4 md:text-h3 '>
        {isOpen ? <AiOutlineClose /> : <FiMenu />}
      </button>

      {/* Sidebar container */}
      <div
        ref={sidebarRef}
        className={`fixed top-0 w-[100%] xxs:max-w-72 left-0 h-screen bg-primaryColor-50 shadow-3xl transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}>
        {/* Sidebar header with title and close button */}
        <div className='flex items-center justify-between p-4'>
          <div className={`  ${isOpen ? '' : 'hidden'}`}>
            <Logo />
          </div>
          <button
            onClick={toggleSidebar}
            className='p-1 -mt-12 -mr-1 text-xl duration-300 rounded-lg hover:shadow-inner hover:bg-gray-200'>
            <AiOutlineClose className='duration-300 hover:rotate-90 ' />
          </button>
        </div>

        {/* Sidebar navigation */}
        <nav className='mt-3 py-3 px-2 no-scrollbar overflow-y-auto h-[calc(100vh-160px)]'>
          {/* Dashboard button */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === 'Dashboard'}
            onClick={() => handleCollapsibleClick('Dashboard')}
            icon={<LuLayoutDashboard className='text-lg' />}
            text={'Dashboard'}
            isActive={activeButton === 'Dashboard'}>
            <div>
              <CustomButton
                isCollapsed={false}
                text={'Quick actions'}
                isActive={activeButton === 'Quick actions'}
                onClick={navigateAdminDashboard}
                icon={undefined}
              />

              <CustomButton
                isCollapsed={false}
                text={'Analytics'}
                isActive={activeButton === 'Analytics'}
                onClick={navigateAnalytics}
                icon={undefined}
              />
            </div>
          </CollapsibleButton>
          {/* Registration collapsible section */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === 'Customers'}
            onClick={() => handleCollapsibleClick('Customers')}
            icon={<FiUserPlus className='text-lg' />}
            text={'Customers'}
            isActive={activeButton === 'Customers'}>
            <div>
              <CustomButton
                isCollapsed={false}
                text={'Customer Registration'}
                isActive={activeButton === 'Customer Registration'}
                onClick={navigateCustomerReg}
                icon={undefined}
              />

              <CustomButton
                isCollapsed={false}
                text={'Approve And Release'}
                isActive={activeButton === 'Approve And Release'}
                onClick={navigateApproveRelease}
                icon={undefined}
              />

              <CustomButton
                isCollapsed={false}
                text={'Customer List'}
                icon={undefined}
                isActive={activeButton === 'Customer List'}
                onClick={navigateCustomerlist}
              />
            </div>
          </CollapsibleButton>

          {/* Employees collapsible section */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === 'Employees'}
            onClick={() => handleCollapsibleClick('Employees')}
            icon={<GoPeople className='text-lg' />}
            text={'Employees'}
            isActive={activeButton === 'Employees'}>
            <div>
              <CustomButton
                isCollapsed={false}
                text={'Add Employee'}
                icon={undefined}
                isActive={activeButton === 'Add Employee'}
                onClick={navigateEmployeeReg}
              />
              <CustomButton
                isCollapsed={false}
                text={'Employees List'}
                icon={undefined}
                isActive={activeButton === 'Employees List'}
                onClick={navigateEmployeeList}
              />
              <CustomButton
                isCollapsed={false}
                text={'Assign Employee'}
                icon={undefined}
                isActive={activeButton === 'Assign Employee'}
                onClick={navigateAssignEmployee}
              />
            </div>
          </CollapsibleButton>

          {/* Payments collapsible section */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === 'Payments'}
            onClick={() => handleCollapsibleClick('Payments')}
            icon={<MdOutlinePayment className='text-lg' />}
            text={'Payments'}
            isActive={activeButton === 'Payments'}>
            <div>
              <CustomButton
                isCollapsed={false}
                text={'Add Payments'}
                icon={undefined}
                isActive={activeButton === 'Add Payments'}
                onClick={navigateAddPayment}
              />
              <CustomButton
                isCollapsed={false}
                text={'View Payments'}
                icon={undefined}
                isActive={activeButton === 'View Payments'}
                onClick={navigatePaymentList}
              />
            </div>
          </CollapsibleButton>

          {/* Analytics button */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === 'analytics'}
            onClick={() => handleCollapsibleClick('analytics')}
            icon={<IoAnalyticsOutline className='text-lg' />}
            text={'Analytics'}
            isActive={activeButton === 'analytics'}>
            <div>
              <CustomButton
                isCollapsed={false}
                text={'System Settings'}
                icon={undefined}
                isActive={activeButton === 'System Settings'}
                onClick={navigateEmployeePerformance}
              />
              <CustomButton
                isCollapsed={false}
                text={'Guarantee List'}
                icon={undefined}
                isActive={activeButton === 'Guarantee List'}
                onClick={navigateGuaranteeList}
              />
              <CustomButton
                isCollapsed={false}
                text={'Collateral List'}
                icon={undefined}
                isActive={activeButton === 'Collateral List'}
                onClick={navigateCollateralList}
              />
              <CustomButton
                isCollapsed={false}
                text={'Loan List'}
                icon={undefined}
                isActive={activeButton === 'Loan List'}
                onClick={navigateLoanList}
              />
              <CustomButton
                isCollapsed={false}
                text={'Blacklist'}
                icon={undefined}
                isActive={activeButton === 'Blacklist'}
                onClick={navigateBlacklist}
              />
            </div>
          </CollapsibleButton>

          {/* shedules section */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === 'Schedules'}
            onClick={() => handleCollapsibleClick('Schedules')}
            icon={<RiCalendarScheduleLine className='text-lg' />}
            text={'Schedules'}
            isActive={activeButton === 'Schedules'}>
            <div>
              <CustomButton
                isCollapsed={false}
                text={'Schedule List'}
                icon={undefined}
                isActive={activeButton === 'Schedule List'}
                onClick={navigateScheduleList}
              />
              <CustomButton
                isCollapsed={false}
                text={'Calender'}
                icon={undefined}
                isActive={activeButton === 'Calander'}
                onClick={navigateCalendar}
              />
            </div>
          </CollapsibleButton>

          {/* Console log */}
          <CustomButton
            isCollapsed={false}
            text={'Console Log'}
            icon={<VscDebugConsole className='text-lg' />}
            isActive={activeButton === 'console'}
            onClick={navigateConsoleLog}
          />
          {/* Sign out button */}
          <CustomButton
            isCollapsed={false}
            text='Sign Out'
            icon={<MdLogout className='text-lg' />}
            isActive={activeButton === 'Log Out'}
            isSignOut={false}
            onClick={handleLogOut}
            fontWeight
          />
        </nav>
      </div>
    </div>
  );
};

export default A_Sidebar;
