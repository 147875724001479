import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Empty, Form, Table } from "antd";
import { getCollectorForAnalytics } from "../../../api/employee";

interface Item {
  collectorId: number;
  username: string;
  contactNumber: string;
  totalApprovedLoanAmount: number;
  totalPaymentsMade: number;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        ></Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const M_CollectorPerfoTable = () => {
  const [form] = Form.useForm();
  const [customersData, setCustomersData] = useState<Item[]>([]);

  const columns = [
    {
      title: "Collector",
      dataIndex: "username",
      align: "center" as const,
    },
    {
      title: "Disbursed (Rs.)",
      dataIndex: "totalApprovedLoanAmount",
      align: "center" as const,
    },
    {
      title: "Recovered Amount (Rs.)",
      dataIndex: "totalPaymentsMade",
      align: "center" as const,
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "number" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  // Fetch all collectors data
  useEffect(() => {
    const getEmployeeData = async () => {
      const token = localStorage.getItem("token") || "";
      const appUserId = Number(localStorage.getItem("userId"));

      try {
        const response = await getCollectorForAnalytics(token, appUserId);
        if (response && Array.isArray(response)) {
          const formattedData = response.map((item: Item) => ({
            ...item,
            key: item.collectorId,
          }));
          setCustomersData(formattedData);
        } else {
          console.error("Unexpected data format");
          setCustomersData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getEmployeeData();
  }, []);
  return (
    <div>
      {/* Table */}
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          columns={mergedColumns}
          dataSource={customersData}
          rowClassName="editable-row "
          className="font-semibold "
          locale={{
            emptyText: <Empty description={<span>No data available</span>} />,
          }}
          pagination={false}
          scroll={{ y: 240 }}
        />
      </Form>
    </div>
  );
};

export default M_CollectorPerfoTable;
