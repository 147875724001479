import React, { useEffect, useState } from "react";
import { Main } from "../../common";
import A_AnaliticsCardsSection from "./sections/A_AnaliticsCardsSection";
import A_ChartsSection from "./sections/A_ChartsSection";
import A_LineChartSection from "./sections/A_LineChartSection";
import { loanSummeryUserId } from "../../../api/loan";
import { Data, defaultData } from "./DefaultData";
import {
  getTotalLoanANdpaymentCurrentDaily,
  getTotalLoanANdpaymentCurrentMonthly,
  getTotalLoanANdpaymentCurrentWeekly,
} from "../../../api/summarry";

interface LegendData {
  label: string;
  value: string;
  color: string;
}
type PeriodType = "year" | "month" | "week" | "day";

interface BarData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
  }[];
}
interface PaymentPlanSummary {
  repaymentFrequency: "DAILY" | "WEEKLY" | "MONTHLY";
  count: number;
  totalRemainingAmount: number;
  percentage: number;
}
const A_AnaliticsOverview: React.FC = () => {
  const [data, setData] = useState<Data>(defaultData);
  const [timeframeDay, setTimeframeDay] = useState("12 months");
  const [timeframeWeek, setTimeframeWeek] = useState("12 months");
  const [timeframeMonth, setTimeframeMonth] = useState("12 months");
  const [getYearTotalLoanAmountsDaily, setGetYearTotalLoanAmountsDaily] =
    useState<{ totalLoanAmounts: number[]; totalPaymentsAmounts: number[] }>({
      totalLoanAmounts: [],
      totalPaymentsAmounts: [],
    });
  const [getYearTotalLoanAmountsWeekly, setGetYearTotalLoanAmountsWeekly] =
    useState<{ totalLoanAmounts: number[]; totalPaymentsAmounts: number[] }>({
      totalLoanAmounts: [],
      totalPaymentsAmounts: [],
    });
  const [getYearTotalLoanAmountsMonthly, setGetYearTotalLoanAmountsMonthly] =
    useState<{ totalLoanAmounts: number[]; totalPaymentsAmounts: number[] }>({
      totalLoanAmounts: [],
      totalPaymentsAmounts: [],
    });

  const [doughnutData, setDoughnutData] = useState({
    datasets: [
      {
        data: [],
        backgroundColor: ["#506F6B", "#5B9D8D", "#8EBBB1"],
        borderWidth: 1,
      },
    ],
    labels: [],
    totalDue: 0,
  });

  const [legendData, setLegendData] = useState<LegendData[]>([]);
  const [legendDataC, setCustomerLegendData] = useState<LegendData[]>([]);
  const [legendDoughnut, setLegendDoughnut] = useState<LegendData[]>([]);
  const [barData, setBarData] = useState<BarData>({
    labels: ["DAILY", "WEEKLY", "MONTHLY"],
    datasets: [
      {
        label: "Approved",
        data: [],
        backgroundColor: "#506F6B",
      },
      {
        label: "Pending",
        data: [],
        backgroundColor: "#5B9D8D",
      },
      {
        label: "Complete",
        data: [],
        backgroundColor: "#8EBBB1",
      },
      {
        label: "Rejected",
        data: [],
        backgroundColor: "#9AACAA",
      },
    ],
  });

  const [barDataC, setBarDataC] = useState<BarData>({
    labels: ["DAILY", "WEEKLY", "MONTHLY"],
    datasets: [
      {
        label: "Fully Paid",
        data: [],
        backgroundColor: "#506F6B",
      },
      {
        label: "Performing",
        data: [],
        backgroundColor: "#5B9D8D",
      },
      {
        label: "Overdue",
        data: [],
        backgroundColor: "#8EBBB1",
      },
      {
        label: "Legal Action",
        data: [],
        backgroundColor: "#9AACAA",
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token") || "";
      try {
        const fetchedData = await loanSummeryUserId(token);

        setData(fetchedData.data);

        setDoughnutData({
          datasets: [
            {
              data: fetchedData.data.paymentPlanSummary.map(
                (item: PaymentPlanSummary) => item.percentage
              ),
              backgroundColor: ["#506F6B", "#5B9D8D", "#8EBBB1"],
              borderWidth: 1,
            },
          ],
          labels: fetchedData.data.paymentPlanSummary.map(
            (item: PaymentPlanSummary) => item.repaymentFrequency
          ),
          totalDue: fetchedData.data.paymentPlanSummary.reduce(
            (sum: number, item: PaymentPlanSummary) =>
              sum + item.totalRemainingAmount,
            0
          ),
        });

        const updatedLegendData = [
          {
            label: "Approved",
            value: String(
              fetchedData.data.loanStatusSummary.DAILY.approvedCount +
                fetchedData.data.loanStatusSummary.WEEKLY.approvedCount +
                fetchedData.data.loanStatusSummary.MONTHLY.approvedCount
            ),
            color: "bg-[#506F6B]",
          },
          {
            label: "Pending",
            value: String(
              fetchedData.data.loanStatusSummary.DAILY.pendingCount +
                fetchedData.data.loanStatusSummary.WEEKLY.pendingCount +
                fetchedData.data.loanStatusSummary.MONTHLY.pendingCount
            ),
            color: "bg-[#5B9D8D]",
          },
          {
            label: "Complete",
            value: String(
              fetchedData.data.loanStatusSummary.DAILY.completeCount +
                fetchedData.data.loanStatusSummary.WEEKLY.completeCount +
                fetchedData.data.loanStatusSummary.MONTHLY.completeCount
            ),
            color: "bg-[#8EBBB1]",
          },
          {
            label: "Rejected",
            value: String(
              fetchedData.data.loanStatusSummary.DAILY.rejectedCount +
                fetchedData.data.loanStatusSummary.WEEKLY.rejectedCount +
                fetchedData.data.loanStatusSummary.MONTHLY.rejectedCount
            ),
            color: "bg-[#9AACAA]",
          },
        ];

        setLegendData(updatedLegendData);

        const legendDoughnut = [
          {
            label: "Daily",
            value: `Rs. ${
              fetchedData.data.paymentPlanSummary.find(
                (item: { repaymentFrequency: string }) =>
                  item.repaymentFrequency === "DAILY"
              )?.totalRemainingAmount || 0
            }`,
            color: "bg-[#506F6B]",
          },
          {
            label: "Weekly",
            value: `Rs. ${
              fetchedData.data.paymentPlanSummary.find(
                (item: { repaymentFrequency: string }) =>
                  item.repaymentFrequency === "WEEKLY"
              )?.totalRemainingAmount || 0
            }`,
            color: "bg-[#5B9D8D]",
          },
          {
            label: "Monthly",
            value: `Rs. ${
              fetchedData.data.paymentPlanSummary.find(
                (item: { repaymentFrequency: string }) =>
                  item.repaymentFrequency === "MONTHLY"
              )?.totalRemainingAmount || 0
            }`,
            color: "bg-[#8EBBB1]",
          },
        ];

        setLegendDoughnut(legendDoughnut);

        const customerLegendData = [
          {
            label: "Fully Paid",
            value: String(
              fetchedData.data.customerLoanStatusSummary.DAILY.fullyPaidCount +
                fetchedData.data.customerLoanStatusSummary.WEEKLY
                  .fullyPaidCount +
                fetchedData.data.customerLoanStatusSummary.MONTHLY
                  .fullyPaidCount
            ),
            color: "bg-[#506F6B]",
          },
          {
            label: "Performing",
            value: String(
              fetchedData.data.customerLoanStatusSummary.DAILY.performingCount +
                fetchedData.data.customerLoanStatusSummary.WEEKLY
                  .performingCount +
                fetchedData.data.customerLoanStatusSummary.MONTHLY
                  .performingCount
            ),
            color: "bg-[#5B9D8D]",
          },
          {
            label: "Overdue",
            value: String(
              fetchedData.data.customerLoanStatusSummary.DAILY.overdueCount +
                fetchedData.data.customerLoanStatusSummary.WEEKLY.overdueCount +
                fetchedData.data.customerLoanStatusSummary.MONTHLY.overdueCount
            ),
            color: "bg-[#8EBBB1]",
          },
          {
            label: "Legal Action",
            value: String(
              fetchedData.data.customerLoanStatusSummary.DAILY.defaultCount +
                fetchedData.data.customerLoanStatusSummary.WEEKLY.defaultCount +
                fetchedData.data.customerLoanStatusSummary.MONTHLY.defaultCount
            ),
            color: "bg-[#9AACAA]",
          },
        ];

        setCustomerLegendData(customerLegendData);

        setBarData({
          labels: ["DAILY", "WEEKLY", "MONTHLY"],
          datasets: [
            {
              label: "Approved",
              data: [
                fetchedData.data.loanStatusSummary.DAILY.approvedCount,
                fetchedData.data.loanStatusSummary.WEEKLY.approvedCount,
                fetchedData.data.loanStatusSummary.MONTHLY.approvedCount,
              ],
              backgroundColor: "#506F6B",
            },
            {
              label: "Pending",
              data: [
                fetchedData.data.loanStatusSummary.DAILY.pendingCount,
                fetchedData.data.loanStatusSummary.WEEKLY.pendingCount,
                fetchedData.data.loanStatusSummary.MONTHLY.pendingCount,
              ],
              backgroundColor: "#5B9D8D",
            },
            {
              label: "Complete",
              data: [
                fetchedData.data.loanStatusSummary.DAILY.completeCount,
                fetchedData.data.loanStatusSummary.WEEKLY.completeCount,
                fetchedData.data.loanStatusSummary.MONTHLY.completeCount,
              ],
              backgroundColor: "#8EBBB1",
            },
            {
              label: "Rejected",
              data: [
                fetchedData.data.loanStatusSummary.DAILY.rejectedCount,
                fetchedData.data.loanStatusSummary.WEEKLY.rejectedCount,
                fetchedData.data.loanStatusSummary.MONTHLY.rejectedCount,
              ],
              backgroundColor: "#9AACAA",
            },
          ],
        });

        setBarDataC({
          labels: ["DAILY", "WEEKLY", "MONTHLY"],
          datasets: [
            {
              label: "Fully Paid",
              data: [
                fetchedData.data.customerLoanStatusSummary.DAILY.fullyPaidCount,
                fetchedData.data.customerLoanStatusSummary.WEEKLY
                  .fullyPaidCount,
                fetchedData.data.customerLoanStatusSummary.MONTHLY
                  .fullyPaidCount,
              ],
              backgroundColor: "#506F6B",
            },
            {
              label: "Performing",
              data: [
                fetchedData.data.customerLoanStatusSummary.DAILY
                  .performingCount,
                fetchedData.data.customerLoanStatusSummary.WEEKLY
                  .performingCount,
                fetchedData.data.customerLoanStatusSummary.MONTHLY
                  .performingCount,
              ],
              backgroundColor: "#5B9D8D",
            },
            {
              label: "Overdue",
              data: [
                fetchedData.data.customerLoanStatusSummary.DAILY.overdueCount,
                fetchedData.data.customerLoanStatusSummary.WEEKLY.overdueCount,
                fetchedData.data.customerLoanStatusSummary.MONTHLY.overdueCount,
              ],
              backgroundColor: "#8EBBB1",
            },
            {
              label: "Legal Action",
              data: [
                fetchedData.data.customerLoanStatusSummary.DAILY.defaultCount,
                fetchedData.data.customerLoanStatusSummary.WEEKLY.defaultCount,
                fetchedData.data.customerLoanStatusSummary.MONTHLY.defaultCount,
              ],
              backgroundColor: "#9AACAA",
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const getCurrentTotalLoans = async (
    freq: PeriodType,
    setLoanAmounts: React.Dispatch<
      React.SetStateAction<{
        totalLoanAmounts: number[];
        totalPaymentsAmounts: number[];
      }>
    >,
    fetchFunction: (
      token: string,
      userId: number,
      frequency: string
    ) => Promise<{ totalLoanAmounts: number[]; totalPaymentsAmounts: number[] }>
  ) => {
    const userId = Number(localStorage.getItem("userId"));
    const token = localStorage.getItem("token") || "";

    try {
      const response = await fetchFunction(token, userId, freq);
      setLoanAmounts(response);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getCurrentTotalLoans(
      "year",
      setGetYearTotalLoanAmountsDaily,
      getTotalLoanANdpaymentCurrentDaily
    );
    getCurrentTotalLoans(
      "year",
      setGetYearTotalLoanAmountsWeekly,
      getTotalLoanANdpaymentCurrentWeekly
    );
    getCurrentTotalLoans(
      "year",
      setGetYearTotalLoanAmountsMonthly,
      getTotalLoanANdpaymentCurrentMonthly
    );
  }, []);

  const convertToPeriodType = (freq: string): PeriodType => {
    switch (freq) {
      case "12 months":
        return "year";
      case "30 days":
        return "month";
      case "7 days":
        return "week";
      case "24 hours":
        return "day";
      default:
        throw new Error("Invalid frequency");
    }
  };

  const setTimeframeForDaily = async (freq: string) => {
    setTimeframeDay(freq);
    const type = convertToPeriodType(freq);
    getCurrentTotalLoans(
      type,
      setGetYearTotalLoanAmountsDaily,
      getTotalLoanANdpaymentCurrentDaily
    );
  };

  const setTimeframeForWeekly = async (freq: string) => {
    setTimeframeWeek(freq);
    const type = convertToPeriodType(freq);
    getCurrentTotalLoans(
      type,
      setGetYearTotalLoanAmountsWeekly,
      getTotalLoanANdpaymentCurrentWeekly
    );
  };

  const setTimeframeForMonthly = async (freq: string) => {
    setTimeframeMonth(freq);
    const type = convertToPeriodType(freq);
    getCurrentTotalLoans(
      type,
      setGetYearTotalLoanAmountsMonthly,
      getTotalLoanANdpaymentCurrentMonthly
    );
  };

  const getLabels = (timeframe: string): string[] => {
    switch (timeframe) {
      case "30 days":
        return Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`);
      case "7 days":
        return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      case "24 hours":
        return Array.from({ length: 24 }, (_, i) => `${i}:00`);
      default:
        return [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
    }
  };

  const lineDataToday = (timeframe: string) => ({
    labels: getLabels(timeframe),
    datasets: [
      {
        label: "Disbursed Amount",
        data: getYearTotalLoanAmountsDaily.totalLoanAmounts,
        borderColor: "#2d6a4f",
        backgroundColor: "rgba(45, 106, 79, 0.5)",
        fill: true,
      },
      {
        label: "Revenue",
        data: getYearTotalLoanAmountsDaily.totalPaymentsAmounts,
        borderColor: "#FF0000",
        backgroundColor: "rgba(255, 0, 0, 0.5)",
        fill: true,
      },
    ],
  });

  const lineDataWeek = (timeframe: string) => ({
    labels: getLabels(timeframe),
    datasets: [
      {
        label: "Disbursed Amount",
        data: getYearTotalLoanAmountsWeekly.totalLoanAmounts,
        borderColor: "#2d6a4f",
        backgroundColor: "rgba(45, 106, 79, 0.5)",
        fill: true,
      },
      {
        label: "Revenue",
        data: getYearTotalLoanAmountsWeekly.totalPaymentsAmounts,
        borderColor: "#FF0000",
        backgroundColor: "rgba(255, 0, 0, 0.5)",
        fill: true,
      },
    ],
  });

  const lineDataMonth = (timeframe: string) => ({
    labels: getLabels(timeframe),
    datasets: [
      {
        label: "Disbursed Amount",
        data: getYearTotalLoanAmountsMonthly.totalLoanAmounts,
        borderColor: "#2d6a4f",
        backgroundColor: "rgba(45, 106, 79, 0.5)",
        fill: true,
      },
      {
        label: "Revenue",
        data: getYearTotalLoanAmountsMonthly.totalPaymentsAmounts,
        borderColor: "#FF0000",
        backgroundColor: "rgba(255, 0, 0, 0.5)",
        fill: true,
      },
    ],
  });

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: "Transactions",
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      y: {
        type: "linear" as const,
        display: true,
        beginAtZero: true,
        position: "left" as const,
        grid: {
          display: true,
        },
      },
    },
  };

  return (
    <div>
      <Main>
        <A_AnaliticsCardsSection loanSummary={data.loanSummary} />
        <A_ChartsSection
          doughnutData={doughnutData}
          barData={barData}
          barDataC={barDataC}
          legendData={legendData}
          customerLegendData={legendDataC}
          legendDataDoughnut={legendDoughnut}
        />
        <A_LineChartSection
          title="Transactions-Daily"
          timeframe={timeframeDay}
          lineData={lineDataToday}
          setTimeframe={setTimeframeForDaily}
          options={lineOptions}
        />

        <A_LineChartSection
          title="Transactions-Weekly"
          timeframe={timeframeWeek}
          lineData={lineDataWeek}
          setTimeframe={setTimeframeForWeekly}
          options={lineOptions}
        />

        <A_LineChartSection
          title="Transactions-Monthly"
          timeframe={timeframeMonth}
          lineData={lineDataMonth}
          setTimeframe={setTimeframeForMonthly}
          options={lineOptions}
        />
      </Main>
    </div>
  );
};

export default A_AnaliticsOverview;
