import React, { useState } from "react";
import { Description, Input } from "../../../../common";
import { CollateralNew } from "../../../../../types";

import ImageChip from "../../../../common/image-chip/ImageChip";
import CollateralImageOverviewModal from "../../../../common/modals/CollateralImageOverviewModal";

interface collateralDetailsTypes {
  collateralName: CollateralNew["collateralName"];
  collDescription: CollateralNew["description"];
  netValue: CollateralNew["netValue"];
  imgUrl: CollateralNew["imgUrl"];
}

const CollateralDetails: React.FC<collateralDetailsTypes> = ({
  collateralName,
  collDescription,
  netValue,
  imgUrl,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageURL, setCurrentImageURL] = useState<string[]>([]);

  // handle open image modals
  const handleOpenImageOverviewModal = (imageURL: string[]) => {
    setCurrentImageURL(imageURL);
    setIsModalOpen(true);
  };

  return (
    <div className="mt-5">
      <div className="mt-5">
        <Description
          content="ඇප භාණ්ඩය පිලිබද විස්තර"
          fontWeight="font-bold"
          fontSize="text-h7 lg:text-h6 3xl:text-h3"
        />
      </div>
      <div className="grid gap-3 md:grid-cols-2">
        {/* collateral Name */}
        <Input
          id={"collateralName"}
          name={"collateralName"}
          placeholder={"භාණ්ඩය"}
          label="භාණ්ඩය*"
          value={collateralName}
          disabled
        />
        {/* net Value */}
        <Input
          id={"netValue"}
          name={"netValue"}
          placeholder={"දළ වටිනාකම (රු)"}
          label="දළ වටිනාකම (රු)*"
          value={netValue.toString()}
          disabled
        />
      </div>
      {/* description */}
      <Input
        id={"description"}
        name={"description"}
        placeholder={"භාණ්ඩය පිළිබද විස්තරය"}
        label="භාණ්ඩය පිළිබද විස්තරය*"
        value={collDescription}
        disabled
      />
      {/*first guarantors Attach a copy of water bill or electricity bill */}
      <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row">
        <Description
          content="ඇපකර භාණ්ඩයෙහි ඡායාරූපයක් අමුණන්න"
          fontWeight="font-bold"
        />

        <ImageChip
          id="firstGuarantorsBill"
          imageName={"ඇපකර භාණ්ඩය"}
          onClick={() => handleOpenImageOverviewModal(imgUrl)}
        />
      </div>
      {/* image overview modal  */}

      <CollateralImageOverviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        imageUrl={currentImageURL}
        disableUpload={true}
      />
    </div>
  );
};

export default CollateralDetails;
