import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import PrimaryTitle from "../text/PrimaryTitle";
import Button from "../buttons/Button";
import Input from "../inputs/Input";
import { inputSchema } from "../../../lib/validation/inputValidation";

const SettingModal: React.FC<{
  visible: boolean;
  icon?: React.ReactNode;
  onClose?: () => void;
  mainTitle: string;
  planName:
    | "DAILY"
    | "WEEKLY"
    | "MONTHLY"
    | "RegularRate"
    | "LatePaymentRate"
    | "OverdueDayPaymentRate";
  content?: string;
  inputLoanValue?: string;
  inputLabel?: string;
  primaryBtnLabel?: string;
  secondaryBtnLabel?: string;
  primaryOnClick?: (planName: string, planValue: number) => void;
  secondaryOnClick?: () => void;
  closeIcon?: React.ReactNode;
}> = ({
  visible,
  onClose,
  icon,
  mainTitle,
  inputLabel,
  inputLoanValue,
  primaryBtnLabel = "primary button",
  primaryOnClick,
  closeIcon,
  planName,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [initialValue, setInitialValue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    // Remove any "-" characters from the input
    newValue = newValue.replace(/-/g, "");

    // Convert the new value to a number to check if it's less than 0
    const numericValue = parseFloat(newValue);

    // If the numeric value is less than 0, set it to 0 and show an error message if needed
    if (numericValue < 0) {
      setError("Value cannot be less than 0");
      newValue = "0";
    } else {
      setError(null); // Clear the error if the value is valid
    }

    setInputValue(newValue);

    // Enable/disable button based on if value has changed or reverted back to the initial value
    setIsButtonDisabled(newValue === initialValue);
  };

  useEffect(() => {
    if (visible) {
      const valueToSet = inputLoanValue || "";
      setInputValue(valueToSet);
      setInitialValue(valueToSet);
      setIsButtonDisabled(true);
      setError(null);
    }
  }, [visible, inputLoanValue]);

  const handleSubmit = () => {
    // Validate the input using the Zod schema
    const validationResult = inputSchema.safeParse(inputValue);

    // Check if validation failed
    if (!validationResult.success) {
      // Get the first error message from Zod validation
      setError(validationResult.error.errors[0].message);
      return;
    }

    // If all validations pass, proceed with the logic
    const inputPlan = parseFloat(inputValue);
    if (primaryOnClick) {
      primaryOnClick(planName, inputPlan);
    }

    setError(null);
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="custom-modal"
    >
      <div className="space-y-5 font-poppins">
        <div className="text-h2">{icon}</div>
        <div className="flex justify-center text-center">
          <PrimaryTitle
            text={mainTitle}
            fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
            center
          />
        </div>
        <div className="py-3">
          <Input
            type="number"
            onChange={handleChange}
            label={inputLabel}
            placeholder="Enter Value"
            value={inputValue}
            id={"inputLabel"}
            name={"inputLabel"}
            errorMessage={error || ""}
          />
        </div>

        <div className="justify-center xxs:flex">
          <div className="flex justify-center">
            <Button
              text={primaryBtnLabel}
              onClick={handleSubmit}
              width="min-w-40"
              disabled={isButtonDisabled} // Button is disabled based on the state
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SettingModal;
