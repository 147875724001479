import React from "react";
import { CustomerData } from "../../../../../types";
import { Description } from "../../../../common";

interface signSectionTypes {
  signatureImageURL: CustomerData["signatureImageURL"];
}

const A_SignSection: React.FC<signSectionTypes> = ({ signatureImageURL }) => {
  return (
    <div>
      <div className="mt-5">
        {/* Description */}
        <Description
          content="මා විසින් ඉහත සඳහන් කළ තොරතුරු සියල්ල නිවැරදි බවත් මේ සමග මාගේ ජාතික
        හැඳුනුම්පතෙහි ඡායා පිටපතක් ඉදිරිපත් කරන බවට ආයතනික නීතිරීති වලට එකඟ
        වෙමින් ඉහත සඳහන් කළ ණය මුදල ඉල්ලා සිටින බවත් මෙයින් ප්‍රකාශ කර සිටිමි."
          fontWeight="font-bold"
        />
      </div>
      {/*customer signature */}
      <div className="h-40 p-2 mt-5 bg-white border-2 rounded-lg  max-w-[100%] border-primaryColor-200">
        <img
          src={signatureImageURL || ""}
          className="object-contain w-full h-full"
        />
      </div>
    </div>
  );
};

export default A_SignSection;
