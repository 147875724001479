import React, { useState } from "react";
import { Modal } from "antd";
import PrimaryTitle from "../text/PrimaryTitle";
import Button from "../buttons/Button";
import Input from "../inputs/Input";

const PrimaryModal: React.FC<{
  visible: boolean;
  icon?: React.ReactNode;
  onClose?: () => void;
  mainTitle: string;
  content: string;
  primaryBtnLabel?: string;
  secondaryBtnLabel?: string;
  primaryOnClick?: (text: string) => void;
  secondaryOnClick?: () => void;
  closeIcon?: React.ReactNode;
}> = ({
  visible,
  onClose,
  icon,
  mainTitle,
  primaryBtnLabel = "primary button",
  secondaryBtnLabel,
  primaryOnClick,
  secondaryOnClick,
  closeIcon,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setError(null);
  };

  const handleSubmit = () => {
    if (inputValue.trim() === "") {
      setError("Reason for the loan rejection cannot be empty");
      return;
    }
    if (primaryOnClick) {
      primaryOnClick(inputValue);
    }
    setInputValue("");
  };

  const clearInput = () => {
    setInputValue("");
    setError(null);
  };

  const handleSecondaryClick = () => {
    clearInput();
    if (secondaryOnClick) secondaryOnClick();
  };

  const handleClose = () => {
    clearInput();
    if (onClose) onClose();
  };

  return (
    <Modal
      open={visible}
      onCancel={handleClose}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="custom-modal" // Add your custom class here
    >
      <div className="space-y-5 font-poppins">
        {/* icon  */}
        <div className="text-h2">{icon}</div>

        {/* main title  */}
        <div className="flex justify-center text-center">
          <PrimaryTitle
            text={mainTitle}
            fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
            center
          />
        </div>
        <div className="py-3">
          <Input
            type="text"
            onChange={handleChange}
            label={"Reason for the loan rejection*"}
            placeholder="Enter reason"
            value={inputValue}
            id={"rejection"}
            name={"rejection"}
            errorMessage={error || ""}
          />
        </div>

        <div className="justify-center gap-4 xxs:flex ">
          {/*primary button  */}{" "}
          <div className="flex justify-center">
            <Button
              text={primaryBtnLabel}
              onClick={handleSubmit}
              width="min-w-40"
            />
          </div>
          {/* secondary button  */}
          {secondaryBtnLabel && (
            <div className="flex justify-center max-xxs:mt-3">
              <Button
                text={secondaryBtnLabel}
                variant="secondary"
                width="min-w-40"
                onClick={handleSecondaryClick}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PrimaryModal;
