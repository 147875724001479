import React from "react";
import { Chart } from "../../../common/";

interface LegendData {
  label: string;
  value: string;
  color: string;
}

interface DoughnutData {
  datasets: {
    data: number[];
    backgroundColor: string[];
    borderWidth: number;
  }[];
  labels: string[];
  totalDue?: number;
}

interface BarData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
  }[];
}

interface C_ChartsSectionProps {
  doughnutData: DoughnutData;
  barData: BarData;
  legendData: LegendData[];
  legendDataBar:LegendData[];
  totalDue: string; 
}

const C_ChartsSection: React.FC<C_ChartsSectionProps> = ({
  doughnutData,
  barData,
  legendData,
  legendDataBar,
  totalDue,
}) => {
  return (
    <div className="flex flex-wrap justify-center gap-4 p-4">
      <Chart
        type="doughnut"
        title="Payment Plan"
        data={doughnutData}
        options={{
          plugins: {
            legend: { display: false },
            centerText: {},
          },
          cutout: "70%",
        }}
        legendData={legendData}
        centerTextOptions={{
          text: "Total Due",
          amount: `Rs. ${totalDue}`,
        }}
      />
      <Chart
        type="bar"
        title="Application Overview"
        data={barData}
        options={{
          responsive: true,
          scales: {
            x: { beginAtZero: true, stacked: true, grid: { display: false } },
            y: { beginAtZero: true, stacked: true, grid: { display: true } },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
        legendData={legendDataBar}
      />
    </div>
  );
};

export default C_ChartsSection;
