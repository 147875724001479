import React, { useEffect, useState } from "react";

import {
  format,
  addWeeks,
  subWeeks,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  isToday,
} from "date-fns";
import { getAllshedulesForCalander } from "../../../../api/calander";
import { MdOutlineNavigateNext } from "react-icons/md";
import CalanderOverviewModal from "../../../common/modals/CalanderOverviewModal";
import CalanderShedulesCollectionModal from "../../../common/modals/CalanderShedulesCollectionModal";

interface Schedule {
  id: number;
  date: string;
  customerName: string;
  customerAddress: string;
  customerNic: string;
  dueDate: string;
  dueAmount: number;
  loanId: number;
  customerPhoneNumber: string;
  customerWhatsAppNumber: string;
  numberOfInstallments: number;
  loanAmount: number;
  collectorId: number;
  managerId: number;
}

interface CustomerLoan {
  customerName: string;
  loanId: number;
}

interface weekProps {
  selectedJsCalanderDate: Date;
  selectedCustomer: CustomerLoan[];
  selectedcollectorId: number | null;
  selectedManagerId: number | null;
}

const Week: React.FC<weekProps> = ({
  selectedJsCalanderDate,
  selectedCustomer,
  selectedcollectorId,
  selectedManagerId,
}) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [schedules, setSchedules] = useState<Record<string, Schedule[]>>({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule | null>(
    null
  );

  // state management for screens below lg
  const [isModalOpenSm, setIsModalOpenSm] = useState(false);
  const [selectedSchedulesSm, setSelectedSchedulesSm] = useState<Schedule[]>(
    []
  );

  //selectedDate pass to modal
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [filteredSchedules, setFilteredSchedules] = useState<
    Record<string, Schedule[]>
  >({});

  useEffect(() => {
    setCurrentDate(selectedJsCalanderDate);
  }, [selectedJsCalanderDate]);

  // Function to get the start and end dates of the current week
  const getWeekDays = (date: Date) => {
    const start = startOfWeek(date, { weekStartsOn: 0 });
    const end = endOfWeek(date, { weekStartsOn: 0 });
    return eachDayOfInterval({ start, end });
  };

  // Function to go to the previous week
  const goToPreviousWeek = () => {
    const previousWeek = subWeeks(currentDate, 1);
    setCurrentDate(previousWeek);
  };

  // Function to go to the next week
  const goToNextWeek = () => {
    const nextWeek = addWeeks(currentDate, 1);
    setCurrentDate(nextWeek);
  };

  // Function to get schedules for a given day
  const getSchedulesForDay = (day: Date) => {
    const dayKey = format(day, "yyyy-MM-dd");
    return filteredSchedules[dayKey] || [];
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token") || "";
      const Id = localStorage.getItem("userId") || "";
      const userId = parseInt(Id);
      try {
        const response = await getAllshedulesForCalander(userId, token);

        let filteredResponse = response;
        if (selectedManagerId !== null) {
          filteredResponse = response.filter(
            (schedule: Schedule) => schedule.managerId === selectedManagerId
          );
        }

        if (selectedcollectorId !== null) {
          filteredResponse = filteredResponse.filter(
            (schedule: Schedule) => schedule.collectorId === selectedcollectorId
          );
        }

        const formattedSchedules = formatSchedules(filteredResponse);
        setSchedules(formattedSchedules);
      } catch (error) {
        console.error("Error fetching schedules:", error);
      }
    };

    fetchData();
  }, [selectedcollectorId, selectedManagerId]);

  const formatSchedules = (data: Schedule[]): Record<string, Schedule[]> => {
    return data.reduce(
      (acc, item) => {
        const dateKey = item.dueDate;
        if (!acc[dateKey]) {
          acc[dateKey] = [];
        }
        acc[dateKey].push({
          id: item.id,
          customerName: item.customerName,
          customerAddress: item.customerAddress,
          customerPhoneNumber: item.customerPhoneNumber,
          loanId: item.loanId,
          dueDate: item.dueDate,
          customerNic: item.customerNic,
          dueAmount: item.dueAmount,
          customerWhatsAppNumber: item.customerWhatsAppNumber,
          numberOfInstallments: item.numberOfInstallments,
          collectorId: item.collectorId,
          loanAmount: item.loanAmount,
          managerId: item.managerId,
          date: dateKey,
        });
        return acc;
      },
      {} as Record<string, Schedule[]>
    );
  };

  useEffect(() => {
    if (selectedCustomer.length > 0) {
      // Filter schedules based on selected customers' loanIds
      const filtered = Object.entries(schedules).reduce(
        (acc, [dateKey, scheduleArray]) => {
          const matchingSchedules = scheduleArray.filter((schedule) =>
            selectedCustomer.some(
              (customer) => customer.loanId === schedule.loanId
            )
          );
          if (matchingSchedules.length > 0) {
            acc[dateKey] = matchingSchedules;
          }

          return acc;
        },
        {} as Record<string, Schedule[]>
      );

      setFilteredSchedules(filtered);
    } else {
      // No selected customer, use original schedules
      setFilteredSchedules(schedules);
    }
  }, [selectedCustomer, schedules]);

  const handleScheduleClick = (schedule: Schedule) => {
    setSelectedSchedule(schedule);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedSchedule(null);
  };

  // Handlers for screens below lg modal
  const handleDayCardClick = (day: Date) => {
    const daySchedules = getSchedulesForDay(day);
    setSelectedSchedulesSm(daySchedules);
    setIsModalOpenSm(true);
    setSelectedDate(day);
  };

  const handleModalCloseSm = () => {
    setIsModalOpenSm(false);
    setSelectedSchedulesSm([]);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between mb-4">
        <h2 className="text-lg font-bold lg:text-2xl text-primaryColor-900">
          {format(startOfWeek(currentDate, { weekStartsOn: 0 }), "MMMM d")} -{" "}
          {format(endOfWeek(currentDate, { weekStartsOn: 0 }), "MMMM d, yyyy")}
        </h2>

        <div className="flex flex-row text-h5 lg:text-h3">
          <button
            onClick={goToPreviousWeek}
            className="px-4 rounded text-primaryColor-900"
          >
            <MdOutlineNavigateNext className="transform scale-x-[-1]" />
          </button>
          <button
            onClick={goToNextWeek}
            className="px-4 rounded text-primaryColor-900"
          >
            <MdOutlineNavigateNext />
          </button>
        </div>
      </div>

      <div className="hidden lg:block">
        <table className="w-full border-collapse table-fixed">
          <thead>
            <tr>
              {getWeekDays(currentDate).map((day, index) => (
                <th
                  key={index}
                  className={`p-2 border-b text-h8 font-semibold w-1/7 text-primaryColor-300 ${
                    isToday(day)
                      ? "bg-primaryColor-500 text-white"
                      : "bg-gray-100"
                  } `}
                >
                  {format(day, "EEE,  d")}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {getWeekDays(currentDate).map((day, index) => (
                <td
                  key={index}
                  className="p-2 border w-1/7 place-content-start"
                >
                  <div className="flex flex-col w-full break-words place-self-start">
                    {getSchedulesForDay(day).map((schedule) => (
                      <div
                        key={schedule.id}
                        className="w-full p-2 mb-2 rounded-lg shadow-md bg-primaryColor-100"
                        onClick={() => handleScheduleClick(schedule)}
                      >
                        <div className="font-bold text-primaryColor-600">
                          {schedule.customerName}
                        </div>
                        <div className="text-primaryColor-900">
                          {schedule.customerAddress}
                        </div>
                      </div>
                    ))}
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      {/* Day Cards for screens below lg */}
      <div className="grid grid-cols-1 gap-4 lg:hidden">
        {getWeekDays(currentDate).map((day, index) => (
          <div
            key={index}
            className="flex justify-between p-3 bg-white border-b-2 cursor-pointer border-primaryColor-600"
            onClick={() => handleDayCardClick(day)}
          >
            <div className="font-semibold w-[130px] text-h7 text-primaryColor-300">
              {format(day, "EEEE, d")}
            </div>
            <div className="px-3 py-1 text-base text-black bg-[#E7F0EE] rounded-xl">
              {getSchedulesForDay(day).length} Schedules
            </div>
          </div>
        ))}
      </div>

      <CalanderOverviewModal
        visible={isModalOpen}
        onClose={handleModalClose}
        content={selectedSchedule}
        closeIcon
      />

      <CalanderShedulesCollectionModal
        visible={isModalOpenSm}
        onClose={handleModalCloseSm}
        schedules={selectedSchedulesSm}
        selectedDate={selectedDate}
        closeIcon
      />
    </div>
  );
};

export default Week;
