import axios from "axios";
import { apiOptionJson, BASE_URL } from "./base-url";
import { guaranterUpdateTypes } from "../types";

// get all Guarantee List
export const getAllGuarantees = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v5/guarantors`, {
      headers: {
        ...apiOptionJson.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching managers:", error);
    throw error;
  }
};

// update guaranter details
export const updateGuaranterDetails = async (
  token: string,
  guaranterId: number,
  guaranterUpdatedData: guaranterUpdateTypes
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/api/v5/guarantors/${guaranterId}`,
      guaranterUpdatedData,
      {
        ...apiOptionJson.headers,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
// get all Guarantee List
export const getGuaranteesUserid = async (token: string, userId: number) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v5/guarantors/byUser/${userId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching managers:", error);
    throw error;
  }
};

// get guarantee by guaranter nic
export const getGuaranterBynic = async (token: string, nic: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v5/guarantors/find?nic=${nic}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
