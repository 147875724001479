import React from "react";
import { C_GuaranteeListTable } from "../../Tables";
import { Main, Title } from "../../common";

const C_GuaranteeListPage: React.FC = () => {
  return (
    <div>
      <Main>
        {" "}
        {/* title  */}
        <Title title="Guarantee List" bgStyle={false} />
        {/* collector guaantee list table  */}
        <C_GuaranteeListTable />
      </Main>
    </div>
  );
};

export default C_GuaranteeListPage;
