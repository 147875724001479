import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Empty, Form, Table, Typography } from "antd";
import { Dropdown, Input } from "../../common";
import { getGuaranteesUserid } from "../../../api/guarantee";
import { getCollectors } from "../../../api/employee";
import { allCustomers } from "../../../api/customer";
import { useNavigate } from "react-router-dom";

interface Item {
  id: number;
  customerId: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        ></Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

interface RecordType {
  id: number;
  customerId: string;
  guarantorId: string;
  fullName: string;
  address: string;
  guarantorPhoneNumber: string;
  nic: string;
  paymentId: string;
  customerName: string;
  collectorName: string;
}

interface collectorsTypes {
  collectorId: number;
  collectorUserName: string;
  contactNumber: string;
}

interface RecordType {
  id: number;
  guarantorId: string;
  fullName: string;
  address: string;
  guarantorPhoneNumber: string;
  nic: string;
  paymentId: string;
  customerName: string;
  collectorName: string;
}

const A_GuaranteeListTable = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [customersData, setCustomersData] = useState<RecordType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [allCollectors, setAllCollectors] = useState<collectorsTypes[]>([]);
  const [getallCustomers, setgetallCustomers] = useState<RecordType[]>([]);
  const [selectedCollector, setSelectedCollector] = useState<string>("All");
  const [selectedCustomer, setSelectedCustomer] = useState<string>("All");

  // navigate edit customer overview
  const handleNavigate = (customerId: string) => {
    navigate(`/customer-update-overview/${customerId}`);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "guarantorId",
      fixed: "left" as const,
      align: "center" as const,
    },
    {
      title: "Guarantor Name",
      dataIndex: "fullName",
      align: "center" as const,
    },
    {
      title: "Address",
      dataIndex: "address",
      align: "center" as const,
    },
    {
      title: "Phone Number",
      dataIndex: "guarantorPhoneNumber",
      align: "center" as const,
    },
    {
      title: "NIC",
      dataIndex: "nic",
      align: "center" as const,
    },
    {
      title: "Customer name",
      dataIndex: "customerName",
      align: "center" as const,
    },
    {
      title: "Collector Name",
      dataIndex: "collectorName",
      align: "center" as const,
    },
    {
      title: "View",
      dataIndex: "paymentId",
      render: (_: number, record: Item) => (
        <div className="flex justify-center">
          <Typography.Link>
            <div
              onClick={() => handleNavigate(record.customerId)}
              className="flex justify-center w-20 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
            >
              View
            </div>
          </Typography.Link>
        </div>
      ),
      align: "center" as const,
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "netValue" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  // Get all collectors
  useEffect(() => {
    const token = localStorage.getItem("token") || "";

    const fetchCollectors = async () => {
      try {
        const response = await getCollectors(token);
        setAllCollectors([{ collectorUserName: "All" }, ...response]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCollectors();
  }, []);

  // Get all Customers
  useEffect(() => {
    const token = localStorage.getItem("token") || "";
    const userId = localStorage.getItem("userId");

    if (!userId) {
      console.error("User ID is not available");
      return;
    }

    const fetchCustomers = async () => {
      try {
        const response = await allCustomers(token, parseInt(userId));
        setgetallCustomers([{ fullName: "All" }, ...response.data]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCustomers();
  }, []);

  // get all guarantee list
  useEffect(() => {
    const getPaymentsData = async () => {
      const token = localStorage.getItem("token") || "";
      const userId = localStorage.getItem("userId") || "";

      try {
        const response = await getGuaranteesUserid(token, parseInt(userId));

        // Sort the data in descending order based on guarantorId
        const sortedData = response.data.sort(
          (a: RecordType, b: RecordType) =>
            parseInt(b.guarantorId) - parseInt(a.guarantorId)
        );

        setCustomersData(sortedData);
      } catch (error) {
        console.log(error);
      }
    };

    getPaymentsData();
  }, []);

  // Filter data based on search text, selected customer, and selected collector
  const filteredData = customersData.filter((record) => {
    const searchValue = searchText.toLowerCase();
    const fullName = record.fullName?.toLowerCase() || "";
    const address = record.address?.toLowerCase() || "";
    const guarantorPhoneNumber =
      record.guarantorPhoneNumber?.toLowerCase() || "";
    const nic = record.nic?.toLowerCase() || "";
    const customerName = record.customerName?.toLowerCase() || "";
    const collectorName = record.collectorName?.toLowerCase() || "";

    const matchesSearch =
      fullName.includes(searchValue) ||
      address.includes(searchValue) ||
      guarantorPhoneNumber.includes(searchValue) ||
      nic.includes(searchValue);

    const matchesCustomer =
      selectedCustomer === "All" ||
      customerName === selectedCustomer.toLowerCase();
    const matchesCollector =
      selectedCollector === "All" ||
      collectorName === selectedCollector.toLowerCase();

    return matchesSearch && matchesCustomer && matchesCollector;
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleCollectorSelect = (option: string) => {
    setSelectedCollector(option);
  };

  const handleCustomerSelect = (option: string) => {
    setSelectedCustomer(option);
  };

  // Options for the manager dropdown
  const collectors = allCollectors.map(
    (response) => response.collectorUserName
  );

  // Options for the manager dropdown
  const customers = getallCustomers.map((response) => response.fullName);

  return (
    <div>
      {/* mobile view filters  */}
      <div className="md:hidden">
        <div className="grid xxs:gap-3 xxs:grid-cols-2 ">
          {/* filter guarantee by collectors */}
          <Dropdown
            label="Collector"
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            options={collectors}
            selectedOption={selectedCollector}
            onOptionSelect={handleCollectorSelect}
            placeholder="Collector"
            // width="w-[100%] md:max-w-60"
          />
          {/* filter guarantee by customer */}
          <Dropdown
            label="Customer"
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            options={customers}
            selectedOption={selectedCustomer}
            onOptionSelect={handleCustomerSelect}
            placeholder="Customer"
            // width="w-[100%] xxs:max-w-60"
          />
        </div>
        {/* filter guarantors */}
        <div>
          <Input
            id={"filterGuarantee"}
            name={"filterGuarantee"}
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            width="w-[100%] xxs:max-w-60 -mt-[2px] mx-auto"
          />
        </div>
      </div>

      {/* web view filters  */}
      <div className="flex items-center justify-between max-md:hidden">
        <div className="w-2/3 space-x-3 ">
          {/* filter guarantee by collectors */}
          <Dropdown
            label="Collector"
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            options={collectors}
            selectedOption={selectedCollector}
            onOptionSelect={handleCollectorSelect}
            placeholder="Collector"
            width="w-[100%] md:max-w-48 "
          />

          {/* filter guarantee by customer */}
          <Dropdown
            label="Customer"
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            options={customers}
            selectedOption={selectedCustomer}
            onOptionSelect={handleCustomerSelect}
            placeholder="Customer"
            width="w-[100%] xxs:max-w-48"
          />
        </div>
        {/* filter guarantors */}
        <div>
          <Input
            id={"filterGuarantee"}
            name={"filterGuarantee"}
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            width="w-[100%] xxs:max-w-60 mt-4"
          />
        </div>
      </div>

      {/* table */}
      <Form form={form} component={false}>
        <Table
          scroll={{ x: "max-content" }} // Ensure the table scrolls horizontally if needed
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData} // Use filtered data
          columns={mergedColumns}
          rowClassName="editable-row"
          className="font-semibold"
          locale={{
            emptyText: <Empty description={<span>No data available</span>} />,
          }}
        />
      </Form>
    </div>
  );
};

export default A_GuaranteeListTable;
