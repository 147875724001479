import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

interface SignaturePadProps {
  SignaturePadText?: string;
  onImageGenerate: (url: string) => void;
  onOk: () => void;
  signurl?: string;
  height?: string;
  width?: string;
  primaryButtonLabel?: string;
  isClear?: boolean;
  onClear?: () => void;
}

const SignaturePad: React.FC<SignaturePadProps> = ({
  SignaturePadText,
  onImageGenerate,
  onOk,
  signurl,
  primaryButtonLabel = "Save",
  isClear,
  onClear,
}) => {
  const signCanvasRef = useRef<SignatureCanvas | null>(null);
  const [divWidth, setDivWidth] = useState(0);
  const divRef = useRef<HTMLDivElement>(null);
  const [canvasHeight, setCanvasHeight] = useState(500);

  useEffect(() => {
    if (divRef.current) {
      setDivWidth(divRef.current.offsetWidth);
    }
    updateCanvasHeight();
    window.addEventListener("resize", updateCanvasHeight);

    return () => {
      window.removeEventListener("resize", updateCanvasHeight);
    };
  }, []);

  useEffect(() => {
    if (signCanvasRef.current && signurl) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = signurl;

      img.onload = () => {
        if (signCanvasRef.current) {
          const canvas = signCanvasRef.current.getCanvas();
          const context = canvas.getContext("2d");

          if (context) {
            context.clearRect(0, 0, canvas.width, canvas.height);

            // Calculate the scaling factor to fit the image within the canvas dimensions
            const scaleFactor = Math.min(
              canvas.width / img.width,
              canvas.height / img.height
            );

            // Calculate the new image width and height
            const newWidth = img.width * scaleFactor;
            const newHeight = img.height * scaleFactor;

            // Draw the scaled image onto the canvas, centered
            context.drawImage(
              img,
              (canvas.width - newWidth) / 2,
              (canvas.height - newHeight) / 2,
              newWidth,
              newHeight
            );
          }
        }
      };
    }
  }, [signurl]);

  const updateCanvasHeight = () => {
    const width = window.innerWidth;
    if (width < 768) {
      setCanvasHeight(470);
    } else {
      setCanvasHeight(500);
    }
  };

  // clear signature pad
  const handleClear = () => {
    if (signCanvasRef.current) {
      signCanvasRef.current.clear();
      onImageGenerate("");
    }
    if (onClear) {
      onClear();
    }
  };

  // Watch for isClear prop to clear the canvas
  useEffect(() => {
    if (isClear && signCanvasRef.current) {
      signCanvasRef.current.clear();
      onImageGenerate(""); // Pass an empty string as the cleared image
    }
  }, [isClear]);

  // save and generate image from canvas
  const handleEnd = () => {
    if (signCanvasRef.current?.getTrimmedCanvas) {
      const url = signCanvasRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      onImageGenerate(url);
    }
  };

  return (
    <div className="w-[100%]">
      <div className="border-2 border-primaryColor-200  w-[100%] rounded-lg relative flex flex-col items-center ">
        <div ref={divRef} className=" w-[100%] ">
          <SignatureCanvas
            ref={signCanvasRef}
            canvasProps={{
              width: divWidth,
              height: canvasHeight,
              className: `sigCanvas   `,
            }}
            onEnd={handleEnd}
          />
        </div>
        {SignaturePadText && (
          <div className="py-1 text-center w-[80%] border-t-2 text-[#042D24] border-[#8EBBB1] ">
            {SignaturePadText}
          </div>
        )}
      </div>

      <div className="flex items-end justify-end mt-3 space-x-2">
        <button
          className="w-20 py-1 text-sm font-normal border-2 rounded-md font-poppins text-primaryColor-900 border-primaryColor-300 "
          onClick={handleClear}
        >
          Clear
        </button>
        <button
          className="w-20 py-1 text-sm font-normal border-2 rounded-md font-poppins text-primaryColor-900 border-primaryColor-300 "
          onClick={onOk}
        >
          {primaryButtonLabel}
        </button>
      </div>
    </div>
  );
};

export default SignaturePad;
