import React from "react";

const Loading: React.FC = () => {
  return (
    <div>
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="mt-5 text-2xl font-bold uppercase text-primaryColor-500">
          Loading...
        </div>
      </div>
    </div>
  );
};

export default Loading;
