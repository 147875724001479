import React, { useEffect, useRef, useState } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import Button from "../buttons/Button";
import toast from "react-hot-toast";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { storage } from "../../../lib/configs/firebaseConfig";

interface ImageOverviewModalProps {
  isOpen: boolean;
  disableUpload?: boolean;
  onClose: () => void;
  imageUrl: string;
  buttonText?: string;
  onclick?: () => void;
  onFileSelect?: (file: File | null) => void;
  onUploadComplete?: (url: string) => void;
  isClicked: () => void;
}

const UpdateImageOverviewModal: React.FC<ImageOverviewModalProps> = ({
  isOpen,
  onClose,
  buttonText,
  imageUrl,
  onFileSelect,
  onUploadComplete,
  disableUpload,
  onclick,
  isClicked,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadedImage, setUploadedImage] = useState<string>(imageUrl);
  const [newImageUrl, setNewImageUrl] = useState<string | null>(null);
  const [previousImageUrl, setPreviousImageUrl] = useState<string | null>(
    imageUrl
  );
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isNewImageSelected, setIsNewImageSelected] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      setUploadedImage(imageUrl);
      setPreviousImageUrl(imageUrl);
    }
  }, [imageUrl]);

  useEffect(() => {
    if (newImageUrl) {
      isClicked();
    }
  }, [newImageUrl, isClicked]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!file.type.includes("image/png") && !file.type.includes("image/jpeg")) {
      toast.error("Please upload a PNG or JPEG file.");
      return;
    }

    setSelectedFile(file);
    setIsNewImageSelected(true); // Mark that a new image is selected

    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      setUploadedImage(base64String);
      if (onFileSelect) {
        onFileSelect(file);
      }
    };
    reader.readAsDataURL(file);
  };

  const uploadImageToFirebase = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      setUploading(true);
      const storageRef = ref(storage, `userRegistration/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (_snapshot) => {
          // Handle upload progress if needed
        },
        (_error) => {
          setUploading(false);
          reject("Upload failed, please try again.");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUploading(false);
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const handleSaveClick = async () => {
    if (selectedFile) {
      try {
        // Upload new image
        const newUrl = await uploadImageToFirebase(selectedFile);

        // Delete previous image
        if (previousImageUrl) {
          const imageRef = ref(storage, previousImageUrl);
          await deleteObject(imageRef);
        }

        setNewImageUrl(newUrl);
        if (onUploadComplete) {
          onUploadComplete(newUrl);
        }

        if (onclick) {
          onclick();
        }

        setIsNewImageSelected(false);
      } catch (error) {
        console.log(error);
        toast.error("An error occurred while uploading the image.");
      }
    } else {
      toast.error("No new image selected. Please select an image first.");
    }
  };

  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCloseClick = () => {
    setIsNewImageSelected(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative overflow-hidden bg-[#E7F0EE] w-[100%] sm:max-w-[80%] md:w-[500px] xl:w-[600px] shadow-3xl rounded-lg">
        <div className="absolute flex gap-5 top-3 right-3">
          {!disableUpload && (
            <button className="text-[#0A6C55]" onClick={handleEditClick}>
              <FiEdit className="w-5 h-5 md:w-6 md:h-6" />
            </button>
          )}

          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            className="hidden"
            onChange={handleFileChange}
          />

          <button className="text-[#0A6C55] text-xl" onClick={handleCloseClick}>
            <FaRegWindowClose className="w-5 h-5 rounded-md md:w-6 md:h-6" />
          </button>
        </div>
        <div className="px-4 py-2 mt-10 mb-2">
          {uploadedImage ? (
            <div className="flex justify-center">
              <div className="relative w-auto h-[300px] sm:h-[350px]">
                <img
                  src={uploadedImage}
                  alt="Modal Content"
                  className="w-full h-full mx-auto mb-4"
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-center">
              <div className="relative w-auto h-[300px] sm:h-[350px]">
                <img
                  src={"/images/imageUpload.png"}
                  alt="Modal Content"
                  className="w-full h-full mx-auto mb-4"
                />
              </div>
            </div>
          )}

          {buttonText && isNewImageSelected && (
            <div className="flex justify-center mt-3">
              <Button
                text={buttonText}
                onClick={handleSaveClick}
                isLoading={uploading}
                disabled={uploading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateImageOverviewModal;
