import React, { useEffect, useState } from "react";
import {
  A_UserOverviewPage,
  Loading,
  M_UserOverviewPage,
  NotFound,
} from "../components/page";
import { getCurrentUser } from "../api/auth";
import { useParams } from "react-router-dom";

const UserOverview: React.FC = () => {
  const { userId: userIdParam } = useParams<{ userId: string }>(); // Get the userId from the URL parameters
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(true);

  // Convert userId from string to number
  const userId = userIdParam ? parseInt(userIdParam, 10) : undefined;

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token") || "";
      try {
        const userData = await getCurrentUser(token);

        // get user role
        setUserRole(userData.data.role);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUserRole(""); // Reset userRole to trigger NotFound component
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div>
        {/* loading screen */}
        <Loading />
      </div>
    );
  }

  if (userId === undefined) {
    // Handle the case where userId is undefined
    return <NotFound />;
  }

  switch (userRole) {
    case "ADMIN":
      return <A_UserOverviewPage userDetails={{ userId }} />;
    case "MANAGER":
      return <M_UserOverviewPage userDetails={{ userId }} />;
    case "COLLECTOR":
      return <NotFound />;
    default:
      return <NotFound />;
  }
};

export default UserOverview;
