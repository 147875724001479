import React, { useEffect, useState } from "react";
import { CustomerData } from "../../../../types";
import { Description, Input, RadioButton } from "../../../common";
import ImageOverviewModal from "../../../common/modals/ImageOverviewModal";
import ImageChip from "../../../common/image-chip/ImageChip";
import { CiEdit } from "react-icons/ci";
import { MdDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { customerSchema } from "../../../../lib/validation/customerSchema";

// Define an interface for the component's props
const CustomerDetails: React.FC = () => {
  const [customerData, setCustomerData] = useState<CustomerData | null>(null);
  const [isOpenFrontImage, setIsOpenFrontImage] = useState(false);
  const [isOpenBackImage, setIsOpenBackImage] = useState(false);
  const [isOpenCustomerImage, setIsOpenCustomerImage] = useState(false);
  const [isOpenelEctricityBillImage, setIsOpenEctricityBillImage] =
    useState(false);
  const [isRadioEditMode, setIsRadioEditMode] = useState(false);

  const [inputs, setInputs] = useState({
    loanReason: {
      value: "",
      disabled: true,
      error: "",
    },
    fullName: {
      value: "",
      disabled: true,
      error: "",
    },
    address: {
      value: "",
      disabled: true,
      error: "",
    },
    nic: {
      value: "",
      disabled: true,
      error: "",
    },
    policeStation: {
      value: "",
      disabled: true,
      error: "",
    },
    gramaDivision: {
      value: "",
      disabled: true,
      error: "",
    },
    provincialOffice: {
      value: "",
      disabled: true,
      error: "",
    },
    verificationMethod: {
      value: "",
      disabled: true,
      error: "",
    },
    phoneNumber: {
      value: "",
      disabled: true,
      error: "",
    },
    email: {
      value: "",
      disabled: true,
      error: "",
    },
    optionalContactNumber: {
      value: "",
      disabled: true,
      error: "",
    },
    whatsAppNumber: {
      value: "",
      disabled: true,
      error: "",
    },
    companyName: {
      value: "",
      disabled: true,
      error: "",
    },
    occupation: {
      value: "",
      disabled: true,
      error: "",
    },
    companyAddress: {
      value: "",
      disabled: true,
      error: "",
    },
    frontImageURL: {
      value: "",
      disabled: true,
      error: "",
    },
    backImageURL: {
      value: "",
      disabled: true,
      error: "",
    },
    customerImageURL: {
      value: "",
      disabled: true,
      error: "",
    },
    electricityBillImageURL: {
      value: "",
      disabled: true,
      error: "",
    },
  });
  const [activeField, setActiveField] = useState<keyof typeof inputs | null>(
    null
  );
  const [previousValue, setPreviousValue] = useState<string | null>(null);

  // Fetch customer data from localStorage and combine when the component mounts
  useEffect(() => {
    const storedData1 = localStorage.getItem("customerRegisterFormData1");
    const storedData2 = localStorage.getItem("customerRegisterFormData2");
    const storedData3 = localStorage.getItem("customerRegisterFormData3");

    if (storedData1 || storedData2 || storedData3) {
      const parsedData1 = storedData1 ? JSON.parse(storedData1) : {};
      const parsedData2 = storedData2 ? JSON.parse(storedData2) : {};
      const parsedData3 = storedData3 ? JSON.parse(storedData3) : {};

      // Merge both objects into a single customerData object
      const combinedData = { ...parsedData1, ...parsedData2, ...parsedData3 };
      setCustomerData(combinedData);
    }
  }, []);

  useEffect(() => {
    if (customerData) {
      setInputs({
        loanReason: {
          value: customerData.loanReason || "",
          disabled: true,
          error: "",
        },
        fullName: {
          value: customerData.fullName || "",
          disabled: true,
          error: "",
        },
        address: {
          value: customerData.address || "",
          disabled: true,
          error: "",
        },
        nic: {
          value: customerData.nic || "",
          disabled: true,
          error: "",
        },
        policeStation: {
          value: customerData.policeStation || "",
          disabled: true,
          error: "",
        },
        gramaDivision: {
          value: customerData.gramaDivision || "",
          disabled: true,
          error: "",
        },
        provincialOffice: {
          value: customerData.provincialOffice || "",
          disabled: true,
          error: "",
        },
        verificationMethod: {
          value: customerData.verificationMethod || "",
          disabled: true,
          error: "",
        },
        phoneNumber: {
          value: customerData.phoneNumber || "",
          disabled: true,
          error: "",
        },
        email: {
          value: customerData.email || "",
          disabled: true,
          error: "",
        },
        optionalContactNumber: {
          value: customerData.optionalContactNumber || "",
          disabled: true,
          error: "",
        },
        whatsAppNumber: {
          value: customerData.whatsAppNumber || "",
          disabled: true,
          error: "",
        },
        companyName: {
          value: customerData.companyName || "",
          disabled: true,
          error: "",
        },
        occupation: {
          value: customerData.occupation || "",
          disabled: true,
          error: "",
        },
        companyAddress: {
          value: customerData.companyAddress || "",
          disabled: true,
          error: "",
        },
        frontImageURL: {
          value: customerData.frontImageURL || "",
          disabled: true,
          error: "",
        },
        backImageURL: {
          value: customerData.backImageURL || "",
          disabled: true,
          error: "",
        },
        customerImageURL: {
          value: customerData.customerImageURL || "",
          disabled: true,
          error: "",
        },
        electricityBillImageURL: {
          value: customerData.electricityBillImageURL || "",
          disabled: true,
          error: "",
        },
      });
    }
  }, [customerData]);

  // Handle input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof typeof inputs
  ) => {
    const { value } = e.target;

    // Validate the field against the schema dynamically
    const fieldSchema = customerSchema.shape[field];
    const result = fieldSchema.safeParse(value);

    setInputs((prev) => ({
      ...prev,
      [field]: {
        value,
        error: result.success
          ? ""
          : result.error?.issues[0]?.message || "Invalid input",
      },
    }));
  };

  // Handle edit click to enable the input
  const handleEditClick = (field: keyof typeof inputs) => {
    if (activeField && activeField !== field) {
      handleSaveOrCancel(activeField, false);
    }

    // Set previousValue to the current value before editing
    setPreviousValue(inputs[field].value);
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: { ...prevInputs[field], disabled: false },
    }));
    setActiveField(field);
  };

  const handleSaveOrCancel = (field: keyof typeof inputs, save: boolean) => {
    if (save) {
      handleSave(field);
    } else {
      handleCancel(field);
    }
  };

  // Handle save click to save the updated value and disable the input
  const handleSave = (field: keyof typeof inputs) => {
    const formData = {
      loanReason: inputs.loanReason.value,
      fullName: inputs.fullName.value,
      address: inputs.address.value,
      nic: inputs.nic.value,
      policeStation: inputs.policeStation.value,
      gramaDivision: inputs.gramaDivision.value,
      provincialOffice: inputs.provincialOffice.value,
      verificationMethod: inputs.verificationMethod.value,
      phoneNumber: inputs.phoneNumber.value,
      email: inputs.email.value,
      optionalContactNumber: inputs.optionalContactNumber.value,
      whatsAppNumber: inputs.whatsAppNumber.value,
      companyName: inputs.companyName.value,
      occupation: inputs.occupation.value,
      companyAddress: inputs.companyAddress.value,
      frontImageURL: inputs.frontImageURL.value,
      backImageURL: inputs.backImageURL.value,
      customerImageURL: inputs.customerImageURL.value,
      electricityBillImageURL: inputs.electricityBillImageURL.value,
    };

    const validationResult = customerSchema.safeParse(formData);

    if (!validationResult.success) {
      const errorMessages = validationResult.error.errors;

      // Handle validation errors
      setInputs((prevInputs) => {
        const updatedInputs = { ...prevInputs };
        errorMessages.forEach((error) => {
          const field = error.path[0] as keyof typeof inputs; // Type assertion here
          if (field in updatedInputs) {
            updatedInputs[field].error = error.message;
          }
        });
        return updatedInputs;
      });

      return; // Stop save process if validation fails
    }

    // Proceed with saving if validation passes
    const updatedData = { ...customerData, ...formData };
    localStorage.setItem(
      "customerRegisterFormData1",
      JSON.stringify(updatedData)
    );
    localStorage.setItem(
      "customerRegisterFormData2",
      JSON.stringify(updatedData)
    );
    // Reset active field state
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: { ...prevInputs[field], disabled: true },
    }));
    setActiveField(null);
    setPreviousValue(null);
  };
  // Handle cancel click to revert the changes and disable the input
  const handleCancel = (field: keyof typeof inputs) => {
    setInputs((prevInputs) => {
      const updatedInputs = { ...prevInputs };

      // Revert the specific field to its previous value and disable it
      updatedInputs[field] = {
        ...updatedInputs[field],
        value: previousValue || customerData?.[field] || "", // Revert to previous value
        disabled: true,
        error: "", // Clear error message for this field
      };

      // Clear errors for all fields if needed
      for (const key in updatedInputs) {
        updatedInputs[key as keyof typeof inputs].error = "";
      }

      return updatedInputs;
    });

    setActiveField(null);
    setPreviousValue(null);
  };

  // Handle image file selection
  const handleImageFileSelect = (
    file: File | null,
    imageType: keyof typeof inputs
  ) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;

        setInputs((prevInputs) => ({
          ...prevInputs,
          [imageType]: { ...prevInputs[imageType], value: base64String },
        }));

        // Update localStorage for the specific image type
        const updatedData = {
          ...customerData,
          [imageType]: base64String,
        };

        localStorage.setItem(
          `customerRegisterFormData2`,
          JSON.stringify(updatedData)
        );
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRadioChange = (newValue: string) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      verificationMethod: { ...prevInputs.verificationMethod, value: newValue },
    }));
  };

  const handleRadioEditClick = () => {
    setIsRadioEditMode(true);
  };

  const handleRadioSaveClick = () => {
    const updatedData = {
      ...customerData,
      verificationMethod: inputs.verificationMethod.value,
    };
    localStorage.setItem(
      `customerRegisterFormData2`,
      JSON.stringify(updatedData)
    );
    setIsRadioEditMode(false);
  };

  const handleRadioCancelClick = () => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      verificationMethod: {
        ...prevInputs.verificationMethod,
        value: customerData?.verificationMethod || "",
      },
    }));
    setIsRadioEditMode(false);
  };

  return (
    <div>
      {/* සම්පූර්ණ නම* */}
      <Input
        id={"loanReason"}
        name={"loanReason"}
        label="ණයක් ලබා ගැනීමට හේතුව*"
        placeholder={"ණයක් ලබා ගැනීමට හේතුව*"}
        disabled={inputs.loanReason.disabled}
        value={inputs.loanReason.value}
        onChange={(e) => handleInputChange(e, "loanReason")}
        backIcon={
          <CiEdit
            className="mt-5 cursor-pointer lg:mt-6"
            onClick={() => handleEditClick("loanReason")}
          />
        }
        saveCancel={!inputs.loanReason.disabled}
        saveOnclick={() => handleSave("loanReason")}
        cancelOnclick={() => handleCancel("loanReason")}
        errorMessage={inputs.loanReason.error}
      />
      {/* සම්පූර්ණ නම* */}
      <Input
        id={"fullName"}
        name={"fullName"}
        label="සම්පූර්ණ නම*"
        placeholder={"සම්පූර්ණ නම*"}
        disabled={inputs.fullName.disabled}
        value={inputs.fullName.value}
        onChange={(e) => handleInputChange(e, "fullName")}
        backIcon={
          <CiEdit
            className="mt-5 cursor-pointer lg:mt-6"
            onClick={() => handleEditClick("fullName")}
          />
        }
        saveCancel={!inputs.fullName.disabled}
        saveOnclick={() => handleSave("fullName")}
        cancelOnclick={() => handleCancel("fullName")}
        errorMessage={inputs.fullName.error}
      />
      {/* ස්ථීර ලිපිනය* */}
      <Input
        id={"address"}
        name={"address"}
        label="ස්ථීර ලිපිනය*"
        placeholder={"ස්ථීර ලිපිනය*"}
        disabled={inputs.address.disabled}
        value={inputs.address.value}
        onChange={(e) => handleInputChange(e, "address")}
        backIcon={
          <CiEdit
            className="mt-5 cursor-pointer lg:mt-6"
            onClick={() => handleEditClick("address")}
          />
        }
        saveCancel={!inputs.address.disabled}
        saveOnclick={() => handleSave("address")}
        cancelOnclick={() => handleCancel("address")}
        errorMessage={inputs.address.error}
      />

      <div className="grid md:gap-3 md:grid-cols-2">
        {/* ජාතික හැඳුනුම්පත් අංකය* */}
        <Input
          id={"NIC"}
          name={"NIC"}
          label="ජාතික හැඳුනුම්පත් අංකය*"
          placeholder={"ජාතික හැඳුනුම්පත් අංකය*"}
          disabled={inputs.nic.disabled}
          value={inputs.nic.value}
          onChange={(e) => handleInputChange(e, "nic")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("nic")}
            />
          }
          saveCancel={!inputs.nic.disabled}
          saveOnclick={() => handleSave("nic")}
          cancelOnclick={() => handleCancel("nic")}
          errorMessage={inputs.nic.error}
          restrictSpaces
        />
        {/* පොලිස් ස්ථානය* */}
        <Input
          id={"policeStation"}
          name={"policeStation"}
          label="පොලිස් ස්ථානය*"
          placeholder={"පොලිස් ස්ථානය*"}
          disabled={inputs.policeStation.disabled}
          value={inputs.policeStation.value}
          onChange={(e) => handleInputChange(e, "policeStation")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("policeStation")}
            />
          }
          saveCancel={!inputs.policeStation.disabled}
          saveOnclick={() => handleSave("policeStation")}
          cancelOnclick={() => handleCancel("policeStation")}
          errorMessage={inputs.policeStation.error}
        />
      </div>
      <div className="grid md:gap-3 md:grid-cols-2">
        {/* ග්‍රාම නිලධාරී වසම* */}
        <Input
          id={"gramaDivision"}
          name={"gramaDivision"}
          label="ග්‍රාම නිලධාරී වසම*"
          placeholder={"ග්‍රාම නිලධාරී වසම*"}
          disabled={inputs.gramaDivision.disabled}
          value={inputs.gramaDivision.value}
          onChange={(e) => handleInputChange(e, "gramaDivision")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("gramaDivision")}
            />
          }
          saveCancel={!inputs.gramaDivision.disabled}
          saveOnclick={() => handleSave("gramaDivision")}
          cancelOnclick={() => handleCancel("gramaDivision")}
          errorMessage={inputs.gramaDivision.error}
        />
        {/* පළාත් පාලන ආයතනය */}
        <Input
          id={"provincialOffice"}
          name={"provincialOffice"}
          label="පළාත් පාලන ආයතනය*"
          placeholder={"පළාත් පාලන ආයතනය*"}
          disabled={inputs.provincialOffice.disabled}
          value={inputs.provincialOffice.value}
          onChange={(e) => handleInputChange(e, "provincialOffice")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("provincialOffice")}
            />
          }
          saveCancel={!inputs.provincialOffice.disabled}
          saveOnclick={() => handleSave("provincialOffice")}
          cancelOnclick={() => handleCancel("provincialOffice")}
          errorMessage={inputs.provincialOffice.error}
        />
      </div>
      {/* අනන්‍යතාව සහතික කිරීම සඳහා පහත ඒවායින් එකක් තෝරාගන්න */}
      <div className="flex">
        <div className="mr-3">
          <Description
            content="අනන්‍යතාව සහතික කිරීම සඳහා පහත ඒවායින් එකක් තෝරාගන්න*"
            fontWeight="font-bold"
          />{" "}
        </div>
        {/* edit button  */}
        <div>
          {!isRadioEditMode && (
            <CiEdit
              className="mt-[2px] cursor-pointer text-h5 text-primaryColor-500"
              onClick={handleRadioEditClick}
            />
          )}

          {isRadioEditMode && (
            <div className={` flex  gap-5 text-h4`}>
              <span
                className={`text-green-600 cursor-pointer`}
                onClick={handleRadioSaveClick}
              >
                <MdDone />
              </span>
              <span
                className={`text-red-600 cursor-pointer`}
                onClick={handleRadioCancelClick}
              >
                <RxCross2 />
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-wrap gap-5">
        <RadioButton
          id={"customernic"}
          label={"ජාතික හැඳුනුම්පත"}
          checked={inputs.verificationMethod.value === "NIC"}
          disabled={!isRadioEditMode}
          onChange={() => handleRadioChange("NIC")}
        />
        <RadioButton
          id={"customerdrivingLicense"}
          label={"රියදුරු බලපත්‍රය"}
          checked={inputs.verificationMethod.value === "DRIVER_LICENCE"}
          disabled={!isRadioEditMode}
          onChange={() => handleRadioChange("DRIVER_LICENCE")}
        />
        <RadioButton
          id={"customerpassport"}
          label={"විදේශ ගමන් බලපත්‍රය"}
          checked={inputs.verificationMethod.value === "PASSPORT"}
          disabled={!isRadioEditMode}
          onChange={() => handleRadioChange("PASSPORT")}
        />
      </div>
      {/* Attach nic, driving license or passport photos  */}
      <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row">
        <Description content="ඡායාරූප අමුණන්න*" fontWeight="font-bold" />
        {/* add images */}
        <div className="flex space-x-2">
          <ImageChip
            id={"customerNicImageFront"}
            imageName={"ඉදිරිපස"}
            onClick={() => setIsOpenFrontImage(true)}
          />
          <ImageChip
            id={"customerNicImageBack"}
            imageName={"පසුපස"}
            onClick={() => setIsOpenBackImage(true)}
          />
        </div>
      </div>
      {/*customer Image URL */}
      <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row ">
        <Description content="ණයගැනුම්කරුගෙ ඡායාරූපය*" fontWeight="font-bold" />
        <ImageChip
          id="customerImageURL"
          imageName={"ඡායාරූපය"}
          onClick={() => setIsOpenCustomerImage(true)}
        />
      </div>
      {/*electricity Bill Image URL */}
      <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row ">
        <Description
          content="ජල බිල හෝ විදුලි බිලෙහි පිටපතක්  අමුණන්න*"
          fontWeight="font-bold"
        />
        <ImageChip
          id="electricityBillImageURL"
          imageName={"බිල්පත "}
          onClick={() => setIsOpenEctricityBillImage(true)}
        />
      </div>
      <div className="grid mt-5 md:gap-3 md:grid-cols-2">
        {/* phone Number  */}
        <Input
          id={"phoneNumber"}
          name={"phoneNumber"}
          type="number"
          label="දුරකථන අංකය*"
          placeholder={"දුරකථන අංකය*"}
          disabled={inputs.phoneNumber.disabled}
          value={inputs.phoneNumber.value}
          onChange={(e) => handleInputChange(e, "phoneNumber")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("phoneNumber")}
            />
          }
          saveCancel={!inputs.phoneNumber.disabled}
          saveOnclick={() => handleSave("phoneNumber")}
          cancelOnclick={() => handleCancel("phoneNumber")}
          errorMessage={inputs.phoneNumber.error}
          restrictSpaces
        />
        {/* email Address  */}
        <Input
          id={"email"}
          name={"email"}
          label="ඊමේල් ලිපිනය*"
          placeholder={"ඊමේල් ලිපිනය"}
          disabled={inputs.email.disabled}
          value={inputs.email.value}
          onChange={(e) => handleInputChange(e, "email")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("email")}
            />
          }
          saveCancel={!inputs.email.disabled}
          saveOnclick={() => handleSave("email")}
          cancelOnclick={() => handleCancel("email")}
          errorMessage={inputs.email.error}
          restrictSpaces
        />
      </div>
      <div className="grid md:gap-3 md:grid-cols-2">
        {/* phone Number  */}
        <Input
          id={"optionalContactNumber"}
          name={"optionalContactNumber"}
          type="number"
          placeholder={"දුරකථන අංකය "}
          label="දුරකථන අංකය (අමතර)"
          disabled={inputs.optionalContactNumber.disabled}
          value={inputs.optionalContactNumber.value}
          onChange={(e) => handleInputChange(e, "optionalContactNumber")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("optionalContactNumber")}
            />
          }
          saveCancel={!inputs.optionalContactNumber.disabled}
          saveOnclick={() => handleSave("optionalContactNumber")}
          cancelOnclick={() => handleCancel("optionalContactNumber")}
          errorMessage={inputs.optionalContactNumber.error}
          restrictSpaces
        />

        {/* whatsapp Number  */}
        <Input
          id={"whatsAppNumber"}
          name={"whatsAppNumber"}
          type="number"
          placeholder={"වට්ස් ඇප්"}
          label="වට්ස් ඇප්*"
          disabled={inputs.whatsAppNumber.disabled}
          value={inputs.whatsAppNumber.value}
          onChange={(e) => handleInputChange(e, "whatsAppNumber")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("whatsAppNumber")}
            />
          }
          saveCancel={!inputs.whatsAppNumber.disabled}
          saveOnclick={() => handleSave("whatsAppNumber")}
          cancelOnclick={() => handleCancel("whatsAppNumber")}
          errorMessage={inputs.whatsAppNumber.error}
          restrictSpaces
        />
      </div>
      <div className="grid md:gap-3 md:grid-cols-2">
        {/* work Place  */}
        <Input
          id={"companyName"}
          name={"companyName"}
          placeholder={"සේවා ස්ථානය "}
          label="සේවා ස්ථානය*"
          labelWeight="font-bold"
          disabled={inputs.companyName.disabled}
          value={inputs.companyName.value}
          onChange={(e) => handleInputChange(e, "companyName")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("companyName")}
            />
          }
          saveCancel={!inputs.companyName.disabled}
          saveOnclick={() => handleSave("companyName")}
          cancelOnclick={() => handleCancel("companyName")}
          errorMessage={inputs.companyName.error}
        />

        {/* job  */}
        <Input
          id={"job"}
          name={"job"}
          placeholder={"රැකියාව "}
          label="රැකියාව*"
          labelWeight="font-bold"
          disabled={inputs.occupation.disabled}
          value={inputs.occupation.value}
          onChange={(e) => handleInputChange(e, "occupation")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("occupation")}
            />
          }
          saveCancel={!inputs.occupation.disabled}
          saveOnclick={() => handleSave("occupation")}
          cancelOnclick={() => handleCancel("occupation")}
          errorMessage={inputs.occupation.error}
        />
      </div>
      {/* job Location  */}
      <Input
        id={"jobLocation"}
        name={"jobLocation"}
        placeholder={"සේවා ස්ථානයෙ ලිපිනය "}
        label="සේවා ස්ථානයෙ ලිපිනය*"
        labelWeight="font-bold"
        disabled={inputs.companyAddress.disabled}
        value={inputs.companyAddress.value}
        onChange={(e) => handleInputChange(e, "companyAddress")}
        backIcon={
          <CiEdit
            className="mt-5 cursor-pointer lg:mt-6"
            onClick={() => handleEditClick("companyAddress")}
          />
        }
        saveCancel={!inputs.companyAddress.disabled}
        saveOnclick={() => handleSave("companyAddress")}
        cancelOnclick={() => handleCancel("companyAddress")}
        errorMessage={inputs.companyAddress.error}
      />
      {/* frontImageURL iamge overview modal  */}
      <ImageOverviewModal
        isOpen={isOpenFrontImage}
        onClose={() => setIsOpenFrontImage(false)}
        imageUrl={
          customerData?.frontImageURL ? `${customerData.frontImageURL}` : ""
        }
        onFileSelect={(file) => handleImageFileSelect(file, "frontImageURL")}
      />

      {/* backImageURL iamge overview modal  */}
      <ImageOverviewModal
        isOpen={isOpenBackImage}
        onClose={() => setIsOpenBackImage(false)}
        imageUrl={
          customerData?.backImageURL ? `${customerData.backImageURL}` : ""
        }
        onFileSelect={(file) => handleImageFileSelect(file, "backImageURL")}
      />
      {/* customerImageURL iamge overview modal  */}
      <ImageOverviewModal
        isOpen={isOpenCustomerImage}
        onClose={() => setIsOpenCustomerImage(false)}
        imageUrl={
          customerData?.customerImageURL
            ? `${customerData.customerImageURL}`
            : ""
        }
        onFileSelect={(file) => handleImageFileSelect(file, "customerImageURL")}
      />
      {/* electricityBillImageURL iamge overview modal  */}
      <ImageOverviewModal
        isOpen={isOpenelEctricityBillImage}
        onClose={() => setIsOpenEctricityBillImage(false)}
        imageUrl={
          customerData?.electricityBillImageURL
            ? `${customerData.electricityBillImageURL}`
            : ""
        }
        onFileSelect={(file) =>
          handleImageFileSelect(file, "electricityBillImageURL")
        }
      />
    </div>
  );
};

export default CustomerDetails;
