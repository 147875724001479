import React from "react";
import { A_DashboardTable, A_ReleaseFundsTable } from "../../Tables";
import { Main, Title } from "../../common";

const A_ApproveRelease: React.FC = () => {
  return (
    <div>
      {/* to be approve table  */}
      <Main>
        <Title title="To be Approved Loans" bgStyle={false} />
        {/* table  */}
        <A_DashboardTable />
      </Main>

      {/* to be release table  */}
      <Main>
        <div>
          {/* table  */}
          <A_ReleaseFundsTable />
        </div>
      </Main>
    </div>
  );
};

export default A_ApproveRelease;
