import React from "react";
import { Modal } from "antd";
import PrimaryTitle from "../text/PrimaryTitle";
import Button from "../buttons/Button";
import Input from "../inputs/Input";

const AddNewInterestRate: React.FC<{
  visible: boolean;
  icon?: React.ReactNode;
  onClose: () => void;
  closeIcon?: React.ReactNode;
  value: string;
  handleAddNewInterestRate: () => void;
  handleChangeNewRate: React.ChangeEventHandler<HTMLInputElement>;
}> = ({
  visible,
  onClose,
  icon,
  closeIcon,
  value,
  handleChangeNewRate,
  handleAddNewInterestRate,
}) => {
  // handle close modal
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <Modal
      open={visible}
      onCancel={handleCloseModal}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="custom-modal"
    >
      <div className="space-y-5 font-poppins">
        {/* icon */}
        <div className="text-h2">{icon}</div>
        {/* main title */}
        <div className="flex justify-center text-center">
          <PrimaryTitle
            text={"Add New Interest Rate"}
            fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
            center
          />
        </div>

        {/* add new interest */}
        <div>
          <Input
            id={"newRate"}
            name={"newRate"}
            placeholder={"Enter Interest Rate"}
            value={value}
            onChange={handleChangeNewRate}
            type="number"
          />
        </div>

        <div className="justify-center gap-4 xs:flex">
          {/* add new interest rate */}
          <div className="justify-center max-xs:flex">
            <Button text={"Add Interest"} onClick={handleAddNewInterestRate} />
            {/* call the add handler */}
          </div>
          {/* cancel button */}
          <div className="justify-center max-xs:flex max-xs:mt-2">
            <Button
              text={"Cancel"}
              variant="secondary"
              onClick={handleCloseModal}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddNewInterestRate;
