import React, { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import { getAllSchedulesByCusNIC } from "../../api/schedule";
import { Button, Description, Logo } from "../common";
import { formatDate } from "../../utils/formatters";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";

// Extend the jsPDF interface to include autoTable
declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}

interface Installments {
  currentInstallmentAmount: number;
  dueDate: string;
  latePaymentInterest: number;
  loanAmount: number;
  loanStartDate: string;
  paymentStatus: string;
  totalAmountDue: number;
  scheduleId: number;
}

interface BlacklistOverviewProps {
  visible: boolean;
  icon?: React.ReactNode;
  onClose?: () => void;
  closeIcon?: React.ReactNode;
  customerNic: string;
  customerName?: string;
  customerAddress?: string;
}

const ScheduleListModal: React.FC<BlacklistOverviewProps> = ({
  visible,
  onClose,
  closeIcon,
  customerNic,
  customerName,
  customerAddress,
}) => {
  const [getInstallments, setGetInstallments] = useState<Installments[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getInstallmentsByCusNIC = async () => {
      const token = localStorage.getItem("token") || "";
      const nic = customerNic || "";

      try {
        const response = await getAllSchedulesByCusNIC(token, nic);
        const sortedData = response.data.sort(
          (a: Installments, b: Installments) => a.scheduleId - b.scheduleId
        );
        setGetInstallments(sortedData);
      } catch (error) {
        console.error(error);
      }
    };
    if (customerNic) {
      getInstallmentsByCusNIC();
    }
  }, [customerNic]);

  const handleDownloadPDF = () => {
    setIsLoading(true); // Set loading state to true

    const content = document.getElementById("pdf-header-content");

    if (content) {
      html2canvas(content, { scale: 2 })
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = 190;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          // Add the image (HTML content) to the PDF
          pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);

          // Prepare table data
          const tableData = getInstallments.map((installment, index) => [
            index + 1,
            installment.currentInstallmentAmount.toFixed(2),
            formatDate(installment.dueDate),
            installment.latePaymentInterest,
            formatDate(installment.loanStartDate),
            installment.totalAmountDue.toFixed(2),
            installment.paymentStatus === "PAID" ? "Paid" : " ",
          ]);

          // Generate the table after the image
          pdf.autoTable({
            head: [
              [
                "ID",
                "Installment Amount (Rs.)",
                "Due Date",
                "Late Interest",
                "Loan Start Date",
                "Total Amount Due",
                "Payment Status",
              ],
            ],
            body: tableData,
            startY: imgHeight + 20,
            theme: "striped",
            headStyles: {
              fillColor: [142, 187, 177],
              textColor: [255, 255, 255],
              fontStyle: "bold",
              halign: "center",
            },
            styles: { fontSize: 8 },
            bodyStyles: {
              textColor: [0, 0, 0],
              halign: "center",
            },
            columnStyles: {
              0: { cellWidth: 10 },
              6: { cellWidth: 15, halign: "center" },
            },
          });

          pdf.save(`${customerNic}_all-schedules.pdf`);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="p-5 font-semibold custom-modal"
      width="max-w-xl"
    >
      <div className="flex justify-center">
        <Button
          text={isLoading ? "Downloading..." : "Download as PDF"}
          onClick={handleDownloadPDF}
          disabled={isLoading}
          isLoading={isLoading}
        />
      </div>

      <div id="pdf-header-content" className="p-5 ">
        {/* Our Details */}

        <div className="-mb-2 -ml-2">
          <Logo width="w-52" />
        </div>
        <div className="flex justify-between">
          <div className="mb-5 text-center">
            <Description
              content="26, ඉසුරු නිවස, "
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor="text-gray-600"
            />
            <Description
              content="කෝන්කැටියාර සූරියවැව"
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor="text-gray-600"
            />
            <Description
              content="info@credimanage.com, "
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor={"text-gray-600"}
            />
          </div>

          {/* customer details  */}
          <div>
            <Description
              content={customerName || ""}
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor="text-gray-600"
              right
            />
            <Description
              content={customerNic || ""}
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor="text-gray-600"
              right
            />
            <Description
              content={customerAddress || ""}
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor={"text-gray-600"}
              right
            />
          </div>
        </div>
      </div>

      <Table
        dataSource={getInstallments}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center" as const,
            render: (_: any, __: any, index: number) => index + 1,
          },
          {
            title: "Installment Amount (Rs.)",
            dataIndex: "currentInstallmentAmount",
            key: "currentInstallmentAmount",
            align: "center" as const,
            render: (amount: number) => `${amount.toFixed(2)}`,
          },
          {
            title: "Due Date",
            dataIndex: "dueDate",
            key: "dueDate",
            align: "center" as const,
            render: (date: string) => formatDate(date),
          },
          {
            title: "Late Payment Interest",
            dataIndex: "latePaymentInterest",
            key: "latePaymentInterest",
            align: "center" as const,
          },
          {
            title: "Loan Start Date",
            dataIndex: "loanStartDate",
            key: "loanStartDate",
            align: "center" as const,
            render: (date: string) => formatDate(date),
          },
          {
            title: "Total Amount Due",
            dataIndex: "totalAmountDue",
            key: "totalAmountDue",
            align: "center" as const,
            render: (amount: number) => `${amount.toFixed(2)}`,
          },
          {
            title: "Payment Status",
            dataIndex: "paymentStatus",
            key: "paymentStatus",
            align: "center" as const,
            render: (status: string) => (
              <span className="flex justify-center">
                {status === "PAID" && <div className="-mt-2 text-h7">Paid</div>}
              </span>
            ),
          },
        ]}
        rowKey="scheduleId"
        pagination={false}
        bordered
        scroll={{ x: "max-content" }}
      />
    </Modal>
  );
};

export default ScheduleListModal;
