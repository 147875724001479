import React from "react";
import { LoginPage } from "../components/page";

const Login: React.FC = () => {
  return (
    <div>
      {/* login page  */}
      <LoginPage />
    </div>
  );
};

export default Login;
