import React from "react";
import { Main, Title } from "../../common";
import { A_AllLoansTable } from "../../Tables";

const A_LoanLIstPage: React.FC = () => {
  return (
    <div>
      {/* main container  */}
      <Main>
        {/* title  */}
        <Title title="All Loans" bgStyle={false} />
        {/* table  */}
        <A_AllLoansTable />
      </Main>
    </div>
  );
};

export default A_LoanLIstPage;
