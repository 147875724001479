import axios from "axios";
import { BASE_URL, apiOptionJson } from "./base-url";

// get console logs
export const concoleLogs = async (token: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/admin/get-log-entries`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.data;
  } catch (error) {
    return error;
  }
};
