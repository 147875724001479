import { useState } from "react";
import imageCompression from "browser-image-compression";

const useImageCompressor = () => {
  const [compressionError, setCompressionError] = useState<string | null>(null);

  const compressImage = async (file: File, targetSizeFactor: number) => {
    try {
      // Calculate the original size in MB and the target size (50% of the original)
      const originalSizeMB = file.size / 1024 / 1024;
      const targetSizeMB = originalSizeMB * targetSizeFactor;

      // Compress the image using the target size and other options
      const compressedFile = await imageCompression(file, {
        maxSizeMB: targetSizeMB,
        maxWidthOrHeight: 1024, // Adjust this value as needed
        useWebWorker: true, // Use web workers for large images
      });

      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
      setCompressionError("Failed to compress image");
      return null;
    }
  };

  return { compressImage, compressionError };
};

export default useImageCompressor;
