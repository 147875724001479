import axios from "axios";
import { BASE_URL, apiOptionJson } from "./base-url";

interface registerTypes {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  contactNumber: string;
  role: string;
  password: string;
  confirmPassword: string;
  supervisedBy: string;
}

interface loginTypes {
  identifier: string;
  password: string;
}

// register
export const register = async (token: string, data: registerTypes) => {
  try {
    const res = await axios.post(`${BASE_URL}/api/v1/auth/register`, data, {
      headers: {
        ...apiOptionJson.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

// login
export const login = async (data: loginTypes) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/auth/login`,
      data,
      apiOptionJson
    );
    return response.data;
  } catch (response) {
    return response;
  }
};

// get current user
export const getCurrentUser = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/auth/current-user`, {
      headers: {
        ...apiOptionJson.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (response) {
    return response;
  }
};

// get otp by email
export const getOtpByEmail = async (email: string) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/auth/forgot-password/email`,
      email,
      {
        headers: {
          ...apiOptionJson.headers,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// verify oty getting email or phone number
export const verifyOtp = async (getOtp: string, email: string) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/auth/forgot-password/verify?otp=${getOtp}&email=${email}`,
      {
        headers: {
          ...apiOptionJson.headers,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// update new password
export const resetPassword = async (email: string, newPassword: string) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/api/v1/auth/forgot-password/change-email?email=${email}`,
      newPassword,
      {
        headers: {
          ...apiOptionJson.headers,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// delete user
export const deleteuser = async (token: string, userId: number) => {
  try {
    const response = await axios.delete(`${BASE_URL}/api/v1/users/${userId}`, {
      headers: {
        ...apiOptionJson.headers,
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

// check if can user delete
export const canUserDelete = async (token: string, appUserId: number) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/users/can-delete/${appUserId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

//delete and update app user
export const updateNewAppuser = async (
  token: string,
  oldAppUserId: number,
  newAppUserId: number
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/api/user-role-transfer/transfer?oldAppUserId=${oldAppUserId}&newAppUserId=${newAppUserId}`,
      {},
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// permanent delete
export const permanentDelete = async (token: string, appUserId: number) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/api/v1/users/delete/${appUserId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get app user User role
export const getUserRoleByUserId = async (token: string, appUserId: number) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/user-role-transfer/${appUserId}/role`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
