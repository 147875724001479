import React, { useEffect, useState } from "react";
import { Button, Main, Title } from "../../common";
import { C_DashboardTable } from "../../Tables";
import { useNavigate } from "react-router-dom";
import { allCustomers } from "../../../api/customer";

interface Item {
  id: number;
  customerId: string;
  customerName: string;
  collectorUsername?: string; // Add '?' to handle cases where this might be undefined
  customerAddress: string;
  startDate: string;
  loanStatus: string;
  collImgUrl?: string;
  loanAmount?: number;
}

const C_Dashboard: React.FC = () => {
  const navigate = useNavigate(); // Hook to navigate between routes
  const [customersData, setCustomersData] = useState<Item[]>([]);

  // buttons labels and onclick data
  const buttonsData = [
    {
      text: "Customer Registration",
      onClick: () => navigate("/customer-register"),
    },
    { text: "Add Payment", onClick: () => navigate("/add-payment") },
    { text: "Loan List", onClick: () => navigate("/loan-list") },
    {
      text: "Payment List",
      onClick: () => navigate("/payment-list"),
    },
    { text: "Schedules List", onClick: () => navigate("/schedule-list") },
    { text: "View Customers", onClick: () => navigate("/customer-list") },
  ];

  // handle navigate customer overview
  const handleNavigateCusOverview = (status: string) => {
    navigate(`/customer-list`, { state: { status } });
  };

  // Fetch all customers data
  useEffect(() => {
    const getCustomersData = async () => {
      const token = localStorage.getItem("token") || "";
      const userId = localStorage.getItem("userId");

      if (!userId) {
        console.error("User ID is not available");
        return;
      }

      try {
        const response = await allCustomers(token, parseInt(userId));
        setCustomersData(response.data);
       } catch (error) {
        console.log(error);
      }
    };

    getCustomersData();
  }, []);

  // Filter customers by loan status and count them
  const countByStatus = (status: string) => {
    return customersData.filter((customer) => customer.loanStatus === status)
      .length;
  };

  const pendingCount = countByStatus("PENDING");
  const approvedCount = countByStatus("APPROVED");
  const rejectedCount = countByStatus("REJECTED");
  const completeCount = countByStatus("COMPLETE");

  return (
    <div>
      {/* main container  */}
      <Main>
        {/* main title  */}
        <div className="justify-between pb-2 md:flex">
          <Title title="Quick Actions" bgStyle={false} />
          {/* loan status  */}
          <div className="flex justify-center gap-5 max-md:pb-4 max-md:pt-3">
            {/* pending status  */}
            <div className="gap-5 sm:flex max-sm:space-y-5">
              <div
                onClick={() => handleNavigateCusOverview("PENDING")}
                className="relative w-24 h-8 py-1 font-semibold text-center rounded-lg cursor-pointer text-h8 bg-primaryColor-50 text-primaryColor-500"
              >
                Pending
                <div className="absolute flex items-center justify-center w-8 h-8 text-white rounded-full text-h9 -mt-11 -right-3 bg-primaryColor-500">
                  {pendingCount}
                </div>
              </div>
              {/* Approved status  */}
              <div
                onClick={() => handleNavigateCusOverview("APPROVED")}
                className="relative w-24 h-8 py-1 font-semibold text-center rounded-lg cursor-pointer text-h8 bg-primaryColor-50 text-primaryColor-500"
              >
                Approved
                <div className="absolute flex items-center justify-center w-8 h-8 text-white rounded-full text-h9 -mt-11 -right-3 bg-primaryColor-500">
                  {approvedCount}
                </div>
              </div>
            </div>
            <div className="gap-5 sm:flex max-sm:space-y-5">
              {/* Rejected status  */}
              <div
                onClick={() => handleNavigateCusOverview("REJECTED")}
                className="relative w-24 h-8 py-1 font-semibold text-center rounded-lg cursor-pointer text-h8 bg-primaryColor-50 text-primaryColor-500"
              >
                Rejected
                <div className="absolute flex items-center justify-center w-8 h-8 text-white rounded-full text-h9 -mt-11 -right-3 bg-primaryColor-500">
                  {rejectedCount}
                </div>
              </div>{" "}
              {/* Complete status  */}
              <div
                onClick={() => handleNavigateCusOverview("COMPLETE")}
                className="relative w-24 h-8 py-1 font-semibold text-center rounded-lg cursor-pointer text-h8 bg-primaryColor-50 text-primaryColor-500"
              >
                Complete
                <div className="absolute flex items-center justify-center w-8 h-8 text-white rounded-full text-h9 -mt-11 -right-3 bg-primaryColor-500">
                  {completeCount}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*quick actions buttons  */}
        <div className="grid gap-5 sm:gap-10 sm:grid-cols-2 lg:grid-cols-3 w-[90%] mx-auto">
          {buttonsData.map((button, index) => (
            <Button
              key={index}
              text={button.text}
              width="w-[100%]"
              onClick={button.onClick}
            />
          ))}
        </div>
        <div className="mt-10">
          {/* table title  */}
          <Title title={"Today Activities"} bgStyle={false} />

          {/* table  */}
          <C_DashboardTable />
        </div>
      </Main>
    </div>
  );
};

export default C_Dashboard;
