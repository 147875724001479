import React from "react";
import Description from "../text/Description";

interface NotificationCardProp {
  title: string;
  description: string; // Added description prop
  dateTime: string;
}

const NotificationCard: React.FC<NotificationCardProp> = ({
  title,
  description,
  dateTime,
}) => {
  return (
    <div className="py-2 pl-3 my-2 leading-snug duration-150 shadow hover:bg-gray-100">
      {/* title  */}
      <Description content={title} fontWeight="font-bold" />
      {/* message  */}
      <Description
        content={description}
        fontSize="text-h8"
        fontColor="text-gray-600"
      />
      {/* date and time  */}
      <div className="flex justify-end mr-2">
        <Description
          content={dateTime}
          fontSize="text-h9"
          fontColor="text-gray-400"
        />
      </div>
    </div>
  );
};

export default NotificationCard;
