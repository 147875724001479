// src/App.tsx
import React from "react";
import MainRouter from "./router/MainRouter";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div>
      {/* toast provider  */}
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            marginTop: "70px", // Adjust this value as needed
            marginBottom: "-70px", // Adjust this value as needed
          },
        }}
      />
      {/* main router */}
      <MainRouter />
    </div>
  );
}

export default App;
