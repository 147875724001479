import React, { useState } from "react";
import { Description, Input, RadioButton, Title } from "../../../../common";
import { CustomerData, Loan } from "../../../../../types";
import ImageChip from "../../../../common/image-chip/ImageChip";
import ImageOverviewModal from "../../../../common/modals/ImageOverviewModal";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { CustomerPassword } from "../../../../modal";

// Define an interface for the component's props
interface CustomerDetailsProps {
  loanStatus: Loan["loanStatus"];
  reason?: Loan["reason"];
  fullName: CustomerData["fullName"];
  address: CustomerData["address"];
  NIC: CustomerData["nic"];
  policeStation: CustomerData["policeStation"];
  gramaDivision: CustomerData["gramaDivision"];
  provincialOffice: CustomerData["provincialOffice"];
  verificationMethod: CustomerData["verificationMethod"];
  frontImageURL: CustomerData["frontImageURL"];
  backImageURL: CustomerData["backImageURL"];
  customerImageURL: CustomerData["customerImageURL"];
  electricityBillImageURL: CustomerData["electricityBillImageURL"];
  companyName: CustomerData["companyName"];
  occupation: CustomerData["occupation"];
  companyAddress: CustomerData["companyAddress"];
  email: CustomerData["email"];
  whatsAppNumber: CustomerData["whatsAppNumber"];
  phoneNumber: CustomerData["phoneNumber"];
  optionalContactNumber: CustomerData["optionalContactNumber"];
  passowrd: string;
  loanReleaseStatus: boolean;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({
  loanStatus,
  reason,
  fullName,
  address,
  NIC,
  policeStation,
  gramaDivision,
  provincialOffice,
  verificationMethod,
  frontImageURL,
  backImageURL,
  customerImageURL,
  electricityBillImageURL,
  companyName,
  occupation,
  companyAddress,
  email,
  whatsAppNumber,
  phoneNumber,
  optionalContactNumber,
  passowrd,
  loanReleaseStatus,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageURL, setCurrentImageURL] = useState<string | null>(null);
  const [passwordModalOpen, setPassowrdModalOpen] = useState<boolean>(false);

  const handleOpenPasswordModal = () => {
    setPassowrdModalOpen(true);
  };
  const handleClosePasswordModal = () => {
    setPassowrdModalOpen(false);
  };

  // handle open image modals
  const handleOpenImageOverviewModal = (imageURL: string) => {
    setCurrentImageURL(imageURL);
    setIsModalOpen(true);
  };

  // Define a function to determine the text color based on the loan status
  const getStatusColor = (status: string) => {
    switch (status) {
      case "APPROVED":
        return "text-green-500";
      case "REJECTED":
        return "text-red-500";
      case "PENDING":
        return "text-gray-500";
      case "COMPLETE":
        return "text-blue-600";
      case "LEGAL_ACTION":
        return "text-red-500";
      default:
        return "text-black";
    }
  };

  return (
    <div>
      <div className="flex justify-between">
        {/* Loan Status */}
        <div className="mb-5">
          <div className="flex gap-3">
            <Title title={"Loan Status : "} bgStyle={false} />
            <Title
              title={loanStatus}
              bgStyle={false}
              textColor={getStatusColor(loanStatus)}
            />
          </div>
          {loanStatus === "REJECTED" && (
            <div className="flex gap-3 -mt-2">
              <Description content={"Reason :"} fontWeight="font-bold" />
              <Description content={reason || ""} />
            </div>
          )}
        </div>

        {/* customer password  */}
        {/* customer password  */}
        {loanReleaseStatus && (
          <div
            className={`flex items-center justify-center h-10 gap-2 px-3 text-white cursor-pointer bg-primaryColor-500 text-h5 rounded-2xl ${loanStatus === "REJECTED" || loanStatus === "LEGAL_ACTION" || loanStatus === "COMPLETE" || loanStatus === "PENDING" ? "hidden" : ""}`}
            onClick={handleOpenPasswordModal}
          >
            <div className="text-h7">Password</div>
            <AiOutlineEyeInvisible
              className={`${passwordModalOpen ? "hidden" : ""}`}
            />
            <AiOutlineEye className={`${passwordModalOpen ? "" : "hidden"}`} />
          </div>
        )}
      </div>

      {/* සම්පූර්ණ නම* */}
      <Input
        id={"fullName"}
        name={"fullName"}
        label="සම්පූර්ණ නම*"
        placeholder={"සම්පූර්ණ නම*"}
        value={fullName}
        disabled
      />
      {/* ස්ථීර ලිපිනය* */}
      <Input
        id={"address"}
        name={"address"}
        label="ස්ථීර ලිපිනය*"
        placeholder={"ස්ථීර ලිපිනය*"}
        value={address}
        disabled
      />
      <div className="grid md:gap-3 md:grid-cols-2">
        {/* ජාතික හැඳුනුම්පත් අංකය* */}
        <Input
          id={"NIC"}
          name={"NIC"}
          label="ජාතික හැඳුනුම්පත් අංකය*"
          placeholder={"ජාතික හැඳුනුම්පත් අංකය*"}
          value={NIC}
          disabled
        />
        {/* පොලිස් ස්ථානය* */}
        <Input
          id={"policeStation"}
          name={"policeStation"}
          label="පොලිස් ස්ථානය*"
          placeholder={"පොලිස් ස්ථානය*"}
          value={policeStation}
          disabled
        />
      </div>
      <div className="grid md:gap-3 md:grid-cols-2">
        {/* ග්‍රාම නිලධාරී වසම* */}
        <Input
          id={"gramaDivision"}
          name={"gramaDivision"}
          label="ග්‍රාම නිලධාරී වසම*"
          placeholder={"ග්‍රාම නිලධාරී වසම*"}
          value={gramaDivision}
          disabled
        />
        {/* පළාත් පාලන ආයතනය */}
        <Input
          id={"provincialOffice"}
          name={"provincialOffice"}
          label="පළාත් පාලන ආයතනය*"
          placeholder={"පළාත් පාලන ආයතනය*"}
          value={provincialOffice}
          disabled
        />
      </div>
      {/* අනන්‍යතාව සහතික කිරීම සඳහා පහත ඒවායින් එකක් තෝරාගන්න */}
      <div className="flex">
        <div className="mr-3">
          <Description
            content="අනන්‍යතාව සහතික කිරීම සඳහා පහත ඒවායින් එකක් තෝරාගන්න"
            fontWeight="font-bold"
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-5">
        <RadioButton
          id={"customernic"}
          label={"ජාතික හැඳුනුම්පත"}
          checked={verificationMethod === "NIC"}
          disabled
        />
        <RadioButton
          id={"customerdrivingLicense"}
          label={"රියදුරු බලපත්‍රය"}
          checked={verificationMethod === "DRIVER_LICENCE"}
          disabled
        />
        <RadioButton
          id={"customerpassport"}
          label={"විදේශ ගමන් බලපත්‍රය"}
          checked={verificationMethod === "PASSPORT"}
          disabled
        />
      </div>
      {/* Attach nic, driving license or passport photos  */}
      <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row">
        <Description
          content="හැඳුනුම්තෙහි ඡායාරූප අමුණන්න"
          fontWeight="font-bold"
        />
        {/* add images */}
        <div className="flex space-x-2">
          <ImageChip
            id={"customerNicImageFront"}
            imageName={"ඉදිරිපස"}
            onClick={() => handleOpenImageOverviewModal(frontImageURL)}
          />
          <ImageChip
            id={"customerNicImageBack"}
            imageName={"පසුපස"}
            onClick={() => handleOpenImageOverviewModal(backImageURL)}
          />
        </div>
      </div>
      {/*customer Image URL */}
      <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row ">
        <Description content="ණයගැනුම්කරුගෙ ඡායාරූපය" fontWeight="font-bold" />
        <ImageChip
          id="customerImageURL"
          imageName={"ඡායාරූපය"}
          onClick={() => handleOpenImageOverviewModal(customerImageURL)}
        />
      </div>
      {/*electricity Bill Image URL */}
      <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row ">
        <Description
          content="ජල බිල හෝ විදුලි බිලෙහි පිටපතක්  අමුණන්න"
          fontWeight="font-bold"
        />
        <ImageChip
          id="electricityBillImageURL"
          imageName={"බිල්පත "}
          onClick={() =>
            handleOpenImageOverviewModal(electricityBillImageURL || "")
          }
        />
      </div>
      <div className="grid mt-5 md:gap-3 md:grid-cols-2">
        {/* phone Number  */}
        <Input
          id={"phoneNumber"}
          name={"phoneNumber"}
          label="දුරකථන අංකය*"
          placeholder={"දුරකථන අංකය*"}
          value={phoneNumber}
          disabled
        />
        {/* email Address  */}
        <Input
          id={"email"}
          name={"email"}
          label="ඊමේල් ලිපිනය"
          placeholder={"ඊමේල් ලිපිනය"}
          value={email}
          disabled
        />
      </div>
      <div className="grid md:gap-3 md:grid-cols-2">
        {/* phone Number  */}
        <Input
          id={"optionalContactNumber"}
          name={"optionalContactNumber"}
          placeholder={"දුරකථන අංකය "}
          label="දුරකථන අංකය (අමතර)*"
          value={optionalContactNumber}
          disabled
        />

        {/* whatsapp Number  */}
        <Input
          id={"whatsAppNumber"}
          name={"whatsAppNumber"}
          placeholder={"වට්ස් ඇප්"}
          label="වට්ස් ඇප්*"
          value={whatsAppNumber}
          disabled
        />
      </div>
      <div className="grid md:gap-3 md:grid-cols-2">
        {/* work Place  */}
        <Input
          id={"companyName"}
          name={"companyName"}
          placeholder={"සේවා ස්ථානය "}
          label="සේවා ස්ථානය*"
          labelWeight="font-bold"
          value={companyName}
          disabled
        />

        {/* job  */}
        <Input
          id={"job"}
          name={"job"}
          placeholder={"රැකියාව "}
          label="රැකියාව*"
          labelWeight="font-bold"
          value={occupation}
          disabled
        />
      </div>
      {/* job Location  */}
      <Input
        id={"jobLocation"}
        name={"jobLocation"}
        placeholder={"සේවා ස්ථානයෙ ලිපිනය "}
        label="සේවා ස්ථානයෙ ලිපිනය*"
        labelWeight="font-bold"
        value={companyAddress}
        disabled
      />

      {/* iamge overview modal  */}
      <ImageOverviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        imageUrl={currentImageURL || ""}
        disableUpload
      />

      {/* customer password modal  */}
      <CustomerPassword
        visible={passwordModalOpen}
        onClose={handleClosePasswordModal}
        customerNIC={NIC || ""}
        customerUserNameValue={NIC || ""}
        closeModal={handleClosePasswordModal}
        customerPassword={passowrd || ""}
      />
    </div>
  );
};

export default CustomerDetails;
