import React from "react";

interface CustomButtonProps {
  isCollapsed: boolean;
  text: string;
  icon: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
  isSubItem?: boolean;
  isSignOut?: boolean;
  fontWeight?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  isCollapsed,
  text,
  icon,
  isActive,
  onClick,
  isSubItem,
  isSignOut,
  fontWeight,
}) => {
  const inactiveStyles =
    isSubItem && !isActive
      ? {
          backgroundColor: "#F3F4F8",
        }
      : isSignOut && !isActive
      ? {
          backgroundColor: "#F3F4F8",
        }
      : {
          backgroundColor: "",
        };

  const activeStyles = {
    backgroundColor: "#b3d1ca",
    color: "#042d24",
    fontFamily: "'PT Poppins', Poppins",

  };

  const buttonStyles = {
    ...inactiveStyles,
    color: "#042d24",
    fontFamily: "'PT Poppins', Poppins",

  };

  const iconStyles = {
    marginRight: isCollapsed ? "0" : "",
  };

  return (
    <div
      onClick={onClick}
      className={`flex font-Poppins ${
        fontWeight && "font-semibold"
      } text-h8 items-center justify-between px-2 py-3 mt-4 rounded-lg cursor-pointer transition-colors duration-200 ${
        isCollapsed
          ? "justify-center"
          : "hover:bg-primaryColor-100 hover:text-primaryColor-900"
      }`}
      style={isActive ? activeStyles : buttonStyles}
    >
      <div
        className="flex items-center"
        style={{ paddingLeft: isCollapsed ? "0" : "" }}
      >
        <span style={iconStyles} className={` ${isCollapsed ? "" : ""}`}>
          {icon}
        </span>
        <span
          className={`font-semibold ${icon ? "ml-3" : "ml-6"} ${
            isCollapsed ? "hidden" : "block whitespace-nowrap"
          }`}
        >
          {text}
        </span>
      </div>
    </div>
  );
};

export default CustomButton;
