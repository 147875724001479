import React, { useEffect, useState } from "react";
import { Input, Main, Title } from "../../common";
import { getUserByUserId } from "../../../api/employee";

type InputField =
  | "firstName"
  | "lastName"
  | "email"
  | "contactNumber"
  | "username";

interface InputState {
  disabled: boolean;
  value: string;
  initialValue: string;
}

interface userDetailsProps {
  userId: number;
}

const A_UserOverviewPage: React.FC<{ userDetails: userDetailsProps }> = ({
  userDetails,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [, setActiveInput] = useState<InputField | null>(null);
  const [, setManagerOption] = useState<string>("");

  // Local state for each input's disabled status, value, and initial value
  const [inputs, setInputs] = useState<Record<InputField, InputState>>({
    firstName: { disabled: true, value: "", initialValue: "" },
    lastName: { disabled: true, value: "", initialValue: "" },
    email: { disabled: true, value: "", initialValue: "" },
    contactNumber: { disabled: true, value: "", initialValue: "" },
    username: { disabled: true, value: "", initialValue: "" },
  });

  // Fetch user details by user id
  useEffect(() => {
    const getUserDetails = async () => {
      const token = localStorage.getItem("token") || "";
      const userId = userDetails.userId;

      try {
        const response = await getUserByUserId(token, userId);
        if (response) {
          setInputs({
            firstName: {
              disabled: true,
              value: response.data.firstName || "",
              initialValue: response.data.firstName || "",
            },
            lastName: {
              disabled: true,
              value: response.data.lastName || "",
              initialValue: response.data.lastName || "",
            },
            email: {
              disabled: true,
              value: response.data.email || "",
              initialValue: response.data.email || "",
            },
            contactNumber: {
              disabled: true,
              value: response.data.contactNumber || "",
              initialValue: response.data.contactNumber || "",
            },
            username: {
              disabled: true,
              value: response.data.username || "",
              initialValue: response.data.username || "",
            },
          });
          setSelectedOption(response.data.role || "");
          setManagerOption(response.data.manager || ""); // Assuming manager information is present
        } else {
          console.log("No response data");
        }
      } catch (error) {
        console.log(error);
      }
    };

    getUserDetails();
  }, [userDetails.userId]);

  // Function to handle input change
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: InputField
  ) => {
    setInputs({
      ...inputs,
      [field]: { ...inputs[field], value: e.target.value },
    });
  };

  // Function to handle save
  const handleSave = (field: InputField) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: {
        ...prevInputs[field],
        disabled: true,
        initialValue: prevInputs[field].value,
      },
    }));
    setActiveInput(null);
  };

  // Function to handle cancel
  const handleCancel = (field: InputField) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: {
        ...prevInputs[field],
        disabled: true,
        value: prevInputs[field].initialValue,
      },
    }));
    setActiveInput(null);
  };

  return (
    <div>
      {/* main title */}
      <Title title={"User Overview"} />

      {/* main container */}
      <Main>
        <div>
          <div className="grid-cols-2 gap-2 sm:grid">
            {/* first name */}
            <Input
              id="firstName"
              name="firstName"
              placeholder="First Name"
              label="First Name"
              disabled={inputs.firstName.disabled}
              value={inputs.firstName.value}
              onChange={(e) => handleInputChange(e, "firstName")}
              saveCancel={!inputs.firstName.disabled}
              saveOnclick={() => handleSave("firstName")}
              cancelOnclick={() => handleCancel("firstName")}
            />

            {/* last name */}
            <Input
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              label="Last Name"
              disabled={inputs.lastName.disabled}
              value={inputs.lastName.value}
              onChange={(e) => handleInputChange(e, "lastName")}
              saveCancel={!inputs.lastName.disabled}
              saveOnclick={() => handleSave("lastName")}
              cancelOnclick={() => handleCancel("lastName")}
            />
          </div>

          <div className="grid-cols-2 gap-2 sm:grid">
            {/* email address */}
            <Input
              id="email"
              name="email"
              placeholder="Email Address"
              label="Email Address"
              disabled={inputs.email.disabled}
              value={inputs.email.value}
              onChange={(e) => handleInputChange(e, "email")}
              saveCancel={!inputs.email.disabled}
              saveOnclick={() => handleSave("email")}
              cancelOnclick={() => handleCancel("email")}
            />

            {/* contact address */}
            <Input
              id="contactNumber"
              name="contactNumber"
              placeholder="Contact Number"
              label="Contact Number"
              type="number"
              disabled={inputs.contactNumber.disabled}
              value={inputs.contactNumber.value}
              onChange={(e) => handleInputChange(e, "contactNumber")}
              saveCancel={!inputs.contactNumber.disabled}
              saveOnclick={() => handleSave("contactNumber")}
              cancelOnclick={() => handleCancel("contactNumber")}
            />
          </div>

          {/* User name */}
          <div className="grid-cols-2 gap-2 sm:grid">
            <Input
              id="username"
              name="username"
              placeholder="User Name"
              label="User Name"
              disabled={inputs.username.disabled}
              value={inputs.username.value}
              onChange={(e) => handleInputChange(e, "username")}
              saveCancel={!inputs.username.disabled}
              saveOnclick={() => handleSave("username")}
              cancelOnclick={() => handleCancel("username")}
            />
          </div>
          <div className="grid-cols-2 gap-2 sm:grid">
            {/* role */}
            <Input
              id="role"
              name="role"
              placeholder=" "
              label="Role"
              disabled={true}
              value={selectedOption}
            />
          </div>
        </div>
      </Main>
    </div>
  );
};

export default A_UserOverviewPage;
