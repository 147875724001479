import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  A_CustomerUpdateOverviewPage,
  M_CustomerUpdateOverviewPage,
  C_CustomerUpdateOverviewPage,
  NotFound,
  Loading,
} from "../components/page";
import { getCurrentUser } from "../api/auth";
import { Main, Title } from "../components/common";

interface Params extends Record<string, string | undefined> {
  userId: string;
}

const CustomerUpdateOverview: React.FC = () => {
  const [userRole, setUserRole] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { userId } = useParams<Params>();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token") || "";
      try {
        const userData = await getCurrentUser(token);
        if (userData && userData.data && userData.data.role) {
          setUserRole(userData.data.role);
        } else {
          console.error("Invalid user data:");
          setUserRole("");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUserRole(""); // Reset userRole to trigger NotFound component
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (!userId) {
    return <NotFound />;
  }

  // Convert userId to a number
  const userIdNumber = Number(userId);

  if (userRole === "ADMIN") {
    return <A_CustomerUpdateOverviewPage customerId={userIdNumber} />;
  } else if (userRole === "MANAGER") {
    return <M_CustomerUpdateOverviewPage customerId={userIdNumber} />;
  } else if (userRole === "COLLECTOR") {
    return (
      <div>
        {/* main title  */}
        <Title title={"ණය ගැනුම්කරු පිලිබඳ විස්තර"} />
        <Main>
          <C_CustomerUpdateOverviewPage customerId={userIdNumber} />
        </Main>
      </div>
    );
  } else {
    return <NotFound />;
  }
};

export default CustomerUpdateOverview;
