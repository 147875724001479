import React from "react";
import { Main, Title } from "../../common";
import { M_GuaranteeListTable } from "../../Tables";

const A_GuaranteeListpage: React.FC = () => {
  return (
    <div>
      {/* main container  */}
      <Main>
        {" "}
        {/* title  */}
        <Title title={"Guarantee List"} bgStyle={false} />
        {/* table  */}
        <M_GuaranteeListTable />
      </Main>
    </div>
  );
};

export default A_GuaranteeListpage;
