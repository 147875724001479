import React from "react";

interface LoginTitleProps {
  text: string;
  fontFamily?: string;
  fontSize?: string;
  fontColor?: "text-primaryColor-900" | "text-primaryColor-500" | "text-soft-red"| string ;
  fontWeight?: "font-semibold" | "font-bold";
  italic?: boolean;
  uppercase?: boolean;
  right?: boolean;
  left?: boolean;
  center?: boolean;
}

const PrimaryTitle: React.FC<LoginTitleProps> = ({
  text,
  fontColor = "text-primaryColor-500",
  fontFamily = "font-Poppins",
  italic,
  fontSize = "text-h4 md:text-h3 lg:text-h2 3xl:text-h1",
  fontWeight = "font-semibold",
  uppercase,
  center,
  right,
  left,
}) => {
  return (
    <span
      className={`flex items-center ${fontFamily} ${fontColor} ${fontSize} ${fontWeight} ${
        italic ? "italic" : ""
      } ${center ? "justify-center" : ""} ${left ? "justify-start" : ""} ${
        right ? "justify-end" : ""
      } ${uppercase ? "uppercase" : ""}`}
    >
      {text}
    </span>
  );
};

export default PrimaryTitle;
