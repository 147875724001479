import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Description, Input } from "../../../common";
import { Step1Schema } from "../../../../lib/validation/customer-register-val";
import { z } from "zod";
import { useGetCustomerDetails } from "../../../../hooks/useGetCustomerDetails";

interface Step1Props {
  setSelect: (step: number) => void;
}

const Step1: React.FC<Step1Props> = ({ setSelect }) => {
  const [formData, setFormData] = useState({
    loanReason: "",
    fullName: "",
    address: "",
    nic: "",
    policeStation: "",
    gramaDivision: "",
    provincialOffice: "",
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isFirstValidation, setIsFirstValidation] = useState(false);

  const { customerDetails, getCustomerDetails, responseSuccess } =
    useGetCustomerDetails();

  const inputRefs = useRef<Record<string, HTMLInputElement | null>>({
    loanReason: null,
    fullName: null,
    address: null,
    nic: null,
    policeStation: null,
    gramaDivision: null,
    provincialOffice: null,
  });

  const clearFirstGuarantor = () => {
    setFormData((prevData) => ({
      ...prevData,
      loanReason: "",
      fullName: "",
      address: "",
      nic: "",
      policeStation: "",
      gramaDivision: "",
      provincialOffice: "",
    }));
    localStorage.removeItem("customerRegisterFormData1");
    localStorage.removeItem("customerRegisterFormData2");
    localStorage.removeItem("nic");
  };

  // Populate formData from localStorage first, then from customerDetails
  useEffect(() => {
    const savedData = localStorage.getItem("customerRegisterFormData1");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData((prevData) => ({ ...prevData, ...parsedData }));
    } else if (customerDetails) {
      setFormData((prevData) => ({
        ...prevData,
        ...customerDetails,
      }));
    }
  }, [customerDetails]);

  // Function to handle input changes and validate form on change
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (isFirstValidation) {
      validateForm({ ...formData, [name]: value });
    }
  };

  // Function to save NIC to localStorage on blur
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "nic") {
      localStorage.setItem("nic", value);
      getCustomerDetails();
    }
  };

  // Function to validate form data using Zod schema
  const validateForm = (data: typeof formData) => {
    try {
      Step1Schema.parse(data);
      setErrors({});
      return { isValid: true, newErrors: {} };
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          newErrors[err.path[0]] = err.message;
        });
        setErrors(newErrors);
        return { isValid: false, newErrors };
      }
      return { isValid: false, newErrors: {} };
    }
  };

  // Function to handle registration step1
  const handleNext = async () => {
    setIsFirstValidation(true);
    const { isValid, newErrors } = validateForm(formData);

    if (isValid) {
      localStorage.setItem(
        "customerRegisterFormData1",
        JSON.stringify(formData)
      );
      setSelect(2);
    } else {
      scrollToFirstError(newErrors);
    }
  };

  const scrollToFirstError = (newErrors: Record<string, string>) => {
    const orderedErrors: Record<string, string> = {};

    Object.keys(formData).forEach((key) => {
      if (newErrors[key]) {
        orderedErrors[key] = newErrors[key];
      }
    });

    const firstErrorKey = Object.keys(orderedErrors)[0];

    if (firstErrorKey && inputRefs.current[firstErrorKey]) {
      const element = inputRefs.current[firstErrorKey];
      const elementOffsetTop =
        element.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: elementOffsetTop - 80,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      {/* clear button  */}
      {responseSuccess && (
        <div className="flex justify-end max-md:mb-3">
          <div
            onClick={clearFirstGuarantor}
            className="flex items-center w-20 px-5 py-1 border rounded-md cursor-pointer md:-mt-3 hover:shadow-3xl active:shadow-inner border-primaryColor-300"
          >
            <Description content="Clear" />
          </div>
        </div>
      )}

      <Input
        ref={(el) => (inputRefs.current.loanReason = el)}
        id={"loanReason"}
        name={"loanReason"}
        placeholder={"ණයක් ලබා ගැනීමට හේතුව"}
        label="ණයක් ලබා ගැනීමට හේතුව*"
        labelWeight="font-bold"
        onChange={handleChange}
        value={formData.loanReason}
        errorMessage={errors.loanReason}
      />
      <Input
        ref={(el) => (inputRefs.current.nic = el)}
        id={"nic"}
        name={"nic"}
        placeholder={"ජාතික හැඳුනුම්පත් අංකය"}
        label="ජාතික හැඳුනුම්පත් අංකය*"
        labelWeight="font-bold"
        onChange={handleChange}
        onBlur={handleBlur}
        value={formData.nic}
        errorMessage={errors.nic}
        restrictSpaces
      />
      <Input
        ref={(el) => (inputRefs.current.fullName = el)}
        id={"fullName"}
        name={"fullName"}
        placeholder={"සම්පූර්ණ නම"}
        label="සම්පූර්ණ නම*"
        labelWeight="font-bold"
        onChange={handleChange}
        value={formData.fullName}
        errorMessage={errors.fullName}
      />

      <div className="grid md:gap-3 md:grid-cols-2">
        <Input
          ref={(el) => (inputRefs.current.address = el)}
          id={"address"}
          name={"address"}
          placeholder={"ස්ථීර ලිපිනය"}
          label="ස්ථීර ලිපිනය*"
          labelWeight="font-bold"
          onChange={handleChange}
          value={formData.address}
          errorMessage={errors.address}
        />

        <Input
          ref={(el) => (inputRefs.current.policeStation = el)}
          id={"policeStation"}
          name={"policeStation"}
          placeholder={"පොලිස් ස්ථානය"}
          label="පොලිස් ස්ථානය*"
          labelWeight="font-bold"
          onChange={handleChange}
          value={formData.policeStation}
          errorMessage={errors.policeStation}
        />
      </div>

      <div className="grid md:gap-3 md:grid-cols-2">
        <Input
          ref={(el) => (inputRefs.current.gramaDivision = el)}
          id={"gramaDivision"}
          name={"gramaDivision"}
          placeholder={"ග්‍රාම නිලධාරී වසම"}
          label="ග්‍රාම නිලධාරී වසම*"
          labelWeight="font-bold"
          onChange={handleChange}
          value={formData.gramaDivision}
          errorMessage={errors.gramaDivision}
        />

        <Input
          ref={(el) => (inputRefs.current.provincialOffice = el)}
          id={"provincialOffice"}
          name={"provincialOffice"}
          placeholder={"පළාත් පාලන ආයතනය"}
          label="පළාත් පාලන ආයතනය*"
          labelWeight="font-bold"
          onChange={handleChange}
          value={formData.provincialOffice}
          errorMessage={errors.provincialOffice}
        />
      </div>

      <div className="flex justify-center mt-3">
        <Button text="ඊළඟ පිටුවට යන්න" onClick={handleNext} />
      </div>
    </div>
  );
};

export default Step1;
