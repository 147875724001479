import React, { useEffect, useState } from "react";
import { getCurrentUser } from "../api/auth";
import {
  A_CustomerListPage,
  C_CustomerListPage,
  Loading,
  M_CustomerListPage,
  NotFound,
} from "../components/page";
import { useLocation } from "react-router-dom";

const CustomerList: React.FC = () => {
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(true);

  const location = useLocation(); // Hook to access location object
  const status = location.state?.status || ""; // Retrieve the status from the navigation state

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token") || "";
      try {
        const userData = await getCurrentUser(token);

        // Get user role
        setUserRole(userData.data.role);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUserRole(""); // Reset userRole to trigger NotFound component
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  switch (userRole) {
    case "ADMIN":
      return <A_CustomerListPage />;
    case "MANAGER":
      return <M_CustomerListPage />;
    case "COLLECTOR":
      return <C_CustomerListPage status={status} />;
    default:
      return <NotFound />;
  }
};

export default CustomerList;
