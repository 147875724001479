import React, { useEffect, useState } from "react";
import {
  Description,
  SignatureModal,
  Checkbox,
  UploadImageInput,
} from "../../../common";
import { CustomerData } from "../../../../types";

interface signSectionTypes {
  customerSignatureImageURL: CustomerData["signatureImageURL"];
}

const SignSection: React.FC = () => {
  const [customerSignUrl, setCustomerSignUrl] =
    useState<signSectionTypes | null>(null);
  const [isSignatureModalVisible, setIsSignatureModalVisible] = useState(false);
  const [newSign, setNewSign] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isUploadMode, setIsUploadMode] = useState(() => {
    const savedMode = localStorage.getItem("isCustomerUploadMode");
    return savedMode ? JSON.parse(savedMode) : false;
  });

  // Fetch customer data from localStorage when the component mounts
  const handleGetCustomerSign = () => {
    const storedData1 = localStorage.getItem("customerRegisterFormData5");
    if (storedData1) {
      const parsedData1 = JSON.parse(storedData1);
      setCustomerSignUrl(parsedData1);
    }
  };

  useEffect(() => {
    handleGetCustomerSign();
  }, []);

  // Function to handle checkbox change
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsUploadMode(isChecked);
    localStorage.setItem("isCustomerUploadMode", JSON.stringify(isChecked));

    if (isChecked) {
      // Clear the signature when switching to image upload mode
      setCustomerSignUrl((prev) => ({
        ...prev,
        customerSignatureImageURL: "",
      }));
      setNewSign(null);
      setError("Image is required.");
    } else {
      // Clear the image when switching to signature mode
      setError(null);
    }
  };

  // Function to handle opening signature modal
  const handleSignatureModalOpen = () => {
    setIsSignatureModalVisible(true);
  };

  // Function to handle closing signature modal
  const handleFirstSignatureModalClose = () => {
    setIsSignatureModalVisible(false);
  };

  // Function to handle clearing the customer signature URL
  const handleCustomerSignModalClear = () => {
    if (customerSignUrl) {
      const updatedData = { ...customerSignUrl, customerSignatureImageURL: "" };
      setCustomerSignUrl(updatedData);
      setNewSign(null);
      setError("Signature is required.");

      localStorage.setItem(
        "customerRegisterFormData5",
        JSON.stringify(updatedData)
      );
    }
  };

  // Function to handle saving the customer signature URL only to localStorage
  const handleCustomerSignModalOk = (url: string | null) => {
    if (customerSignUrl) {
      if (url) {
        const updatedData = {
          ...customerSignUrl,
          customerSignatureImageURL: url,
        };
        setCustomerSignUrl(updatedData);
        setNewSign(url);
        setError(null);

        // Save the updated signature URL (base64) to localStorage only
        localStorage.setItem(
          "customerRegisterFormData5",
          JSON.stringify(updatedData)
        );
      } else {
        setError("Signature is required.");
      }
    }
    setIsSignatureModalVisible(false);
  };

  return (
    <div>
      <div className="mt-5">
        {/* Description */}
        <Description
          content="මා විසින් ඉහත සඳහන් කළ තොරතුරු සියල්ල නිවැරදි බවත් මේ සමග මාගේ ජාතික
          හැඳුනුම්පතෙහි ඡායා පිටපතක් ඉදිරිපත් කරන බවට ආයතනික නීතිරීති වලට එකඟ
          වෙමින් ඉහත සඳහන් කළ ණය මුදල ඉල්ලා සිටින බවත් මෙයින් ප්‍රකාශ කර සිටිමි."
          fontWeight="font-bold"
        />
      </div>

      {/* Checkbox to toggle between image upload and signature */}
      <div className="flex items-center gap-2 mt-2">
        <Checkbox
          onChange={handleCheckboxChange}
          checked={isUploadMode}
          label="Upload Image"
        />
      </div>

      {/* Conditional rendering for image upload or signature */}
      {isUploadMode ? (
        <UploadImageInput
          id="customerSignatureImageUpload"
          name="customerSignatureImageUpload"
          text=""
          placeholder=""
          onFileSelect={(file) => {
            if (file) {
              const reader = new FileReader();
              reader.onload = () => {
                const base64String = reader.result as string;
                setCustomerSignUrl({
                  ...customerSignUrl,
                  customerSignatureImageURL: base64String,
                });
                localStorage.setItem(
                  "customerRegisterFormData5",
                  JSON.stringify({
                    ...customerSignUrl,
                    customerSignatureImageURL: base64String,
                  })
                );
                setError(null);
              };
              reader.readAsDataURL(file);
            } else {
              setError("Image is required.");
            }
          }}
          selectImage={customerSignUrl?.customerSignatureImageURL || ""}
        />
      ) : (
        <div
          className="h-40 p-2 mt-2 bg-white border-2 rounded-lg cursor-pointer max-w-[100%] border-primaryColor-200"
          onClick={handleSignatureModalOpen}
        >
          {customerSignUrl?.customerSignatureImageURL ? (
            <img
              src={customerSignUrl.customerSignatureImageURL}
              className="object-contain w-full h-full"
            />
          ) : (
            <div className="flex items-center justify-center h-full underline duration-300 text-primaryColor-200 hover:scale-105">
              <Description
                content="Sign here"
                fontWeight="font-medium"
                fontColor="text-primaryColor-200"
                center
              />
            </div>
          )}
        </div>
      )}

      {/* Signature modal */}
      <SignatureModal
        visible={isSignatureModalVisible}
        onClose={handleFirstSignatureModalClose}
        onOk={handleCustomerSignModalOk}
        signurl={newSign ? "" : customerSignUrl?.customerSignatureImageURL}
        onClear={handleCustomerSignModalClear}
      />
    </div>
  );
};

export default SignSection;
