import { z } from "zod";

export const collateralUpdateSchema = z.object({
  collateralName: z.string().nonempty("Required."),
  netValue: z
    .preprocess((val) => {
      if (typeof val === "number") {
        return String(val);
      }
      if (typeof val === "string" && /^\d+(\.\d+)?$/.test(val)) {
        return val;
      }
      return val;
    }, z.string().nonempty("Required."))
    .refine((val) => !isNaN(Number(val)), {
      message: "Must be a valid number.",
    }),
  collDescription: z.string().nonempty("Required."),
  collImgUrl: z.string().nonempty("Required."),
});
