import React from "react";

interface logoProps {
  width?: string;
}

const Logo: React.FC<logoProps> = ({ width = "w-96" }) => {
  return (
    <div>
      {/* company logo  */}
      <img
        src="/credimanage-logo.png"
        alt="crediManage-logo"
        className={`${width}`}
      />
    </div>
  );
};

export default Logo;
