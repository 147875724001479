import React, { useEffect, useState } from "react";
import {
  Description,
  Input,
  RadioButton,
  Title,
  UpdateImageOverviewModal,
} from "../../../../common";
import { CustomerUpdateTypes, Loan } from "../../../../../types";
import ImageChip from "../../../../common/image-chip/ImageChip";
import { CiEdit } from "react-icons/ci";
import { useCustomerDetailsHandlers } from "./useCustomerDetailsHandlers";
import { MdDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { CustomerPassword } from "../../../../modal";

interface CustomerDetailsProps {
  loanStatus: Loan["loanStatus"];
  reason?: Loan["reason"];
  fullName: CustomerUpdateTypes["fullName"];
  address: CustomerUpdateTypes["address"];
  nic: CustomerUpdateTypes["nic"];
  policeStation: CustomerUpdateTypes["policeStation"];
  gramaDivision: CustomerUpdateTypes["gramaDivision"];
  provincialOffice: CustomerUpdateTypes["provincialOffice"];
  verificationMethod: CustomerUpdateTypes["verificationMethod"];
  frontImageURL: CustomerUpdateTypes["frontImageURL"];
  backImageURL: CustomerUpdateTypes["backImageURL"];
  customerImageURL: CustomerUpdateTypes["customerImageURL"];
  electricityBillImageURL: CustomerUpdateTypes["electricityBillImageURL"];
  companyName: CustomerUpdateTypes["companyName"];
  occupation: CustomerUpdateTypes["occupation"];
  companyAddress: CustomerUpdateTypes["companyAddress"];
  email: CustomerUpdateTypes["email"];
  whatsAppNumber: CustomerUpdateTypes["whatsAppNumber"];
  phoneNumber: CustomerUpdateTypes["phoneNumber"];
  optionalContactNumber: CustomerUpdateTypes["optionalContactNumber"];
  netMonthlySalary: CustomerUpdateTypes["netMonthlySalary"];
  onSave: (data: Partial<CustomerUpdateTypes>) => void;
  isClicked: () => void;
  passowrd: string;
  loanReleaseStatus: boolean;
}

const A_CustomerDetails: React.FC<CustomerDetailsProps> = ({
  loanStatus,
  reason,
  fullName,
  address,
  nic,
  policeStation,
  gramaDivision,
  provincialOffice,
  verificationMethod,
  frontImageURL,
  backImageURL,
  customerImageURL,
  electricityBillImageURL,
  companyName,
  occupation,
  companyAddress,
  email,
  whatsAppNumber,
  phoneNumber,
  optionalContactNumber,
  netMonthlySalary,
  onSave,
  isClicked,
  passowrd,
  loanReleaseStatus,
}) => {
  // customer updated values
  const [savedValues, setSavedValues] = useState<Partial<CustomerUpdateTypes>>({
    fullName,
    address,
    nic,
    policeStation,
    gramaDivision,
    provincialOffice,
    companyName,
    occupation,
    companyAddress,
    email,
    whatsAppNumber,
    phoneNumber,
    optionalContactNumber,
    netMonthlySalary,
  });

  const {
    inputs,
    handleInputChange,
    handleEditClick,
    handleSave,
    handleCancel,
  } = useCustomerDetailsHandlers({
    fullName,
    address,
    nic,
    policeStation,
    gramaDivision,
    provincialOffice,
    companyName,
    occupation,
    companyAddress,
    email,
    whatsAppNumber,
    phoneNumber,
    optionalContactNumber,
    netMonthlySalary,
    frontImageURL,
    backImageURL,
    customerImageURL,
    electricityBillImageURL,
    savedValues,
    setSavedValues,
  });

  useEffect(() => {
    onSave(savedValues); // Call the onSave callback with updated values
  }, [savedValues, onSave]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageURL, setCurrentImageURL] = useState<string | null>(null);
  const [imageType, setImageType] = useState<string | null>(null);
  const [isRadioEditMode, setIsRadioEditMode] = useState(false);
  const [selectedIdentityOption, setSelectedIdentityOption] =
    useState<string>(verificationMethod);
  const [passwordModalOpen, setPassowrdModalOpen] = useState<boolean>(false);

  const handleOpenPasswordModal = () => {
    setPassowrdModalOpen(true);
  };
  const handleClosePasswordModal = () => {
    setPassowrdModalOpen(false);
  };

  // handle open image modals
  const handleOpenImageOverviewModal = (imageURL: string, type: string) => {
    setCurrentImageURL(imageURL);
    setImageType(type);
    setIsModalOpen(true);
  };
 
  const handleUploadComplete = (url: string) => {
    // Directly update state to check if there's an issue with setSavedValues
    const updatedValues = { ...savedValues };
    if (imageType === "frontImageURL") {
      updatedValues.frontImageURL = url;
    } else if (imageType === "backImageURL") {
      updatedValues.backImageURL = url;
    } else if (imageType === "electricityBillImageURL") {
      updatedValues.electricityBillImageURL = url;
    } else if (imageType === "customerImageURL") {
      updatedValues.customerImageURL = url;
    } else {
      console.warn("Unknown image type");
    }

    setSavedValues(updatedValues);
  };

  const handleModalClick = () => {
    setIsModalOpen(false);
  };

  const handleIdentityRadioButtonChange = (value: string) => {
    setSelectedIdentityOption(value);
  };

  // New handlers for radio button edit mode
  const handleRadioEditClick = () => {
    setIsRadioEditMode(true);
  };

  const handleRadioSaveClick = () => {
    setIsRadioEditMode(false);

    setSavedValues((prevValues) => ({
      ...prevValues,
      verificationMethod: selectedIdentityOption,
    }));
  };

  const handleRadioCancelClick = () => {
    setIsRadioEditMode(false);
    // Revert to the original selected option if needed
    setSelectedIdentityOption(verificationMethod);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "APPROVED":
        return "text-green-500";
      case "REJECTED":
        return "text-red-500";
      case "PENDING":
        return "text-gray-500";
      case "COMPLETE":
        return "text-blue-600";
      case "LEGAL_ACTION":
        return "text-red-700";
      default:
        return "text-black";
    }
  };

  return (
    <div>
      <div className="flex justify-between">
        {/* Loan Status */}
        <div className="mb-5">
          <div className="flex gap-3">
            <Title title={"Loan Status : "} bgStyle={false} />
            <Title
              title={loanStatus}
              bgStyle={false}
              textColor={getStatusColor(loanStatus)}
            />
          </div>
          {loanStatus === "REJECTED" && (
            <div className="flex gap-3 -mt-2">
              <Description content={"Reason :"} fontWeight="font-bold" />
              <Description content={reason || ""} />
            </div>
          )}
        </div>

        {/* customer password  */}
        {loanReleaseStatus && (
          <div
            className={`flex items-center justify-center h-10 gap-2 px-3 text-white cursor-pointer bg-primaryColor-500 text-h5 rounded-2xl ${loanStatus === "REJECTED" || loanStatus === "LEGAL_ACTION" || loanStatus === "COMPLETE" || loanStatus === "PENDING" ? "hidden" : ""}`}
            onClick={handleOpenPasswordModal}
          >
            <div className="text-h7">Password</div>
            <AiOutlineEyeInvisible
              className={`${passwordModalOpen ? "hidden" : ""}`}
            />
            <AiOutlineEye className={`${passwordModalOpen ? "" : "hidden"}`} />
          </div>
        )}
      </div>

      {/* Full Name */}
      <Input
        id="fullName"
        name="fullName"
        label="සම්පූර්ණ නම*"
        placeholder={"සම්පූර්ණ නම*"}
        disabled={inputs.fullName.disabled}
        value={inputs.fullName.value}
        onChange={(e) => handleInputChange(e, "fullName")}
        backIcon={
          <CiEdit
            className="mt-5 cursor-pointer lg:mt-6"
            onClick={() => handleEditClick("fullName")}
          />
        }
        saveCancel={!inputs.fullName.disabled}
        saveOnclick={() => handleSave("fullName")}
        cancelOnclick={() => handleCancel("fullName")}
        errorMessage={inputs.fullName.error}
      />
      {/* Address */}
      <Input
        id="address"
        name="address"
        label="ස්ථීර ලිපිනය*"
        placeholder={"ස්ථීර ලිපිනය*"}
        disabled={inputs.address.disabled}
        value={inputs.address.value}
        onChange={(e) => handleInputChange(e, "address")}
        backIcon={
          <CiEdit
            className="mt-5 cursor-pointer lg:mt-6"
            onClick={() => handleEditClick("address")}
          />
        }
        saveCancel={!inputs.address.disabled}
        saveOnclick={() => handleSave("address")}
        cancelOnclick={() => handleCancel("address")}
        errorMessage={inputs.address.error}
      />

      <div className="grid md:gap-3 md:grid-cols-2">
        {/* NIC */}
        <Input
          id="NIC"
          name="NIC"
          label="ජාතික හැඳුනුම්පත් අංකය*"
          placeholder={"ජාතික හැඳුනුම්පත් අංකය*"}
          disabled={inputs.nic.disabled}
          value={inputs.nic.value}
          onChange={(e) => handleInputChange(e, "nic")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("nic")}
            />
          }
          saveCancel={!inputs.nic.disabled}
          saveOnclick={() => handleSave("nic")}
          cancelOnclick={() => handleCancel("nic")}
          errorMessage={inputs.nic.error}
        />
        {/* Police Station */}
        <Input
          id="policeStation"
          name="policeStation"
          label="පොලිස් ස්ථානය*"
          placeholder={"පොලිස් ස්ථානය*"}
          disabled={inputs.policeStation.disabled}
          value={inputs.policeStation.value}
          onChange={(e) => handleInputChange(e, "policeStation")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("policeStation")}
            />
          }
          saveCancel={!inputs.policeStation.disabled}
          saveOnclick={() => handleSave("policeStation")}
          cancelOnclick={() => handleCancel("policeStation")}
          errorMessage={inputs.policeStation.error}
        />
      </div>
      <div className="grid md:gap-3 md:grid-cols-2">
        {/* Grama Division */}
        <Input
          id="gramaDivision"
          name="gramaDivision"
          label="ග්‍රාම නිලධාරී වසම*"
          placeholder={"ග්‍රාම නිලධාරී වසම*"}
          disabled={inputs.gramaDivision.disabled}
          value={inputs.gramaDivision.value}
          onChange={(e) => handleInputChange(e, "gramaDivision")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("gramaDivision")}
            />
          }
          saveCancel={!inputs.gramaDivision.disabled}
          saveOnclick={() => handleSave("gramaDivision")}
          cancelOnclick={() => handleCancel("gramaDivision")}
          errorMessage={inputs.gramaDivision.error}
        />
        {/* Provincial Office */}
        <Input
          id="provincialOffice"
          name="provincialOffice"
          label="පළාත් පාලන ආයතනය*"
          placeholder={"පළාත් පාලන ආයතනය*"}
          disabled={inputs.provincialOffice.disabled}
          value={inputs.provincialOffice.value}
          onChange={(e) => handleInputChange(e, "provincialOffice")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("provincialOffice")}
            />
          }
          saveCancel={!inputs.provincialOffice.disabled}
          saveOnclick={() => handleSave("provincialOffice")}
          cancelOnclick={() => handleCancel("provincialOffice")}
          errorMessage={inputs.provincialOffice.error}
        />
      </div>
      {/* අනන්‍යතාව සහතික කිරීම සඳහා පහත ඒවායින් එකක් තෝරාගන්න */}
      <div className="flex">
        <div className="mr-3">
          <Description
            content="අනන්‍යතාව සහතික කිරීම සඳහා පහත ඒවායින් එකක් තෝරාගන්න"
            fontWeight="font-bold"
          />
        </div>
        {/* edit button  */}
        <div>
          {!isRadioEditMode && (
            <CiEdit
              className="mt-[2px] cursor-pointer text-h5 text-primaryColor-500"
              onClick={handleRadioEditClick}
            />
          )}

          {isRadioEditMode && (
            <div className={` flex  gap-5 text-h4`}>
              <span
                className={`text-green-600 cursor-pointer`}
                onClick={handleRadioSaveClick}
              >
                <MdDone />
              </span>
              <span
                className={`text-red-600 cursor-pointer`}
                onClick={handleRadioCancelClick}
              >
                <RxCross2 />
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-wrap gap-5 mt-2">
        <RadioButton
          id={"firstGuarantorNic"}
          label={"ජාතික හැඳුනුම්පත"}
          checked={selectedIdentityOption === "NIC"}
          onChange={() => handleIdentityRadioButtonChange("NIC")}
          disabled={!isRadioEditMode}
        />
        <RadioButton
          id={"firstGuarantorDrivingLicense"}
          label={"රියදුරු බලපත්‍රය"}
          checked={selectedIdentityOption === "DRIVER_LICENCE"}
          onChange={() => handleIdentityRadioButtonChange("DRIVER_LICENCE")}
          disabled={!isRadioEditMode}
        />
        <RadioButton
          id={"firstGuarantorPassport"}
          label={"විදේශ ගමන් බලපත්‍රය"}
          checked={selectedIdentityOption === "PASSPORT"}
          onChange={() => handleIdentityRadioButtonChange("PASSPORT")}
          disabled={!isRadioEditMode}
        />
      </div>
      {/* Attach nic, driving license or passport photos  */}
      <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row">
        <Description
          content="හැඳුනුම්තෙහි ඡායාරූප අමුණන්න"
          fontWeight="font-bold"
        />
        {/* add images */}
        <div className="flex space-x-2">
          <ImageChip
            id={"customerNicImageFront"}
            imageName={"ඉදිරිපස"}
            onClick={() =>
              handleOpenImageOverviewModal(frontImageURL, "frontImageURL")
            }
          />
          <ImageChip
            id={"customerNicImageBack"}
            imageName={"පසුපස"}
            onClick={() =>
              handleOpenImageOverviewModal(backImageURL, "backImageURL")
            }
          />
        </div>
      </div>
      {/*customer Image URL */}
      <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row ">
        <Description content="ණයගැනුම්කරුගෙ ඡායාරූපය" fontWeight="font-bold" />
        <ImageChip
          id="customerImageURL"
          imageName={"ඡායාරූපය"}
          onClick={() =>
            handleOpenImageOverviewModal(customerImageURL, "customerImageURL")
          }
        />
      </div>
      {/*electricity Bill Image URL */}
      <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row ">
        <Description
          content="ජල බිල හෝ විදුලි බිලෙහි පිටපතක් අමුණන්න"
          fontWeight="font-bold"
        />
        <ImageChip
          id="electricityBillImageURL"
          imageName={"බිල්පත "}
          onClick={() =>
            handleOpenImageOverviewModal(
              electricityBillImageURL || "",
              "electricityBillImageURL"
            )
          }
        />
      </div>
      <div className="grid mt-5 md:gap-3 md:grid-cols-2">
        {/* Phone Number */}
        <Input
          id="phoneNumber"
          name="phoneNumber"
          type="number"
          label="දුරකථන අංකය*"
          placeholder={"දුරකථන අංකය*"}
          disabled={inputs.phoneNumber.disabled}
          value={inputs.phoneNumber.value}
          onChange={(e) => handleInputChange(e, "phoneNumber")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("phoneNumber")}
            />
          }
          saveCancel={!inputs.phoneNumber.disabled}
          saveOnclick={() => handleSave("phoneNumber")}
          cancelOnclick={() => handleCancel("phoneNumber")}
          errorMessage={inputs.phoneNumber.error}
        />
        {/* Email */}
        <Input
          id="email"
          name="email"
          label="ඊමේල් ලිපිනය"
          placeholder={"ඊමේල් ලිපිනය"}
          disabled={inputs.email.disabled}
          value={inputs.email.value}
          onChange={(e) => handleInputChange(e, "email")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("email")}
            />
          }
          saveCancel={!inputs.email.disabled}
          saveOnclick={() => handleSave("email")}
          cancelOnclick={() => handleCancel("email")}
          errorMessage={inputs.email.error}
        />
      </div>

      <div className="grid mt-5 md:gap-3 md:grid-cols-2">
        {" "}
        {/* Optional Contact Number */}
        <Input
          id="optionalContactNumber"
          name="optionalContactNumber"
          type="number"
          placeholder={"දුරකථන අංකය (අමතර) "}
          label="දුරකථන අංකය (අමතර)*"
          disabled={inputs.optionalContactNumber.disabled}
          value={inputs.optionalContactNumber.value}
          onChange={(e) => handleInputChange(e, "optionalContactNumber")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("optionalContactNumber")}
            />
          }
          saveCancel={!inputs.optionalContactNumber.disabled}
          saveOnclick={() => handleSave("optionalContactNumber")}
          cancelOnclick={() => handleCancel("optionalContactNumber")}
          errorMessage={inputs.optionalContactNumber.error}
        />
        {/* WhatsApp Number */}
        <Input
          id="whatsAppNumber"
          name="whatsAppNumber"
          type="number"
          placeholder={"වට්ස් ඇප්"}
          label="වට්ස් ඇප්*"
          disabled={inputs.whatsAppNumber.disabled}
          value={inputs.whatsAppNumber.value}
          onChange={(e) => handleInputChange(e, "whatsAppNumber")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("whatsAppNumber")}
            />
          }
          saveCancel={!inputs.whatsAppNumber.disabled}
          saveOnclick={() => handleSave("whatsAppNumber")}
          cancelOnclick={() => handleCancel("whatsAppNumber")}
          errorMessage={inputs.whatsAppNumber.error}
        />
      </div>

      <div className="grid mt-5 md:gap-3 md:grid-cols-2">
        {/* Company Name */}
        <Input
          id="companyName"
          name="companyName"
          placeholder={"සේවා ස්ථානය "}
          label="සේවා ස්ථානය*"
          disabled={inputs.companyName.disabled}
          value={inputs.companyName.value}
          onChange={(e) => handleInputChange(e, "companyName")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("companyName")}
            />
          }
          saveCancel={!inputs.companyName.disabled}
          saveOnclick={() => handleSave("companyName")}
          cancelOnclick={() => handleCancel("companyName")}
          errorMessage={inputs.companyName.error}
        />
        {/* Occupation */}
        <Input
          id="occupation"
          name="occupation"
          placeholder={"රැකියාව "}
          label="රැකියාව*"
          disabled={inputs.occupation.disabled}
          value={inputs.occupation.value}
          onChange={(e) => handleInputChange(e, "occupation")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("occupation")}
            />
          }
          saveCancel={!inputs.occupation.disabled}
          saveOnclick={() => handleSave("occupation")}
          cancelOnclick={() => handleCancel("occupation")}
          errorMessage={inputs.occupation.error}
        />
      </div>
      {/* Company Address */}
      <Input
        id="companyAddress"
        name="companyAddress"
        placeholder={"සේවා ස්ථානයෙ ලිපිනය "}
        label="සේවා ස්ථානයෙ ලිපිනය*"
        disabled={inputs.companyAddress.disabled}
        value={inputs.companyAddress.value}
        onChange={(e) => handleInputChange(e, "companyAddress")}
        backIcon={
          <CiEdit
            className="mt-5 cursor-pointer lg:mt-6"
            onClick={() => handleEditClick("companyAddress")}
          />
        }
        saveCancel={!inputs.companyAddress.disabled}
        saveOnclick={() => handleSave("companyAddress")}
        cancelOnclick={() => handleCancel("companyAddress")}
        errorMessage={inputs.companyAddress.error}
      />

      {/*Gross Monthly Income  */}
      <Input
        id={"netMonthlySalary"}
        name={"netMonthlySalary"}
        type="number"
        placeholder={"දළ මාසික ආදායම (රු) "}
        label="දළ මාසික ආදායම (රු)*"
        width="sm:w-[50%]"
        disabled={inputs.netMonthlySalary.disabled}
        value={inputs.netMonthlySalary.value}
        onChange={(e) => handleInputChange(e, "netMonthlySalary")}
        backIcon={
          <CiEdit
            className="mt-5 cursor-pointer lg:mt-6"
            onClick={() => handleEditClick("netMonthlySalary")}
          />
        }
        saveCancel={!inputs.netMonthlySalary.disabled}
        saveOnclick={() => handleSave("netMonthlySalary")}
        cancelOnclick={() => handleCancel("netMonthlySalary")}
        errorMessage={inputs.netMonthlySalary.error}
      />

      {/* image overview modal  */}
      <UpdateImageOverviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        imageUrl={currentImageURL || ""}
        onUploadComplete={handleUploadComplete}
        onclick={handleModalClick}
        isClicked={isClicked}
        buttonText="Save Image"
      />

      {/* customer password modal  */}
      <CustomerPassword
        visible={passwordModalOpen}
        onClose={handleClosePasswordModal}
        customerNIC={nic || ""}
        customerUserNameValue={nic || ""}
        closeModal={handleClosePasswordModal}
        customerPassword={passowrd || ""}
      />
    </div>
  );
};

export default A_CustomerDetails;
