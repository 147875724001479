import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import PrimaryTitle from "../text/PrimaryTitle";
import Dropdown from "../inputs/Dropdown";
import Button from "../buttons/Button";
import {
  assignManagerUnassignCollector,
  getManagers,
} from "../../../api/employee";
import toast from "react-hot-toast";
import PrimaryModal from "./PrimaryModal";

interface Manager {
  managerId: number;
  userId: number;
  username: string;
}

const AssignManagerModal: React.FC<{
  visible: boolean;
  onClose: () => void;
  closeIcon: React.ReactNode;
  selectedCustomersId: React.Key[];
  onSuccess: () => void; // Add onSuccess callback prop
}> = ({ visible, onClose, closeIcon, selectedCustomersId, onSuccess }) => {
  const [managers, setManagers] = useState<Manager[]>([]);
  const [selectedManager, setSelectedManager] = useState<Manager | null>(null);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [errorMessagem, setErrorMessage] = useState("");

  // Fetch active managers
  useEffect(() => {
    const getActiveAllManagers = async () => {
      const token = localStorage.getItem("token") || "";

      try {
        const response = await getManagers(token);
        setManagers(response);
      } catch (error) {
        console.log(error);
      }
    };

    getActiveAllManagers();
  }, []);

  // Handle manager selection
  const handleManagerSelect = (option: string) => {
    const selectedManager = managers.find(
      (manager) => manager.username === option
    );
    if (selectedManager) {
      setSelectedManager(selectedManager);
      setErrorMessage("");
    }
  };

  // Assign new manager for unassign collector
  const assignNewManager = async () => {
    if (!selectedManager) {
      console.log("No manager selected");
      return;
    }

    const token = localStorage.getItem("token") || "";
    const requestBody = {
      collectorAppUserIds: selectedCustomersId,
      managerAppUserId: selectedManager.userId,
    };

    try {
      const response = await assignManagerUnassignCollector(token, requestBody);
      if (response.success) {
        toast.success(response.message);
        onSuccess(); // Call the onSuccess callback
        setSelectedManager(null);
      } else {
        console.log("assign manager failed!");
      }
    } catch (error) {
      console.log(error);
    }

    // Close the confirmation modal after action
    setIsOpenConfirmModal(false);
    // Optionally, close the main modal
    onClose();
  };

  // Open confirmation modal
  const handleAssignClick = () => {
    if (!selectedManager) {
      setErrorMessage("Please select a manager.");
      return;
    }
    setIsOpenConfirmModal(true);
  };

  // Handle modal close
  const handleModalClose = () => {
    setSelectedManager(null); // Clear selected manager
    setErrorMessage(""); // Clear error message
    onClose(); // Call the onClose function passed in props
  };

  return (
    <>
      <Modal
        open={visible}
        onCancel={handleModalClose}
        centered
        footer={null}
        closeIcon={closeIcon}
        className="custom-modal"
      >
        <div className="space-y-4">
          {/* Main title */}
          <PrimaryTitle
            text={"Assign New Manager"}
            fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
            center
          />

          {/* Select manager */}
          <Dropdown
            label="Select Manager"
            placeholder="Select Manager"
            options={managers.map((manager) => manager.username)}
            selectedOption={selectedManager?.username || ""}
            onOptionSelect={handleManagerSelect}
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            width="w-[100%]"
            errorMessage={errorMessagem}
          />

          {/* Assign button */}
          <div className="flex justify-center">
            <Button text={"Assign"} onClick={handleAssignClick} />
          </div>
        </div>
      </Modal>

      {/* New manager assign confirm message  */}
      <PrimaryModal
        visible={isOpenConfirmModal}
        mainTitle={"Assign New Manager"}
        content={"Are you sure you want to assign the new manager?"}
        primaryBtnLabel="Yes"
        secondaryBtnLabel="No"
        primaryOnClick={assignNewManager}
        secondaryOnClick={() => setIsOpenConfirmModal(false)}
        onClose={() => setIsOpenConfirmModal(false)}
      />
    </>
  );
};

export default AssignManagerModal;
