import React, { useEffect, useState } from "react";
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  format,
  getDay,
  isToday,
  isSameDay,
} from "date-fns";
import { MdOutlineNavigateNext } from "react-icons/md";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface YearProps {
  onDateSelect: (date: Date) => void;
  selectedJsCalanderDate: Date;
}

const Year: React.FC<YearProps> = ({
  onDateSelect,
  selectedJsCalanderDate,
}) => {
  const [currentYear, setCurrentYear] = useState<number>(2024);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    const year = selectedJsCalanderDate.getFullYear();
    setCurrentYear(year);

    const isSmallScreen = window.matchMedia("(max-width: 639px)").matches;
    if (!isSmallScreen) {
      onDateSelect(selectedJsCalanderDate);
    }
  }, [selectedJsCalanderDate]);

  // Function to generate days for a given month
  const generateMonthDays = (year: number, monthIndex: number) => {
    const start = startOfMonth(new Date(year, monthIndex));
    const end = endOfMonth(new Date(year, monthIndex));
    const days = eachDayOfInterval({ start, end });

    // Create an array with empty strings for the first days of the week until the start of the month
    const leadingEmptyDays = Array(getDay(start)).fill("");
    const daysInMonth = leadingEmptyDays.concat(days);

    // Chunk the array into weeks (arrays of 7 days)
    const weeks = [];
    for (let i = 0; i < daysInMonth.length; i += 7) {
      weeks.push(daysInMonth.slice(i, i + 7));
    }

    return weeks;
  };

  // Function to handle date click
  const handleDateClick = (day: Date) => {
    setSelectedDate(day);
    onDateSelect(day);
  };

  // Functions to navigate between years
  const goToPreviousYear = () => {
    setCurrentYear(currentYear - 1);
  };

  const goToNextYear = () => {
    setCurrentYear(currentYear + 1);
  };

  return (
    <div className="p-6 ">
      <div>
        <div className="flex justify-between mb-4 ">
          <h2 className="text-lg font-bold ml-7 lg:text-2xl text-primaryColor-900">
            {currentYear}
          </h2>

          <div className="flex flex-row text-h5 lg:text-h3">
            <button
              onClick={goToPreviousYear}
              className="px-4 rounded text-primaryColor-900"
            >
              <MdOutlineNavigateNext className="transform scale-x-[-1]" />
            </button>
            <button
              onClick={goToNextYear}
              className="px-4 rounded text-primaryColor-900"
            >
              <MdOutlineNavigateNext />
            </button>
          </div>
        </div>
        <div className="flex flex-wrap justify-center">
          {months.map((month, index) => (
            <div key={month} className="p-3 rounded-lg">
              <h3 className="mb-4 text-lg font-semibold text-center text-primaryColor-600 ">
                {month} {currentYear}
              </h3>
              <table className="w-full text-center">
                <thead>
                  <tr className="text-h8 text-primaryColor-300">
                    <th className="pb-2 pr-1">Sun</th>
                    <th className="pb-2 pr-1">Mon</th>
                    <th className="pb-2 pr-1">Tue</th>
                    <th className="pb-2 pr-1">Wed</th>
                    <th className="pb-2 pr-1">Thu</th>
                    <th className="pb-2 pr-1">Fri</th>
                    <th className="pb-2 pr-1">Sat</th>
                  </tr>
                </thead>
                <tbody>
                  {generateMonthDays(currentYear, index).map(
                    (week, weekIndex) => (
                      <tr key={weekIndex}>
                        {week.map((day, dayIndex) => (
                          <td
                            key={dayIndex}
                            className={`font-semibold h-7 w-7 relative text-h9 cursor-pointer rounded-full ${
                              selectedDate
                                ? isSameDay(day, selectedDate)
                                  ? "bg-primaryColor-500 text-white"
                                  : "hover:bg-gray-200"
                                : isToday(day)
                                  ? "bg-primaryColor-500 text-white"
                                  : "hover:bg-gray-200"
                            }`}
                            onClick={() => day && handleDateClick(day)}
                          >
                            {day ? format(day, "d") : ""}
                          </td>
                        ))}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Year;
