import axios from "axios";
import { BASE_URL, apiOptionJson } from "./base-url";
import {
  CreatePaymentRequestBody,
  MakePaymentRequestBody,
} from "../types/paymentsTypes";

// Get all payments
export const getAllPayments = async (token: string, appUserId: number) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/payments/getAllPayments/${appUserId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

// Get loan details by customer id
export const getPaymentScheduleByCusID = async (
  customerNic: string,
  token: string,
  date: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v4/customers/getScheduleByCustomerId/${customerNic}?date=${encodeURIComponent(date)}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// Make payment
export const createPayment = async (
  requestBody: MakePaymentRequestBody,
  token: string
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/payments/make_payment`,
      requestBody,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// udapte paid amount
export const updatePaidAmount = async (
  token: string,
  paymentId: number,
  requestBody: CreatePaymentRequestBody
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/api/v1/payments/${paymentId}`,
      requestBody,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
