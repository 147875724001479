import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RouteWatcher: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = () => {
      const currentPath = location.pathname;

      if (
        currentPath !== "/customer-register" &&
        currentPath !== "/customer-register-overview"
      ) {
        // Remove localStorage items
        localStorage.removeItem("customerRegisterFormData1");
        localStorage.removeItem("customerRegisterFormData2");
        localStorage.removeItem("customerRegisterFormData3");
        localStorage.removeItem("customerRegisterFormData4");
        localStorage.removeItem("customerRegisterFormData5");
        localStorage.removeItem("newInterestRate");
        localStorage.removeItem("isFirstUploadMode");
        localStorage.removeItem("isSecondUploadMode");
        localStorage.removeItem("isCustomerUploadMode");
        localStorage.removeItem("nic");
        localStorage.removeItem("FGNic");
        localStorage.removeItem("SGNic");
        localStorage.removeItem("collateralOnly");
      }
    };

    handleRouteChange();

    return () => {
      handleRouteChange();
    };
  }, [location]);

  return <>{children}</>;
};

export default RouteWatcher;
