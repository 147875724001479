import React, { useEffect, useState } from "react";
import { GuaranterDetailsTypes } from "../../../../types";
import {
  Checkbox,
  Description,
  Input,
  RadioButton,
  SignatureModal,
  UploadImageInput,
} from "../../../common";
import ImageChip from "../../../common/image-chip/ImageChip";
import ImageOverviewModal from "../../../common/modals/ImageOverviewModal";
import { CiEdit } from "react-icons/ci";
import { MdDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { FirstGuarantorSchema } from "../../../../lib/validation/guarantorSchema";

// Replace this type definition with the correct type for FGAddress, FGNic, etc.
export interface FirstGuaranterDetailsTypes {
  FGFullName: GuaranterDetailsTypes["guaranterFullName"];
  FGAddress: GuaranterDetailsTypes["guaranterAddress"];
  FGNic: GuaranterDetailsTypes["guaranterNic"];
  FGPhoneNumber: GuaranterDetailsTypes["guarantorPhoneNumber"];
  FGVerificationMethod: GuaranterDetailsTypes["guaranterVerificationMethod"];
  FGuarantorImageURL: GuaranterDetailsTypes["guaranterFrontImageURL"];
  FGFrontImageURL: GuaranterDetailsTypes["guaranterFrontImageURL"];
  FGBackImageURL: GuaranterDetailsTypes["guaranterBackImageURL"];
  FGElectricityBillOrWaterImageURL: GuaranterDetailsTypes["guaranterElectricityBillOrWaterImageURL"];
  FGSignatureImageURL: GuaranterDetailsTypes["guaranterSignatureImageURL"];
}

interface signSectionTypes {
  FGFullName?: GuaranterDetailsTypes["guaranterFullName"];
  FGAddress?: GuaranterDetailsTypes["guaranterAddress"];
  FGNic?: GuaranterDetailsTypes["guaranterNic"];
  FGPhoneNumber?: GuaranterDetailsTypes["guarantorPhoneNumber"];
  FGuarantorImageURL?: GuaranterDetailsTypes["guaranterFrontImageURL"];
  FGVerificationMethod?: GuaranterDetailsTypes["guaranterVerificationMethod"];
  FGFrontImageURL?: GuaranterDetailsTypes["guaranterFrontImageURL"];
  FGBackImageURL?: GuaranterDetailsTypes["guaranterBackImageURL"];
  FGElectricityBillOrWaterImageURL?: GuaranterDetailsTypes["guaranterElectricityBillOrWaterImageURL"];
  FGSignatureImageURL?: GuaranterDetailsTypes["guaranterSignatureImageURL"];
}

const FirstGuranter: React.FC = () => {
  const [guarantorDetails, setGuarantorDetails] =
    useState<FirstGuaranterDetailsTypes>();
  const [isRadioEditMode, setIsRadioEditMode] = useState(false);
  const [newSign, setNewSign] = useState<string>();
  const [isSignatureModalVisible, setIsSignatureModalVisible] = useState(false);
  const [customerSignUrl, setCustomerSignUrl] = useState<signSectionTypes>();
  const [isOpenFrontImage, setIsOpenFrontImage] = useState(false);
  const [isOpenFGuarantorImageURL, setIsOpenFGuarantorImageURL] =
    useState(false);
  const [isOpenBackImage, setIsOpenBackImage] = useState(false);
  const [isOpenelEctricityBillImage, setIsOpenEctricityBillImage] =
    useState(false);

  const [isUploadMode, setIsUploadMode] = useState(() => {
    const savedMode = localStorage.getItem("isFirstUploadMode");
    return savedMode ? JSON.parse(savedMode) : false;
  });

  const [inputs, setInputs] = useState({
    FGFullName: {
      value: "",
      disabled: true,
      error: "",
    },
    FGAddress: {
      value: "",
      disabled: true,
      error: "",
    },
    FGNic: {
      value: "",
      disabled: true,
      error: "",
    },
    FGPhoneNumber: {
      value: "",
      disabled: true,
      error: "",
    },
    FGVerificationMethod: {
      value: "",
      disabled: true,
      error: "",
    },
    FGuarantorImageURL: {
      value: "",
      disabled: true,
      error: "",
    },
    FGFrontImageURL: {
      value: "",
      disabled: true,
      error: "",
    },
    FGBackImageURL: {
      value: "",
      disabled: true,
      error: "",
    },
    FGElectricityBillOrWaterImageURL: {
      value: "",
      disabled: true,
      error: "",
    },
    FGSignatureImageURL: {
      value: "",
      disabled: true,
      error: "",
    },
  });
  const [activeField, setActiveField] = useState<keyof typeof inputs | null>(
    null
  );
  const [previousValue, setPreviousValue] = useState<string | null>(null);

  // Fetch customer data from localStorage and combine when the component mounts

  const handleGetGuarantorDetailsInLocal = () => {
    const storedData1 = localStorage.getItem("customerRegisterFormData4");

    if (storedData1) {
      const parsedData1 = storedData1 ? JSON.parse(storedData1) : {};

      // Merge both objects into a single guarantorDetails object
      const combinedData = { ...parsedData1 };
      setGuarantorDetails(combinedData);
      setCustomerSignUrl(combinedData);
    }
  };
  useEffect(() => {
    handleGetGuarantorDetailsInLocal();
  }, []);

  // Update inputs state when collateralDetails changes
  const handleGetGuarantersDetails = () => {
    if (guarantorDetails) {
      setInputs({
        FGFullName: {
          value: guarantorDetails.FGFullName || "",
          disabled: true,
          error: "",
        },
        FGAddress: {
          value: guarantorDetails.FGAddress || "",
          disabled: true,
          error: "",
        },
        FGNic: {
          value: guarantorDetails.FGNic || "",
          disabled: true,
          error: "",
        },
        FGPhoneNumber: {
          value: guarantorDetails.FGPhoneNumber || "",
          disabled: true,
          error: "",
        },
        FGVerificationMethod: {
          value: guarantorDetails.FGVerificationMethod || "",
          disabled: true,
          error: "",
        },
        FGuarantorImageURL: {
          value: guarantorDetails.FGuarantorImageURL || "",
          disabled: true,
          error: "",
        },
        FGFrontImageURL: {
          value: guarantorDetails.FGFrontImageURL || "",
          disabled: true,
          error: "",
        },
        FGBackImageURL: {
          value: guarantorDetails.FGBackImageURL || "",
          disabled: true,
          error: "",
        },
        FGElectricityBillOrWaterImageURL: {
          value: guarantorDetails.FGElectricityBillOrWaterImageURL || "",
          disabled: true,
          error: "",
        },
        FGSignatureImageURL: {
          value: guarantorDetails.FGSignatureImageURL || "",
          disabled: true,
          error: "",
        },
      });
    }
  };
  useEffect(() => {
    handleGetGuarantersDetails();
  }, [guarantorDetails]);

  const handleFirstGuarantorCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;
    setIsUploadMode(isChecked);
    localStorage.setItem("isFirstUploadMode", JSON.stringify(isChecked));

    if (isChecked) {
      // Clear the signature when switching to image upload mode
      setCustomerSignUrl((prev) => ({ ...prev, FGSignatureImageURL: "" }));
      const updatedData = { ...guarantorDetails, FGSignatureImageURL: "" };
      localStorage.setItem(
        "customerRegisterFormData4",
        JSON.stringify(updatedData)
      );
    } else {
      // Clear the image when switching to signature mode
      setInputs((prevInputs) => ({
        ...prevInputs,
        FGSignatureImageURL: { ...prevInputs.FGSignatureImageURL, value: "" },
      }));
      const updatedData = { ...guarantorDetails, FGSignatureImageURL: "" };
      localStorage.setItem(
        "customerRegisterFormData4",
        JSON.stringify(updatedData)
      );
    }
  };

  // Handle input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof typeof inputs
  ) => {
    const { value } = e.target;

    // Validate the specific field using Zod schema
    const fieldSchema = FirstGuarantorSchema.shape[field];
    const result = fieldSchema.safeParse(value);

    setInputs((prev) => ({
      ...prev,
      [field]: {
        value,
        error: result.success
          ? "" // No error
          : result.error.issues[0]?.message || "Invalid input",
      },
    }));
  };

  const handleEditClick = (field: keyof typeof inputs) => {
    if (activeField && activeField !== field) {
      handleSaveOrCancel(activeField, false);
    }

    // Set previousValue to the current value before editing
    setPreviousValue(inputs[field].value);
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: { ...prevInputs[field], disabled: false },
    }));
    setActiveField(field);
  };

  const handleSaveOrCancel = (field: keyof typeof inputs, save: boolean) => {
    if (save) {
      handleSave(field);
    } else {
      handleCancel(field);
    }
  };

  const handleSave = (field: keyof typeof inputs) => {
    const fieldSchema = FirstGuarantorSchema.shape[field];
    const validationResult = fieldSchema.safeParse(inputs[field].value);

    if (!validationResult.success) {
      // If validation fails, show the error
      setInputs((prevInputs) => ({
        ...prevInputs,
        [field]: {
          ...prevInputs[field],
          error: validationResult.error.issues[0]?.message,
        },
      }));
      return;
    }

    // If validation passes, save the data
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: { ...prevInputs[field], disabled: true, error: "" },
    }));

    // Fetch current data from localStorage
    const existingData = localStorage.getItem("customerRegisterFormData4");
    let updatedData = existingData ? JSON.parse(existingData) : {};

    // Update the specific field
    updatedData = {
      ...updatedData,
      [field]: inputs[field].value,
    };

    // Save updated data to localStorage
    localStorage.setItem(
      "customerRegisterFormData4",
      JSON.stringify(updatedData)
    );

    setActiveField(null);
    setPreviousValue(null);
  };

  const handleCancel = (field: keyof typeof inputs) => {
    setInputs((prevInputs) => {
      const updatedInputs = { ...prevInputs };

      // Revert the specific field to its previous value and disable it
      updatedInputs[field] = {
        ...updatedInputs[field],
        value: previousValue || guarantorDetails?.[field] || "",
        disabled: true,
        error: "", // Clear error message for this field
      };

      // Clear errors for all fields if needed
      for (const key in updatedInputs) {
        updatedInputs[key as keyof typeof inputs].error = "";
      }

      return updatedInputs;
    });

    setActiveField(null);
    setPreviousValue(null);
  };

  // Handle image file selection
  const handleImageFileSelect = (
    file: File | null,
    imageType: keyof typeof inputs
  ) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;

        setInputs((prevInputs) => ({
          ...prevInputs,
          [imageType]: { ...prevInputs[imageType], value: base64String },
        }));

        // Update localStorage for the specific image type
        const updatedData = {
          ...guarantorDetails,
          [imageType]: base64String,
        };

        localStorage.setItem(
          `customerRegisterFormData4`,
          JSON.stringify(updatedData)
        );
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRadioChange = (newValue: string) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      FGVerificationMethod: {
        ...prevInputs.FGVerificationMethod,
        value: newValue,
      },
    }));
  };

  const handleRadioEditClick = () => {
    setIsRadioEditMode(true);
  };

  const handleRadioSaveClick = () => {
    // Retrieve current guarantor details from localStorage
    const guarantorData = localStorage.getItem("customerRegisterFormData4");

    let updatedGuarantorData = {};
    if (guarantorData) {
      try {
        const parsedGuarantorData = JSON.parse(guarantorData);
        // Only update the FGVerificationMethod field
        updatedGuarantorData = {
          ...parsedGuarantorData,
          FGVerificationMethod: inputs.FGVerificationMethod.value,
        };
      } catch (error) {
        console.error("Error parsing guarantor data:", error);
      }
    } else {
      // If no data exists, create new data with only FGVerificationMethod
      updatedGuarantorData = {
        FGVerificationMethod: inputs.FGVerificationMethod.value,
      };
    }

    // Update localStorage with the new FGVerificationMethod value
    localStorage.setItem(
      `customerRegisterFormData4`,
      JSON.stringify(updatedGuarantorData)
    );

    // Exit edit mode
    setIsRadioEditMode(false);
  };

  const handleRadioCancelClick = () => {
    // Retrieve the FGVerificationMethod from localStorage and reset form input
    const guarantorData = localStorage.getItem("customerRegisterFormData4");

    if (guarantorData) {
      try {
        const parsedGuarantorData = JSON.parse(guarantorData);
        // Reset only the FGVerificationMethod field
        setInputs((prevInputs) => ({
          ...prevInputs,
          FGVerificationMethod: {
            ...prevInputs.FGVerificationMethod,
            value: parsedGuarantorData.FGVerificationMethod || "",
          },
        }));
      } catch (error) {
        console.error("Error parsing guarantor data:", error);
      }
    }

    // Exit edit mode
    setIsRadioEditMode(false);
  };

  // Function to handle clearing the customer signature URL
  const handleCustomerSignModalClear = () => {
    setCustomerSignUrl({ ...customerSignUrl, FGSignatureImageURL: "" });

    const updatedData = { ...customerSignUrl, FGSignatureImageURL: "" };
    setCustomerSignUrl(updatedData);

    localStorage.setItem(
      "customerRegisterFormData4",
      JSON.stringify(updatedData)
    );
  };

  // Function to handle saving the customer signature URL only to localStorage
  const handleCustomerSignModalOk = (url: string | null) => {
    setIsSignatureModalVisible(false);
    if (url) {
      const updatedData = {
        ...guarantorDetails,
        FGSignatureImageURL: url,
      };
      setCustomerSignUrl(updatedData);
      setNewSign(url);
      // Save the updated signature URL (base64) to localStorage only
      localStorage.setItem(
        "customerRegisterFormData4",
        JSON.stringify(updatedData)
      );
    }
  };

  // Function to handle opening signature modal
  const handleSignatureModalOpen = () => {
    handleGetGuarantorDetailsInLocal();
    handleGetGuarantersDetails();
    setIsSignatureModalVisible(true);
  };

  // Function to handle closing signature modal
  const handleFirstSignatureModalClose = () => {
    setIsSignatureModalVisible(false);
  };

  const handleOpenElectricityBill = () => {
    handleGetGuarantorDetailsInLocal();
    handleGetGuarantersDetails();
    setIsOpenEctricityBillImage(true);
  };

  const handleOpenBackId = () => {
    handleGetGuarantorDetailsInLocal();
    handleGetGuarantersDetails();
    setIsOpenBackImage(true);
  };

  const handleOpenFrontId = () => {
    handleGetGuarantorDetailsInLocal();
    handleGetGuarantersDetails();
    setIsOpenFrontImage(true);
  };

  const handleOpenFGuarantorImageURL = () => {
    console.log("updated");
    handleGetGuarantorDetailsInLocal();
    handleGetGuarantersDetails();
    setIsOpenFGuarantorImageURL(true);
  };

  return (
    <div>
      <div>
        {/* first guarantor  */}
        <div className="my-5">
          <Description
            content="පළමු ඇපකරු"
            fontWeight="font-bold"
            fontSize="text-h7 lg:text-h6 3xl:text-h3"
          />
        </div>
        <div className="grid md:gap-3 md:grid-cols-2">
          {/*first guarantors Name  */}
          <Input
            id={"FirstGuarantorsName"}
            name={"guarantorsName"}
            placeholder={"නම"}
            label="නම*"
            disabled={inputs.FGFullName.disabled}
            value={inputs.FGFullName.value}
            onChange={(e) => handleInputChange(e, "FGFullName")}
            backIcon={
              <CiEdit
                className="mt-5 cursor-pointer lg:mt-6"
                onClick={() => handleEditClick("FGFullName")}
              />
            }
            saveCancel={!inputs.FGFullName.disabled}
            saveOnclick={() => handleSave("FGFullName")}
            cancelOnclick={() => handleCancel("FGFullName")}
            errorMessage={inputs.FGFullName.error}
          />

          {/*first guarantors Address  */}
          <Input
            id={"guarantorsAddress"}
            name={"guarantorsAddress"}
            placeholder={"ලිපිනය"}
            label="ලිපිනය*"
            disabled={inputs.FGAddress.disabled}
            value={inputs.FGAddress.value}
            onChange={(e) => handleInputChange(e, "FGAddress")}
            backIcon={
              <CiEdit
                className="mt-5 cursor-pointer lg:mt-6"
                onClick={() => handleEditClick("FGAddress")}
              />
            }
            saveCancel={!inputs.FGAddress.disabled}
            saveOnclick={() => handleSave("FGAddress")}
            cancelOnclick={() => handleCancel("FGAddress")}
            errorMessage={inputs.FGAddress.error}
          />
        </div>
        <div className="grid md:gap-3 md:grid-cols-2">
          {/*first guarantors Id  */}
          <Input
            id={"guarantorsId"}
            name={"guarantorsId"}
            placeholder={"ජාතික හැඳුනුම්පත් අංකය"}
            label="ජාතික හැඳුනුම්පත් අංකය*"
            disabled={inputs.FGNic.disabled}
            value={inputs.FGNic.value}
            onChange={(e) => handleInputChange(e, "FGNic")}
            backIcon={
              <CiEdit
                className="mt-5 cursor-pointer lg:mt-6"
                onClick={() => handleEditClick("FGNic")}
              />
            }
            saveCancel={!inputs.FGNic.disabled}
            saveOnclick={() => handleSave("FGNic")}
            cancelOnclick={() => handleCancel("FGNic")}
            errorMessage={inputs.FGNic.error}
            restrictSpaces
          />

          {/*first guarantors Tp Number */}
          <Input
            id={"guarantorsTpNumber"}
            name={"guarantorsTpNumber"}
            type="number"
            placeholder={"දුරකථන අංකය"}
            label="දුරකථන අංකය*"
            disabled={inputs.FGPhoneNumber.disabled}
            value={inputs.FGPhoneNumber.value}
            onChange={(e) => handleInputChange(e, "FGPhoneNumber")}
            backIcon={
              <CiEdit
                className="mt-5 cursor-pointer lg:mt-6"
                onClick={() => handleEditClick("FGPhoneNumber")}
              />
            }
            saveCancel={!inputs.FGPhoneNumber.disabled}
            saveOnclick={() => handleSave("FGPhoneNumber")}
            cancelOnclick={() => handleCancel("FGPhoneNumber")}
            errorMessage={inputs.FGPhoneNumber.error}
            restrictSpaces
          />
        </div>

        {/* first guarantor image  */}
        <div>
          <Description
            content="පළමු ඇපකරුගෙ ඡායාරූපය*"
            fontWeight="font-bold"
          />
          <ImageChip
            id={"FGuarantorImageURL"}
            imageName={"ඉදිරිපස"}
            onClick={handleOpenFGuarantorImageURL}
          />
        </div>

        {/*first guarantors select nic, driving license or passport  */}
        <div className="flex">
          <div className="mr-3">
            <Description
              content="අනන්‍යතාව සහතික කිරීම සඳහා පහත ඒවායින් එකක් තෝරාගන්න*"
              fontWeight="font-bold"
            />
          </div>
          {/* edit button  */}
          <div>
            {!isRadioEditMode && (
              <CiEdit
                className="mt-[2px] cursor-pointer text-h5 text-primaryColor-500"
                onClick={handleRadioEditClick}
              />
            )}
            {isRadioEditMode && (
              <div className={` flex  gap-5 text-h4`}>
                <span
                  className={`text-green-600 cursor-pointer`}
                  onClick={handleRadioSaveClick}
                >
                  <MdDone />
                </span>
                <span
                  className={`text-red-600 cursor-pointer`}
                  onClick={handleRadioCancelClick}
                >
                  <RxCross2 />
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-wrap gap-5 mt-3 lg:gap-10">
          <RadioButton
            id={"firstGuarantorNic"}
            label={"ජාතික හැඳුනුම්පත"}
            checked={inputs.FGVerificationMethod.value === "NIC"}
            disabled={!isRadioEditMode}
            onChange={() => handleRadioChange("NIC")}
          />
          <RadioButton
            id={"firstGuarantorDrivingLicense"}
            label={"රියදුරු බලපත්‍රය"}
            checked={inputs.FGVerificationMethod.value === "DRIVER_LICENCE"}
            disabled={!isRadioEditMode}
            onChange={() => handleRadioChange("DRIVER_LICENCE")}
          />
          <RadioButton
            id={"firstGuarantorPassport"}
            label={"විදේශ ගමන් බලපත්‍රය"}
            checked={inputs.FGVerificationMethod.value === "PASSPORT"}
            disabled={!isRadioEditMode}
            onChange={() => handleRadioChange("PASSPORT")}
          />
        </div>

        {/*first guarantors Attach nic, driving license or passport photos  */}
        <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row ">
          <Description content="ඡායාරූප අමුණන්න*" fontWeight="font-bold" />
          <div className="flex space-x-2">
            <ImageChip
              id={"firstGuarantorsIdFront"}
              imageName={"ඉදිරිපස"}
              onClick={handleOpenFrontId}
            />

            <ImageChip
              id={"firstGuarantorsIdBack"}
              imageName={"පසුපස"}
              onClick={handleOpenBackId}
            />
          </div>
        </div>
        {/*first guarantors Attach a copy of water bill or electricity bill */}
        <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row">
          <Description
            content="ජල බිල හෝ විදුලි බිලෙහි පිටපතක් අමුණන්න*"
            fontWeight="font-bold"
          />

          <ImageChip
            id="firstGuarantorsBill"
            imageName={"බිල්පත"}
            onClick={handleOpenElectricityBill}
          />
        </div>

        {/*first guarantors display signature */}
        <div className="mt-4">
          <Description
            content="අත්සන"
            fontWeight="font-bold"
            fontSize="text-h7 lg:text-h6 3xl:text-h3"
          />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <Checkbox
            onChange={handleFirstGuarantorCheckboxChange}
            checked={isUploadMode}
            label="Upload Image"
          />
        </div>
        {isUploadMode ? (
          // Show image upload input if checkbox is checked
          <UploadImageInput
            id="FGSignatureImageUpload"
            name="FGSignatureImageUpload"
            text=""
            placeholder=""
            onFileSelect={(file) => {
              if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                  const base64String = reader.result as string;
                  setCustomerSignUrl({
                    ...customerSignUrl,
                    FGSignatureImageURL: base64String,
                  });
                  localStorage.setItem(
                    "customerRegisterFormData4",
                    JSON.stringify({
                      ...guarantorDetails,
                      FGSignatureImageURL: base64String,
                    })
                  );
                };
                reader.readAsDataURL(file);
              }
            }}
            selectImage={customerSignUrl?.FGSignatureImageURL || ""}
          />
        ) : (
          // Show signature input if checkbox is not checked
          <div
            className="h-40 p-2 mt-2 bg-white border-2 rounded-lg cursor-pointer max-w-[100%] border-primaryColor-200"
            onClick={handleSignatureModalOpen}
          >
            {customerSignUrl?.FGSignatureImageURL ? (
              <img
                src={customerSignUrl.FGSignatureImageURL}
                className="object-contain w-full h-full"
              />
            ) : (
              <div className="flex items-center justify-center h-full underline duration-300 text-primaryColor-200 hover:scale-105">
                <Description
                  content="Sign here"
                  fontWeight="font-medium"
                  fontColor="text-primaryColor-200"
                  center
                />
              </div>
            )}
          </div>
        )}
      </div>

      {/* frontImageURL iamge overview modal  */}
      <ImageOverviewModal
        isOpen={isOpenFGuarantorImageURL}
        onClose={() => setIsOpenFGuarantorImageURL(false)}
        imageUrl={
          guarantorDetails?.FGuarantorImageURL
            ? `${guarantorDetails.FGuarantorImageURL}`
            : ""
        }
        onFileSelect={(file) =>
          handleImageFileSelect(file, "FGuarantorImageURL")
        }
      />

      {/* frontImageURL iamge overview modal  */}
      <ImageOverviewModal
        isOpen={isOpenFrontImage}
        onClose={() => setIsOpenFrontImage(false)}
        imageUrl={
          guarantorDetails?.FGFrontImageURL
            ? `${guarantorDetails.FGFrontImageURL}`
            : ""
        }
        onFileSelect={(file) => handleImageFileSelect(file, "FGFrontImageURL")}
      />

      {/* backImageURL iamge overview modal  */}
      <ImageOverviewModal
        isOpen={isOpenBackImage}
        onClose={() => setIsOpenBackImage(false)}
        imageUrl={
          guarantorDetails?.FGBackImageURL
            ? `${guarantorDetails.FGBackImageURL}`
            : ""
        }
        onFileSelect={(file) => handleImageFileSelect(file, "FGBackImageURL")}
      />

      {/* electricityBillImageURL iamge overview modal  */}
      <ImageOverviewModal
        isOpen={isOpenelEctricityBillImage}
        onClose={() => setIsOpenEctricityBillImage(false)}
        imageUrl={
          guarantorDetails?.FGElectricityBillOrWaterImageURL
            ? `${guarantorDetails.FGElectricityBillOrWaterImageURL}`
            : ""
        }
        onFileSelect={(file) =>
          handleImageFileSelect(file, "FGElectricityBillOrWaterImageURL")
        }
      />
      {/* Signature modal */}
      <SignatureModal
        visible={isSignatureModalVisible}
        onClose={handleFirstSignatureModalClose}
        onOk={handleCustomerSignModalOk}
        signurl={newSign ? "" : customerSignUrl?.FGSignatureImageURL}
        onClear={handleCustomerSignModalClear}
      />
    </div>
  );
};

export default FirstGuranter;
