import React from "react";
import { M_ReleaseFundsTable } from "../../Tables";

const M_ReleaseFundsPage: React.FC = () => {
  return (
    <div>
      {/* release funds table  */}
      <M_ReleaseFundsTable />
    </div>
  );
};

export default M_ReleaseFundsPage;
