import React, { useEffect, useState } from "react";
import { Input, Main, Title, Button, PrimaryModal } from "../../common";
import { CiEdit } from "react-icons/ci";
import { getUserByUserId, updateUser } from "../../../api/employee";
import toast from "react-hot-toast";
import {
  canUserDelete,
  deleteuser,
  getUserRoleByUserId,
  permanentDelete,
} from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { userUpdateSchema } from "../../../lib/validation/userUpdate-val";
import AssignManagerAndDeleteUserModal from "../../common/modals/AssignManagerAndDeleteUserModal";
import AssignCollectorAndDeleteuserModal from "../../common/modals/AssignCollectorAndDeleteuserModal";

// Define the InputField type
type InputField =
  | "firstName"
  | "lastName"
  | "email"
  | "contactNumber"
  | "username"
  | "supervisorName"
  | "role";

// Define the InputState type
interface InputState {
  disabled: boolean;
  value: string;
  initialValue: string;
}

interface userDetailsProps {
  userId: number;
}

const A_UserOverviewPage: React.FC<{ userDetails: userDetailsProps }> = ({
  userDetails,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [activeInput, setActiveInput] = useState<InputField | null>(null);
  const [managerOption, setManagerOption] = useState<string>("");
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, setSupervisorError] = useState<string>("");
  const userId = localStorage.getItem("userId") || 0;
  const [errors, setErrors] = useState<Record<string, string>>({});
  //  is user id and selected user id same
  const isUserSame = Number(userId) === userDetails.userId;
  const [isFirstValidation, setIsFirstValidation] = useState(false);
  const [isOpenAssignManager, setIsOpenAssignManager] = useState(false);
  const [isOpenAssignCollector, setIsOpenAssignCollector] = useState(false);

  // Local state for each input's disabled status, value, and initial value
  const [inputs, setInputs] = useState<Record<InputField, InputState>>({
    firstName: { disabled: true, value: "", initialValue: "" },
    lastName: { disabled: true, value: "", initialValue: "" },
    email: { disabled: true, value: "", initialValue: "" },
    contactNumber: { disabled: true, value: "", initialValue: "" },
    username: { disabled: true, value: "", initialValue: "" },
    supervisorName: { disabled: true, value: "", initialValue: "" },
    role: { disabled: true, value: "", initialValue: "" },
  });

  // Fetch user details by user id
  useEffect(() => {
    const getUserDetails = async () => {
      const token = localStorage.getItem("token") || "";
      const userId = userDetails.userId;

      try {
        const response = await getUserByUserId(token, userId);
        if (response) {
          setInputs({
            firstName: {
              disabled: true,
              value: response.data.firstName || "",
              initialValue: response.data.firstName || "",
            },
            lastName: {
              disabled: true,
              value: response.data.lastName || "",
              initialValue: response.data.lastName || "",
            },
            email: {
              disabled: true,
              value: response.data.email || "",
              initialValue: response.data.email || "",
            },
            contactNumber: {
              disabled: true,
              value: response.data.contactNumber || "",
              initialValue: response.data.contactNumber || "",
            },
            username: {
              disabled: true,
              value: response.data.username || "",
              initialValue: response.data.username || "",
            },
            supervisorName: {
              disabled: true,
              value: response.data.supervisorName || "",
              initialValue: response.data.supervisorName || "",
            },
            role: {
              disabled: true,
              value: response.data.role || "",
              initialValue: response.data.role || "",
            },
          });
          setSelectedOption(response.data.role || "");
          setManagerOption(response.data.manager || ""); // Assuming manager information is present
        } else {
          console.log("No response data");
        }
      } catch (error) {
        console.log(error);
      }
    };

    getUserDetails();
  }, [userDetails.userId]);

  // Function to handle input change
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: InputField
  ) => {
    setInputs({
      ...inputs,
      [field]: { ...inputs[field], value: e.target.value },
    });
    if (e.target.value !== inputs[field].initialValue) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
    if (isFirstValidation) {
      validateForm({
        ...inputs,
        [field]: { ...inputs[field], value: e.target.value },
      });
    }
  };

  const validateForm = (data: typeof inputs) => {
    try {
      userUpdateSchema.parse(data);
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          newErrors[err.path[0]] = err.message;
        });
        setErrors(newErrors);
        return false;
      }
      return false;
    }
  };

  // Function to enable input and store initial value
  const handleEditClick = (field: InputField) => {
    setInputs((prevInputs) => {
      const updatedInputs = { ...prevInputs };
      if (activeInput && activeInput !== field) {
        // Reset the value of the previously active input to its initial value
        updatedInputs[activeInput] = {
          ...updatedInputs[activeInput],
          disabled: true,
          value: updatedInputs[activeInput].initialValue,
        };
      }
      // Enable the clicked input
      updatedInputs[field] = {
        ...updatedInputs[field],
        disabled: false,
        initialValue: updatedInputs[field].value,
      };
      return updatedInputs;
    });
    setActiveInput(field);
  };

  // Function to handle save
  const handleSave = (field: InputField) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: {
        ...prevInputs[field],
        disabled: true,
        initialValue: prevInputs[field].value,
      },
    }));
    setActiveInput(null);
    // Check if any other fields still have changes
    const anyChanges = Object.keys(inputs).some(
      (key) =>
        inputs[key as InputField].value !==
        inputs[key as InputField].initialValue
    );
    setHasChanges(anyChanges);
  };

  // Function to handle save all changes
  const handleSaveAll = async () => {
    setIsLoading(true);
    setIsFirstValidation(true);
    const token = localStorage.getItem("token") || "";

    const updatedData = {
      userId: userDetails.userId,
      firstName: inputs.firstName.value,
      lastName: inputs.lastName.value,
      email: inputs.email.value,
      contactNumber: inputs.contactNumber.value,
      username: inputs.username.value,
      role: selectedOption,
      manager: managerOption,
    };

    if (!validateForm(inputs)) {
      // Check if passwords match
      setIsLoading(false);
      return;
    }

    try {
      const response = await updateUser(token, updatedData);
      if (response.success) {
        toast.success(response.message);
        setInputs((prevInputs) => {
          const updatedInputs = { ...prevInputs };
          Object.keys(updatedInputs).forEach((key) => {
            updatedInputs[key as InputField].initialValue =
              updatedInputs[key as InputField].value;
            updatedInputs[key as InputField].disabled = true; // Disable the inputs after saving
          });
          return updatedInputs;
        });
        setHasChanges(false);
        setSupervisorError("");
      } else if (
        response.message === "Supervisor ID must be provided for COLLECTOR role"
      ) {
        toast.error(response.message);
        setSupervisorError(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("System Error");
    } finally {
      setIsLoading(false);
    }
    setIsLoading(false);
    setIsFirstValidation(false);
  };

  // handle navigate employee list
  const handleNavigateEmployeeList = () => {
    navigate("/employee-list");
  };

  // handle delete user
  const handleDeleteUser = async () => {
    const token = localStorage.getItem("token") || "";
    const userId = userDetails.userId;

    try {
      const response = await deleteuser(token, Number(userId));
      if (response.success) {
        handleNavigateEmployeeList();
        toast.success(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle cancel
  const handleCancel = (field: InputField) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: {
        ...prevInputs[field],
        disabled: true,
        value: prevInputs[field].initialValue,
      },
    }));
    setActiveInput(null);
    setHasChanges(false);
  };

  // get user role, check if can delete, update new user, and delete user
  const handlePermanentDelete = async () => {
    const token = localStorage.getItem("token") || "";
    const appUserId = userDetails.userId;
    // get user role
    try {
      const getUserRoleRes = await getUserRoleByUserId(token, appUserId);
      if (getUserRoleRes.success) {
        // if user role is ADMNIN
        if (getUserRoleRes.data === "ADMIN") {
          // delete permanently
          try {
            const res = await permanentDelete(token, appUserId);
            if (res.success) {
              toast.success(res.message);
              navigate("/employee-list", { replace: true });
            } else {
              toast.error(res.message);
            }
          } catch (error) {
            console.log(error);
          }
        }
        // if not admin
        //check if can permanent delete
        try {
          const response = await canUserDelete(token, appUserId);
          if (response.data) {
            // delete permanently
            try {
              const res = await permanentDelete(token, appUserId);
              if (res.success) {
                toast.success(res.message);
                navigate("/employee-list", { replace: true });
              } else {
                toast.error(res.message);
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            // if user role is MANAGER
            if (getUserRoleRes.data === "MANAGER") {
              setIsOpenAssignManager(true);
            }

            // if user role is MANAGER
            if (getUserRoleRes.data === "COLLECTOR") {
              setIsOpenAssignCollector(true);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {/* main title */}
      <Title title={"User Overview"} />

      {/* main container */}
      <Main>
        <div className="mb-6">
          <div className="grid-cols-2 gap-2 sm:grid">
            {/* first name */}
            <Input
              id="firstName"
              name="firstName"
              placeholder="First Name"
              label="First Name"
              disabled={inputs.firstName.disabled}
              value={inputs.firstName.value}
              onChange={(e) => handleInputChange(e, "firstName")}
              backIcon={
                <CiEdit
                  className="mt-5 cursor-pointer lg:mt-6"
                  onClick={() => handleEditClick("firstName")}
                />
              }
              saveCancel={!inputs.firstName.disabled}
              saveOnclick={() => handleSave("firstName")}
              cancelOnclick={() => handleCancel("firstName")}
              errorMessage={errors.firstName}
            />

            {/* last name */}
            <Input
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              label="Last Name"
              disabled={inputs.lastName.disabled}
              value={inputs.lastName.value}
              onChange={(e) => handleInputChange(e, "lastName")}
              backIcon={
                <CiEdit
                  className="mt-5 cursor-pointer lg:mt-6"
                  onClick={() => handleEditClick("lastName")}
                />
              }
              saveCancel={!inputs.lastName.disabled}
              saveOnclick={() => handleSave("lastName")}
              cancelOnclick={() => handleCancel("lastName")}
              errorMessage={errors.lastName}
            />
          </div>

          <div className="grid-cols-2 gap-2 sm:grid">
            {/* email address */}
            <Input
              id="email"
              name="email"
              placeholder="Email Address"
              label="Email Address"
              disabled={inputs.email.disabled}
              value={inputs.email.value}
              onChange={(e) => handleInputChange(e, "email")}
              backIcon={
                <CiEdit
                  className="mt-5 cursor-pointer lg:mt-6"
                  onClick={() => handleEditClick("email")}
                />
              }
              saveCancel={!inputs.email.disabled}
              saveOnclick={() => handleSave("email")}
              cancelOnclick={() => handleCancel("email")}
              errorMessage={errors.email}
              restrictSpaces
            />

            {/* contact address */}
            <Input
              id="contactNumber"
              name="contactNumber"
              placeholder="Contact Number"
              label="Contact Number"
              type="number"
              disabled={inputs.contactNumber.disabled}
              value={inputs.contactNumber.value}
              onChange={(e) => handleInputChange(e, "contactNumber")}
              backIcon={
                <CiEdit
                  className="mt-5 cursor-pointer lg:mt-6"
                  onClick={() => handleEditClick("contactNumber")}
                />
              }
              saveCancel={!inputs.contactNumber.disabled}
              saveOnclick={() => handleSave("contactNumber")}
              cancelOnclick={() => handleCancel("contactNumber")}
              errorMessage={errors.contactNumber}
              restrictSpaces
            />
          </div>

          {/* User name */}
          <div className="grid-cols-2 gap-2 sm:grid">
            <Input
              id="username"
              name="username"
              placeholder="User Name"
              label="User Name"
              disabled={inputs.username.disabled}
              value={inputs.username.value}
              onChange={(e) => handleInputChange(e, "username")}
              backIcon={
                <CiEdit
                  className="mt-5 cursor-pointer lg:mt-6"
                  onClick={() => handleEditClick("username")}
                />
              }
              saveCancel={!inputs.username.disabled}
              saveOnclick={() => handleSave("username")}
              cancelOnclick={() => handleCancel("username")}
              errorMessage={errors.username}
              restrictSpaces
            />
          </div>
          <div className="grid-cols-2 gap-2 sm:grid">
            <Input
              id="role"
              name="role"
              placeholder="Role "
              label="Role"
              disabled
              value={inputs.role.value}
            />
            {/* If selected option is collector then display the manager dropdown */}
            {selectedOption === "COLLECTOR" && (
              <Input
                id="supervisor"
                name="supervisor"
                placeholder="Supervisor "
                label="Supervisor"
                disabled
                value={inputs.supervisorName.value}
              />
            )}
          </div>
        </div>

        <div className="flex justify-center gap-4">
          {hasChanges && (
            <div className="flex justify-center">
              <Button
                text={isLoading ? "Saving..." : "Save Changes"}
                onClick={handleSaveAll}
                disabled={isLoading}
                border="border-none"
              />
            </div>
          )}

          {!isUserSame && (
            <div className={` xs:flex justify-center max-xs:space-y-3 gap-3`}>
              {selectedOption !== "UNASSIGNED" && (
                <Button
                  text={"Delete"}
                  bgColor="bg-red-700  hover:bg-red-800"
                  border="border-none"
                  onClick={() => setIsModalOpen(true)}
                />
              )}

              <Button
                text={"Permanent Delete"}
                bgColor="bg-red-700  hover:bg-red-800"
                border="border-none"
                onClick={handlePermanentDelete}
              />
            </div>
          )}
        </div>
      </Main>

      {/* delete confirm modal  */}
      <PrimaryModal
        visible={isModalOpen}
        mainTitle={"Employee Delete"}
        content={"Are you sure you want to delete this Employee?"}
        primaryBtnLabel="Yes"
        secondaryBtnLabel="No"
        primaryOnClick={() => {
          handleDeleteUser();
          setIsModalOpen(false);
        }}
        secondaryOnClick={() => setIsModalOpen(false)}
        onClose={() => setIsModalOpen(false)}
      />

      <AssignManagerAndDeleteUserModal
        visible={isOpenAssignManager}
        onClose={() => setIsOpenAssignManager(false)}
        selectedMangersId={userDetails.userId}
      />

      <AssignCollectorAndDeleteuserModal
        visible={isOpenAssignCollector}
        onClose={() => setIsOpenAssignCollector(false)}
        selectedCollectorsId={userDetails.userId}
      />
    </div>
  );
};

export default A_UserOverviewPage;
