import { useEffect, useState } from "react";
import { Guarantor } from "../types";
import { getGuaranterBynic } from "../api/guarantee";

export const useGetGuaratorDetails = () => {
  const [guarantorDetails, setGuarantorDetails] = useState<Guarantor>();
  const [guarantorSecondDetails, setGuarantorSecondDetails] =
    useState<Guarantor>();

  const [firstSuccess, setFirstSuccess] = useState(false);
  const [secondSuccess, setSecondSuccess] = useState(false);

  //   get guarantor Details using api
  const getGuarantorDetailsByNic = async () => {
    const token = localStorage.getItem("token") || "";
    const FGNic = localStorage.getItem("FGNic") || "";
    if (FGNic) {
      try {
        const response = await getGuaranterBynic(token, FGNic);
        setGuarantorDetails(response.data);
        setFirstSuccess(response.success);
      } catch {
        console.log("error fetching guarantor details");
      }
    }
  };

  //   get second guarantor Details using api
  const getSecondGuarantorDetailsByNic = async () => {
    const token = localStorage.getItem("token") || "";
    const SGNic = localStorage.getItem("SGNic") || "";
    if (SGNic) {
      try {
        const response = await getGuaranterBynic(token, SGNic);
        setGuarantorSecondDetails(response.data);
        setSecondSuccess(response.success);
      } catch {
        console.log("error fetching guarantor details");
      }
    }
  };

  useEffect(() => {
    getSecondGuarantorDetailsByNic();
  }, []);

  useEffect(() => {
    getGuarantorDetailsByNic();
  }, []);

  return {
    guarantorDetails,
    guarantorSecondDetails,
    firstSuccess,
    secondSuccess,
    getGuarantorDetailsByNic,
    getSecondGuarantorDetailsByNic,
    setFirstSuccess,
    setSecondSuccess,
  };
};
