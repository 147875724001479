import React, { useState } from "react";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ChartConfiguration,
} from "chart.js";
import PrimaryTitle from "../text/PrimaryTitle";

ChartJS.register(
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const centerTextPlugin = {
  id: "centerText",
  beforeDraw: (
    chart: { config?: ChartConfiguration; width?: number; height?: number; ctx?: CanvasRenderingContext2D },
    _args: unknown,
    options: { text: string; amount: number }
  ) => {
    if (chart.config && chart.config.type === "doughnut") {
      const { width, height, ctx } = chart;
      if (ctx) {
        ctx.restore();

        ctx.globalAlpha = 0.5;
        ctx.font = `14px sans-serif`;
        ctx.textBaseline = "middle";
        const text = options.text;
        const textX = Math.round((width ? width - ctx.measureText(text).width : 0) / 2);
        const textY = (height ?? 0) / 2.4;
        ctx.fillText(text, textX, textY);

        ctx.globalAlpha = 1.0;
        ctx.font = `18px sans-serif`;
        const amount = options.amount;
        const amountX = Math.round(((width ?? 0) - ctx.measureText(String(amount)).width) / 2);
        const amountY = (height ?? 0) / 1.8;
        ctx.fillText(String(amount), amountX, amountY);

        ctx.save();
      }
    }
  },
};


ChartJS.register(centerTextPlugin);

interface LegendData {
  label: string;
  value: string;
  color: string;
}

interface CenterTextOptions {
  text: string;
  amount: string;
}

interface BarData {
  labels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    backgroundColor: string;
  }>;
}

interface DoughnutData {
  datasets: Array<{
    data: number[];
    backgroundColor: string[];
    borderWidth: number;
  }>;
  labels: string[];
}

interface ChartOptions {
  responsive?: boolean;
  scales?: object;
  plugins?: any;
  cutout?: string | number; 
}


interface ChartProps {
  type: "doughnut" | "bar" | "line";
  title: string;
  data: BarData | DoughnutData; // Data can be either BarData or DoughnutData
  options?: ChartOptions;
  legendData?: LegendData[];
  timeframeOptions?: string[];
  centerTextOptions?: CenterTextOptions;
}

const Chart: React.FC<ChartProps> = ({
  type,
  title,
  data,
  options,
  legendData,
  timeframeOptions,
  centerTextOptions,
}) => {
  const [timeframe, setTimeframe] = useState(
    timeframeOptions ? timeframeOptions[0] : ""
  );

  const handleTimeframeChange = (newTimeframe: string) => {
    setTimeframe(newTimeframe);
  };

  // Function to format data to 2 decimal places
  const formatDataToFixedTwoDecimals = (data: BarData | DoughnutData) => {
    return {
      ...data,
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        data: dataset.data.map((value) => Number(value.toFixed(2))),
      })),
    };
  };

  const formattedData = formatDataToFixedTwoDecimals(data);

  return (
    <div
      className={`p-4 bg-primaryColor-50 shadow-md rounded-lg ${
        type === "line" ? "w-full" : "w-[370px]"
      }`}
    >
      <div
        className={`pb-3 mb-4 ${
          type === "line"
            ? "justify-start border-b-0"
            : "justify-center border-b-2 border-primaryColor-100"
        } flex`}
      >
        <PrimaryTitle fontColor="text-black" fontSize="text-2xl" text={title} />
      </div>
      <div className="flex flex-col items-center justify-center mb-4 space-y-2 xxs:flex-row sm:items-start xxs:space-y-0 xxs:space-x-2">
        {type === "line" && (
          <div className="flex items-center justify-center p-1 space-y-2 bg-white border rounded-lg shadow-sm xxs:space-y-0 xxs:space-x-2">
            {timeframeOptions &&
              timeframeOptions.map((tf) => (
                <div
                  key={tf}
                  className={`py-2 xs:px-2 sm:px-4 cursor-pointer rounded-md ${
                    timeframe === tf
                      ? "text-green-700 font-semibold"
                      : "text-gray-700"
                  } hover:bg-gray-100`}
                  onClick={() => handleTimeframeChange(tf)}
                >
                  {tf}
                </div>
              ))}
          </div>
        )}
      </div>

      <div className="flex flex-col items-center justify-center space-x-0 align-middle sm:flex-row sm:space-x-2">
        <div className={`${type === "line" ? "w-full" : "w-full xs:w-3/5"}`}>
          {type === "doughnut" && (
            <Doughnut
              data={formattedData}
              options={{
                ...options,
                plugins: {
                  ...options?.plugins,
                  centerText: centerTextOptions,
                },
              }}
            />
          )}

          {type === "bar" && (
            <Bar
              data={{
                ...formattedData,
                datasets: formattedData.datasets.map((dataset) => ({
                  ...dataset,
                  barThickness: 20,
                })),
              }}
              options={{
                ...options,
                scales: {
                  x: {
                    beginAtZero: true,
                    stacked: true,
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    beginAtZero: true,
                    stacked: true,
                    grid: {
                      color: "#ffffff",
                      display: true,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                },
              }}
            />
          )}
          {type === "line" && (
            <Line
              data={formattedData}
              options={{
                ...options,
                scales: {
                  x: {
                    beginAtZero: true,
                    stacked: true,
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    beginAtZero: true,
                    stacked: true,
                    grid: {
                      color: "#ffffff",
                      display: true,
                    },
                    ticks: {
                      display: true,
                    },
                  },
                },
                elements: {
                  point: {
                    radius: 0,
                  },
                },
              }}
            />
          )}
        </div>
        {legendData && (
          <div
            className={`${
              type === "line" ? "w-full" : "w-full sm:w-2/5"
            } space-y-2 mt-4 sm:mt-0 sm:ml-4`}
          >
            {legendData.map((item) => (
              <div
                key={item.label}
                className="flex justify-between p-2 bg-white rounded-md shadow-sm sm:flex-col"
              >
                <div className="flex items-center">
                  <div className={`w-3 h-3 ${item.color} mr-2`}></div>
                  <p className="text-gray-700">{item.label}</p>
                </div>
                <p className="text-gray-700">{item.value}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Chart;
