import React, { useState } from "react";
import { Modal } from "antd";
import {
  Checkbox,
  Description,
  SignatureModal,
  UploadImageInput,
} from "../common";
import { collateralRelease } from "../../api/collateral";
import toast from "react-hot-toast";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../../lib/configs/firebaseConfig";
import { removeBackgroundFromImage } from "../../api/bgremover";

const CollateralReleaseModal: React.FC<{
  visible: boolean;
  onClose?: () => void;
  closeIcon?: React.ReactNode;
  loanId: number;
  customerId: number;
  onUpdateCollateralData: (loanId: number) => void;
}> = ({
  visible,
  onClose,
  closeIcon,
  loanId,
  customerId,
  onUpdateCollateralData,
}) => {
  const [customerSignatureUrl, setCustomerSignatureUrl] = useState<string>("");
  const [isSignatureModalVisible, setIsSignatureModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [isCustomerUploadMode, setIsCustomerUploadMode] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);
  const [customerSignError, setCustomerSignError] = useState<string | null>(
    null
  );
  // Function to handle opening signature modal
  const handleSignatureModalOpen = () => {
    setIsSignatureModalVisible(true);
  };

  // Function to handle closing signature modal
  const handleSignatureModalClose = () => {
    setIsSignatureModalVisible(false);
  };

  // Firebase upload function
  // Firebase upload function
  const uploadImageToFirebase = async (): Promise<string> => {
    if (!customerSignatureUrl) {
      throw new Error("No signature to upload.");
    }

    // Check if the URL is in the correct data URL format
    let base64String = customerSignatureUrl;

    // Ensure that base64String is a valid data URL
    if (!base64String.startsWith("data:")) {
      throw new Error("Invalid base64 data URL.");
    }

    // Extract the base64 part if necessary
    if (!base64String.includes(";base64,")) {
      throw new Error("Base64 data URL does not contain ';base64,'.");
    }

    const uniqueName = uuidv4();
    const storageRef = ref(
      storage,
      `CollateralReleaseSignatures/${uniqueName}`
    );

    try {
      // Upload the base64 string to Firebase
      await uploadString(storageRef, base64String, "data_url");
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  // Handle the collateral release process
  const handleCollateralRelease = async () => {
    setIsLoading(true); // Set loading to true
    try {
      const firebaseUrl = await uploadImageToFirebase();

      const token = localStorage.getItem("token") || "";
      const requestBody = {
        customerId: customerId,
        loanId: loanId,
        signatureImageUrl: firebaseUrl,
      };

      const response = await collateralRelease(token, requestBody);
      if (response.success) {
        toast.success(response.message);
        if (onClose) onClose(); // Close modal after successful release

        onUpdateCollateralData(loanId); // Call the update function to refresh data

        setCustomerSignatureUrl("");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while releasing collateral.");
    } finally {
      setIsLoading(false); // Set loading to false regardless of success or error
    }
  };

  // Handle the signature OK button
  const handleSignatureOk = (signatureUrl: string | null) => {
    if (signatureUrl) {
      setCustomerSignatureUrl(signatureUrl);
      handleSignatureModalClose();
    }
  };

  // Optional: Function to clear the signature
  const handleClearSignature = () => {
    setCustomerSignatureUrl("");
  };

  // Modify the function handling the checkbox change
  const handleCustomerUploadModeChange = (isChecked: boolean) => {
    setIsCustomerUploadMode(isChecked);
    localStorage.setItem("isCustomerUploadMode", JSON.stringify(isChecked));

    // Clear the signature URL or image URL when switching modes
    if (isChecked) {
      setCustomerSignatureUrl(""); // Clear signature URL if switching to upload mode
    } else {
      // Clear any uploaded image if switching to signature mode
      setCustomerSignatureUrl("");
    }
  };

  // Helper function to convert Blob to base64 data URL
  const convertBlobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  };

  // Function to handle image selection and uploading
  const handleImageChangeSignatures = async (
    file: File,
    imageField: string
  ) => {
    try {
      setLoadingImages(true);

      // Convert the file to a Blob
      const blob = new Blob([file], { type: file.type });

      // Process the Blob to remove the background
      const processedBlob = await removeBackgroundFromImage(blob);

      // Convert the processed Blob to a base64 data URL
      const base64Image = await convertBlobToBase64(processedBlob);

      // Set the appropriate state based on the imageField
      if (imageField === "customerSignatureImageURL") {
        setCustomerSignatureUrl(base64Image);
        setCustomerSignError(null); // Clear any errors
      }
    } catch (error) {
      setCustomerSignError("Failed to upload image. Please try again.");
      console.error("Image upload error:", error);
    } finally {
      setLoadingImages(false);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="custom-modal-payment-recipt"
    >
      <div>
        {/* Display the description */}
        <Description
          content="මෙම ඇප බාන්ඩය ලැබුන බවට සහතික කරමි."
          fontWeight="font-semibold"
        />

        <Checkbox
          onChange={(e) => handleCustomerUploadModeChange(e.target.checked)}
          checked={isCustomerUploadMode}
          label="Upload Image"
        />

        {isCustomerUploadMode ? (
          // Image upload input appears when the checkbox is checked
          <UploadImageInput
            id={"customerSignatureImageURL"}
            name={"customerSignatureImageURL"}
            text={""}
            placeholder={""}
            onFileSelect={(file) => {
              if (file) {
                handleImageChangeSignatures(file, "customerSignatureImageURL");
              }
            }}
            selectImage={customerSignatureUrl}
            isLoading={loadingImages}
          />
        ) : (
          <div
            className="h-40 p-2 mt-2 bg-white border-2 rounded-lg cursor-pointer max-w-[100%] border-primaryColor-200"
            onClick={handleSignatureModalOpen}
          >
            {customerSignatureUrl ? (
              <img
                src={customerSignatureUrl}
                className="object-contain w-full h-full"
                alt="Customer Signature"
              />
            ) : (
              <div className="flex items-center justify-center h-full underline duration-300 text-primaryColor-200 hover:scale-105">
                <Description
                  content="Sign here"
                  fontWeight="font-medium"
                  fontColor="text-primaryColor-200"
                  center
                />
              </div>
            )}
          </div>
        )}

        {/* Signature Modal */}
        <SignatureModal
          visible={isSignatureModalVisible}
          onClose={handleSignatureModalClose}
          onOk={handleSignatureOk}
          signurl={customerSignatureUrl}
          onClear={handleClearSignature}
        />
      </div>

      {/* Submit Button */}
      <div className="mt-4">
        <button
          onClick={handleCollateralRelease}
          className={`w-full p-2 text-white rounded-lg ${
            customerSignatureUrl
              ? "bg-primaryColor-500"
              : "bg-primaryColor-200 cursor-not-allowed"
          }`}
          disabled={!customerSignatureUrl || isLoading} // Disable if loading
        >
          {isLoading && (
            <div
              role="status"
              className="inline-block w-4 h-4 mr-2 border-2 border-current border-solid rounded-full text-primary-purple 3xl:h-5 3xl:w-5 animate-spin border-r-transparent"
            ></div>
          )}
          {isLoading ? "Processing..." : "Release Collateral"}
        </button>
      </div>
    </Modal>
  );
};

export default CollateralReleaseModal;
