import React from "react";
import { Button, Main, Title } from "../../common";
import { M_CollectorListTable } from "../../Tables";
import { useNavigate } from "react-router-dom";

const EmployeeListPage: React.FC = () => {
  const navigate = useNavigate();

  // handle navigate user registration
  const handleNavigateUserRegister = () => {
    navigate("/user-register");
  };

  return (
    <div>
      {/* main container  */}
      <Main>
        {/* title  */}
        <Title title="All Collectors" bgStyle={false} />
        {/* table  */}
        <M_CollectorListTable />
        <Button text={"Add Collector"} onClick={handleNavigateUserRegister} />
      </Main>
    </div>
  );
};

export default EmployeeListPage;
