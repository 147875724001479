import { z } from "zod";

// Define the Zod schema for the guarantor data
export const FirstGuarantorSchema = z.object({
  FGFullName: z
    .string()
    .min(3, "නමෙහි අවම අකුරු 3ක්වත් තිබිය යුතුය")
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .regex(
      /^[A-Za-z\u0D80-\u0DFF\s]+$/,
      "නමෙහි විශේෂ අකුරු සහ සංඛ්‍යා නොවිය යුතුය"
    )
    .nonempty("අවශ්යයි"),
  FGAddress: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .min(1, "අවශ්‍යයි"),
  FGNic: z
    .string()
    .min(10, " අවම වශයෙන් අක්ෂර 10 ක් විය යුතුය")
    .max(12, "අක්ෂර 12 ඉක්මවිය නොහැක"),
  FGVerificationMethod: z.string().min(1, "අවශ්‍යයි"),
  FGPhoneNumber: z
    .string()
    .regex(/^\d+$/, "දුරකථන අංකය ඉලක්කම් විය යුතුය")
    .min(10, "දුරකථන අංකය අවම වශයෙන් ඉලක්කම් 10ක් විය යුතුය")
    .max(10, "දුරකථන අංකය වැඩිම ඉලක්කම් 10ක් විය යුතුය"),
  FGuarantorImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  FGFrontImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  FGBackImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  FGSignatureImageURL: z.any().refine((value) => value !== "", {
    message: "අත්සන අවශ්‍ය වේ",
  }),
  FGElectricityBillOrWaterImageURL: z.any().refine((value) => value !== "", {
    message: "බිල්පතෙහි ඡායාරූපය අවශ්‍ය වේ",
  }),
});

// Define the Zod schema for the guarantor data
export const SecondGuarantorSchema = z.object({
  SGFullName: z
    .string()
    .min(3, "නමෙහි අවම අකුරු 3ක්වත් තිබිය යුතුය")
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .regex(
      /^[A-Za-z\u0D80-\u0DFF\s]+$/,
      "නමෙහි විශේෂ අකුරු සහ සංඛ්‍යා නොවිය යුතුය"
    )
    .nonempty("අවශ්යයි"),

  SGAddress: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .min(1, "අවශ්‍යයි"),
  SGNic: z
    .string()
    .min(10, " අවම වශයෙන් අක්ෂර 10 ක් විය යුතුය")
    .max(12, "අක්ෂර 12 ඉක්මවිය නොහැක"),
  SGVerificationMethod: z.string().min(1, "අවශ්‍යයි"),
  SGPhoneNumber: z
    .string()
    .regex(/^\d+$/, "දුරකථන අංකය ඉලක්කම් විය යුතුය")
    .min(10, "දුරකථන අංකය අවම වශයෙන් ඉලක්කම් 10ක් විය යුතුය")
    .max(10, "දුරකථන අංකය වැඩිම ඉලක්කම් 10ක් විය යුතුය"),
  SGuarantorImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  SGFrontImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  SGBackImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  SGSignatureImageURL: z.any().refine((value) => value !== "", {
    message: "අත්සන අවශ්‍ය වේ",
  }),
  SGElectricityBillOrWaterImageURL: z.any().refine((value) => value !== "", {
    message: "බිල්පතෙහි ඡායාරූපය අවශ්‍ය වේ",
  }),
});
