import React from "react";
import { Main, Title } from "../../common";
import { A_PaymentListTable } from "../../Tables";

const M_PaymentListPage: React.FC = () => {
  return (
    <div>
      {/* manager payment list  */}
      <Main>
        {/* title  */}
        <Title title="All Payments" bgStyle={false} />
        {/* table  */}
        <A_PaymentListTable />
      </Main>
    </div>
  );
};

export default M_PaymentListPage;
