import { z } from "zod";

// Validation schema for step 1
export const Step1Schema = z.object({
  loanReason: z
    .string()
    .min(3, "හේතුව සඳහා අවම අකුරු 3ක්වත් තිබිය යුතුය")
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .nonempty("අවශ්යයි"),
  fullName: z
    .string()
    .min(3, "නමෙහි අවම අකුරු 3ක්වත් තිබිය යුතුය")
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .regex(
      /^[A-Za-z\u0D80-\u0DFF\s]+$/,
      "නමෙහි විශේෂ අකුරු සහ සංඛ්‍යා නොවිය යුතුය"
    )
    .nonempty("අවශ්යයි"),
  address: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .nonempty({ message: "අවශ්යයි" }),
  nic: z
    .string()
    .min(10, "අවම අකුරු 10ක් තිබිය යුතුය")
    .max(12, "උපරිම අකුරු 12ක් විය යුතුය")
    .nonempty({ message: "අවශ්යයි" }),
  policeStation: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .nonempty("අවශ්යයි"),
  gramaDivision: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .nonempty({ message: "අවශ්යයි" }),
  provincialOffice: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .nonempty({ message: "අවශ්යයි" }),
});

// Validation schema for step 2
export const Step2Schema = z.object({
  verificationMethod: z.string().nonempty("අවශ්යයි"),
  phoneNumber: z
    .string()
    .regex(/^\d+$/, "සංඛ්‍යා පමණක් තිබිය යුතුය")
    .min(10, "දුරකථන අංකය ඉලක්කම් 10ක් විය යුතුය")
    .max(10, "දුරකථන අංකය ඉලක්කම් 10ක් විය යුතුය")
    .nonempty("අවශ්යයි"),

  email: z
    .string()
    .email("වලංගු නොවන ඊමේල් ආකෘතියකි")
    .nonempty("අවශ්යයි")
    .max(254, "උපරිම අකුරු 254ක් විය යුතුය")
    .refine((value) => /\S+@\S+\.\S+/.test(value), {
      message: "වලංගු නොවන ඊමේල් ආකෘතියකි",
    }),
  optionalContactNumber: z
    .string()
    .refine((value) => value === "" || value.length === 10, {
      message: "දුරකථන අංකය ඉලක්කම් 10ක් විය යුතුය",
    })
    .refine((value) => value === "" || /^\d+$/.test(value), {
      message: "සංඛ්‍යා පමණක් තිබිය යුතුය",
    }),

  whatsAppNumber: z
    .string()
    .regex(/^\d+$/, "සංඛ්‍යා පමණක් තිබිය යුතුය")
    .min(10, "දුරකථන අංකය ඉලක්කම් 10ක් විය යුතුය")
    .max(10, "දුරකථන අංකය ඉලක්කම් 10ක් විය යුතුය")
    .nonempty("අවශ්යයි"),

  frontImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  backImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  customerImageURL: z.any().refine((value) => value !== "", {
    message: "ණය ගැනුම්කරුගේ ඡායාරූපය අවශ්යයි",
  }),
  electricityBillImageURL: z.any().refine((value) => value !== "", {
    message: "බිල්පතෙහි ඡායාරූපය අවශ්යයි",
  }),
});

// Validation schema for step 3
export const Step3Schema = z.object({
  companyName: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .nonempty("අවශ්යයි"),
  occupation: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .nonempty({ message: "අවශ්යයි" }),
  companyAddress: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .nonempty({ message: "අවශ්යයි" }),
  netMonthlySalary: z
    .string()
    .nonempty("අවශ්යයි")
    .regex(
      /^\d+(\.\d{1,2})?$/,
      "සංඛ්‍යා පමණක් තිබිය යුතුය (තැබිය හැකි උපරිමය දශම ස්ථාන ගණන 2කි)"
    )
    .refine((value) => parseFloat(value) > 999, {
      message: "ණය මුදලේ වටිනාකම 1000 හෝ 1000 ට වඩා වැඩි විය යුතුය.",
    }),
  loanAmount: z
    .string()
    .nonempty("අවශ්යයි")

    .regex(
      /^\d+(\.\d{1,2})?$/,
      "සංඛ්‍යා පමණක් තිබිය යුතුය (තැබිය හැකි උපරිමය දශම ස්ථාන ගණන 2කි)"
    )
    .refine((value) => parseFloat(value) > 999, {
      message: "ණය මුදලේ වටිනාකම 1000 හෝ 1000 ට වඩා වැඩි විය යුතුය.",
    }),

  repaymentFrequency: z.string().nonempty("අවශ්යයි"),
  selectedNumberofInstallment: z
    .string()
    .nonempty("අවශ්යයි")
    .regex(/^\d+$/, "සංඛ්‍යා පමණක් තිබිය යුතුය")
    .refine((value) => parseInt(value) > 0, {
      message: "වාරික ගණන 0 ට වඩා වැඩි විය යුතුය.",
    }),
  // startDate: z.string().nonempty("අවශ්යයි"),
  // endDate: z.string().nonempty("අවශ්යයි"),
});

// Validation schema for step 4
export const Step4Schema = z.object({
  FGFullName: z
    .string()
    .min(3, "නමෙහි අවම අකුරු 3ක්වත් තිබිය යුතුය")
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    // .regex(
    //   /^[A-Za-z\u0D80-\u0DFF\s]+$/,
    //   "නමෙහි විශේෂ අකුරු සහ සංඛ්‍යා නොවිය යුතුය"
    // )
    .nonempty("අවශ්යයි"),
  FGAddress: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .nonempty({ message: "අවශ්යයි" }),

  FGNic: z
    .string()
    .min(10, "අවම අකුරු 10ක් තිබිය යුතුය")
    .max(12, "උපරිම අකුරු 12ක් විය යුතුය")
    .nonempty({ message: "අවශ්යයි" }),
  FGPhoneNumber: z
    .string()
    .regex(/^\d+$/, "සංඛ්‍යා පමණක් තිබිය යුතුය")
    .min(10, "දුරකථන අංකය ඉලක්කම් 10ක් විය යුතුය")
    .max(10, "දුරකථන අංකය ඉලක්කම් 10ක් විය යුතුය")
    .nonempty("අවශ්යයි"),

  FGVerificationMethod: z.string().nonempty("අවශ්යයි"),
  FGuarantorImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  FGFrontImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  FGBackImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  FGElectricityBillOrWaterImageURL: z.any().refine((value) => value !== "", {
    message: "බිල්පතෙහි ඡායාරූපය අවශ්‍ය වේ",
  }),
  FGSignatureImageURL: z.any().refine((value) => value !== "", {
    message: "අත්සන අවශ්‍ය වේ",
  }),
});

// Validation schema for step 4
export const Step4OptionSchema = z.object({
  SGFullName: z
    .string()
    .min(3, "නමෙහි අවම අකුරු 3ක්වත් තිබිය යුතුය")
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .regex(
      /^[A-Za-z\u0D80-\u0DFF\s]+$/,
      "නමෙහි විශේෂ අකුරු සහ සංඛ්‍යා නොවිය යුතුය"
    )
    .nonempty("අවශ්යයි"),

  SGAddress: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .nonempty({ message: "අවශ්යයි" }),

  SGNic: z
    .string()
    .min(10, "අවම අකුරු 10ක් තිබිය යුතුය")
    .max(12, "උපරිම අකුරු 12ක් විය යුතුය")
    .nonempty({ message: "අවශ්යයි" }),
  SGPhoneNumber: z
    .string()
    .min(10, "දුරකථන අංකය ඉලක්කම් 10ක් විය යුතුය")
    .max(10, "දුරකථන අංකය ඉලක්කම් 10ක් විය යුතුය")
    .regex(/^\d+$/, "සංඛ්‍යා පමණක් තිබිය යුතුය")
    .nonempty("අවශ්යයි"),

  SGVerificationMethod: z.string().nonempty("අවශ්යයි"),
  SGuarantorImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  SGFrontImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  SGBackImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  SGElectricityBillOrWaterImageURL: z.any().refine((value) => value !== "", {
    message: "බිල්පතෙහි ඡායාරූපය අවශ්‍ය වේ",
  }),
  SGSignatureImageURL: z.any().refine((value) => value !== "", {
    message: "අත්සන අවශ්‍ය වේ",
  }),
});

// Validation schema for step 5
export const Step5Schema = z.object({
  selectOptionalguarantorsProduct: z.string().nonempty("අවශ්යයි"),
  customerSignatureImageURL: z.any().refine((value) => value !== "", {
    message: "අත්සන අවශ්‍ය වේ",
  }),
});

// Validation schema for step 5
export const Step5OptionalSchema = z.object({
  collNetValue: z
    .string()
    .nonempty("අවශ්යයි")
    .regex(
      /^\d+(\.\d{1,2})?$/,
      "සංඛ්‍යා පමණක් තිබිය යුතුය (තැබිය හැකි උපරිමය දශම ස්ථාන ගණන 2කි)"
    )
    .refine((value) => parseFloat(value) > 500, {
      message: "ණය මුදලේ වටිනාකම 500 ට වඩා වැඩි විය යුතුය.",
    }),
  collateralName: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .nonempty("අවශ්යයි"),

  collDescription: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    // .optional(),
    .nonempty("අවශ්යයි"),

  collImgUrls: z
    .array(z.string().url("සෑම රූපයක්ම වලංගු URL එකක් විය යුතුය"))
    .min(1, "අවම වශයෙන් එක් පින්තූරයක් උඩුගත කළ යුතුය")
    .max(5, "උපරිම පින්තූර 5ක් උඩුගත කළ හැක"),
});
