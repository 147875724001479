import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Table,
  Typography,
} from "antd";
import { getAllSchedules } from "../../../api/schedule";
import { Dropdown } from "../../common";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";

interface Item {
  id: number;
  loanId?: number;
  dueDate?: string;
  dueAmount?: number;
  interest?: number;
  principleAmount?: number;
  paymentStatus?: string;
  customerNic?: string; // Add this field to the Item interface
}

interface ScheduleResponse {
  data: Item[];
  status: number;
  statusText: string;
  headers: Record<string, string>;
  config: Record<string, unknown>;
  request: XMLHttpRequest;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const C_DashboardTable = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<Item[]>([]);
  const [filteredData, setFilteredData] = useState<Item[]>([]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] =
    useState<string>("PENDING");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

  // Fetch data from API when the component mounts
  useEffect(() => {
    const getData = async () => {
      try {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        if (!token) {
          throw new Error("Authentication token not found");
        }

        const response = (await getAllSchedules(
          token,
          Number(userId)
        )) as ScheduleResponse;
        const data = response.data;

        if (Array.isArray(data)) {
          setDataSource(data);
        } else {
          console.error("Data is not in expected format:", data);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    getData();
  }, []);

  // Filter data based on selected payment status and selected date
  useEffect(() => {
    const filterData = () => {
      let filtered = dataSource;

      if (selectedPaymentStatus !== "All") {
        filtered = filtered.filter(
          (item) => item.paymentStatus === selectedPaymentStatus
        );
      } else {
        filtered = filtered.filter(
          (item) =>
            item.paymentStatus === "PENDING" || item.paymentStatus === "OVERDUE"
        );
      }

      if (selectedDate) {
        filtered = filtered.filter((item) =>
          dayjs(item.dueDate).isSame(selectedDate, "day")
        );
      }

      setFilteredData(filtered);
    };

    filterData();
  }, [dataSource, selectedPaymentStatus, selectedDate]);

  // handle navigate get paymnet  screen
  const handlenavigateAddPayment = (customerNIC: string) => {
    console.log("Customer NIC:", customerNIC); // This will print the customer NIC to the console.
    navigate("/add-payment", { state: { customerNIC } });
  };

  const columns = [
    {
      title: "Loan ID",
      dataIndex: "loanId",
      align: "center" as const,
      fixed: "left" as const,
      width: 90,
    },
    {
      title: "Due Date ",
      dataIndex: "dueDate",
      align: "center" as const,
    },
    {
      title: "Customer NIC",
      dataIndex: "customerNic",
      align: "center" as const,
    },
    {
      title: "Due Amount (Rs.)",
      dataIndex: "dueAmount",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Late Payment Interest (Rs.)",
      dataIndex: "interest",
      render: (amount: number) => `${amount.toFixed(2)}`,

      align: "center" as const,
    },
    {
      title: "Current installment Amount (Rs.)",
      dataIndex: "principleAmount",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      align: "center" as const,
      render: (text: string) => {
        let color = "";
        if (text === "PENDING") {
          color = "gray";
        } else if (text === "OVERDUE") {
          color = "red";
        }
        return <span style={{ color, fontWeight: "bold" }}>{text}</span>;
      },
    },
    {
      title: "Add Payment",
      dataIndex: "paymentId",
      render: (_: number, record: Item) => (
        <div className="flex justify-center">
          <Typography.Link>
            <div
              onClick={() => handlenavigateAddPayment(record.customerNic || "")}
              className="flex justify-center w-20 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
            >
              Add
            </div>
          </Typography.Link>
        </div>
      ),
      align: "center" as const,
    },
  ];

  // payment status
  const paymentStatus = ["All", "PENDING", "OVERDUE"];

  return (
    <div>
      <div className="justify-between gap-2 xxs:flex ">
        {/* Dropdown to filter by payment status */}
        <Dropdown
          label="Payment Status"
          fontSize="text-h9"
          fontColor={"text-gray-400"}
          options={paymentStatus}
          selectedOption={selectedPaymentStatus}
          onOptionSelect={(option: string) => setSelectedPaymentStatus(option)}
          placeholder="Payment Status"
          width="w-[100%] xxs:max-w-48 md:max-w-[250px] -mt-[2px]"
        />
        <div>
          {/* Date picker to filter by due date */}
          <DatePicker
            onChange={(date) => setSelectedDate(date)}
            format="YYYY-MM-DD"
            value={selectedDate}
            className="mb-5 w-[100%] md:w-[250px] xxs:mt-4 xxs:max-w-[250px] py-[6px] lg:py-[8px] bg-primaryColor-50 hover:bg-primaryColor-50 focus:outline-none focus-within:ring-0 hover:border-1 hover:border-primaryColor-100 focus-within:border-primaryColor-100 focus-within:bg-primaryColor-50"
            popupClassName=" z-10"
          />
        </div>
      </div>
      <Form form={form} component={false}>
        <Table
          scroll={{ x: 1000 }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
          )}
          columns={columns}
          rowClassName="editable-row"
          pagination={{
            current: currentPage,
            pageSize,
            total: filteredData.length,
            onChange: (page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            },
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "40"],
          }}
          locale={{
            emptyText: <Empty description={<span>No data available</span>} />,
          }}
          rowKey={(record) =>
            record.id
              ? record.id.toString()
              : `temp-${record.loanId}-${record.dueDate}`
          }
          className="font-semibold"
        />
      </Form>
    </div>
  );
};

export default C_DashboardTable;
