import React, { useEffect, useState } from "react";
import {
  A_CustomerOverviewPage,
  Loading,
  M_CustomerOverviewPage,
  NotFound,
} from "../components/page";
import { getCurrentUser } from "../api/auth";
import { useLocation, useParams } from "react-router-dom";

interface userDetailsProps {
  collectorAppUserId: number;
  managerAppUserId: {
    userId: number;
  };
  userId: {
    userId: number;
  };
}

const ToBeApproveCusOverview: React.FC = () => {
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [saveLoanId, setSaveLoanId] = useState<string>("");
  const { userId } = useParams<{ userId: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const collectorAppUserId = queryParams.get("CAU")
    ? Number(queryParams.get("CAU"))
    : 0;
  const managerAppUserId = queryParams.get("MAU")
    ? Number(queryParams.get("MAU"))
    : 0;

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token") || "";
      try {
        const userData = await getCurrentUser(token);
        setUserRole(userData.data.role);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUserRole("");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  const userDetails: userDetailsProps = {
    userId: {
      userId: userId ? Number(userId) : 0,
    },
    collectorAppUserId,
    managerAppUserId: {
      userId: managerAppUserId,
    },
  };

  switch (userRole) {
    case "ADMIN":
      return (
        <A_CustomerOverviewPage
          customerId={userDetails.userId.userId}
          saveLoanId={saveLoanId}
          setSaveLoanId={setSaveLoanId}
          managerAppUserId={userDetails.managerAppUserId.userId}
          userDetails={userDetails}
        />
      );
    case "MANAGER":
      return (
        <M_CustomerOverviewPage
          customerId={userDetails.userId.userId}
          saveLoanId={saveLoanId}
          managerAppUserId={userDetails.managerAppUserId.userId}
          userDetails={userDetails}
          setSaveLoanId={setSaveLoanId}
        />
      );
    case "COLLECTOR":
      return <NotFound />;
    default:
      return <NotFound />;
  }
};

export default ToBeApproveCusOverview;
