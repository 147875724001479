import { z } from "zod";

// Define the Zod schema for the loan data
export const LoanDetailsSchema = z.object({
  netMonthlySalary: z
    .string()
    .nonempty("අවශ්යයි")
    .regex(
      /^\d+(\.\d{1,2})?$/,
      "සංඛ්‍යා පමණක් තිබිය යුතුය (තැබිය හැකි උපරිමය දශම ස්ථාන ගණන 2කි)"
    )
    .refine((value) => parseFloat(value) > 1000, {
      message: "දළ මාසික ආදායම 1000 ට වඩා වැඩි විය යුතුය.",
    }),
  loanAmount: z
    .string()
    .nonempty("අවශ්යයි")

    .regex(
      /^\d+(\.\d{1,2})?$/,
      "සංඛ්‍යා පමණක් තිබිය යුතුය (තැබිය හැකි උපරිමය දශම ස්ථාන ගණන 2කි)"
    )
    .refine((value) => parseFloat(value) > 1000, {
      message: "ණය මුදලේ වටිනාකම 1000 ට වඩා වැඩි විය යුතුය.",
    }),

  repaymentFrequency: z
    .enum(["DAILY", "WEEKLY", "MONTHLY"])
    .refine((val) => ["DAILY", "WEEKLY", "MONTHLY"].includes(val), {
      message: "Invalid repayment frequency",
    }),
  numberOfInstallments: z.string().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
});
