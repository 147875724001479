import React from "react";
import { Button, Main, Title } from "../../common";
import { A_AllCustomersTable } from "../../Tables";
import { useNavigate } from "react-router-dom";

const A_CustomerListPage: React.FC = () => {
  const navigate = useNavigate();

  // handle navigate add customer screen
  const handleNavigateCustomeReg = () => {
    navigate("/customer-register");
  };
  return (
    <div>
      {/* main container  */}
      <Main>
        {/* title  */}
        <Title title={"All Customers"} bgStyle={false} />
        {/* all customers table  */}
        <A_AllCustomersTable />
        {/* add customer button  */}
        <div className="mt-3">
          <Button text={"Add Customer"} onClick={handleNavigateCustomeReg} />
        </div>
      </Main>
    </div>
  );
};

export default A_CustomerListPage;
