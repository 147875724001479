import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Button, Empty, Form, Modal, Table, Typography } from "antd";
import { Description, Dropdown, Input } from "../../common";
import { getAllPayments } from "../../../api/payment";
import { PaymentReciptPaymentList } from "../../modal";

interface Item {
  nic: string;
  id: number;
  paymentId: string;
  customerId: number;
  customerName: string;
  collectorName: string;
  paymentDate: string;
  dueAmount: number;
  paidAmount: number;
  balanceAmount: number;
  paymentType: string; // Add this line  nic: string;
  address: string;
  repaymentFrequency: string;
  remainingAmount: number;
  loanAmount: number;
  releaseDate: string;
  totalPaymentAmount: number;
  totalLatePaymentInterest: number;
  interestAmount: number;
}

// Define the type for receipt details
type ReceiptDetails = {
  customer: string;
  nic: string;
  paymentDate: string;
  paymentTime: string;
  loanAmount: number;
  paidAmount: number;
  remainingAmount: number;
  paymentType: string;
  repaymentFrequency: string;
  releaseDate: string;
  customerId: number;
  address: string;
  fullLoanAmount: number;
  lateInstallmentAmount: number;
  totlaIntrestAmount: number;
  // Add other necessary details here
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        ></Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const C_PaymentListTable = () => {
  const [form] = Form.useForm();
  const [customersData, setCustomersData] = useState<Item[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedPaymentStatus, setSelectedPaymentStatus] =
    useState<string>("All");
  const [receiptDetails, setReceiptDetails] = useState<ReceiptDetails>({
    customer: "",
    nic: "",
    paymentDate: "",
    paymentTime: "",
    loanAmount: 0,
    paidAmount: 0,
    remainingAmount: 0,
    paymentType: "",
    repaymentFrequency: "",
    releaseDate: "",
    customerId: 0,
    address: "",
    fullLoanAmount: 0,
    lateInstallmentAmount: 0,
    totlaIntrestAmount: 0,
  });
  const [selectedPayment, setSelectedPayment] = useState<Item | null>(null);
  const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);
  const [isSignatureModalVisible, setIsSignatureModalVisible] = useState(false);
  const [signatureImageURL, setSignatureImageURL] = useState<string | null>(
    null
  );

  // Function to handle opening the modal
  const handleOpenSignatureModal = (url: string) => {
    setSignatureImageURL(url);
    setIsSignatureModalVisible(true);
  };

  // Function to handle closing the modal
  const handleCloseSignatureModal = () => {
    setIsSignatureModalVisible(false);
    setSignatureImageURL(null); // Clear the image URL when modal closes
  };

  // handle close payment receipt modal
  const handleClosePaymentReceiptModal = () => {
    setIsReceiptModalVisible(false);
  };

  const columns = [
    {
      title: "Payment ID",
      dataIndex: "paymentId",
      fixed: "left" as const,
      align: "center" as const,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      align: "center" as const,
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      render: (text: string) => new Date(text).toLocaleDateString(),
      align: "center" as const,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      align: "center" as const,
      render: (text: string) => {
        let color = "";
        if (text === "EARLY") {
          color = "blue";
        } else if (text === "SCHEDULE") {
          color = "green";
        } else if (text === "LATE") {
          color = "red";
        }
        return <span style={{ color, fontWeight: "bold" }}>{text}</span>;
      },
    },
    {
      title: "Due Amount (Rs.)",
      dataIndex: "dueAmount",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Paid Amount (Rs.)",
      dataIndex: "paidAmount",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Balance Amount (Rs.)",
      dataIndex: "balanceAmount",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Customer Sign",
      dataIndex: "signatureImageURL",
      align: "center" as const,
      render: (signatureImageURL: string) => (
        <div className="flex justify-center font-semibold">
          {signatureImageURL ? (
            <Button
              type="link"
              onClick={() => handleOpenSignatureModal(signatureImageURL)}
            >
              View
            </Button>
          ) : (
            <span className="opacity-50">No Signature</span>
          )}
        </div>
      ),
    },
    {
      title: "View",
      dataIndex: "paymentId",
      render: (_paymentId: string, record: Item) => (
        <div className="flex justify-center">
          <Typography.Link
            onClick={() => {
              setSelectedPayment(record); // Set the selected payment details
              setIsReceiptModalVisible(true); // Open the modal

              // Set receipt details when a payment is selected
              setReceiptDetails({
                customer: record.customerName || "",
                nic: record.nic, // Populate as needed
                paymentDate:
                  new Date(record.paymentDate).toLocaleDateString() || "",
                paymentTime:
                  new Date(record.paymentDate).toLocaleTimeString() || "",
                loanAmount: record.loanAmount, // Populate as needed
                paidAmount: record.paidAmount != null ? record.paidAmount : 0,
                remainingAmount:
                  record.remainingAmount != null ? record.remainingAmount : 0,
                paymentType: record.paymentType || "",
                repaymentFrequency: record.repaymentFrequency, // Populate as needed
                releaseDate: record.releaseDate
                  ? new Date(record.releaseDate).toLocaleDateString()
                  : "", // Format date only
                customerId: record.customerId, // Populate as needed
                address: record.address,
                fullLoanAmount: record.totalPaymentAmount,
                lateInstallmentAmount: record.totalLatePaymentInterest,
                totlaIntrestAmount: record.interestAmount,
              });
            }}
          >
            <div className="flex justify-center w-20 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900">
              View
            </div>
          </Typography.Link>
        </div>
      ),
      align: "center" as const,
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "netValue" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  // Fetch all payments data
  useEffect(() => {
    const getPaymentsData = async () => {
      const token = localStorage.getItem("token") || "";
      const appUserId = Number(localStorage.getItem("userId")) || 0;

      try {
        const response = await getAllPayments(token, appUserId);

        // Sort the data by paymentId
        const sortedData = response.sort((a: Item, b: Item) => {
          const paymentIdA = parseInt(a.paymentId, 10);
          const paymentIdB = parseInt(b.paymentId, 10);
          return paymentIdA - paymentIdB;
        });

        setCustomersData(sortedData);
      } catch (error) {
        console.log(error);
      }
    };

    getPaymentsData();
  }, []);

  // Filter data based on search text and selected payment status
  const filteredData = customersData
    .filter((item) => {
      const searchValue = searchText.toLowerCase();
      const matchesSearchText =
        item.customerName.toLowerCase().includes(searchValue) ||
        item.collectorName.toLowerCase().includes(searchValue);
      const matchesPaymentStatus =
        selectedPaymentStatus === "All" ||
        item.paymentType === selectedPaymentStatus;

      return matchesSearchText && matchesPaymentStatus;
    })
    .reverse(); // Reverse the filtered data

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  // Calculate totals for filtered data
  const filteredTotalPaidAmount = filteredData.reduce(
    (sum, item) => sum + item.paidAmount,
    0
  );
  const filteredTotalBalanceAmount = filteredData.reduce(
    (sum, item) => sum + item.balanceAmount,
    0
  );

  const paymentStatus = ["All", "EARLY", "SCHEDULE", "LATE"];

  // Footer component for totals
  const footer = () => {
    const paidAmount = filteredTotalPaidAmount;
    const balanceAmount = filteredTotalBalanceAmount;

    return (
      <div className="space-y-1">
        {/* Paid Amount total */}
        <div className="xxs:flex w-[100%] max-w-96 justify-between">
          <div className="xxs:w-44">
            <Description content="Paid Amount Total " fontWeight="font-bold" />
          </div>
          <Description content={`Rs. ${paidAmount.toFixed(2)}`} />
        </div>
        {/* Balance Amount total */}
        <div className="xxs:flex w-[100%] max-w-96 justify-between">
          <div className="xxs:w-44">
            <Description
              content="Balance Amount Total "
              fontWeight="font-bold"
            />
          </div>
          <Description content={`Rs. ${balanceAmount.toFixed(2)}`} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="justify-between gap-2 xxs:flex">
        <Dropdown
          label="Payment Status"
          fontSize="text-h9"
          fontColor={"text-gray-400"}
          options={paymentStatus}
          selectedOption={selectedPaymentStatus}
          onOptionSelect={(option: string) => setSelectedPaymentStatus(option)}
          placeholder="Collateral Status"
          width="w-[100%] xxs:max-w-60"
        />
        <div className="xxs:mt-[18px]">
          <Input
            id={"filterPayments"}
            name={"filterPayments"}
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            width="w-[100%] xxs:max-w-60 -mt-[2px]"
          />
        </div>
      </div>

      <Form form={form} component={false}>
        <Table
          scroll={{ x: "max-content" }} // Ensure the table scrolls horizontally if needed
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData}
          columns={mergedColumns}
          rowClassName="editable-row"
          className="font-semibold"
          pagination={{ pageSize: 10 }}
          footer={footer}
          locale={{
            emptyText: <Empty description={<span>No data available</span>} />,
          }}
        />
      </Form>

      {/* Payment Receipt Modal */}
      {selectedPayment && (
        <PaymentReciptPaymentList
          visible={isReceiptModalVisible}
          onClose={handleClosePaymentReceiptModal}
          receiptDetails={receiptDetails}
          primaryBtnLabel="Download Receipt"
          primaryOnClick={() => {
            // Logic to download receipt as a PDF
            const element = document.createElement("a");
            const file = new Blob([JSON.stringify(receiptDetails, null, 2)], {
              type: "application/json",
            });
            element.href = URL.createObjectURL(file);
            element.download = "receipt.json"; // Change this to "receipt.pdf" if you generate a PDF
            document.body.appendChild(element);
            element.click();
          }}
        />
      )}

      <Modal
        visible={isSignatureModalVisible}
        onCancel={handleCloseSignatureModal}
        footer={null}
        width={300}
        centered
      >
        {signatureImageURL && (
          <img
            src={signatureImageURL}
            alt="Customer Signature"
            className="pt-5 mx-auto max-w-56"
          />
        )}
      </Modal>
    </div>
  );
};

export default C_PaymentListTable;
