import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import PrimaryTitle from "../text/PrimaryTitle";
import Dropdown from "../inputs/Dropdown";
import Button from "../buttons/Button";
import {
  assignCollectorUnassignCustomers,
  getCollectorsByManagerId,
} from "../../../api/employee";
import PrimaryModal from "./PrimaryModal";
import toast from "react-hot-toast";

interface Collector {
  collectorId: number;
  username: string;
}

const AssignCollectorModal: React.FC<{
  visible: boolean;
  onClose: () => void;
  closeIcon?: React.ReactNode;
  managerId: number;
  selectedCustomersId: React.Key[];
  onSuccess: () => void;
}> = ({
  visible,
  onClose,
  closeIcon,
  managerId,
  selectedCustomersId,
  onSuccess,
}) => {
  const [collectors, setCollectors] = useState<Collector[]>([]);
  const [selectedCollectorId, setSelectedCollectorId] = useState<number | null>(
    null
  );
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const fetchCollectors = async () => {
      if (!managerId) return;
      const token = localStorage.getItem("token") || "";
      try {
        const response = await getCollectorsByManagerId(managerId, token);
        if (Array.isArray(response)) {
          setCollectors(response);
        } else {
          console.error("Expected an array from API, but received:");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCollectors();
  }, [managerId]);

  const handleCollectorSelect = (option: string) => {
    const selectedCollector = collectors.find(
      (collector) => collector.username === option
    );
    if (selectedCollector) {
      setSelectedCollectorId(selectedCollector.collectorId);
      setErrorMessage(""); // Clear any previous error message
    }
  };

  const handleAssignNewCollector = async () => {
    if (!selectedCollectorId) {
      setErrorMessage("Please select a collector.");
      return;
    }

    const token = localStorage.getItem("token") || "";
    const assignCollectorRequestBody = {
      customerIds: selectedCustomersId,
      collectorId: selectedCollectorId,
    };

    try {
      const response = await assignCollectorUnassignCustomers(
        token,
        assignCollectorRequestBody
      );
      if (response.success) {
        toast.success(response.message);
        setSelectedCollectorId(null);
        onSuccess();
        setIsOpenConfirmModal(false);
        onClose();
      } else {
        toast.error(response.message);
        setIsOpenConfirmModal(false);
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenConfirmModal = () => {
    if (!selectedCollectorId) {
      setErrorMessage("Please select a collector.");
      return;
    }
    setIsOpenConfirmModal(true);
  };

  // handle modal close
  const handleModalClose = () => {
    setSelectedCollectorId(null);
    setErrorMessage("");
    onClose();
  };

  return (
    <>
      <Modal
        open={visible}
        onCancel={handleModalClose}
        centered
        footer={null}
        closeIcon={closeIcon}
        className="custom-modal"
      >
        <div className="space-y-4">
          <PrimaryTitle
            text={"Assign New Collector"}
            fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
            center
          />
          <Dropdown
            label="Select Collector"
            placeholder="Select Collector"
            options={collectors.map((collector) => collector.username)}
            selectedOption={
              collectors.find(
                (collector) => collector.collectorId === selectedCollectorId
              )?.username || ""
            }
            onOptionSelect={handleCollectorSelect}
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            width="w-[100%]"
            errorMessage={errorMessage}
          />
          <div className="flex justify-center">
            <Button text={"Assign"} onClick={handleOpenConfirmModal} />
          </div>
        </div>
      </Modal>

      <PrimaryModal
        visible={isOpenConfirmModal}
        mainTitle={"Assign New Collector "}
        content={"Are you sure you want to assign the new collector?"}
        primaryBtnLabel="Yes"
        secondaryBtnLabel="No"
        primaryOnClick={handleAssignNewCollector}
        secondaryOnClick={() => setIsOpenConfirmModal(false)}
        onClose={() => setIsOpenConfirmModal(false)}
      />
    </>
  );
};

export default AssignCollectorModal;
