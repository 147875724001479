import React from "react";
import { Main, Title } from "../../common";
import { C_ScheduleListTable } from "../../Tables";

const C_ScheduleListPage: React.FC = () => {
  return (
    <div>
      {/* <main title  */}
      <Main>
        <Title title={"Schedule List"} bgStyle={false} />
        {/* <main title  */}
        <C_ScheduleListTable />
      </Main>
    </div>
  );
};

export default C_ScheduleListPage;
