import { z } from "zod";

export const CashReleaseSchema = z.object({
  selectedCollector: z
    .string()
    .nonempty({ message: "Please select a collector." })
    .optional(),
  customerSignUrl: z
    .string()
    .nonempty({ message: "Customer Signature is required." })
    .optional(),
  employeeSignUrl: z
    .string()
    .nonempty({ message: "Employee Signature is required." })
    .optional(),
  password: z
    .string()
    .nonempty("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(16, "Password must be at most 16 characters long")
    .refine((value) => /[A-Z]/.test(value), {
      message: "Password must contain at least one uppercase letter",
    })
    .refine((value) => /[!@#$%^&*(),.?":{}|<>]/.test(value), {
      message: "Password must contain at least one symbol",
    }),
});
