import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Description,
  Input,
  RadioButton,
  SignatureModal,
  UploadImageInput,
} from "../../../common";
import {
  Step5OptionalSchema,
  Step5Schema,
} from "../../../../lib/validation/customer-register-val";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import CollateralUploadImageInput from "../../../common/inputs/CollateralUploadImageInput";
import { storage } from "../../../../lib/configs/firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import imageCompression from "browser-image-compression";
import { removeBackgroundFromImage } from "../../../../api/bgremover";

interface Step5Props {
  setSelect: (step: number) => void;
}

interface fromDataTypes {
  selectOptionalguarantorsProduct: string;
  collateralName: string;
  collNetValue: string;
  collImgUrls: string[];
  customerSignatureImageURL: string;
  collDescription: string;
}

// Helper to compress and upload an image from a URL to Firebase
const uploadImageToFirebase = async (
  imageUrl: string,
  imagePath: string,
  targetSizeFactor: number = 0.5
) => {
  try {
    // Fetch the image from the URL and convert it to a Blob
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const file = new File([blob], "image.jpg", {
      type: blob.type,
      lastModified: Date.now(),
    });

    // Compress the Blob using browser-image-compression
    const compressedBlob = await imageCompression(file, {
      maxSizeMB: (blob.size / 1024 / 1024) * targetSizeFactor,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    });
    const storageRef = ref(storage, imagePath);
    await uploadBytes(storageRef, compressedBlob);
    const downloadUrl = await getDownloadURL(storageRef);
    return downloadUrl;
  } catch (error) {
    console.error("Image upload failed:", error);
    throw error;
  }
};

// Function to upload signature to Firebase
const uploadSignatureToFirebaseImages = async (
  imageBlob: Blob,
  id: string
): Promise<string> => {
  try {
    // Create a reference to the file location in Firebase
    const storageRef = ref(storage, `userRegistration/${id}_signature.jpg`);

    // Upload the background-removed image as a Blob
    await uploadBytes(storageRef, imageBlob); // Upload the Blob directly
    const downloadUrl = await getDownloadURL(storageRef); // Get the download URL

    return downloadUrl; // Return the URL for the uploaded image
  } catch (error) {
    console.error("Error uploading signature:", error);
    throw error;
  }
};

const Step5: React.FC<Step5Props> = () => {
  const navigate = useNavigate();
  const [guaranteProduct] = useState<string | null>(null);
  const [isSignatureModalVisible, setIsSignatureModalVisible] = useState(false);
  const [isCustomerUploadMode, setIsCustomerUploadMode] = useState(false);
  const [loadingImages, setLoadingImages] = useState<Record<string, boolean>>({
    customerSignatureImageURL: false,
  });

  const [selectedOption, setSelectedOption] = useState<string>("PDno");
  const [isSetOpenGuarantorItemsection, setOpenGuarantorItemsection] =
    useState(false);
  const [formData, setFormData] = useState({
    selectOptionalguarantorsProduct: "PDno",
    collateralName: "",
    collNetValue: "",
    collImgUrls: [],
    customerSignatureImageURL: "",
    collDescription: "",
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [, setIsFirstValidation] = useState(false); // State to check if initial validation has been done
  const [isCollateralOnly, setIsCollateralOnly] = useState(
    localStorage.getItem("collateralOnly") === "true"
  );

  useEffect(() => {
    if (localStorage.getItem("collateralOnly") === "true") {
      setIsCollateralOnly(true);
    } else {
      setIsCollateralOnly(false);
    }
  }, [isCollateralOnly]);

  const inputRefs = useRef<
    Record<string, HTMLInputElement | HTMLDivElement | null>
  >({
    collateralName: null,
    collNetValue: null,
    collImgUrl: null,
    customerSignatureImageURL: null,
    collDescription: null,
  });

  // Handle radio button selection for guarantor product
  const handleguarantorsProductSelectChange = (value: string) => {
    setSelectedOption(value);
    setFormData({ ...formData, selectOptionalguarantorsProduct: value });
    setErrors({ ...errors, selectOptionalguarantorsProduct: "" });

    if (value === "PDyes") {
      setOpenGuarantorItemsection(true);
    } else {
      setOpenGuarantorItemsection(false);
      setFormData((prevFormData) => ({
        ...prevFormData,
        collNetValue: "",
        collateralName: "",
        collImgUrls: [],
        collDescription: "",
      }));
    }
  };

  // Validation schema for main and optional sections
  const validateStep5Schema = (formData: fromDataTypes) => {
    try {
      Step5Schema.parse(formData);
      return {};
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          newErrors[err.path[0]] = err.message;
        });
        return newErrors;
      }
      return {};
    }
  };

  const validateStep5OptionalSchema = (formData: fromDataTypes) => {
    try {
      Step5OptionalSchema.parse(formData);
      return {};
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          newErrors[err.path[0]] = err.message;
        });
        return newErrors;
      }
      return {};
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleNext = async () => {
    setIsFirstValidation(true);
    const formErrors = validateStep5Schema(formData);
    const optionalErrors = isSetOpenGuarantorItemsection
      ? validateStep5OptionalSchema(formData)
      : {};

    const combinedErrors = { ...formErrors, ...optionalErrors };
    if (Object.keys(combinedErrors).length === 0) {
      localStorage.setItem(
        "customerRegisterFormData5",
        JSON.stringify(formData)
      );
      navigate("/customer-register-overview");
    } else {
      setErrors(combinedErrors);
      scrollToFirstError(combinedErrors);
    }
  };

  const scrollToFirstError = (newErrors: Record<string, string>) => {
    const orderedErrors: Record<string, string> = {};

    Object.keys(formData).forEach((key) => {
      if (newErrors[key]) {
        orderedErrors[key] = newErrors[key];
      }
    });

    const firstErrorKey = Object.keys(orderedErrors)[0];

    if (firstErrorKey && inputRefs.current[firstErrorKey]) {
      const element = inputRefs.current[firstErrorKey];
      const elementOffsetTop =
        element!.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: elementOffsetTop - 80, // Offset by 80px from the top
        behavior: "smooth",
      });
    }
  };

  const handleImageChange = async (
    imagesUrl: string[] | null,
    id: keyof typeof formData
  ) => {
    if (imagesUrl && imagesUrl.length > 0) {
      const uploadPromises = imagesUrl.map(async (imageUrl, index) => {
        const imagePath = `collaterals/collateral_${index + 1}.jpg`;
        return uploadImageToFirebase(imageUrl, imagePath);
      });

      const uploadedImageUrls = await Promise.all(uploadPromises); // Upload all images
      setFormData((prevData) => ({
        ...prevData,
        [id]: uploadedImageUrls, // Save uploaded Firebase URLs
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: [], // Reset images if no files are provided
      }));
    }
  };

  // handle images change signatures
  const handleImageChangeSignatures = async (
    file: File | null,
    id: keyof typeof formData
  ) => {
    if (!file) {
      // If no file is selected, clear the corresponding field in formData
      setFormData((prevData) => ({
        ...prevData,
        [id]: "",
      }));
      return;
    }

    try {
      // Set loading state for the image
      setLoadingImages((prev) => ({ ...prev, [id]: true }));

      // Convert the file to a Blob
      const imageBlob = new Blob([file], { type: file.type });

      // Call the background removal function
      const processedBlob = await removeBackgroundFromImage(imageBlob);

      // Check if the processed blob is valid
      if (!processedBlob || processedBlob.size === 0) {
        throw new Error("Background removal failed: Received empty blob");
      }

      // Upload the background-removed image to Firebase
      const uploadedUrl = await uploadSignatureToFirebaseImages(
        processedBlob,
        id
      );

      // Update the formData with the uploaded image URL
      setFormData((prevData) => ({
        ...prevData,
        [id]: uploadedUrl,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    } catch (error) {
      console.error("Error handling image change:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "Failed to upload image",
      }));
    } finally {
      // Remove loading state
      setLoadingImages((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleSignatureModalOpen = () => {
    setIsSignatureModalVisible(true);
  };

  const handleFirstSignatureModalClose = () => {
    setIsSignatureModalVisible(false);
  };

  const handleGuaranteProductModalOk = async (url: string | null) => {
    const storedData1 = localStorage.getItem("customerRegisterFormData1");
    const parsedData1 = storedData1 ? JSON.parse(storedData1) : {};

    const combinedData = {
      ...parsedData1,
    };

    setIsSignatureModalVisible(false);
    if (url) {
      const signaturePath = `signatures/${combinedData.nic || `"unknown"`}_signature.jpg`;
      const uploadedSignatureUrl = await uploadImageToFirebase(
        url,
        signaturePath
      );
      setFormData({
        ...formData,
        customerSignatureImageURL: uploadedSignatureUrl,
      }); // Save Firebase URL
      setErrors({ ...errors, customerSignatureImageURL: "" });
    }
  };

  // Load data from localStorage and display it
  useEffect(() => {
    const storedData5 = localStorage.getItem("customerRegisterFormData5");
    const parsedData5 = storedData5 ? JSON.parse(storedData5) : {};

    const storedCustomerUploadMode = localStorage.getItem(
      "isCustomerUploadMode"
    );
    if (storedCustomerUploadMode !== null) {
      setIsCustomerUploadMode(JSON.parse(storedCustomerUploadMode));
    }

    if (parsedData5) {
      setFormData({
        selectOptionalguarantorsProduct:
          parsedData5.selectOptionalguarantorsProduct || "PDno",
        collateralName: parsedData5.collateralName || "",
        collNetValue: parsedData5.collNetValue || "",
        collImgUrls: parsedData5.collImgUrls || [],
        customerSignatureImageURL: parsedData5.customerSignatureImageURL || "",
        collDescription: parsedData5.collDescription || "",
      });
      setSelectedOption(parsedData5.selectOptionalguarantorsProduct || "PDno");

      if (isCollateralOnly) {
        setOpenGuarantorItemsection(true);
      } else {
        setOpenGuarantorItemsection(
          parsedData5.selectOptionalguarantorsProduct === "PDyes"
        );
      }
    }
  }, []);

  return (
    <div>
      {!isCollateralOnly && (
        <div>
          <Description
            content="ඇපයට භාණ්ඩයක්/ භාණ්ඩ තබන්නේද?*"
            fontWeight="font-bold"
          />

          <div className="flex gap-5 mt-3">
            <RadioButton
              id={"ProductOptionYes"}
              label={"ඔව්"}
              checked={selectedOption === "PDyes"}
              onChange={() => handleguarantorsProductSelectChange("PDyes")}
            />
            <RadioButton
              id={"ProductOptionNo"}
              label={"නැත"}
              checked={selectedOption === "PDno"}
              onChange={() => handleguarantorsProductSelectChange("PDno")}
            />
          </div>
        </div>
      )}

      {errors.selectOptionalguarantorsProduct && (
        <div className="font-normal text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[1px] ">
          {errors.selectOptionalguarantorsProduct}
        </div>
      )}

      {isSetOpenGuarantorItemsection && (
        <div>
          <div className="grid gap-3 mt-5 md:grid-cols-2">
            <Input
              ref={(el) => (inputRefs.current.collateralName = el)}
              id={"collateralName"}
              name={"collateralName"}
              placeholder={"භාණ්ඩය"}
              label="භාණ්ඩය*"
              labelWeight="font-bold"
              onChange={handleChange}
              value={formData.collateralName}
              errorMessage={errors.collateralName}
            />

            <Input
              ref={(el) => (inputRefs.current.collNetValue = el)}
              id={"collNetValue"}
              name={"collNetValue"}
              placeholder={"දළ වටිනාකම (රු)"}
              label="දළ වටිනාකම (රු)*"
              labelWeight="font-bold"
              onChange={handleChange}
              type="number"
              minValue="0"
              value={formData.collNetValue}
              errorMessage={errors.collNetValue}
            />
          </div>
          <div className="mt-3">
            <Input
              ref={(el) => (inputRefs.current.collDescription = el)}
              id={"collDescription"}
              name={"collDescription"}
              placeholder={"භාණ්ඩය පිළිබද විස්තරය "}
              label="භාණ්ඩය පිළිබද විස්තරය "
              labelWeight="font-bold"
              onChange={handleChange}
              value={formData.collDescription}
              errorMessage={errors.collDescription}
            />
          </div>

          <div className="mb-8">
            <Description
              content="ඇපකර භාණ්ඩයෙහි ඡායාරූප අමුණන්න*"
              fontWeight="font-bold"
            />
            <CollateralUploadImageInput
              ref={(el) => (inputRefs.current.collImgUrls = el)}
              id={"collImgUrls"}
              name={"collImgUrls"}
              text={""}
              placeholder={""}
              onFileSelect={(imagesUrl) =>
                handleImageChange(imagesUrl, "collImgUrls")
              }
              errorMessage={errors.collImgUrls}
              selectImages={
                formData.collImgUrls.length ? formData.collImgUrls : []
              } // Pass an array of selected images
            />
          </div>
        </div>
      )}

      <div className="mt-5">
        <Description
          content="මා විසින් ඉහත සඳහන් කළ තොරතුරු සියල්ල නිවැරදි බවත් මේ සමග මාගේ ජාතික
        හැඳුනුම්පතෙහි ඡායා පිටපතක් ඉදිරිපත් කරන බවට ආයතනික නීතිරීති වලට එකඟ
        වෙමින් ඉහත සඳහන් කළ ණය මුදල ඉල්ලා සිටින බවත් මෙයින් ප්‍රකාශ කර සිටිමි."
          fontWeight="font-bold"
        />

        <div className="mt-5">
          <Description
            content="අත්සන*"
            fontWeight="font-bold"
            fontSize="text-h7 lg:text-h6 3xl:text-h3"
          />

          <Checkbox
            onChange={(e) => {
              const isChecked = e.target.checked;
              setIsCustomerUploadMode(isChecked);
              localStorage.setItem(
                "isCustomerUploadMode",
                JSON.stringify(isChecked)
              );

              setFormData((prevData) => ({
                ...prevData,
                customerSignatureImageURL: "",
              }));
            }}
            checked={isCustomerUploadMode}
            label="Upload Image"
          />

          {isCustomerUploadMode ? (
            // Image upload input appears when the checkbox is checked
            <UploadImageInput
              ref={(el) => (inputRefs.current.customerSignatureImageURL = el)}
              id={"customerSignatureImageURL"}
              name={"customerSignatureImageURL"}
              text={""}
              placeholder={""}
              onFileSelect={(file) =>
                handleImageChangeSignatures(file, "customerSignatureImageURL")
              }
              selectImage={
                formData.customerSignatureImageURL
                  ? `${formData.customerSignatureImageURL}`
                  : ""
              }
              isLoading={loadingImages.customerSignatureImageURL}
            />
          ) : (
            // Signature input area appears when the checkbox is unchecked
            <div
              className="h-40 p-2 mt-2 bg-white border-2 rounded-lg cursor-pointer max-w-[100%] border-primaryColor-200"
              onClick={handleSignatureModalOpen}
              ref={(el) => (inputRefs.current.customerSignatureImageURL = el)}
            >
              {formData.customerSignatureImageURL ? (
                <img
                  src={formData.customerSignatureImageURL}
                  className="object-contain w-full h-full"
                />
              ) : (
                <div className="flex items-center justify-center h-full underline duration-300 text-primaryColor-200 hover:scale-105">
                  <Description
                    content="Sign here"
                    fontWeight="font-medium"
                    fontColor="text-primaryColor-200"
                    center
                  />
                </div>
              )}
            </div>
          )}

          {errors.customerSignatureImageURL && (
            <div className="font-normal text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[1px] ">
              {errors.customerSignatureImageURL}
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-center mt-4">
        <Button text="ඊළඟ පිටුවට යන්න" onClick={handleNext} />
      </div>

      <SignatureModal
        visible={isSignatureModalVisible}
        onClose={handleFirstSignatureModalClose}
        onOk={handleGuaranteProductModalOk}
        signurl={guaranteProduct ? guaranteProduct : ""}
      />
    </div>
  );
};

export default Step5;
