import axios from "axios";

// Function to remove the background using remove.bg API
export const removeBackgroundFromImage = async (
  imageBlob: Blob
): Promise<Blob> => {
  try {
    const formData = new FormData();
    formData.append("image_file", imageBlob);

    // Make the API call using axios
    const response = await axios.post(
      "https://api.remove.bg/v1.0/removebg",
      formData,
      {
        headers: {
          "X-Api-Key": "oqpfzzvZ4PDgrPm6pHW9iTXu",
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      }
    );

    // Return the background-removed image as a Blob
    return response.data;
  } catch (error) {
    console.error("Error removing background:", error);
    throw error;
  }
};
