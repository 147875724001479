import React, { useRef, useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa";

interface CollapsibleButtonProps {
  isCollapsed: boolean;
  isOpen: boolean;
  onClick: () => void;
  icon: React.ReactNode;
  text: string;
  children?: React.ReactNode;
  isActive: boolean;
}

const CollapsibleButton: React.FC<CollapsibleButtonProps> = ({
  isCollapsed,
  isOpen,
  onClick,
  icon,
  text,
  children,
  isActive,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [isOpen]);

  const activeStyles = {
    backgroundColor: isActive ? "#b3d1ca" : "",
    color: isActive ? "#042d24" : "#042d24",
    fontFamily: "'PT Poppins', Poppins",
  };

  return (
    <div>
      <div
        onClick={onClick}
        className={`flex items-center text-h8  justify-between px-2 py-3 mt-4 rounded-lg cursor-pointer transition-all duration-200 ${
          isCollapsed ? "justify-center" : "hover:bg-primaryColor-100"
        }`}
        style={activeStyles}
      >
        <div
          className={`flex items-center ${
            isCollapsed ? "" : ""
          } transition-colors duration-300`}
          style={{ color: activeStyles.color }}
        >
          {icon}
          <span
            className={`mx-3 font-semibold ${
              isCollapsed ? "hidden" : "block whitespace-nowrap"
            } transition-colors duration-300`}
            style={{
              fontFamily: "'PT Poppins', Poppins",
              color: activeStyles.color,
            }}
          >
            {text}
          </span>
        </div>
        {!isCollapsed && (
          <FaAngleDown
            className={`${isOpen ? "rotate-180 " : ""} duration-300`}
          />
        )}
      </div>
      <div
        ref={contentRef}
        style={{
          maxHeight: `${contentHeight}px`,
        }}
        className="overflow-hidden duration-500 ease-in-out transition-max-height text-primaryColor-900"
      >
        {children}
      </div>
    </div>
  );
};

export default CollapsibleButton;
