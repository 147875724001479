import React, { useState } from "react";
import { Description, Input, RadioButton } from "../../../../common";
import ImageChip from "../../../../common/image-chip/ImageChip";
import { Guarantor } from "../../../../../types";
import ImageOverviewModal from "../../../../common/modals/ImageOverviewModal";

interface secondGuranterTypes {
  fullName: Guarantor["fullName"];
  address: Guarantor["address"];
  nic: Guarantor["nic"];
  guarantorPhoneNumber: Guarantor["guarantorPhoneNumber"];
  verificationMethod: Guarantor["verificationMethod"];
  signatureImageURL: Guarantor["signatureImageURL"];
  gurantorImageURL: Guarantor["gurantorImageURL"];
  frontImageURL: Guarantor["frontImageURL"];
  backImageURL: Guarantor["backImageURL"];
  electricityBillOrWaterImageURL: Guarantor["electricityBillOrWaterImageURL"];
}

const SecondGuranter: React.FC<secondGuranterTypes> = ({
  fullName,
  address,
  nic,
  guarantorPhoneNumber,
  verificationMethod,
  signatureImageURL,
  gurantorImageURL,
  frontImageURL,
  backImageURL,
  electricityBillOrWaterImageURL,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageURL, setCurrentImageURL] = useState<string | null>(null);

  // handle open image modals
  const handleOpenImageOverviewModal = (imageURL: string) => {
    setCurrentImageURL(imageURL);
    setIsModalOpen(true);
  };

  return (
    <div>
      <div>
        {/* second guarantor  */}
        <div className="my-5">
          <Description
            content="දෙවන ඇපකරු"
            fontWeight="font-bold"
            fontSize="text-h7 lg:text-h6 3xl:text-h3"
          />
        </div>
        <div className="grid md:gap-3 md:grid-cols-2">
          {/*second guarantors Name  */}
          <Input
            id={"FirstGuarantorsName"}
            name={"guarantorsName"}
            placeholder={"නම"}
            label="නම*"
            value={fullName}
            disabled
          />

          {/*second guarantors Address  */}
          <Input
            id={"guarantorsAddress"}
            name={"guarantorsAddress"}
            placeholder={"ලිපිනය"}
            label="ලිපිනය*"
            value={address}
            disabled
          />
        </div>
        <div className="grid md:gap-3 md:grid-cols-2">
          {/*second guarantors Id  */}
          <Input
            id={"guarantorsId"}
            name={"guarantorsId"}
            placeholder={"ජාතික හැඳුනුම්පත් අංකය"}
            label="ජාතික හැඳුනුම්පත් අංකය*"
            value={nic}
            disabled
          />

          {/*second guarantors Tp Number */}
          <Input
            id={"guarantorsTpNumber"}
            name={"guarantorsTpNumber"}
            placeholder={"දුරකථන අංකය"}
            label="දුරකථන අංකය*"
            value={guarantorPhoneNumber}
            disabled
          />
        </div>

        {/* first guarantor image  */}
        <div>
          <Description
            content="දෙවන ඇපකරුගෙ ඡායාරූපය*"
            fontWeight="font-bold"
          />
          <ImageChip
            id={"FGuarantorImageURL"}
            imageName={"ඉදිරිපස"}
            onClick={() => handleOpenImageOverviewModal(gurantorImageURL)}
          />
        </div>

        {/*second guarantors select nic, driving license or passport  */}
        <div className="flex">
          <div className="mr-3">
            <Description
              content="අනන්‍යතාව සහතික කිරීම සඳහා පහත ඒවායින් එකක් තෝරාගන්න*"
              fontWeight="font-bold"
            />
          </div>
        </div>

        <div className="flex flex-wrap gap-5 mt-3 lg:gap-10">
          <RadioButton
            id={"firstGuarantorNic"}
            label={"ජාතික හැඳුනුම්පත"}
            checked={verificationMethod === "NIC"}
            disabled
          />
          <RadioButton
            id={"firstGuarantorDrivingLicense"}
            label={"රියදුරු බලපත්‍රය"}
            checked={verificationMethod === "DRIVER_LICENCE"}
            disabled
          />
          <RadioButton
            id={"firstGuarantorPassport"}
            label={"විදේශ ගමන් බලපත්‍රය"}
            checked={verificationMethod === "PASSPORT"}
            disabled
          />
        </div>

        {/*second guarantors Attach nic, driving license or passport photos  */}
        <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row ">
          <Description
            content="හැඳුනුම්තෙහි ඡායාරූප අමුණන්න"
            fontWeight="font-bold"
          />
          <div className="flex space-x-2">
            <ImageChip
              id={"firstGuarantorsIdFront"}
              imageName={"ඉදිරිපස"}
              onClick={() => handleOpenImageOverviewModal(frontImageURL)}
            />

            <ImageChip
              id={"firstGuarantorsIdBack"}
              imageName={"පසුපස"}
              onClick={() => handleOpenImageOverviewModal(backImageURL)}
            />
          </div>
        </div>
        {/*second guarantors Attach a copy of water bill or electricity bill */}
        <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row">
          <Description
            content="ජල බිල හෝ විදුලි බිලෙහි පිටපතක් අමුණන්න"
            fontWeight="font-bold"
          />

          <ImageChip
            id="firstGuarantorsBill"
            imageName={"බිල්පත"}
            onClick={() =>
              handleOpenImageOverviewModal(electricityBillOrWaterImageURL)
            }
          />
        </div>

        {/*second guarantors display signature */}
        <div className="h-40 p-2 mt-5 bg-white border-2 rounded-lg  max-w-[100%] border-primaryColor-200">
          <img
            src={signatureImageURL}
            className="object-contain w-full h-full"
          />
        </div>
      </div>

      {/* image overview modal  */}
      <ImageOverviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        imageUrl={currentImageURL || ""}
        disableUpload
      />
    </div>
  );
};

export default SecondGuranter;
