import React from "react";

interface QRButtonProps {
  text?: string;
  icon: React.ReactNode;
  onClick: () => void;
  hideTextBeforeMd?: boolean;
  bgColor?: string;
  disabled?: boolean;
}

const QRButton: React.FC<QRButtonProps> = ({
  text,
  icon,
  onClick,
  hideTextBeforeMd,
  bgColor = "bg-primaryColor-500",
  disabled,
}) => {
  return (
    <button disabled={disabled}>
      <div
        onClick={onClick}
        className={`${bgColor} ${disabled && "opacity-50"}  flex items-center justify-between px-[6px] transition-colors duration-300 rounded-lg cursor-pointer h-9 font-Poppins text-h8 lg:h-10 `}
      >
        <div className="flex items-center text-white">
          <span className="text-h4">{icon}</span>
          <span
            className={`${text && "mx-3"} font-poppins font-normal ${
              hideTextBeforeMd ? "md:block md:visible hidden" : "block"
            }`}
          >
            {text}
          </span>
        </div>
      </div>
    </button>
  );
};

export default QRButton;
