import React, { useEffect, useState } from "react";
import { Table } from "antd";
import type { TableColumnsType, TableProps } from "antd";
import {
  AssignCollectorModal,
  Button,
  Description,
  Dropdown,
} from "../../../common";
import "./assignemployee.css";
import {
  getActiveManagers,
  getCustomersByUnassignCollectors,
  getUnassignColectorByManagerId,
} from "../../../../api/employee";

type TableRowSelection<T extends object = object> =
  TableProps<T>["rowSelection"];

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

interface Item {
  customerName: string;
  customerNIC: string;
  customerId: number;
  customerContact: number;
  contactNumber: string;
  customerAddress: string;
  managerId: number;
  collectorId: number;
}

interface collectorItem {
  appUserId: number;
  collectorId: number;
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: number;
  username: string;
}

interface Manager {
  managerId: number;
  userId: number;
  username: string;
}

interface collector {
  collectorId: number;
  appUserId: number;
  username: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Name",
    dataIndex: "customerName",
    align: "center" as const,
  },
  {
    title: "Customer NIC",
    dataIndex: "customerNIC",
    align: "center" as const,
  },
  {
    title: "Contact Number",
    dataIndex: "customerContact",
    align: "center" as const,
  },
  {
    title: "Address",
    dataIndex: "address",
    align: "center" as const,
  },
];

const AssignCollector: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenManagerAssign, setIsOpenManagerAssign] = useState(false);
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [managers, setManagers] = useState<Manager[]>([]);
  const [selectedManager, setSelectedManager] = useState<Manager | null>(null);
  const [selectedManagerId, setSelectedManagerId] = useState(0);
  const [collector, setCollector] = useState<collector[]>([]);
  const [selectedCollector, setSelectedCollector] = useState<collector | null>(
    null
  );
  const [selectedCollectorId, setSelectedCollectorId] = useState<number | null>(
    null
  );

  // Handle open assign manager modal
  const handleOpenAssignManager = () => {
    setIsOpenManagerAssign(true);
  };
  const handleCloseAssignmanager = () => {
    setIsOpenManagerAssign(false);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            return index % 2 === 0;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            return index % 2 !== 0;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  // Get customers by unassigned collectors
  const handleGetCustomersByUnAssignCollectors = async () => {
    console.log("Fetching customers by unassigned collectors");
    const token = localStorage.getItem("token") || "";

    try {
      const response = await getCustomersByUnassignCollectors(token);

      const filteredData = response.data.filter(
        (item: Item) =>
          item.managerId === selectedManagerId &&
          (selectedCollectorId === null ||
            item.collectorId === selectedCollectorId)
      );

      const mappedData = filteredData.map((item: Item) => ({
        key: item.customerId,
        customerContact: item.customerContact,
        age: item.contactNumber,
        address: item.customerAddress,
        customerNIC: item.customerNIC,
        customerName: item.customerName,
      }));

      setTableData(mappedData);
    } catch (error) {
      console.log(error);
    }
  };

  // Get customers by unassigned collectors
  useEffect(() => {
    if (selectedManagerId !== 0) {
      handleGetCustomersByUnAssignCollectors();
    }
  }, [selectedManagerId, selectedCollectorId]); // Re-run the effect when selectedCollectorId changes

  // Fetch active managers
  useEffect(() => {
    const getActiveAllManagers = async () => {
      // Get token
      const token = localStorage.getItem("token") || "";

      try {
        const response = await getActiveManagers(token);
        setManagers(response);
      } catch (error) {
        console.log(error);
      }
    };

    getActiveAllManagers();
  }, []);

  // get unassign collectors by manager id
  useEffect(() => {
    const getUnassignCollector = async () => {
      const managerId = selectedManagerId;
      const token = localStorage.getItem("token") || "";

      try {
        const response = await getUnassignColectorByManagerId(managerId, token);

        // Map the filtered data to match the DataType structure
        const filteredData = response.map((item: collectorItem) => ({
          key: item.collectorId,
          appUserId: item.appUserId,
          customerContact: item.contactNumber,
          emailaddress: item.email,
          firstname: item.firstName,
          lastname: item.lastName,
          username: item.username,
        }));
        setCollector(filteredData);
      } catch (error) {
        console.log(error);
      }
    };

    if (selectedManagerId !== 0) {
      getUnassignCollector();
    }
  }, [selectedManagerId]);

  // Handle manager selection
  const handleManagerSelect = (option: string) => {
    const selected = managers.find((manager) => manager.username === option);
    if (selected) {
      setSelectedManager(selected);
      setSelectedManagerId(selected.userId); // This will trigger the useEffect to filter the customers
      setSelectedCollectorId(null); // Reset selected collector when a new manager is selected
      setSelectedCollector(null);
      setSelectedRowKeys([]);
    }
  };

  // Handle collector selection
  const handleCollectorSelect = (option: string) => {
    if (option === "All") {
      setSelectedCollector(null); // Clear the selected collector
      setSelectedCollectorId(null); // Reset collector filter
    } else {
      const selected = collector.find(
        (collector) => collector.username === option
      );
      if (selected) {
        setSelectedCollector(selected);
        setSelectedCollectorId(selected.appUserId); // Set the selected collectorId
      }
    }
  };

  // handle cancel assign manager
  const handleCancelCollector = () => {
    setSelectedRowKeys([]);
  };

  const handleUpdateTableData = () => {
    console.log("handleUpdateTableData called");
    if (selectedManagerId !== 0) {
      handleGetCustomersByUnAssignCollectors();
    }
  };

  return (
    <div>
      <div className="gap-5 xxs:flex">
        {/* Select manager */}
        <Dropdown
          label=" Manager"
          placeholder="Select Manager"
          options={managers.map((manager) => manager.username)}
          selectedOption={selectedManager?.username || ""}
          onOptionSelect={handleManagerSelect}
          fontSize="text-h9"
          fontColor={"text-gray-400"}
          width="w-[100%] xxs:max-w-60"
        />
        {/* Select Collector */}
        <Dropdown
          label=" Unassign Collector"
          placeholder="All"
          options={["All", ...collector.map((collector) => collector.username)]}
          selectedOption={selectedCollector?.username || ""}
          onOptionSelect={handleCollectorSelect}
          fontSize="text-h9"
          fontColor={"text-gray-400"}
          width="w-[100%] xxs:max-w-60"
          disabled={!selectedManager}
        />
      </div>

      {/* Display message or table */}
      {!selectedManager ? (
        <div>
          <Table
            className="font-semibold custom-row-selection"
            scroll={{ x: "max-content" }}
            columns={columns}
            dataSource={[]}
            locale={{
              emptyText: (
                <>
                  <div className="py-5">
                    <Description content={"Please select a manager"} center />
                  </div>
                </>
              ),
            }}
          />
        </div>
      ) : (
        <Table
          className="font-semibold custom-row-selection"
          scroll={{ x: "max-content" }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData} // Use the filtered table data
          locale={{
            emptyText: (
              <>
                <div className="py-5">
                  <Description content={"No customers found "} center />
                </div>
              </>
            ),
          }}
        />
      )}

      {/* Assign button */}
      <div className="flex justify-center gap-5 mt-5 sm:justify-end">
        <div className={`${selectedRowKeys.length === 0 ? "hidden" : ""}`}>
          <Button
            text={"Cancel "}
            variant="secondary"
            onClick={handleCancelCollector}
          />
        </div>
        <Button
          text={"Assign"}
          onClick={handleOpenAssignManager}
          disabled={!selectedManager || selectedRowKeys.length === 0}
        />
      </div>

      {/* Assign manager modal */}
      <AssignCollectorModal
        visible={isOpenManagerAssign}
        onClose={handleCloseAssignmanager}
        managerId={selectedManagerId}
        selectedCustomersId={selectedRowKeys}
        onSuccess={handleUpdateTableData} // This will update the table data immediately
      />
    </div>
  );
};

export default AssignCollector;
