import React from "react";

interface MainProps {
  children: React.ReactNode;
  margin?: boolean;
}

const Main: React.FC<MainProps> = ({ children, margin }) => {
  return (
    <div className="pb-5">
      <div
        className={`w-[95%] shadow-3xl mx-auto ${
          !margin && "mt-10"
        } bg-white px-6 md:px-12 py-4 md:py-8 rounded-xl`}
      >
        {children}
      </div>
    </div>
  );
};

export default Main;
