import axios from "axios";
import { apiOptionJson, BASE_URL } from "./base-url";

interface requestBodyProps {
  collectorAppUserIds: React.Key[];
  managerAppUserId: number;
}

interface assignCollectorRequestBodyProps {
  customerIds: React.Key[];
  collectorId: number;
}

interface updateEmployeeTypes {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  username: string;
  role: string;
  manager: string;
}

// get all employees
export const allEmployees = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/users`, {
      headers: {
        ...apiOptionJson.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// get all managers
export const getManagers = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v6/managers`, {
      headers: {
        ...apiOptionJson.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Assuming the API returns the array of managers directly in the `data` field
  } catch (error) {
    console.error("Error fetching managers:", error);
    throw error; // Re-throw the error after logging it
  }
};

// get all managers
export const getActiveManagers = async (token: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v6/managers/assignedManager`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Assuming the API returns the array of managers directly in the `data` field
  } catch (error) {
    console.error("Error fetching managers:", error);
    throw error; // Re-throw the error after logging it
  }
};

// get all collectors
export const getCollectors = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/collectors/all`, {
      headers: {
        ...apiOptionJson.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// get collectors by manager id
export const getCollectorsByManagerId = async (
  appUserId: number,
  token: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v6/managers/${appUserId}/collectors`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

// get user by user id
export const getUserByUserId = async (token: string, userId: number) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/users/${userId}`, {
      headers: {
        ...apiOptionJson.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// Update user details API function
export const updateUser = async (
  token: string,
  userData: updateEmployeeTypes
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/api/v1/users/${userData.userId}`,
      userData,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get collectors for manager analystics
export const getCollectorForAnalytics = async (
  token: string,
  userId: number
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v6/managers/${userId}/collectors`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get customers for collector analystics
export const getCustomersForAnalytics = async (
  token: string,
  userId: number
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v4/customers/user/${userId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get collectors unassign managers
export const getCollectorsunassignManager = async (token: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/users/collectors/unassigned-managers`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

// get customers by unassign collector
export const getCustomersByUnassignCollectors = async (token: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v4/customers/unassigned-customers`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get customer by unassign collector and managers
export const getCustomersByUnAssignMancoll = async (token: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/users/unassigned-collectors-managers-customers`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

// get unassign collectors by manager id
export const getUnassignColectorByManagerId = async (
  managerId: number,
  token: string
) => {
  try {
    const repsonse = await axios.get(
      `${BASE_URL}/api/v6/managers/${managerId}/unassigned-collectors`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return repsonse.data;
  } catch (error) {
    return error;
  }
};

// get unassign managers
export const getUnassignManagers = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v6/managers/unassigned`, {
      headers: {
        ...apiOptionJson.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// assign manager for unassigned collectors
export const assignManagerUnassignCollector = async (
  token: string,
  requestBody: requestBodyProps
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/users/update-supervisor`,
      requestBody,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// assign collector for unassign customers
export const assignCollectorUnassignCustomers = async (
  token: string,
  assignCollectorRequestBody: assignCollectorRequestBodyProps
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v4/customers/assignCollector`,
      assignCollectorRequestBody,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
