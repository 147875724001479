// StepButton.jsx
import React from "react";
import { IoIosArrowBack } from "react-icons/io";

interface stepButtonProps {
  step: number;
  label?: string;
  currentStep: number;
  onClick?: () => void;
  showBackButton?: boolean;
}

const StepButton: React.FC<stepButtonProps> = ({
  step,
  currentStep,
  onClick,
  label,
  showBackButton,
}) => {
  const isActive = currentStep >= step;
  return (
    <div className="relative z-0 flex flex-col items-center ">
      {showBackButton && (
        <div
          className={`absolute items-center hover:bg-primaryColor-200 rounded-full duration-300 mt-[9px] md:mt-[14px] lg:mt-[17px] xl:mt-[15px] text-h4 xl:text-h3 -left-6 md:-left-8 xl:-left-[180%] text-primaryColor-900 backButton cursor-pointer`}
          onClick={onClick}
        >
          <IoIosArrowBack />
        </div>
      )}
      <div
        className={`w-10 md:w-12 lg:w-14 h-10 md:h-12 lg:h-14 border-mainBackgrround border-4 text-h7 lg:text-h5 ${
          isActive ? "bg-primaryColor-500" : "bg-primaryColor-200"
        } font-semibold text-gray-50 rounded-full flex flex-col items-center justify-center`}
      >
        {step < 10 ? `${step}` : step}
      </div>
      <div className="absolute w-32 font-semibold text-center -bottom-5 max-sm:hidden">
        {label}
      </div>
    </div>
  );
};

export default StepButton;
