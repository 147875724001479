import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "antd";
import jsQR from "jsqr";
import { MdCameraswitch } from "react-icons/md";

interface ForgetPasswordProps {
  visible: boolean;
  onClose: () => void;
  onScanSuccess: (data: string | null) => void;
}

const QrScannerComponent: React.FC<ForgetPasswordProps> = ({
  visible,
  onClose,
  onScanSuccess,
}) => {
  const [isScanning, setIsScanning] = useState(true);
  const [isBackCamera, setIsBackCamera] = useState(true); // State to toggle between front and back camera
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const animationFrameRef = useRef<number | null>(null);

  useEffect(() => {
    if (visible && isScanning) {
      startCamera();
    } else {
      stopCamera();
    }

    return () => {
      stopCamera();
    };
  }, [visible, isScanning, isBackCamera]); // Reinitialize camera when `isBackCamera` changes

  // Function to start the camera stream
  const startCamera = async () => {
    try {
      // Choose the camera based on the state (front or back)
      const facingMode = isBackCamera ? "environment" : "user";

      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { ideal: facingMode } }, // Switch between front and back camera
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current?.play(); // Play the video once metadata is loaded
          requestAnimationFrame(scanFrame); // Start scanning frames after the video is ready
        };
      }
    } catch (err) {
      console.error("Error accessing camera: ", err);
    }
  };

  // Function to stop the camera stream
  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      stream.getTracks().forEach((track) => track.stop());
    }

    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
  };

  // Function to scan a frame from the video
  const scanFrame = () => {
    if (!canvasRef.current || !videoRef.current) return;

    const canvas = canvasRef.current;
    const video = videoRef.current;
    const ctx = canvas.getContext("2d");

    if (ctx && video.videoWidth > 0 && video.videoHeight > 0) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, imageData.width, imageData.height);

      if (code) {
        console.log("Scanned QR Code:", code.data);
        const nic = extractCustomerNIC(code.data);
        onScanSuccess(nic);
        setIsScanning(false); // Stop scanning after successful scan
        handleModalClose(); // Close the modal
      } else {
        animationFrameRef.current = requestAnimationFrame(scanFrame); // Continue scanning
      }
    } else {
      // Video dimensions are not ready, keep trying
      animationFrameRef.current = requestAnimationFrame(scanFrame);
    }
  };

  // Function to extract NIC from the scanned QR code data
  const extractCustomerNIC = (text: string) => {
    const match = text.match(/NIC\s*:\s*(\d+)/);
    return match ? match[1] : null;
  };

  // Function to close the modal and refresh the camera on success
  const handleModalClose = () => {
    setIsScanning(false); // Ensure scanning stops when modal is closed
    stopCamera();
    onClose();
  };

  // Function to reset scanning when modal opens again
  useEffect(() => {
    if (visible) {
      setIsScanning(true); // Reset scanning when the modal is opened
    }
  }, [visible]);

  // Toggle between front and back camera
  const handleCameraSwitch = () => {
    setIsBackCamera((prev) => !prev); // Toggle the state to switch camera
    stopCamera(); // Stop the current camera stream
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleModalClose}
      footer={null}
      closable={true}
      maskClosable={false}
      centered={true}
    >
      <div style={{ width: "100%", padding: 20 }}>
        <video ref={videoRef} style={{ width: "100%" }} />
        <canvas ref={canvasRef} style={{ display: "none" }} />
        <div className="flex justify-center">
          <Button onClick={handleCameraSwitch} style={{ marginTop: 10 }}>
            <MdCameraswitch
              className={`text-xl duration-300 ${isBackCamera ? "rotate-180" : "rotate-0"}`}
            />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default QrScannerComponent;
