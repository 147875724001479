import React from "react";
import { CiEdit } from "react-icons/ci";

const PlanSettingsCard: React.FC<{
  mainTitle: string;
  content?: string;
  keyValue?: "Days" | "Weeks" | "Months";
  primaryBtnLabel?: string;

  primaryOnClick: () => void;
}> = ({
  mainTitle,
  content,
  keyValue = "Days",

  primaryOnClick,
}) => {
  return (
    <div className=" overflow-hidden bg-white rounded-lg shadow-md w-full sm:w-[200px] lg:w-[250px]">
      <div className="text-center text-white bg-primaryColor-500 ">
        <h2 className="py-2 text-lg font-semibold">{mainTitle}</h2>
      </div>
      <div className="relative flex p-4 font-semibold text-center bg-gray-100">
        <div className="flex gap-3 ">
          <p className="text-lg text-black">{content}</p>
          <p className="text-lg text-black">{keyValue}</p>
        </div>

        <button
          onClick={primaryOnClick}
          className="absolute border-2 rounded cursor-pointer right-1 text-primaryColor-500 border-primaryColor-500"
        >
          <CiEdit className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default PlanSettingsCard;
