import axios from "axios";
import { apiOptionJson, BASE_URL } from "./base-url";

// get all shedules for calander
export const getAllshedulesForCalander = async (
  userId: number,
  token: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/schedules/user/${userId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
