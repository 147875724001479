import React, { useEffect, useState } from "react";
import {
  CollateralNewUpdateProps,
  CollateralUpdateProps,
} from "../../../../types";
import { Description, Input } from "../../../common";
import ImageChip from "../../../common/image-chip/ImageChip";
import { CiEdit } from "react-icons/ci";
import { CollateralDetailsSchema } from "../../../../lib/validation/collateralSchema";
import CollateralImageOverviewModal from "../../../common/modals/CollateralImageOverviewModal";

interface collateralDetailsTypes {
  collateralName: CollateralNewUpdateProps["collateralName"];
  collDescription: CollateralNewUpdateProps["collDescription"];
  collNetValue: CollateralNewUpdateProps["netValue"];
  collImgUrls: CollateralNewUpdateProps["collImgUrls"];
  selectOptionalguarantorsProduct: string;
}

const CollateralDetails: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageURL, setCurrentImageURL] = useState<string[]>([]);

  const [collateralDetails, setColleteralDetails] =
    useState<collateralDetailsTypes>();
  const [inputs, setInputs] = useState({
    collateralName: {
      value: "",
      disabled: true,
      error: "",
    },
    collDescription: {
      value: "",
      disabled: true,
      error: "",
    },
    collNetValue: {
      value: "",
      disabled: true,
      error: "",
    },
    collImgUrls: {
      value: [""],
      disabled: true,
      error: "",
    },
  });
  const [activeField, setActiveField] = useState<keyof typeof inputs | null>(
    null
  );
  const [previousValue, setPreviousValue] = useState<
    string | string[] | null
  >();

  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    const storedData1 = localStorage.getItem("customerRegisterFormData5");
    const collateralProduct = localStorage.getItem("customerRegisterFormData5");

    if (collateralProduct) {
      const parsedProduct = JSON.parse(collateralProduct);
      setIsRequired(parsedProduct.selectOptionalguarantorsProduct === "PDyes");
    }

    if (storedData1) {
      const parsedData1 = storedData1 ? JSON.parse(storedData1) : {};
      setColleteralDetails(parsedData1);
    }
  }, []);

  // Update inputs state when collateralDetails changes
  useEffect(() => {
    if (collateralDetails) {
      setInputs({
        collateralName: {
          value: collateralDetails.collateralName || "",
          disabled: true,
          error: "",
        },
        collDescription: {
          value: collateralDetails.collDescription || "",
          disabled: true,
          error: "",
        },
        collNetValue: {
          value: collateralDetails.collNetValue || "",
          disabled: true,
          error: "",
        },
        collImgUrls: {
          value: collateralDetails.collImgUrls || [],
          disabled: true,
          error: "",
        },
      });
    }
  }, [collateralDetails]);

  // Handle input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof typeof inputs
  ) => {
    const { value } = e.target;
    const schema = CollateralDetailsSchema(isRequired);
    const fieldSchema = schema.shape[field];
    const result = fieldSchema.safeParse(value);

    setInputs((prev) => ({
      ...prev,
      [field]: {
        value,
        error: result.success
          ? "" // No error
          : result.error.issues[0]?.message || "Invalid input",
      },
    }));
  };

  const handleEditClick = (field: keyof typeof inputs) => {
    if (activeField && activeField !== field) {
      handleSaveOrCancel(activeField, false);
    }

    // Set previousValue to the current value before editing
    setPreviousValue(inputs[field].value);
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: { ...prevInputs[field], disabled: false },
    }));
    setActiveField(field);
  };

  const handleSaveOrCancel = (field: keyof typeof inputs, save: boolean) => {
    if (save) {
      handleSave(field);
    } else {
      handleCancel(field);
    }
  };

  const handleSave = (field: keyof typeof inputs) => {
    const schema = CollateralDetailsSchema(isRequired);
    const fieldSchema = schema.shape[field];
    const validationResult = fieldSchema.safeParse(inputs[field].value);

    if (!validationResult.success) {
      // If validation fails, show the error
      setInputs((prevInputs) => ({
        ...prevInputs,
        [field]: {
          ...prevInputs[field],
          error: validationResult.error.issues[0]?.message,
        },
      }));
      return;
    }

    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: { ...prevInputs[field], disabled: true, error: "" },
    }));

    const existingData = localStorage.getItem("customerRegisterFormData5");
    let updatedData = existingData ? JSON.parse(existingData) : {};

    updatedData = {
      ...updatedData,
      collateralName: inputs.collateralName.value,
      collDescription: inputs.collDescription.value,
      collNetValue: inputs.collNetValue.value,
      collImgUrls: inputs.collImgUrls.value,
    };

    localStorage.setItem(
      "customerRegisterFormData5",
      JSON.stringify(updatedData)
    );

    setActiveField(null);
    setPreviousValue(null);
  };

  const handleCancel = (field: keyof typeof inputs) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: {
        ...prevInputs[field],
        value: previousValue || collateralDetails?.[field] || "",
        disabled: true,
        error: "",
      },
    }));

    setActiveField(null);
    setPreviousValue(null);
  };

  // Handle open image modals
  const handleOpenImageOverviewModal = (imageURL: string[]) => {
    setCurrentImageURL(imageURL);
    setIsModalOpen(true);
  };

  // Handle image file selection
  const handleImageFileSelect = (
    imagesUrl: string[] | null,
    field: keyof typeof inputs
  ) => {
    if (imagesUrl) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        collImgUrls: { ...prevInputs.collImgUrls, value: imagesUrl },
      }));
      const existingData = localStorage.getItem("customerRegisterFormData5");
      const updatedData = existingData ? JSON.parse(existingData) : {};
      updatedData.collImgUrls = imagesUrl;

      localStorage.setItem(
        "customerRegisterFormData5",
        JSON.stringify(updatedData)
      );
      setInputs((prevInputs) => ({
        ...prevInputs,
        [field]: {
          ...prevInputs[field],
          error: "",
        },
      }));

      const schema = CollateralDetailsSchema(isRequired);
      const fieldSchema = schema.shape[field];
      const validationResult = fieldSchema.safeParse(imagesUrl);
      if (!validationResult.success) {
        // If validation fails, show the error
        setInputs((prevInputs) => ({
          ...prevInputs,
          [field]: {
            ...prevInputs[field],
            error: validationResult.error.issues[0]?.message,
          },
        }));
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
      };
    }
  };

  return (
    <div className="mt-5">
      <div className="mt-5">
        <Description
          content="ඇප භාණ්ඩය පිලිබද විස්තර"
          fontWeight="font-bold"
          fontSize="text-h7 lg:text-h6 3xl:text-h3"
        />
      </div>
      <div className="grid gap-3 md:grid-cols-2">
        {/* collateral Name */}
        <Input
          id="collateralName"
          name="collateralName"
          placeholder={"භාණ්ඩය"}
          label="භාණ්ඩය*"
          disabled={inputs.collateralName.disabled}
          value={inputs.collateralName.value}
          onChange={(e) => handleInputChange(e, "collateralName")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("collateralName")}
            />
          }
          saveCancel={!inputs.collateralName.disabled}
          saveOnclick={() => handleSave("collateralName")}
          cancelOnclick={() => handleCancel("collateralName")}
          errorMessage={inputs.collateralName.error}
        />
        {/* net Value */}
        <Input
          id="netValue"
          name="netValue"
          type="number"
          placeholder={"දළ වටිනාකම (රු)"}
          label="දළ වටිනාකම (රු)*"
          disabled={inputs.collNetValue.disabled}
          value={inputs.collNetValue.value}
          onChange={(e) => handleInputChange(e, "collNetValue")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("collNetValue")}
            />
          }
          saveCancel={!inputs.collNetValue.disabled}
          saveOnclick={() => handleSave("collNetValue")}
          cancelOnclick={() => handleCancel("collNetValue")}
          errorMessage={inputs.collNetValue.error}
        />
      </div>
      {/* description */}
      <Input
        id="collDescription"
        name="collDescription"
        placeholder={"භාණ්ඩය පිළිබද විස්තරය"}
        label="භාණ්ඩය පිළිබද විස්තරය"
        disabled={inputs.collDescription.disabled}
        value={inputs.collDescription.value}
        onChange={(e) => handleInputChange(e, "collDescription")}
        backIcon={
          <CiEdit
            className="mt-5 cursor-pointer lg:mt-6"
            onClick={() => handleEditClick("collDescription")}
          />
        }
        saveCancel={!inputs.collDescription.disabled}
        saveOnclick={() => handleSave("collDescription")}
        cancelOnclick={() => handleCancel("collDescription")}
        errorMessage={inputs.collDescription.error}
      />
      {/* First guarantors Attach a copy of water bill or electricity bill */}
      <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row">
        <Description
          content="ඇපකර භාණ්ඩයෙහි ඡායාරූප අමුණන්න*"
          fontWeight="font-bold"
        />

        <ImageChip
          id="firstGuarantorsBill"
          imageName={"ඇපකර භාණ්ඩය"}
          onClick={() =>
            handleOpenImageOverviewModal(collateralDetails?.collImgUrls || [])
          }
        />
      </div>
      {inputs.collImgUrls.error && (
        <div className="font-normal text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[1px] ">
          {inputs.collImgUrls.error}
        </div>
      )}
      {/* Image overview modal */}
      <CollateralImageOverviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        imageUrl={inputs.collImgUrls.value ? inputs.collImgUrls.value : []}
        onFileSelect={(imagesUrl) =>
          handleImageFileSelect(imagesUrl, "collImgUrls")
        }
      />
    </div>
  );
};

export default CollateralDetails;
