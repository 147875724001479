import React, { useEffect, useState } from "react";

interface RadioButtonProps {
  id: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange?: () => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  // RadioButton.tsx
  id,
  label,
  checked,
  onChange,
  disabled,
}) => {
  // Inline styles for the inner circle
  const innerCircleStyles = {
    content: '""',
    opacity: checked ? 1 : 0,
    transition: "opacity 0.2s",
  };

  const [isSelectRadioButton, setIsSelectRadioButton] = useState<boolean>();

  const selectRadioButton = () => {
    if (onChange) {
      onChange(); // Only call onChange if it is defined
    }
  };

  useEffect(() => {
    setIsSelectRadioButton(checked);
  }, [checked]);

  return (
    <div
      className="flex flex-row items-center "
      onClick={disabled ? undefined : selectRadioButton}
    >
      <input
        id={id}
        type="radio"
        checked={isSelectRadioButton}
        onChange={disabled ? undefined : selectRadioButton}
        className="hidden"
        disabled={disabled}
      />
      <div className="inline-flex items-center justify-center">
        <div
          className={`relative flex items-center justify-center w-4 h-4 border rounded-full ${
            disabled ? "cursor-default" : "cursor-pointer"
          }  border-primaryColor-200`}
        >
          <div
            style={innerCircleStyles}
            className="flex h-2.5 w-2.5 bg-primaryColor-200 rounded-full absolute"
          ></div>
        </div>
        <label
          htmlFor={id}
          className={`ml-2 text-h8 md:text-h7 font-semibold text-primaryColor-900 ${
            disabled ? "cursor-default" : "cursor-pointer"
          }`}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

export default RadioButton;
