import React, { useEffect, useState } from "react";
import C_CustomerUpdateOverviewPage from "../customer-update-overview/C_CustomerUpdateOverviewPage";
import { Button, Main, PrimaryModal } from "../../common";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import {
  approvedLoanByAdminId,
  approvedLoanWithAssignManager,
  rejectLoan,
} from "../../../api/loan";
import RejectLoanModal from "../../common/modals/RejectLoanModal";
import { useNavigate } from "react-router-dom";
import AssignResponsModal from "../../common/modals/AssignResponsModal";
import { getCustomerforLoanReview } from "../../../api/customer";
import toast from "react-hot-toast";

interface A_CustomerOverviewPageProps {
  customerId?: number;
  saveLoanId: string;
  setSaveLoanId: React.Dispatch<React.SetStateAction<string>>;
  managerAppUserId: number;
  userDetails: {
    collectorAppUserId: number;
    managerAppUserId: { userId: number };
  };
}

const A_CustomerOverviewPage: React.FC<A_CustomerOverviewPageProps> = ({
  customerId,
  saveLoanId,
  setSaveLoanId,
  userDetails,
}) => {
  const navigate = useNavigate();
  const [_loanId, setLoanId] = useState<number | null>(null);
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [isAddResponseModalVisible, setIsAddResponseModalVisible] =
    useState(false);

  useEffect(() => {
    const fetchCustomerData = async () => {
      const token = localStorage.getItem("token") || "";
      if (customerId) {
        try {
          const response = await getCustomerforLoanReview(customerId, token);
          if (response.success && response.data?.loan.length > 0) {
            const customerLoan = response.data.loan[0];
            setLoanId(customerLoan.loanId);
            setSaveLoanId(customerLoan.loanId.toString());
          } else {
            toast.error("Loan data could not be retrieved.");
          }
        } catch (error) {
          toast.error("Failed to fetch customer data.");
          console.error(error);
        }
      }
    };
    fetchCustomerData();
  }, [customerId, saveLoanId]);

  const handleCloseApproveModal = () => setIsApproveModalVisible(false);
  const handleShowSuccessModal = () => setIsApproveModalVisible(true);
  const handleOpenRejectModalModal = () => setIsRejectModalVisible(true);
  const handleCloseRejectModalModal = () => setIsRejectModalVisible(false);
  const handleCloseResponseModalModal = () =>
    setIsAddResponseModalVisible(false);
  const handleShowResponseModalModal = () => {
    setIsAddResponseModalVisible(true);
    setIsApproveModalVisible(false);
  };

  const handleApproveLoan = async (
    managerUserId: number,
    selectedCollectorId: number
  ) => {
    const usercollectorId = userDetails.collectorAppUserId;
    if (usercollectorId === 0) {
      try {
        const token = localStorage.getItem("token") || "";
        const loanId = parseInt(saveLoanId);
        const userId = Number(localStorage.getItem("userId")) || 0;

        let response;

        if (selectedCollectorId > 0) {
          const ApproveLoanBodywithCollector = {
            managerId: managerUserId,
            collectorId: selectedCollectorId,
          };

          response = await approvedLoanWithAssignManager(
            loanId,
            ApproveLoanBodywithCollector,
            token,
            userId
          );
        } else {
          const ApproveLoanBodywithManager = {
            managerId: managerUserId,
          };
          response = await approvedLoanWithAssignManager(
            loanId,
            ApproveLoanBodywithManager,
            token,
            userId
          );
        }

        if (response.success) {
          toast.success(response.message);
          navigate("/dashboard");
        } else {
          toast.error(response.response.data.message);
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    } else {
      ApproveLoan();
    }
  };

  const ApproveLoan = async () => {
    try {
      const token = localStorage.getItem("token") || "";
      const loanId = parseInt(saveLoanId);
      const userId = Number(localStorage.getItem("userId")) || 0;

      const response = await approvedLoanByAdminId(loanId, token, userId);

      if (response.success) {
        toast.success("Successfully approved");
        navigate("/dashboard");
      } else {
        toast.error(response.response.data.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("An error occurred while approving the loan.");
    }
  };

  const handleRejectLoan = async (content: string) => {
    const token = localStorage.getItem("token") || "";
    const loanId = parseInt(saveLoanId);
    const userId = Number(localStorage.getItem("userId")) || 0;

    try {
      const response = await rejectLoan(
        loanId,
        { reason: content, userId: userId },
        token
      );
      if (response.success) {
        toast.success("Successfully rejected the loan");
        navigate("/dashboard");
      } else {
        toast.error(response.response.data.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("An error occurred while rejecting the loan.");
    }
  };

  return (
    <div className="w-xl">
      <Main>
        <C_CustomerUpdateOverviewPage customerId={customerId || 0} />

        <div className="flex flex-row items-center justify-center gap-5 mt-5 md:gap-14">
          <Button
            text="Approve"
            onClick={handleShowSuccessModal}
            width="w-28 md:w-40"
          />
          <Button
            text="Reject"
            onClick={handleOpenRejectModalModal}
            variant="secondary"
            width="w-28 md:w-40"
          />
        </div>
      </Main>
      <PrimaryModal
        icon={
          <IoMdCheckmarkCircleOutline className="mx-auto text-center text-sky-700" />
        }
        visible={isApproveModalVisible}
        onClose={handleCloseApproveModal}
        mainTitle="Do you want to approve this loan?"
        primaryBtnLabel="Yes"
        primaryOnClick={handleShowResponseModalModal}
        secondaryBtnLabel="No"
        secondaryOnClick={handleCloseApproveModal}
        content={""}
      />
      <AssignResponsModal
        icon={
          <IoMdCheckmarkCircleOutline className="mx-auto text-center text-sky-700" />
        }
        visible={isAddResponseModalVisible}
        onClose={handleCloseResponseModalModal}
        mainTitle="Add collector/Manager"
        primaryBtnLabel="Approve"
        collectorId={userDetails.collectorAppUserId || 0}
        managerId={userDetails.managerAppUserId?.userId || 0}
        primaryOnClick={handleApproveLoan}
        secondaryBtnLabel="Go Back"
        secondaryOnClick={handleCloseResponseModalModal}
        content={""}
      />
      <RejectLoanModal
        icon={
          <IoMdCheckmarkCircleOutline className="mx-auto text-center text-sky-700" />
        }
        visible={isRejectModalVisible}
        onClose={handleCloseRejectModalModal}
        mainTitle="Loan Rejection"
        primaryBtnLabel="Reject"
        primaryOnClick={handleRejectLoan}
        secondaryBtnLabel="Back"
        secondaryOnClick={handleCloseRejectModalModal}
        content={""}
      />
    </div>
  );
};

export default A_CustomerOverviewPage;
