import React, { forwardRef, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { IoMdCloseCircle } from "react-icons/io";

interface UploadImageInputProps {
  id: string;
  name: string;
  text: string;
  image?: string;
  type?: string;
  errorMessage?: string;
  placeholder: string;
  onFileSelect?: (imagesUrl: string[] | null) => void;
  selectImages?: string[] | null;
}

const CollateralUploadImageInput = forwardRef<
  HTMLInputElement,
  UploadImageInputProps
>(
  (
    {
      name,
      text,
      image = "/images/imageUpload.png",
      errorMessage,
      onFileSelect,
      selectImages,
    },
    ref
  ) => {
    const [previewImages, setPreviewImages] = useState<string[]>([]);

    // Use ref to access the file input element
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (selectImages) {
        setPreviewImages(selectImages);
      }
    }, [selectImages]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(e.target.files || []);
      if (!files.length) return;

      // Check if file type is not png or jpg
      const invalidFiles = files.some(
        (file) =>
          !file.type.includes("image/png") && !file.type.includes("image/jpeg")
      );
      if (invalidFiles) {
        // Display toast and return
        toast.error("Please upload only PNG or JPEG files.");
        return;
      }

      if (previewImages.length + files.length > 5) {
        toast.error("You can upload up to 5 images.");
        return;
      }

      const newPreviews: string[] = [];

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result as string;
          newPreviews.push(base64String);

          // Once all files have been read
          if (newPreviews.length === files.length) {
            const updatedPreviews = [...previewImages, ...newPreviews];
            setPreviewImages(updatedPreviews);

            // Pass all preview images to onFileSelect
            if (onFileSelect) {
              onFileSelect(updatedPreviews);
            }
          }
        };
        reader.readAsDataURL(file);
      });
    };

    const handleFileInputChange = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

    const handleRemoveFile = (index: number) => {
      const newPreviewImages = previewImages.filter((_, i) => i !== index);

      setPreviewImages(newPreviewImages);
      if (onFileSelect) {
        onFileSelect(newPreviewImages);
      }
    };

    return (
      <div>
        <div
          className="border-2  mt-4 w-[100%] h-[150px] relative  px-5 flex text-center items-center justify-center cursor-pointer border-[#8EBBB1] rounded-2xl flex-col"
          onClick={handleFileInputChange}
          ref={ref}
        >
          <input
            type="file"
            id="fileInput"
            name={name}
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
            multiple // Allow multiple file selection
          />

          {previewImages.length > 0 ? (
            <div className="flex w-full my-auto overflow-x-auto scrollbar-hide flex-nowrap md:justify-center">
              {previewImages.map((imgSrc, index) => (
                <div
                  key={index}
                  className="relative flex items-center justify-center"
                >
                  <img
                    src={imgSrc}
                    className="min-w-[120px] max-w-[130px] h-[130px] xl:min-w-[140px] xl:max-w-[150px] xl:h-[140px] mr-2"
                    alt="Uploaded"
                  />
                  <button
                    className="absolute -top-[1px] right-[5px] flex items-center justify-center text-[#0A6C55]"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveFile(index);
                    }}
                  >
                    <IoMdCloseCircle className="w-5 h-5" />
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className="flex items-center justify-center">
                <img
                  src={image}
                  className="w-[70px] h-[70px] md:w-[90px] md:h-[90px] xl:w-[110px] xl:h-[110px]"
                  alt="Upload"
                />
              </div>
              <span className="text-h7 font-semibold text-[#042D24]">
                {text}
              </span>
            </>
          )}
        </div>
        {/* Error message */}
        {errorMessage && (
          <div className=" font-normal z-50 text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[1px]">
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

export default CollateralUploadImageInput;
