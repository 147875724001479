import React, { useEffect, useState } from "react";
import {
  A_CollateralDetails,
  A_CustomerDetails,
  A_FirstGuranter,
  A_LoanDetails,
  A_SecondGuranter,
  A_SignSection,
} from "./sections";
import {
  getCustomerforLoanReview,
  updateCustomer,
} from "../../../api/customer";
import Loading from "../loading/Loading";
import {
  CollateralUpdateProps,
  CustomerData,
  CustomerUpdateTypes,
  guaranterUpdateTypes,
} from "../../../types";
import { Button, Main, Title } from "../../common";
import { M_LastpaymentsSchedulesTable } from "../../Tables";
import toast from "react-hot-toast";
import { updateGuaranterDetails } from "../../../api/guarantee";
import { updateCollateralDetails } from "../../../api/collateral";

const A_CustomerUpdateOverviewPage: React.FC<{ customerId: number }> = ({
  customerId,
}) => {
  const [customerData, setCustomerData] = useState<CustomerData | null>(null);
  const [updatedValues, setUpdatedValues] = useState<
    Partial<CustomerUpdateTypes>
  >({});
  const [guaranterUpdatedValues, setGuaranterUpdatedValues] = useState<
    Partial<guaranterUpdateTypes>
  >({});
  const [secondGuaranterUpdatedValues, setSecondGuaranterUpdatedValues] =
    useState<Partial<guaranterUpdateTypes>>({});
  const [collateralUpdatedValues, setCollateralUpdatedValues] = useState<
    Partial<CollateralUpdateProps>
  >({});

  // get customer details by customer id
  const getCustomerDetailsById = async () => {
    const token = localStorage.getItem("token") || "";
    try {
      const response = await getCustomerforLoanReview(customerId, token);
      setCustomerData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomerDetailsById();
  }, [customerId]);

  // handle update customer data
  const handleUpdateCustomerData = async () => {
    const token = localStorage.getItem("token") || "";
    const updatedData = {
      customerId: customerData?.customerId || 0,
      fullName: updatedValues.fullName || "",
      email: updatedValues.email || "",
      whatsAppNumber: updatedValues.whatsAppNumber || "",
      phoneNumber: updatedValues.phoneNumber || "",
      optionalContactNumber: updatedValues.optionalContactNumber || "",
      address: updatedValues.address || "",
      nic: updatedValues.nic || "",
      policeStation: updatedValues.policeStation || "",
      gramaDivision: updatedValues.gramaDivision || "",
      provincialOffice: updatedValues.provincialOffice || "",
      verificationMethod:
        updatedValues.verificationMethod ||
        customerData?.verificationMethod ||
        "",
      frontImageURL:
        updatedValues.frontImageURL || customerData?.frontImageURL || "",
      backImageURL:
        updatedValues.backImageURL || customerData?.backImageURL || "",
      customerImageURL:
        updatedValues.customerImageURL || customerData?.customerImageURL || "",
      electricityBillImageURL:
        updatedValues.electricityBillImageURL ||
        customerData?.electricityBillImageURL ||
        "",
      companyName: updatedValues.companyName || "",
      occupation: updatedValues.occupation || "",
      companyAddress: updatedValues.companyAddress || "",
      netMonthlySalary: updatedValues.netMonthlySalary || "",
      signatureImageURL: customerData?.signatureImageURL || "",
    };

    // update customer details
    try {
      const response = await updateCustomer(token, customerId, updatedData);

      if (response.success) {
        console.log(response.message);
        toast.success(response.message);
      } else {
        console.log(response.message);
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }

    // update guaranter details
    const guaranterId = reversedGuarantors[0].guarantorId;
    const guaranterUpdatedData = {
      guarantorId: 0,
      customerId: customerData?.customerId || 0,
      fullName: guaranterUpdatedValues.fullName || "",
      address: guaranterUpdatedValues.address || "",
      nic: guaranterUpdatedValues.nic || "",
      guarantorPhoneNumber: guaranterUpdatedValues.guarantorPhoneNumber || "",
      verificationMethod:
        guaranterUpdatedValues.verificationMethod ||
        customerData?.guarantors[0].verificationMethod ||
        "",
      gurantorImageURL: guaranterUpdatedValues.gurantorImageURL || "",
      frontImageURL: guaranterUpdatedValues.frontImageURL || "",
      backImageURL: guaranterUpdatedValues.backImageURL || "",
      customerImageURL: customerData?.customerImageURL || "",
      electricityBillOrWaterImageURL:
        guaranterUpdatedValues.electricityBillOrWaterImageURL || "",
      signatureImageURL: customerData?.guarantors[0].signatureImageURL || "",
    };

    try {
      const response = await updateGuaranterDetails(
        token,
        guaranterId,
        guaranterUpdatedData
      );
      if (response.success) {
        console.log(response.message);
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }

    // update guaranter details
    if (reversedGuarantors.length === 2) {
      const secondGuaranterId = reversedGuarantors[1].guarantorId;
      const secondGuaranterUpdatedData = {
        guarantorId: 0,
        customerId: customerData?.customerId || 1,
        fullName: secondGuaranterUpdatedValues.fullName || "",
        address: secondGuaranterUpdatedValues.address || "",
        nic: secondGuaranterUpdatedValues.nic || "",
        guarantorPhoneNumber:
          secondGuaranterUpdatedValues.guarantorPhoneNumber || "",
        verificationMethod:
          secondGuaranterUpdatedValues.verificationMethod ||
          customerData?.guarantors[0].verificationMethod ||
          "",
        gurantorImageURL: secondGuaranterUpdatedValues.gurantorImageURL || "",
        frontImageURL: secondGuaranterUpdatedValues.frontImageURL || "",
        backImageURL: secondGuaranterUpdatedValues.backImageURL || "",
        customerImageURL: customerData?.customerImageURL || "",
        electricityBillOrWaterImageURL:
          secondGuaranterUpdatedValues.electricityBillOrWaterImageURL || "",
        signatureImageURL: customerData?.guarantors[1].signatureImageURL || "",
      };

      try {
        const response = await updateGuaranterDetails(
          token,
          secondGuaranterId,
          secondGuaranterUpdatedData
        );

        if (response.success) {
          console.log(response.message);
        } else {
          console.log(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    }

    // Check if there are collaterals before accessing them
    if (customerData?.collaterals && customerData.collaterals.length > 0) {
      const collateralId = customerData.collaterals[0]?.collateralId || 0;
      const collateralDetailsBody = {
        customerId: customerData.customerId || 0,
        collateralName: collateralUpdatedValues.collateralName || "",
        collDescription: collateralUpdatedValues.collDescription || "",
        netValue: Number(collateralUpdatedValues.netValue) || 0,
        collImgUrl: collateralUpdatedValues.collImgUrl || "",
      };

      try {
        const response = await updateCollateralDetails(
          token,
          collateralId,
          collateralDetailsBody
        );
        if (response.success) {
          console.log(response.message);
        } else {
          console.log(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("No collateral data available for update.");
    }
  };

  if (!customerData) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  // Reverse the guarantors list
  const reversedGuarantors = [...customerData.guarantors].reverse();

  return (
    <div>
      {/* main title  */}
      <Title title={"ණය ගැනුම්කරු පිලිබඳ විස්තර"} />

      {/* main container  */}
      <Main>
        {/* customer details  */}
        <A_CustomerDetails
          loanStatus={customerData.loan[0].loanStatus}
          reason={customerData.loan[0].reason}
          fullName={customerData.fullName}
          address={customerData.address}
          nic={customerData.nic}
          policeStation={customerData.policeStation}
          gramaDivision={customerData.gramaDivision}
          provincialOffice={customerData.provincialOffice}
          verificationMethod={customerData.verificationMethod}
          frontImageURL={customerData.frontImageURL}
          backImageURL={customerData.backImageURL}
          customerImageURL={customerData.customerImageURL}
          electricityBillImageURL={customerData.electricityBillImageURL}
          companyName={customerData.companyName}
          occupation={customerData.occupation}
          companyAddress={customerData.companyAddress}
          email={customerData.email}
          whatsAppNumber={customerData.whatsAppNumber}
          phoneNumber={customerData.phoneNumber}
          optionalContactNumber={customerData.optionalContactNumber}
          netMonthlySalary={customerData.netMonthlySalary}
          onSave={setUpdatedValues}
          isClicked={handleUpdateCustomerData}
          passowrd={customerData.password}
          loanReleaseStatus={customerData.loan[0].loanReleaseStatus}
        />
        {/* loan details  */}
        <A_LoanDetails
          loanAmount={customerData.loan[0].loanAmount}
          repaymentFrequency={customerData.loan[0].repaymentFrequency}
          startDate={customerData.loan[0].startDate}
          endDate={customerData.loan[0].endDate}
          numberOfInstallments={customerData.loan[0].numberOfInstallments}
          interestAmount={customerData.loan[0].interestAmount}
          installmentAmount={customerData.loan[0].installmentAmount}
        />
        {/* payment table */}
        <div
          className={`${customerData.loan[0].loanStatus === "REJECTED" || customerData.loan[0].loanStatus === "PENDING" ? "hidden" : ""}`}
        >
          <M_LastpaymentsSchedulesTable customerId={customerId} />
        </div>
        {/* first guranter  */}
        {reversedGuarantors.length !== 0 && (
          <A_FirstGuranter
            guarantorId={reversedGuarantors[0].guarantorId}
            fullName={reversedGuarantors[0].fullName}
            address={reversedGuarantors[0].address}
            nic={reversedGuarantors[0].nic}
            guarantorPhoneNumber={reversedGuarantors[0].guarantorPhoneNumber}
            verificationMethod={reversedGuarantors[0].verificationMethod}
            signatureImageURL={reversedGuarantors[0].signatureImageURL}
            frontImageURL={reversedGuarantors[0].frontImageURL}
            backImageURL={reversedGuarantors[0].backImageURL}
            electricityBillOrWaterImageURL={
              reversedGuarantors[0].electricityBillOrWaterImageURL
            }
            onSave={setGuaranterUpdatedValues}
            isClicked={handleUpdateCustomerData}
            gurantorImageURL={reversedGuarantors[0].gurantorImageURL}
          />
        )}
        {/* second guranter  */}
        {reversedGuarantors.length === 2 && (
          <A_SecondGuranter
            guarantorId={reversedGuarantors[1].guarantorId}
            fullName={reversedGuarantors[1].fullName}
            address={reversedGuarantors[1].address}
            nic={reversedGuarantors[1].nic}
            guarantorPhoneNumber={reversedGuarantors[1].guarantorPhoneNumber}
            verificationMethod={reversedGuarantors[1].verificationMethod}
            signatureImageURL={reversedGuarantors[1].signatureImageURL}
            frontImageURL={reversedGuarantors[1].frontImageURL}
            backImageURL={reversedGuarantors[1].backImageURL}
            electricityBillOrWaterImageURL={
              reversedGuarantors[1].electricityBillOrWaterImageURL
            }
            gurantorImageURL={reversedGuarantors[1].gurantorImageURL}
            onSave={setSecondGuaranterUpdatedValues}
            isClicked={handleUpdateCustomerData}
          />
        )}

        {/* collateral details  */}
        {customerData.collaterals && customerData.collaterals.length > 0 && (
          <A_CollateralDetails
            collateralName={customerData.collaterals[0].collateralName}
            collDescription={customerData.collaterals[0].description}
            netValue={customerData.collaterals[0].netValue.toString()}
            collImgUrl={customerData.collaterals[0].imgUrl}
            onSave={setCollateralUpdatedValues}
            isClicked={handleUpdateCustomerData}
          />
        )}
        {/* sign  */}
        <A_SignSection signatureImageURL={customerData.signatureImageURL} />
        {/* update customer button  */}
        <div className="flex justify-center mt-5">
          <Button text={"Save"} onClick={handleUpdateCustomerData} />
        </div>
      </Main>
    </div>
  );
};

export default A_CustomerUpdateOverviewPage;
