import React, { useEffect, useState } from "react";
import { Description, Input } from "../../../../common";
import { CollateralUpdateProps } from "../../../../../types";
import ImageChip from "../../../../common/image-chip/ImageChip";
import { useColletaralDetailsHandlers } from "./useColletaralDetailsHandlers";
import { CiEdit } from "react-icons/ci";
import CollateralImageOverviewModal from "../../../../common/modals/CollateralImageOverviewModal";

interface collateralDetailsTypes {
  collateralName: CollateralUpdateProps["collateralName"];
  collDescription: CollateralUpdateProps["collDescription"];
  netValue: CollateralUpdateProps["netValue"];
  collImgUrl: CollateralUpdateProps["collImgUrl"];
  onSave: (data: Partial<CollateralUpdateProps>) => void;
  isClicked: () => void;
}

const A_CollateralDetails: React.FC<collateralDetailsTypes> = ({
  collateralName,
  collDescription,
  netValue,
  collImgUrl,
  onSave,
  isClicked,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageURL, setCurrentImageURL] = useState<string | null>(null);
  const [savedValues, setSavedValues] = useState<
    Partial<CollateralUpdateProps>
  >({
    collateralName,
    netValue,
    collDescription,
    collImgUrl,
  });

  const {
    inputs,
    handleInputChange,
    handleEditClick,
    handleSave,
    handleCancel,
  } = useColletaralDetailsHandlers({
    collateralName,
    netValue,
    collDescription,
    collImgUrl,
    savedValues,
    setSavedValues,
  });

  useEffect(() => {
    onSave(savedValues);
  }, [savedValues, onSave]);

  // handle open image modals
  const handleOpenImageOverviewModal = (imageURL: string) => {
    setCurrentImageURL(imageURL);
    setIsModalOpen(true);
  };

  const handleUploadComplete = (url: string) => {
    // Update savedValues with the new image URL
    setSavedValues((prevValues) => ({
      ...prevValues,
      collImgUrl: url,
    }));
  };

  const handleModalClick = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="mt-5">
      <div className="mt-5">
        <Description
          content="ඇප භාණ්ඩය පිලිබද විස්තර"
          fontWeight="font-bold"
          fontSize="text-h7 lg:text-h6 3xl:text-h3"
        />
      </div>
      <div className="grid gap-3 md:grid-cols-2">
        {/* collateral Name */}
        <Input
          id={"collateralName"}
          name={"collateralName"}
          placeholder={"භාණ්ඩය"}
          label="භාණ්ඩය*"
          disabled={inputs.collateralName.disabled}
          value={inputs.collateralName.value}
          onChange={(e) => handleInputChange(e, "collateralName")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("collateralName")}
            />
          }
          saveCancel={!inputs.collateralName.disabled}
          saveOnclick={() => handleSave("collateralName")}
          cancelOnclick={() => handleCancel("collateralName")}
          errorMessage={inputs.collateralName.error}
        />
        {/* net Value */}
        <Input
          id={"netValue"}
          name={"netValue"}
          placeholder={"දළ වටිනාකම (රු)"}
          label="දළ වටිනාකම (රු)*"
          disabled={inputs.netValue.disabled}
          value={inputs.netValue.value}
          onChange={(e) => handleInputChange(e, "netValue")}
          backIcon={
            <CiEdit
              className="mt-5 cursor-pointer lg:mt-6"
              onClick={() => handleEditClick("netValue")}
            />
          }
          saveCancel={!inputs.netValue.disabled}
          saveOnclick={() => handleSave("netValue")}
          cancelOnclick={() => handleCancel("netValue")}
          errorMessage={inputs.netValue.error}
        />
      </div>

      {/* description */}
      <Input
        id={"description"}
        name={"description"}
        placeholder={"භාණ්ඩය පිළිබද විස්තරය"}
        label="භාණ්ඩය පිළිබද විස්තරය*"
        disabled={inputs.collDescription.disabled}
        value={inputs.collDescription.value}
        onChange={(e) => handleInputChange(e, "collDescription")}
        backIcon={
          <CiEdit
            className="mt-5 cursor-pointer lg:mt-6"
            onClick={() => handleEditClick("collDescription")}
          />
        }
        saveCancel={!inputs.collDescription.disabled}
        saveOnclick={() => handleSave("collDescription")}
        cancelOnclick={() => handleCancel("collDescription")}
        errorMessage={inputs.collDescription.error}
      />

      {/*first guarantors Attach a copy of water bill or electricity bill */}
      <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row">
        <Description
          content="ඇපකර භාණ්ඩයෙහි ඡායාරූපයක් අමුණන්න"
          fontWeight="font-bold"
        />

        <ImageChip
          id="firstGuarantorsBill"
          imageName={"ඇපකර භාණ්ඩය"}
          onClick={() => handleOpenImageOverviewModal(collImgUrl)}
        />
      </div>

      {/* image overview modal  */}

      <CollateralImageOverviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        imageUrl={currentImageURL}
        disableUpload={true}
      />
    </div>
  );
};

export default A_CollateralDetails;
