import React from 'react';
import PrimaryTitle from '../text/PrimaryTitle';
import Description from '../text/Description';

interface AnaliticsCardProps {
  title: string;
  amount: string;
  icon: React.ReactNode;
  titleFontSize?: 'text-h4' | 'text-xl' | 'text-lg' | 'text-base';
  titleFontColor?: 'text-black' | 'text-primaryColor-900' | 'text-primaryColor-500' | 'text-gray-600';
  amountFontSize?: 'text-h4' | 'text-xl' | 'text-lg' | 'text-base';
  amountFontColor?: 'text-black' | 'text-primaryColor-900' | 'text-primaryColor-500' | 'text-soft-red';
}

const AnaliticsCard: React.FC<AnaliticsCardProps> = ({
  title,
  amount,
  icon,
  titleFontSize = 'text-h6 ',
  amountFontSize = 'text-xl',
  amountFontColor,
}) => {

  return (
    <div className=" w-full xs:w-3/5 md:w-1/3 lg:w-1/4  xl:w-1/5  flex flex-col justify-center items-center  p-4 bg-primaryColor-50 shadow-md rounded-lg">
      <PrimaryTitle text={title} fontSize={titleFontSize} fontColor="text-primaryColor-900" />
      <div className="flex justify-between w-4/5 items-center mt-4">
        <div className='text-primaryColor-500'>{icon}</div>
        <div className="text-right flex gap-2">
          <Description content="Rs" fontColor={amountFontColor} />
          <PrimaryTitle text={amount} fontSize={amountFontSize} fontColor={amountFontColor} />
        </div>
      </div>
    </div>
  );
};

export default AnaliticsCard;
