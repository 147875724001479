import React from "react";
import { Modal } from "antd";
import Description from "../text/Description";
import { IoPersonSharp } from "react-icons/io5";
import { FaHouse } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

const CalanderOverviewModal: React.FC<{
  visible: boolean;
  icon?: React.ReactNode;
  onClose?: () => void;
  mainTitle?: string;
  content: {
    customerName: string;
    customerAddress: string;
    customerNic: string;
    customerPhoneNumber: string;
    customerWhatsAppNumber: string;
    numberOfInstallments: number;
    dueDate: string;
    dueAmount: number;
    id?: number;
    loanAmount: number;
    date?: string;
  } | null;
  primaryBtnLabel?: string;
  secondaryBtnLabel?: string;
  primaryOnClick?: () => void;
  secondaryOnClick?: () => void;
  closeIcon?: React.ReactNode;
}> = ({ visible, onClose, icon, content, closeIcon }) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="custom-modal" // Add your custom class here
    >
      <div className="space-y-5 font-poppins">
        {/* icon  */}
        <div className="text-h2">{icon}</div>

        {/* sub title  */}
        <div className="max-xxs:text-center">
          <Description content={""} center />
        </div>
        {content && (
          <div className="flex flex-col items-center gap-3 mt-4 ">
            <div className="bg-[#E7F0EE] w-[85%] md:w-[70%] py-2 px-3 rounded-lg">
              <div className="flex gap-3">
                <IoPersonSharp className="w-4 h-4 text-primaryColor-900" />
                <Description
                  content={content.customerName}
                  fontColor="text-primaryColor-900"
                />
              </div>

              <div className="flex gap-3 text-balance">
                <FaHouse className="w-4 h-4 text-primaryColor-900" />
                <Description
                  content={content.customerAddress}
                  fontColor="text-primaryColor-900"
                />
              </div>
              <div className="flex gap-3">
                <FaPhone className="w-4 h-4 text-primaryColor-900" />
                <Description
                  content={content.customerPhoneNumber}
                  fontColor="text-primaryColor-900"
                />
              </div>
              <div className="flex gap-3">
                <IoLogoWhatsapp className="w-4 h-4 text-primaryColor-900" />
                <Description
                  content={content.customerWhatsAppNumber}
                  fontColor="text-primaryColor-900"
                />
              </div>
            </div>

            <div className="w-full mt-4">
              <div className="flex justify-between">
                <Description
                  content={"Due Date :"}
                  fontColor="text-primaryColor-900"
                  fontWeight="font-semibold"
                />
                <Description
                  content={content.dueDate}
                  fontColor="text-primaryColor-900"
                />
              </div>

              <div className="flex justify-between ">
                <Description
                  content={"NIC :"}
                  fontColor="text-primaryColor-900"
                  fontWeight="font-semibold"
                />
                <Description
                  content={content.customerNic}
                  fontColor="text-primaryColor-900"
                />
              </div>

              <div className="flex justify-between">
                <Description
                  content={"Number of Installment: "}
                  fontColor="text-primaryColor-900"
                  fontWeight="font-semibold"
                />
                <Description
                  content={String(content.numberOfInstallments)}
                  fontColor="text-primaryColor-900"
                />
              </div>

              <div className="flex justify-between">
                <Description
                  content={"Loan Amount (Rs) :"}
                  fontColor="text-primaryColor-900"
                  fontWeight="font-semibold"
                />
                <Description
                  content={String(content.loanAmount)}
                  fontColor="text-primaryColor-900"
                />
              </div>

              <div className="flex justify-between">
                <Description
                  content={"Installment Amount (Rs) :"}
                  fontColor="text-primaryColor-900"
                  fontWeight="font-semibold"
                />
                <Description
                  content={String(content.dueAmount)}
                  fontColor="text-primaryColor-900"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CalanderOverviewModal;
