import React, { useEffect, useState } from "react";
import { Table, Typography, Form, Empty, Spin } from "antd";
import type { TableProps } from "antd";
import { PrimaryModal } from "../../common";
import BlacklistOverview from "../../modal/BlacklistOverview";
import {
  getLeagalAction,
  removeCustomerFromLegalActions,
} from "../../../api/legal";
import toast from "react-hot-toast";

interface ResponseItem {
  loan: {
    loanId: number;
    loanReleaseStatus: boolean;
    remainingAmount: number;
    amountCurrentlyPaid: number;
    numberOfInstallments: number;
    loanAmount: number;
    loanStatus: string;
    startDate: string;
    totalPaymentAmount: number;
    repaymentFrequency: string;
    loanDuration: string;
    collector: {
      appUser: {
        firstName: string;
      };
      collectorId: number;
    };
    managerAPPROVED: number | null;
  };
  customer: {
    customerId: number;
    fullName: string;
    address: string;
    customerImageURL: string;
    nic: string;
  };
  reason?: string;
}
interface Item {
  loanId: number;
  loanReleaseStatus: boolean;
  remainingAmount: number;
  amountCurrentlyPaid: number;
  loanInstallments: number;
  loanAmount: number;
  id: number;
  loanState: string;
  customerName: string;
  collectorName: string;
  paymentDate: string;
  dueAmount: number;
  paidAmount: number;
  balanceAmount: number;
  startDate: string;
  repaymentFrequency: string;
  customerAddress: string;
  loanDuration: string;
  customerImageURL: string;
  customerNIC: string;
  collectorAppUserId: number;
  managerAppUserId: number;
  reason: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        />
      ) : (
        children
      )}
    </td>
  );
};

const A_BlacklistTable = () => {
  const [form] = Form.useForm();
  const [customersData, setCustomersData] = useState<Item[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [releaseLoading, _setReleaseLoading] = useState<boolean>(false);
  const [overviewVisible, setOverviewVisible] = useState<boolean>(false);
  const [selectedLoanId, setSelectedLoanId] = useState<number | null>(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(
    null
  );
  const [_assignCollectorId, setAssignCollectorId] = useState<number | null>(
    null
  );
  const [_assignManagerId, setAssignManagerId] = useState<number | null>(null);
  const [isPrimaryModalVisible, setIsPrimaryModalVisible] =
    useState<boolean>(false);
  const [tableData, setTableData] = useState<Item[]>([]);

  useEffect(() => {
    const fetchLegalActions = async () => {
      setLoading(true);
      const userId = Number(localStorage.getItem("userId"));
      const token = localStorage.getItem("token") || "";

      const response = await getLeagalAction(token, userId);
      if (response) {
        const fetchedData: Item[] = response.map((item: ResponseItem) => ({
          key: item.loan.loanId,
          loanId: item.loan.loanId,
          loanReleaseStatus: item.loan.loanReleaseStatus,
          remainingAmount: item.loan.remainingAmount,
          amountCurrentlyPaid: item.loan.amountCurrentlyPaid,
          loanInstallments: item.loan.numberOfInstallments,
          loanAmount: item.loan.loanAmount,
          id: item.customer.customerId,
          loanState: item.loan.loanStatus,
          customerName: item.customer.fullName,
          collectorName: item.loan.collector.appUser.firstName,
          paymentDate: item.loan.startDate,
          dueAmount: item.loan.totalPaymentAmount,
          paidAmount: item.loan.amountCurrentlyPaid,
          balanceAmount: item.loan.remainingAmount,
          startDate: item.loan.startDate,
          repaymentFrequency: item.loan.repaymentFrequency,
          customerAddress: item.customer.address,
          loanDuration: item.loan.loanDuration,
          customerImageURL: item.customer.customerImageURL,
          customerNIC: item.customer.nic,
          collectorAppUserId: item.loan.collector.collectorId,
          managerAppUserId: item.loan.managerAPPROVED
            ? item.loan.managerAPPROVED
            : null,
          reason: item.reason || "N/A",
        }));
        setCustomersData(fetchedData);
      }
      setLoading(false);
    };

    fetchLegalActions();
  }, []);

  const showReleaseModal = (
    loanId: number,
    collectorId: number,
    managerAppUserId: number,
    customerId: number
  ) => {
    setSelectedLoanId(loanId);
    setSelectedCustomerId(customerId);
    setOverviewVisible(true);
    setAssignCollectorId(collectorId);
    setAssignManagerId(managerAppUserId);
  };

  const handleOverviewClose = () => {
    setOverviewVisible(false);
    setSelectedLoanId(null);
    setSelectedCustomerId(null);
  };

  const handleRemoveCustomer = async () => {
    if (selectedCustomerId !== null) {
      const token = localStorage.getItem("token") || "";
      const response = await removeCustomerFromLegalActions(
        token,
        String(selectedCustomerId)
      );

      if (response.success) {
        const updatedData = customersData.filter(
          (item) => item.id !== selectedCustomerId
        );
        setCustomersData(updatedData);
        setTableData(updatedData);
        toast.success(response.message);
      } else {
        console.error(response.message);
      }
    }
    setIsPrimaryModalVisible(false);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "loanId",
      fixed: "left" as const,
      align: "center" as const,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      align: "center" as const,
    },
    {
      title: "Customer NIC",
      dataIndex: "customerNIC",
      align: "center" as const,
    },
    {
      title: "Loan Amount (Rs.)",
      dataIndex: "loanAmount",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      align: "center" as const,
    },
    {
      title: "Remove from Blacklist",
      dataIndex: "paymentId",
      render: (_: number, record: Item) => (
        <div className="flex justify-center">
          <Typography.Link>
            <div
              className="flex justify-center w-20 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
              onClick={() =>
                showReleaseModal(
                  record.loanId,
                  record.collectorAppUserId,
                  record.managerAppUserId,
                  record.id
                )
              }
            >
              {releaseLoading && selectedLoanId === record.loanId ? (
                <Spin />
              ) : (
                "Remove"
              )}
            </div>
          </Typography.Link>
        </div>
      ),
      align: "center" as const,
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "netValue" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  useEffect(() => {
    setTableData(customersData);
  }, [customersData]);

  return (
    <div className="mt-4">
      <Form form={form} component={false}>
        <Table
          scroll={{ x: "max-content" }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={tableData}
          columns={mergedColumns}
          rowClassName="editable-row"
          className="font-semibold"
          locale={{
            emptyText: <Empty description={<span>No data available</span>} />,
          }}
          loading={loading}
        />
      </Form>

      <BlacklistOverview
        visible={overviewVisible}
        onClose={handleOverviewClose}
        primaryBtnLabel="Remove"
        primaryOnClick={() => {
          setOverviewVisible(false);
          setIsPrimaryModalVisible(true);
        }}
        secondaryOnClick={handleOverviewClose}
        secondaryBtnLabel=""
        userDetails=""
        customerId={selectedCustomerId}
      />

      <PrimaryModal
        visible={isPrimaryModalVisible}
        mainTitle={"Remove From Blacklist"}
        content={"Are you sure you want to remove from blacklist?"}
        primaryBtnLabel="Yes"
        secondaryBtnLabel="No"
        primaryOnClick={handleRemoveCustomer}
        secondaryOnClick={() => setIsPrimaryModalVisible(false)}
        onClose={() => setIsPrimaryModalVisible(false)}
        closeIcon={null}
      />
    </div>
  );
};

export default A_BlacklistTable;
