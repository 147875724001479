import React, { useEffect, useState } from "react";
import { AddPaymentPage, Loading, NotFound } from "../components/page";
import { getCurrentUser } from "../api/auth";
import { useLocation } from "react-router-dom";

const AddPayment: React.FC = () => {
  const location = useLocation();
  const { customerNIC } = location.state || {};
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token") || "";
      try {
        const userData = await getCurrentUser(token);
        setUserRole(userData.data.role);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUserRole(""); // Reset userRole to trigger NotFound component
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  switch (userRole) {
    case "ADMIN":
    case "MANAGER":
    case "COLLECTOR":
      return <AddPaymentPage scheduleCustomerNIC={customerNIC} />;
    default:
      return <NotFound />;
  }
};

export default AddPayment;
