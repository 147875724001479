import React, { useState, ChangeEvent, useEffect } from "react";
import { Button, Dropdown, Input, Main, Title } from "../../common";
import { register } from "../../../api/auth";
import toast from "react-hot-toast";
import { z } from "zod";
import { registerSchema } from "../../../lib/validation/registration-val";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { getManagers } from "../../../api/employee";

// Initial state for form data
const initialFormData = {
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  contactNumber: "",
  role: "",
  password: "",
  confirmPassword: "",
  supervisedBy: "",
};

interface Manager {
  userId: string;
  username: string;
  // Add other fields if necessary
}

// Functional component for user registration page
const UserRegisterPage: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string>(""); // State for selected dropdown option
  const [formData, setFormData] = useState(initialFormData); // State for registration form data
  const [errors, setErrors] = useState<Record<string, string>>({}); // State to hold validation errors
  const [isFirstValidation, setIsFirstValidation] = useState(false); // State to check if initial validation has been done
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false); // State to toggle password visibility
  const [selectedManager, setSelectedManager] = useState<string>(""); // State for selected manager
  const [allManagers, setAllManagers] = useState<Manager[]>([]); // State for all managers
  const [, setSupervisorError] = useState<string | undefined>();

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  // Function to toggle password visibility
  const toggleForgetPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  // Function to handle dropdown option selection
  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    const updatedFormData = { ...formData, role: option };
    setFormData(updatedFormData);
    if (isFirstValidation) {
      validateForm(updatedFormData);
    }
  };

  // Function to handle input changes and validate form on change
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (isFirstValidation) {
      validateForm({ ...formData, [name]: value });
    }
  };

  // Function to validate form data using Zod schema
  const validateForm = (data: typeof formData) => {
    try {
      registerSchema.parse(data);
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          newErrors[err.path[0]] = err.message;
        });
        setErrors(newErrors);
        return false;
      }
      return false;
    }
  };

  // Get all managers
  const fetchManagers = async () => {
    try {
      const token = localStorage.getItem("token") || "";

      const response = await getManagers(token);
      setAllManagers(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchManagers();
  }, []);

  // Function to handle manager selection
  const handleManagerSelect = (managerUsername: string) => {
    const selectedManager = allManagers.find(
      (manager) => manager.username === managerUsername
    );
    if (selectedManager) {
      setSelectedManager(managerUsername);
      setFormData({
        ...formData,
        supervisedBy: String(selectedManager.userId),
      });
    }
  };

  // Function to handle registration form submission
  const handleRegisterClick = async () => {
    setIsFirstValidation(true);
    if (validateForm(formData)) {
      // Check if passwords match
      if (formData.password !== formData.confirmPassword) {
        toast.error("Passwords do not match");
        return;
      }

      // start button loading
      setIsLoading(true);

      const token = localStorage.getItem("token") || "";
      try {
        const response = await register(token, formData);
        if (response.success) {
          toast.success("Registration successful");
          fetchManagers();
          // Clear form data on successful registration
          setFormData(initialFormData);
          setSelectedOption("");
          setSelectedManager("");
          setIsFirstValidation(false);
        } else if (response.message === "Username already exists") {
          toast.error(response.message);
        } else if (
          response.message ===
          "Supervisor ID must be provided for COLLECTOR role"
        ) {
          toast.error(response.message);
          setSupervisorError(response.message);
        } else if (response.name === "AxiosError") {
          toast.error(response.response.data.message);
        } else {
          // if invalid data
          toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
        toast.error("System Error");
      } finally {
        // end button loading
        setIsLoading(false);
      }
    } else {
      // required fields
      console.log(errors);
    }
  };

  // Options for the role dropdown
  const options = ["ADMIN", "MANAGER", "COLLECTOR"];

  // Options for the manager dropdown
  const managerOptions = allManagers.map((manager) => manager.username);

  return (
    <>
      {/* Page title */}
      <Title title="User Registration" />
      <Main>
        {/* Main container for the page content */}
        <div className="flex flex-col items-center justify-center w-full ">
          <div className="flex flex-col w-full">
            <div className="grid w-full md:grid-cols-2 md:space-x-3">
              {/* Input fields for first and last name */}
              <Input
                id="firstName"
                name="firstName"
                placeholder="First Name"
                label="First Name"
                type="text"
                labelWeight="font-semibold"
                value={formData.firstName}
                onChange={handleInputChange}
                errorMessage={errors.firstName}
              />
              <Input
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                label="Last Name"
                type="text"
                labelWeight="font-semibold"
                value={formData.lastName}
                onChange={handleInputChange}
                errorMessage={errors.lastName}
              />
            </div>

            <div className="grid md:grid-cols-2 md:space-x-3">
              {/* Input fields for email and contact number */}
              <Input
                id="email"
                name="email"
                placeholder="Email"
                label="Email"
                type="text"
                labelWeight="font-semibold"
                value={formData.email}
                onChange={handleInputChange}
                errorMessage={errors.email}
                restrictSpaces
              />
              <Input
                id="contactNumber"
                name="contactNumber"
                placeholder="Contact Number"
                label="Contact Number"
                type="number"
                labelWeight="font-semibold"
                value={formData.contactNumber}
                onChange={handleInputChange}
                errorMessage={errors.contactNumber}
                restrictSpaces
              />
            </div>

            {/* Input field for username */}
            <Input
              id="username"
              name="username"
              placeholder="Username"
              label="Username"
              type="text"
              labelWeight="font-semibold"
              width="md:w-[50%]"
              value={formData.username}
              onChange={handleInputChange}
              errorMessage={errors.username}
            />

            <div className="grid md:space-x-3 md:grid-cols-2">
              {/* Dropdown for role selection */}
              <Dropdown
                options={options}
                selectedOption={selectedOption}
                onOptionSelect={handleOptionSelect}
                placeholder="Role"
                label="Role"
                errorMessage={errors.role}
              />

              {/* If selected option is collector then display the manager dropdown */}
              {selectedOption === "COLLECTOR" && (
                <Dropdown
                  options={managerOptions}
                  selectedOption={selectedManager}
                  onOptionSelect={handleManagerSelect}
                  placeholder="Supervisor"
                  label="Supervisor"
                  // errorMessage={selectedManager ? "" : supervisorError}
                  errorMessage={errors.supervisedBy}
                />
              )}
            </div>

            <div className="grid md:grid-cols-2 md:space-x-3">
              {/* Input fields for password and confirm password */}
              <Input
                id="password"
                name="password"
                placeholder="Password"
                label="Password"
                type={passwordVisible ? "text" : "password"}
                labelWeight="font-semibold"
                value={formData.password}
                onChange={handleInputChange}
                errorMessage={errors.password}
                restrictSpaces
                backIcon={
                  passwordVisible ? (
                    <AiOutlineEyeInvisible onClick={togglePasswordVisibility} />
                  ) : (
                    <AiOutlineEye onClick={togglePasswordVisibility} />
                  )
                }
                top
              />
              <Input
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
                label="Confirm Password"
                type={confirmPasswordVisible ? "text" : "password"}
                labelWeight="font-semibold"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                errorMessage={errors.confirmPassword}
                restrictSpaces
                backIcon={
                  confirmPasswordVisible ? (
                    <AiOutlineEyeInvisible
                      onClick={toggleForgetPasswordVisibility}
                    />
                  ) : (
                    <AiOutlineEye onClick={toggleForgetPasswordVisibility} />
                  )
                }
                top
              />
            </div>

            {/* Submit button */}
            <div className="flex justify-center mt-5">
              <Button
                text="Submit"
                onClick={handleRegisterClick}
                isLoading={isLoading}
                disabled={isLoading ? true : false}
              />
            </div>
          </div>
        </div>
      </Main>
    </>
  );
};

export default UserRegisterPage;
