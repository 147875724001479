import React, { useEffect, useState } from "react";
import {
  Description,
  Input,
  RadioButton,
  UpdateImageOverviewModal,
} from "../../../../common";
import ImageChip from "../../../../common/image-chip/ImageChip";
import { guaranterUpdateTypes, Guarantor } from "../../../../../types";
import { useGuaranterDetailsHandlers } from "./useGuaranterDetailsHandlers";
import { CiEdit } from "react-icons/ci";
import { MdDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

interface firstGuranterTypes {
  guarantorId: Guarantor["guarantorId"];
  fullName: Guarantor["fullName"];
  address: Guarantor["address"];
  nic: Guarantor["nic"];
  guarantorPhoneNumber: Guarantor["guarantorPhoneNumber"];
  verificationMethod: Guarantor["verificationMethod"];
  signatureImageURL: Guarantor["signatureImageURL"];
  gurantorImageURL: Guarantor["gurantorImageURL"];
  frontImageURL: Guarantor["frontImageURL"];
  backImageURL: Guarantor["backImageURL"];
  electricityBillOrWaterImageURL: Guarantor["electricityBillOrWaterImageURL"];
  onSave: (data: Partial<guaranterUpdateTypes>) => void;
  isClicked: () => void;
}

const A_SecondGuranter: React.FC<firstGuranterTypes> = ({
  guarantorId,
  fullName,
  address,
  nic,
  guarantorPhoneNumber,
  verificationMethod,
  signatureImageURL,
  gurantorImageURL,
  frontImageURL,
  backImageURL,
  electricityBillOrWaterImageURL,
  onSave,
  isClicked,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageURL, setCurrentImageURL] = useState<string | null>(null);
  const [imageType, setImageType] = useState<string | null>(null);
  const [isRadioEditMode, setIsRadioEditMode] = useState(false);
  const [selectedIdentityOption, setSelectedIdentityOption] =
    useState<string>(verificationMethod);

  // customer updated values
  const [savedValues, setSavedValues] = useState<Partial<guaranterUpdateTypes>>(
    {
      guarantorId,
      fullName,
      address,
      nic,
      guarantorPhoneNumber,
      gurantorImageURL,
      frontImageURL,
      backImageURL,
      electricityBillOrWaterImageURL,
    }
  );
  const {
    inputs,
    handleInputChange,
    handleEditClick,
    handleSave,
    handleCancel,
  } = useGuaranterDetailsHandlers({
    fullName,
    address,
    nic,
    guarantorPhoneNumber,
    savedValues,
    gurantorImageURL,
    frontImageURL,
    backImageURL,
    electricityBillOrWaterImageURL,
    setSavedValues,
  });

  useEffect(() => {
    onSave(savedValues); // Call the onSave callback with updated values
  }, [savedValues, onSave]);

  // handle open image modals
  const handleOpenImageOverviewModal = (imageURL: string, type: string) => {
    setCurrentImageURL(imageURL);
    setImageType(type);
    setIsModalOpen(true);
  };

  const handleUploadComplete = (url: string) => {
    // Directly update state to check if there's an issue with setSavedValues
    const updatedValues = { ...savedValues };
    if (imageType === "frontImageURL") {
      updatedValues.frontImageURL = url;
    } else if (imageType === "backImageURL") {
      updatedValues.backImageURL = url;
    } else if (imageType === "electricityBillOrWaterImageURL") {
      updatedValues.electricityBillOrWaterImageURL = url;
    } else if (imageType === "gurantorImageURL") {
      updatedValues.gurantorImageURL = url;
    } else {
      console.warn("Unknown image type");
    }

    setSavedValues(updatedValues);
  };

  const handleModalClick = () => {
    setIsModalOpen(false);
  };

  const handleIdentityRadioButtonChange = (value: string) => {
    setSelectedIdentityOption(value);
  };

  // New handlers for radio button edit mode
  const handleRadioEditClick = () => {
    setIsRadioEditMode(true);
  };

  const handleRadioSaveClick = () => {
    setIsRadioEditMode(false);

    setSavedValues((prevValues) => ({
      ...prevValues,
      verificationMethod: selectedIdentityOption,
    }));
  };

  const handleRadioCancelClick = () => {
    setIsRadioEditMode(false);
    // Revert to the original selected option if needed
    setSelectedIdentityOption(verificationMethod);
  };

  return (
    <div>
      <div>
        {/* first guarantor  */}
        <div className="my-5">
          <Description
            content="දෙවන ඇපකරු"
            fontWeight="font-bold"
            fontSize="text-h7 lg:text-h6 3xl:text-h3"
          />
        </div>
        <div className="grid md:gap-3 md:grid-cols-2">
          {/*first guarantors Name  */}
          <Input
            id={"fullName"}
            name={"fullName"}
            placeholder={"නම"}
            label="නම*"
            disabled={inputs.fullName.disabled}
            value={inputs.fullName.value}
            onChange={(e) => handleInputChange(e, "fullName")}
            backIcon={
              <CiEdit
                className="mt-5 cursor-pointer lg:mt-6"
                onClick={() => handleEditClick("fullName")}
              />
            }
            saveCancel={!inputs.fullName.disabled}
            saveOnclick={() => handleSave("fullName")}
            cancelOnclick={() => handleCancel("fullName")}
            errorMessage={inputs.fullName.error}
          />

          {/*first guarantors Address  */}
          <Input
            id={"address"}
            name={"address"}
            placeholder={"ලිපිනය"}
            label="ලිපිනය*"
            disabled={inputs.address.disabled}
            value={inputs.address.value}
            onChange={(e) => handleInputChange(e, "address")}
            backIcon={
              <CiEdit
                className="mt-5 cursor-pointer lg:mt-6"
                onClick={() => handleEditClick("address")}
              />
            }
            saveCancel={!inputs.address.disabled}
            saveOnclick={() => handleSave("address")}
            cancelOnclick={() => handleCancel("address")}
            errorMessage={inputs.address.error}
          />
        </div>
        <div className="grid md:gap-3 md:grid-cols-2">
          {/*first guarantors Id  */}
          <Input
            id={"nic"}
            name={"nic"}
            placeholder={"ජාතික හැඳුනුම්පත් අංකය"}
            label="ජාතික හැඳුනුම්පත් අංකය*"
            disabled={inputs.nic.disabled}
            value={inputs.nic.value}
            onChange={(e) => handleInputChange(e, "nic")}
            backIcon={
              <CiEdit
                className="mt-5 cursor-pointer lg:mt-6"
                onClick={() => handleEditClick("nic")}
              />
            }
            saveCancel={!inputs.nic.disabled}
            saveOnclick={() => handleSave("nic")}
            cancelOnclick={() => handleCancel("nic")}
            errorMessage={inputs.nic.error}
          />

          {/*first guarantors Tp Number */}
          <Input
            id={"guarantorPhoneNumber"}
            name={"guarantorPhoneNumber"}
            placeholder={"දුරකථන අංකය"}
            label="දුරකථන අංකය*"
            disabled={inputs.guarantorPhoneNumber.disabled}
            value={inputs.guarantorPhoneNumber.value}
            onChange={(e) => handleInputChange(e, "guarantorPhoneNumber")}
            backIcon={
              <CiEdit
                className="mt-5 cursor-pointer lg:mt-6"
                onClick={() => handleEditClick("guarantorPhoneNumber")}
              />
            }
            saveCancel={!inputs.guarantorPhoneNumber.disabled}
            saveOnclick={() => handleSave("guarantorPhoneNumber")}
            cancelOnclick={() => handleCancel("guarantorPhoneNumber")}
            errorMessage={inputs.guarantorPhoneNumber.error}
          />
        </div>

        <div>
          <Description content="පළමු ඇපකරුගෙ ඡායාරූපය" fontWeight="font-bold" />
          <ImageChip
            id={"gurantorImageURL"}
            imageName={"ඉදිරිපස"}
            onClick={() =>
              handleOpenImageOverviewModal(gurantorImageURL, "gurantorImageURL")
            }
          />
        </div>

        {/*first guarantors select nic, driving license or passport  */}
        <div className="flex">
          <div className="mr-3">
            <Description
              content="අනන්‍යතාව සහතික කිරීම සඳහා පහත ඒවායින් එකක් තෝරාගන්න*"
              fontWeight="font-bold"
            />
          </div>
          {/* edit button  */}
          <div>
            {!isRadioEditMode && (
              <CiEdit
                className="mt-[2px] cursor-pointer text-h5 text-primaryColor-500"
                onClick={handleRadioEditClick}
              />
            )}

            {isRadioEditMode && (
              <div className={` flex  gap-5 text-h4`}>
                <span
                  className={`text-green-600 cursor-pointer`}
                  onClick={handleRadioSaveClick}
                >
                  <MdDone />
                </span>
                <span
                  className={`text-red-600 cursor-pointer`}
                  onClick={handleRadioCancelClick}
                >
                  <RxCross2 />
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-wrap gap-5 mt-3 lg:gap-10">
          <RadioButton
            id={"firstGuarantorNic"}
            label={"ජාතික හැඳුනුම්පත"}
            checked={selectedIdentityOption === "NIC"}
            onChange={() => handleIdentityRadioButtonChange("NIC")}
            disabled={!isRadioEditMode}
          />
          <RadioButton
            id={"firstGuarantorDrivingLicense"}
            label={"රියදුරු බලපත්‍රය"}
            checked={selectedIdentityOption === "DRIVER_LICENCE"}
            onChange={() => handleIdentityRadioButtonChange("DRIVER_LICENCE")}
            disabled={!isRadioEditMode}
          />
          <RadioButton
            id={"firstGuarantorPassport"}
            label={"විදේශ ගමන් බලපත්‍රය"}
            checked={selectedIdentityOption === "PASSPORT"}
            onChange={() => handleIdentityRadioButtonChange("PASSPORT")}
            disabled={!isRadioEditMode}
          />
        </div>

        {/*first guarantors Attach nic, driving license or passport photos  */}
        <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row ">
          <Description
            content="හැඳුනුම්තෙහි ඡායාරූප අමුණන්න"
            fontWeight="font-bold"
          />
          <div className="flex space-x-2">
            <ImageChip
              id={"firstGuarantorsIdFront"}
              imageName={"ඉදිරිපස"}
              onClick={() =>
                handleOpenImageOverviewModal(frontImageURL, "frontImageURL")
              }
            />

            <ImageChip
              id={"firstGuarantorsIdBack"}
              imageName={"පසුපස"}
              onClick={() =>
                handleOpenImageOverviewModal(backImageURL, "backImageURL")
              }
            />
          </div>
        </div>
        {/*first guarantors Attach a copy of water bill or electricity bill */}
        <div className="flex flex-col gap-2 mt-5 lg:gap-4 md:flex-row">
          <Description
            content="ජල බිල හෝ විදුලි බිලෙහි පිටපතක් අමුණන්න"
            fontWeight="font-bold"
          />

          <ImageChip
            id="firstGuarantorsBill"
            imageName={"බිල්පත"}
            onClick={() =>
              handleOpenImageOverviewModal(
                electricityBillOrWaterImageURL,
                "electricityBillOrWaterImageURL"
              )
            }
          />
        </div>

        {/*first guarantors display signature */}
        <div className="h-40 p-2 mt-5 bg-white border-2 rounded-lg  max-w-[100%] border-primaryColor-200">
          <img
            src={signatureImageURL}
            className="object-contain w-full h-full"
          />
        </div>
      </div>

      {/* image overview modal  */}
      <UpdateImageOverviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        imageUrl={currentImageURL || ""}
        onUploadComplete={handleUploadComplete}
        onclick={handleModalClick}
        isClicked={isClicked}
        buttonText="Save Image"
      />
    </div>
  );
};

export default A_SecondGuranter;
