import React from "react";
import { AnaliticsCard } from "../../../common/";
import { FaWallet } from "react-icons/fa";
import { FaMoneyBillTransfer } from "react-icons/fa6";

interface LoanSummary {
  totalIssuedLoanAmount: number;
  totalCollectedLoanAmount: number;
  totalProfit: number;
  remainingLoanAmount: number;
  totalAmountOf2days: number;
  totalAmountOf15days: number;
  totalAmountNotOverdue: number;
}

interface M_AnaliticsCardsSectionProps {
  loanSummary: LoanSummary;
}

const M_AnaliticsCardsSection: React.FC<M_AnaliticsCardsSectionProps> = ({
  loanSummary,
}) => {
  return (
    <div className="flex flex-wrap justify-center gap-4 mb-6">
       <AnaliticsCard
            title={"Disbursed Loan Amount"}
            amount={loanSummary.totalIssuedLoanAmount.toFixed(2)}
            icon={<FaMoneyBillTransfer />}
            amountFontColor="text-black"
          />
          <AnaliticsCard
            title={"Total Recovered Amount"}
            amount={loanSummary.totalCollectedLoanAmount.toFixed(2)}
            icon={<FaWallet />}
            amountFontColor="text-black"
          />
          <AnaliticsCard
            title={"Total Profit"}
            amount={loanSummary.totalProfit.toFixed(2)}
            icon={<FaMoneyBillTransfer />}
            amountFontColor="text-black"
          />
          <AnaliticsCard
            title={"Total Amount Due"}
            amount={loanSummary.remainingLoanAmount.toFixed(2)}
            icon={<FaMoneyBillTransfer />}
            amountFontColor="text-soft-red"
          />
          <AnaliticsCard
            title={"Late Amount (2 days)"}
            amount={loanSummary.totalAmountOf2days.toFixed(2)}
            icon={<FaMoneyBillTransfer />}
            amountFontColor="text-black"
          />
          <AnaliticsCard
            title={"Late Amount (15 days)"}
            amount={loanSummary.totalAmountOf15days.toFixed(2)}
            icon={<FaWallet />}
            amountFontColor="text-black"
          />
          <AnaliticsCard
            title={"Amount Due Normally"}
            amount={loanSummary.totalAmountNotOverdue.toFixed(2)}
            icon={<FaMoneyBillTransfer />}
            amountFontColor="text-black"
          />
    </div>
  );
};

export default M_AnaliticsCardsSection;
