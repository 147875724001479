import React, { useEffect, useState } from "react";
import { Main } from "../../common";
import SettingModal from "../../common/modals/SettingModal";
import PlanSettingsCard from "../../common/cards/PlanSettingsCard";
import {
  getLoanDurations,
  getLoanrate,
  postLoanDurations,
  postLoanRate,
} from "../../../api/loan";
import RateSettingsCard from "../../common/cards/RateSettingsCard";
import toast from "react-hot-toast";
import { A_SystemRegularRates } from "../../Tables";

interface LoanDuration {
  id: number;
  day: number;
  month: number;
  week: number;
}

interface LoanRateItem {
  rateId: string;
  name: string;
  rate: string;
}

interface postLoanDataProps {
  rateId: number;
  name: string;
  rate: number;
}

type LoanRateState = {
  RegularRateId: string;
  RegularRateName: string;
  RegularRateValue: string;
  LatePaymentRateId: string;
  LatePaymentRateName: string;
  LatePaymentRateValue: string;
  OverdueDayPaymentRateValue: string;
  OverdueDayPaymentRateName: string;
  OverdueDayPaymentRateId: string;
};

const A_SystemSettingspage: React.FC = () => {
  const [isDailyModalVisible, setIsDailyModalVisible] = useState(false);
  const [isWeeklyModalVisible, setIsWeeklyModalVisible] = useState(false);
  const [isMonthlyModalVisible, setIsMonthlyModalVisible] = useState(false);
  const [isRateModalVisible, setIsRateModalVisible] = useState(false);
  const [isLatePaymentRateModalVisible, setIsLatePaymentRateModalVisible] =
    useState(false);
  const [
    isOverdueDayPaymentRateModalVisible,
    setIsOverdueDayPaymentRateModalVisible,
  ] = useState(false);

  const handleOpenDailyModal = () => {
    setIsDailyModalVisible(true);
  };
  const handleCloseDailyModal = () => {
    setIsDailyModalVisible(false);
  };
  const handleOpenWeeklyModal = () => {
    setIsWeeklyModalVisible(true);
  };
  const handleCloseWeeklyModal = () => {
    setIsWeeklyModalVisible(false);
  };
  const handleOpenMonthlyModal = () => {
    setIsMonthlyModalVisible(true);
  };
  const handleCloseMonthlyModal = () => {
    setIsMonthlyModalVisible(false);
  };

  const handleCloseRegularRateModal = () => {
    setIsRateModalVisible(false);
  };
  const handleOpenLatePaymentRateModal = () => {
    setIsLatePaymentRateModalVisible(true);
  };
  const handleCloseLatePaymentRateModal = () => {
    setIsLatePaymentRateModalVisible(false);
  };
  const handleOpenOverdueDayPaymentRateModal = () => {
    setIsOverdueDayPaymentRateModalVisible(true);
  };
  const handleCloseOverdueDayPaymentRateModal = () => {
    setIsOverdueDayPaymentRateModalVisible(false);
  };

  const [loanDurations, setloanDurations] = useState({
    day: "",
    month: "",
    week: "",
    id: "",
  });

  const [loanRate, setLoanRate] = useState<LoanRateState>({
    RegularRateId: "",
    RegularRateName: "",
    RegularRateValue: "",
    LatePaymentRateId: "",
    LatePaymentRateName: "",
    LatePaymentRateValue: "",
    OverdueDayPaymentRateId: "",
    OverdueDayPaymentRateName: "",
    OverdueDayPaymentRateValue: "",
  });

  useEffect(() => {
    fetchLoanDurations();
    fetchLoanRate();
  }, []);

  const fetchLoanDurations = async () => {
    const token = localStorage.getItem("token") || "";

    try {
      const response = await getLoanDurations(token);
      if (response) {
        setloanDurations({
          day: response[0].day,
          month: response[0].month,
          week: response[0].week,
          id: response[0].id,
        });
      } else {
        console.log("No response data for loan Durations");
      }
    } catch (error) {
      console.log(" get data error -", error);
    }
  };

  const fetchLoanRate = async () => {
    const token = localStorage.getItem("token") || "";

    try {
      const response: LoanRateItem[] = await getLoanrate(token);
      if (response) {
        const newLoanRate: Partial<LoanRateState> = {};

        response.forEach((rate) => {
          if (rate.name === "RegularRate") {
            newLoanRate.RegularRateId = rate.rateId;
            newLoanRate.RegularRateName = rate.name;
            newLoanRate.RegularRateValue = rate.rate;
          } else if (rate.name === "LatePaymentRate") {
            newLoanRate.LatePaymentRateId = rate.rateId;
            newLoanRate.LatePaymentRateName = rate.name;
            newLoanRate.LatePaymentRateValue = rate.rate;
          } else if (rate.name === "OverdueDayPaymentRate") {
            newLoanRate.OverdueDayPaymentRateId = rate.rateId;
            newLoanRate.OverdueDayPaymentRateName = rate.name;
            newLoanRate.OverdueDayPaymentRateValue = rate.rate;
          }
        });

        setLoanRate((prevState) => ({
          ...prevState,
          ...newLoanRate,
        }));
      } else {
        console.log("No response data for loan rates");
      }
    } catch (error) {
      console.log(" get data error -", error);
    }
  };

  const handleChangeLoanDurations = async (
    planName: string,
    planValue: number
  ) => {
    const rest: LoanDuration = {
      id: parseInt(loanDurations.id),
      day: parseInt(loanDurations.day),
      month: parseInt(loanDurations.month),
      week: parseInt(loanDurations.week),
    };

    if (planName === "DAILY") {
      rest.day = planValue;
    } else if (planName === "WEEKLY") {
      rest.week = planValue;
    } else if (planName === "MONTHLY") {
      rest.month = planValue;
    }

    const token = localStorage.getItem("token") || "";

    try {
      await postLoanDurations(rest, token);
      console.log("response");
      toast.success("Loan Duration Change Successful");
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Loan Duration Change failed!");
    }

    setIsMonthlyModalVisible(false);
    setIsWeeklyModalVisible(false);
    setIsDailyModalVisible(false);
    fetchLoanDurations();
  };

  const handleChangeLoanRate = async (planName: string, planValue: number) => {
    let rest: postLoanDataProps | undefined;

    if (planName === "RegularRate") {
      rest = {
        rateId: Number(loanRate.RegularRateId),
        name: loanRate.RegularRateName,
        rate: planValue,
      };
    } else if (planName === "LatePaymentRate") {
      rest = {
        rateId: Number(loanRate.LatePaymentRateId),
        name: loanRate.LatePaymentRateName,
        rate: planValue,
      };
    } else if (planName === "OverdueDayPaymentRate") {
      rest = {
        rateId: Number(loanRate.OverdueDayPaymentRateId),
        name: loanRate.OverdueDayPaymentRateName,
        rate: planValue,
      };
    }

    const token = localStorage.getItem("token") || "";

    if (rest) {
      try {
        const response = await postLoanRate(rest, token);
        if (response) {
          toast.success("Rate Change Successful");
        }
      } catch (error) {
        console.error("API Error:", error);
        toast.error("Rate Change failed");
      }
      setIsRateModalVisible(false);
      setIsLatePaymentRateModalVisible(false);
      setIsOverdueDayPaymentRateModalVisible(false);
      fetchLoanRate();
    } else {
      toast.error("Invalid rate data");
    }
  };

  return (
    <>
      <Main>
        <div>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-3 md:flex-row">
              <RateSettingsCard
                mainTitle=""
                content="Late Payment Interest Rate -"
                keyValue={loanRate.LatePaymentRateValue}
                primaryOnClick={handleOpenLatePaymentRateModal}
              />
              <RateSettingsCard
                mainTitle=""
                content="Overdue Payment Interest Rate -"
                keyValue={loanRate.OverdueDayPaymentRateValue}
                primaryOnClick={handleOpenOverdueDayPaymentRateModal}
              />
            </div>
            <div className="flex flex-col items-center justify-center w-full gap-3 md:gap-0 sm:justify-around sm:flex-row">
              <PlanSettingsCard
                mainTitle="Daily Plan"
                content={loanDurations.day}
                keyValue="Days"
                primaryOnClick={handleOpenDailyModal}
              />
              <PlanSettingsCard
                mainTitle="Weekly Plan"
                content={loanDurations.week}
                keyValue="Weeks"
                primaryOnClick={handleOpenWeeklyModal}
              />
              <PlanSettingsCard
                mainTitle="Monthly Plan"
                content={loanDurations.month}
                keyValue="Months"
                primaryOnClick={handleOpenMonthlyModal}
              />
            </div>
          </div>
        </div>

        {/* regular rates  */}
        <div>
          <A_SystemRegularRates />
        </div>
      </Main>

      <SettingModal
        visible={isRateModalVisible}
        onClose={handleCloseRegularRateModal}
        mainTitle="Edit Regular Interest Rate"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanRate}
        closeIcon
        planName="RegularRate"
        inputLoanValue={loanRate.RegularRateValue}
      />

      <SettingModal
        visible={isLatePaymentRateModalVisible}
        onClose={handleCloseLatePaymentRateModal}
        mainTitle="Edit Late Payment Interest Rate"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanRate}
        closeIcon
        planName="LatePaymentRate"
        inputLoanValue={loanRate.LatePaymentRateValue}
      />

      <SettingModal
        visible={isOverdueDayPaymentRateModalVisible}
        onClose={handleCloseOverdueDayPaymentRateModal}
        mainTitle="Edit Overdue Payment Interest Rate"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanRate}
        closeIcon
        planName="OverdueDayPaymentRate"
        inputLoanValue={loanRate.OverdueDayPaymentRateValue}
      />

      <SettingModal
        visible={isDailyModalVisible}
        onClose={handleCloseDailyModal}
        mainTitle="Edit Daily Loan Plan"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanDurations}
        closeIcon
        planName="DAILY"
        inputLoanValue={loanDurations.day}
      />

      <SettingModal
        visible={isWeeklyModalVisible}
        onClose={handleCloseWeeklyModal}
        mainTitle="Edit Weekly Loan Plan"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanDurations}
        closeIcon
        planName="WEEKLY"
        inputLoanValue={loanDurations.week}
      />

      <SettingModal
        visible={isMonthlyModalVisible}
        onClose={handleCloseMonthlyModal}
        mainTitle="Edit Monthly Loan Plan"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanDurations}
        closeIcon
        planName="MONTHLY"
        inputLoanValue={loanDurations.month}
      />
    </>
  );
};

export default A_SystemSettingspage;
