import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Empty, Form, Table, Typography } from "antd";
import { getAllCollateral } from "../../../api/collateral";
import { Description, Dropdown, Input } from "../../common";
import { useNavigate } from "react-router-dom";
import { CollateralReleaseModal } from "../../modal";
import CollateralImageOverviewModal from "../../common/modals/CollateralImageOverviewModal";

interface Item {
  loanId: number;
  status: string;
  description: string;
  imgUrl: string | undefined;
  id: number;
  customerId: number;
  collateralId: string;
  collateralName: string;
  netValue: number;
  collDescription: string;
  collStatus: string;
  collImgUrl?: string;
  loanStatus: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        ></Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const A_AllCollateralTable = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<number | null>(null);
  const [collateralData, setCollateralData] = useState<Item[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>("All"); // State for selected status
  const [searchText, setSearchText] = useState<string>("");
  const isEditing = (record: Item) => record.id === editingKey;
  const [isOpenImageModal, setIsOpenImageModal] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>("");
  const [isOpenCollateralReleaseModal, setIsOpenCollateralReleaseModal] =
    useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState<number | null>(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(
    null
  );

  // handle open colleteral modal
  const handleOpenReleaseModal = (loanId: number, customerId: number) => {
    setSelectedLoanId(loanId);
    setSelectedCustomerId(customerId);
    setIsOpenCollateralReleaseModal(true);
  };

  // handle colleteral close release modal
  const handleCloseReleaseModal = () => {
    setIsOpenCollateralReleaseModal(false);
    setSelectedLoanId(null);
    setSelectedCustomerId(null);
  };

  // navigate edit customer overview
  const handleNavigate = (customerId: number) => {
    navigate(`/customer-update-overview/${customerId}`);
  };

  const handleOpenImageModal = (imgUrl: string | undefined) => {
    setIsOpenImageModal(true);
    setImageUrl(imgUrl);
  };
  const handleCloseImageModal = () => {
    setImageUrl("");
    setIsOpenImageModal(false);
    setImageUrl("");
  };

  const cancel = () => {
    setEditingKey(null);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "collateralId",
      editable: true,
      fixed: "left" as const, // Use the type assertion
      align: "center" as const,
    },
    {
      title: "Collateral Name",
      dataIndex: "collateralName",
      editable: true,
      align: "center" as const,
    },
    {
      title: "Net Value",
      dataIndex: "netValue",
      render: (amount: number) => `${amount.toFixed(2)}`,
      editable: true,
      align: "center" as const,
    },
    {
      title: "Description",
      dataIndex: "description",
      editable: true,
      align: "center" as const,
    },
    {
      title: "Collateral Image",
      dataIndex: "imgUrl",
      align: "center" as const,
      render: (_: number, record: Item) => {
        return (
          <div className="flex justify-center">
            <Typography.Link>
              <div
                className="flex justify-center w-40 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
                onClick={() => handleOpenImageModal(record.imgUrl)}
              >
                View
              </div>
            </Typography.Link>
          </div>
        );
      },
    },
    {
      title: "Collateral Status",
      dataIndex: "status",
      align: "center" as const,
      editable: true,
      render: (text: string) => {
        let color = "";
        if (text === "RELEASED") {
          color = "green";
        } else if (text === "VERIFIED") {
          color = "gray";
        }
        return <span style={{ color, fontWeight: "bold" }}>{text}</span>;
      },
    },
    {
      title: "Loan Status",
      dataIndex: "loanStatus",
      align: "center" as const,
      render: (text: string) => {
        let color = "";
        if (text === "PENDING") {
          color = "gray";
        } else if (text === "APPROVED") {
          color = "green";
        } else if (text === "COMPLETE") {
          color = "blue";
        } else if (text === "REJECTED") {
          color = "red";
        }
        return <span style={{ color, fontWeight: "bold" }}>{text}</span>;
      },
    },
    {
      title: "View",
      dataIndex: "customerId",
      align: "center" as const,
      render: (_: number, record: Item) => {
        return (
          <div className="flex justify-center">
            <Typography.Link>
              <div
                onClick={() => handleNavigate(record.customerId)}
                className="flex justify-center w-20 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
              >
                View
              </div>
            </Typography.Link>
          </div>
        );
      },
    },
    {
      title: "Release",
      dataIndex: "customerId",
      align: "center" as const,
      render: (_: number, record: Item) => {
        const { status, loanStatus } = record;

        // Check if loanStatus is COMPLETE and status is not RELEASED
        const shouldShowReleaseButton =
          loanStatus === "COMPLETE" && status !== "RELEASED";

        return (
          <div className="flex justify-center">
            <Typography.Link>
              {shouldShowReleaseButton ? (
                <div
                  onClick={() =>
                    handleOpenReleaseModal(record.loanId, record.customerId)
                  }
                  className="flex justify-center w-20 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
                >
                  Release
                </div>
              ) : (
                <div>-</div>
              )}
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "netValue" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  // get all Collateral details
  useEffect(() => {
    const getCollateralData = async () => {
      const token = localStorage.getItem("token") || "";

      try {
        const response = await getAllCollateral(token);

        // Sort data in descending order by collateralId before setting state
        const sortedData = response
          .map((item: { id: number }, index: number) => ({
            ...item,
            id: index + 1,
          }))
          .sort(
            (a: Item, b: Item) =>
              parseInt(b.collateralId) - parseInt(a.collateralId)
          );

        setCollateralData(sortedData);
      } catch (error) {
        console.log(error);
      }
    };

    getCollateralData();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  // Collateral status options
  const collateralStatus = ["All", "VERIFIED", "RELEASED"];

  // Filter data based on selected status
  const filteredData = collateralData
    .filter(
      (collateral) =>
        selectedStatus === "All" || collateral.status === selectedStatus
    )
    .filter((collateral) => {
      const searchValue = searchText.toLowerCase();
      const collateralName = collateral.collateralName.toLowerCase();
      const collDescription = collateral.description.toLowerCase()
        ? collateral.description.toLowerCase()
        : "";
      return (
        collateralName.includes(searchValue) ||
        collDescription.includes(searchValue)
      );
    });

  // Calculate totals for net value
  const totalNetValue = filteredData.reduce(
    (sum, item) => sum + item.netValue,
    0
  );

  const updateCollateralData = (loanId: number) => {
    setCollateralData((prevData) =>
      prevData.map((item) =>
        item.loanId === loanId ? { ...item, status: "RELEASED" } : item
      )
    );
  };

  // Footer component for totals
  const footer = () => {
    return (
      <div className="space-y-1">
        {/* Net Value Total */}
        <div className="xxs:flex w-[100%] max-w-96 justify-between">
          <div className="xxs:w-44">
            <Description content="Net Value Total" fontWeight="font-bold" />
          </div>
          <Description content={`Rs. ${totalNetValue.toFixed(2)}`} />
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* Filter collateral by status */}
      <div className="justify-between gap-2 xxs:flex">
        <Dropdown
          label="Collateral Status"
          fontSize="text-h9"
          fontColor={"text-gray-400"}
          options={collateralStatus}
          selectedOption={selectedStatus}
          onOptionSelect={(option: string) => setSelectedStatus(option)}
          placeholder="Collateral Status"
          width="w-[100%] xxs:max-w-60"
        />

        {/* filter guarantors */}
        <Input
          id={"filterGuarantee"}
          name={"filterGuarantee"}
          placeholder="Search"
          value={searchText}
          onChange={handleSearchChange}
          width="w-[100%] xxs:max-w-60  xxs:mt-[15px]"
        />
      </div>
      {/* Table */}
      <Form form={form} component={false}>
        <Table
          scroll={{ x: "max-content" }} // Ensure the table scrolls horizontally if needed
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData} // Use filtered data
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
          className="font-semibold"
          footer={footer}
          locale={{
            emptyText: <Empty description={<span>No data available</span>} />,
          }}
        />
      </Form>

      <CollateralImageOverviewModal
        isOpen={isOpenImageModal}
        onClose={handleCloseImageModal}
        imageUrl={imageUrl}
        disableUpload={true}
      />

      {/* open colletarel release modal  */}
      <CollateralReleaseModal
        visible={isOpenCollateralReleaseModal}
        onClose={handleCloseReleaseModal}
        loanId={selectedLoanId || 0}
        customerId={selectedCustomerId || 0}
        onUpdateCollateralData={updateCollateralData} // Pass the update function
      />
    </div>
  );
};

export default A_AllCollateralTable;
