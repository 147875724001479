import React from "react";
import { Button, Main, Title } from "../../common";
import { A_DashboardTable, A_ReleaseFundsTable } from "../../Tables";
import { useNavigate } from "react-router-dom";

const A_Dashboard: React.FC = () => {
  const navigate = useNavigate(); // Hook to navigate between routes

  // buttons labels and onclick data
  const buttonsData = [
    {
      text: "Schedule List",
      onClick: () => navigate("/schedule-list"),
    },
    {
      text: "Employee list",
      onClick: () => navigate("/employee-list"),
    },
    { text: "Customer List", onClick: () => navigate("/customer-list") },
    { text: "All Loans", onClick: () => navigate("/loan-list") },
    { text: "Payment Reports", onClick: () => navigate("/payment-list") },
    {
      text: " System settings",
      onClick: () => navigate("/system-settings"),
    },
  ];

  return (
    <div>
      {/* main container  */}
      <Main>
        {/* main title  */}
        <Title title="Quick Actions" bgStyle={false} />
        {/*quick actions buttons  */}
        <div className="grid gap-5 sm:gap-10 sm:grid-cols-2 lg:grid-cols-3 w-[90%] mx-auto">
          {buttonsData.map((button, index) => (
            <Button
              key={index}
              text={button.text}
              width="w-[100%]"
              onClick={button.onClick}
            />
          ))}
        </div>
        <div className="mt-10">
          {/* table title  */}
          <Title title={"To be Approved Loans"} bgStyle={false} />

          {/* table  */}
          <A_DashboardTable />
        </div>

        <div>
          {/* table  */}
          <A_ReleaseFundsTable />
        </div>
      </Main>
    </div>
  );
};

export default A_Dashboard;
