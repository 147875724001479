import React, { useState } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { Button, Description, Input, PrimaryTitle } from "../../common";
import { z } from "zod";
import { contactInfoSchema } from "../../../lib/validation/forgetpassword-val";
import { getOtpByEmail } from "../../../api/auth";

interface Step1Props {
  setSelect: React.Dispatch<React.SetStateAction<number>>;
  setContactInfo: (info: string) => void;
}

const Step1: React.FC<Step1Props> = ({ setSelect, setContactInfo }) => {
  const [contactInfo, setLocalContactInfo] = useState("");
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    setSubmitted(true);
    try {
      // Validate the contact information
      contactInfoSchema.parse({ contactInfo });
      setError(""); // Clear any existing errors
      setLoading(true); // Start loading

      // Attempt to get the OTP by email
      const response = await forgetPassGetOtpByemail();

      // If the response is successful, proceed to the next step
      if (response && response.success) {
        setContactInfo(contactInfo); // Pass contactInfo to parent component
        setSelect(2);
      } else {
        // Handle error cases, such as when the OTP request fails
        setError(response.response.data.message);
      }
    } catch (e) {
      if (e instanceof z.ZodError) {
        setError(e.errors[0]?.message || "Invalid input");
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocalContactInfo(value);

    if (submitted) {
      try {
        contactInfoSchema.parse({ contactInfo: value });
        setError("");
      } catch (e) {
        if (e instanceof z.ZodError) {
          setError(e.errors[0]?.message || "Invalid input");
        }
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleNext();
    }
  };

  const forgetPassGetOtpByemail = async () => {
    const email = contactInfo;
    try {
      const response = await getOtpByEmail(email);
      return response;
    } catch (error) {
      return { success: false };
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center py-5 text-center"
      onKeyDown={handleKeyDown}
    >
      <PrimaryTitle
        text="Reset Your Password"
        center
        fontColor="primaryColor-500"
        fontWeight="font-semibold"
      />
      <div className="space-y-3">
        <Description
          fontWeight="font-medium"
          content="Please enter your email address or phone number"
          center
        />
        <div className="w-full">
          <Input
            placeholder="Email Address or Phone Number"
            frontIcon={<HiOutlineMail />}
            id="contact-info"
            name="contact-info"
            value={contactInfo}
            onChange={handleChange}
            errorMessage={error}
          />
        </div>
      </div>
      <Button
        onClick={handleNext}
        text="Next"
        bgColor="bg-primaryColor-500"
        disabled={loading}
        isLoading={loading}
      />
    </div>
  );
};

export default Step1;
