import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import PrimaryTitle from "../text/PrimaryTitle";
import Description from "../text/Description";
import Button from "../buttons/Button";
import {
  getCollectorsByManagerId,
  getUserByUserId,
} from "../../../api/employee";
import Dropdown from "../inputs/Dropdown";

interface Collector {
  collectorId: number;
  username: string;
  contactNumber: string;
}

const AssignResponsModalManager: React.FC<{
  visible: boolean;
  icon?: React.ReactNode;
  onClose?: () => void;
  mainTitle: string;
  content: string;
  primaryBtnLabel?: string;
  secondaryBtnLabel?: string;
  primaryOnClick?: (selectedCollectorId: number) => void;
  secondaryOnClick?: () => void;
  closeIcon?: React.ReactNode;
  managerId: number;
  collectorId: number;
}> = ({
  visible,
  onClose,
  icon,
  mainTitle,
  primaryBtnLabel = "primary button",
  secondaryBtnLabel,
  primaryOnClick,
  secondaryOnClick,
  closeIcon,
  managerId,
  collectorId,
}) => {
  //save name
  const [collector, setCollector] = useState<string>("");

  //save list
  const [collectors, setCollectors] = useState<Collector[]>([]);
  //dropdown selector
  const [selectedCollector, setSelectedCollector] = useState<string | null>(
    null
  );
  const [errors, setErrors] = useState<string>("");

  const [selectedcollectorId, setCollectorId] = useState<number | null>(null);

  useEffect(() => {
    const getUserDetails = async () => {
      const token = localStorage.getItem("token") || "";
      const usermanagerId = managerId;
      const usercollectorId = collectorId;
      if (usermanagerId > 0 && usercollectorId > 0) {
        try {
          const responseCollector = await getUserByUserId(
            token,
            usercollectorId
          );
          if (responseCollector) {
            setCollector(responseCollector.data.firstName || "");
          } else {
            console.log("No response data for collector");
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    getUserDetails();
  }, [managerId, collectorId]);

  // get Collectors list when modal is open
  useEffect(() => {
    const usermanagerId = managerId;
    const usercollectorId = collectorId;

    if (usermanagerId > 0 && usercollectorId === 0) {
      const fetchCollectors = async () => {
        const token = localStorage.getItem("token") || "";

        try {
          const response = await getCollectorsByManagerId(usermanagerId, token);
          if (response) {
            setCollectors(response);
          } else {
            console.log("No response data for collectors");
            setErrors("No response data for collectors");
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchCollectors();
    }
  }, [managerId, collectorId]);

  //save selected collector
  const handleCollectorSelect = (selectedOption: string) => {
    setSelectedCollector(selectedOption);
    setCollectorId(0);
    const CollectorId = parseInt(selectedOption.split(". ")[0], 10);
    const selectedCollector = collectors.find(
      (collector) => collector.collectorId === CollectorId
    );
    setErrors("");
    if (selectedCollector) {
      setErrors("");
      setCollectorId(selectedCollector.collectorId);
    }
  };

  const handlePrimaryClick = () => {
    const usermanagerId = managerId;
    const usercollectorId = collectorId;

    if (usermanagerId > 0 && usercollectorId === 0) {
      if (primaryOnClick && selectedcollectorId !== null) {
        primaryOnClick(selectedcollectorId);
      } else {
        if (primaryOnClick) {
          primaryOnClick(0);
        }
      }
    } else {
      if (primaryOnClick) {
        primaryOnClick(0);
      }
    }
  };

  const handleSecondaryClick = () => {
    // Clear the selected collector and any errors
    setSelectedCollector(null);
    setCollectorId(null);
    setErrors("");

    // If a secondaryOnClick prop is provided, call it
    if (secondaryOnClick) {
      secondaryOnClick();
    }
  };

  const clearDropdowns = () => {
    setSelectedCollector(null);
    setCollectors([]);
    setErrors("");
  };

  const handleClose = () => {
    clearDropdowns();
    if (onClose) onClose();
  };

  return (
    <Modal
      open={visible}
      onCancel={handleClose}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="custom-modal"
    >
      <div className="space-y-5 font-poppins">
        <div className="text-h2">{icon}</div>

        {/* main title  */}
        <div className="flex justify-center text-center">
          <PrimaryTitle
            text={mainTitle}
            fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
            center
          />
        </div>

        {managerId > 0 && collectorId > 0 ? (
          <div className="flex justify-center gap-5 text-center md:gap-8">
            <div className="max-xxs:text-center">
              <Description content={`Collector : ${collector}`} center />
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Dropdown
                options={[
                  "None",
                  ...collectors.map(
                    (collector) =>
                      `${collector.collectorId}. ${collector.username}`
                  ),
                ]}
                selectedOption={selectedCollector ? selectedCollector : ""}
                onOptionSelect={handleCollectorSelect}
                placeholder="Select Collector"
                errorMessage={errors}
                width="w-[100%]"
              />
            </div>
          </div>
        )}

        <div className="justify-center gap-4 xxs:flex ">
          <div className="flex justify-center">
            <Button
              text={primaryBtnLabel}
              width="min-w-40"
              onClick={handlePrimaryClick}
            />
          </div>

          {secondaryBtnLabel && (
            <div className="flex justify-center max-xxs:mt-3">
              <Button
                text={secondaryBtnLabel}
                variant="secondary"
                width="min-w-40"
                onClick={handleSecondaryClick}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AssignResponsModalManager;
