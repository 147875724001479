import React, { forwardRef, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { IoMdCloseCircle } from "react-icons/io";
import { FaSpinner } from "react-icons/fa"; // Importing a spinner icon for the loading animation

interface UploadImageInputProps {
  id: string;
  name: string;
  text: string;
  image?: string;
  type?: string;
  errorMessage?: string;
  placeholder: string;
  onFileSelect?: (file: File | null) => void;
  selectImage?: string | null;
  isLoading?: boolean; // New prop to indicate loading state
}

const UploadImageInput = forwardRef<HTMLInputElement, UploadImageInputProps>(
  (
    {
      name,
      text,
      image = "/images/imageUpload.png",
      errorMessage,
      onFileSelect,
      selectImage,
      isLoading = false, // Default loading state to false
    },
    ref
  ) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewImage, setPreviewImage] = useState<string>("");

    // Use ref to access the file input element
    const fileInputRef = useRef<HTMLInputElement>(null);

    // Function to handle file removal
    const handleRemoveFile = () => {
      setSelectedFile(null);
      setPreviewImage("");
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input value
      }
      if (onFileSelect) {
        onFileSelect(null); // Notify the parent component that file has been removed
      }
    };

    // Monitor changes to `selectImage` and trigger `handleRemoveFile` if `selectImage` is cleared
    useEffect(() => {
      if (!selectImage) {
        handleRemoveFile();
      } else {
        setPreviewImage(selectImage);
      }
    }, [selectImage]);

    useEffect(() => {
      if (selectImage) {
        setPreviewImage(selectImage);
      }
    }, [selectImage]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) return;

      // Check if file type is not png or jpg
      if (
        !file.type.includes("image/png") &&
        !file.type.includes("image/jpeg") &&
        !file.type.includes("image/webp")
      ) {
        // Display toast and return
        toast.error("Please upload a PNG or JPEG file.");
        return;
      }

      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        setPreviewImage(base64String);
        if (onFileSelect) {
          onFileSelect(file);
        }
      };
      reader.readAsDataURL(file);
    };

    const handleFileInputChange = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

    return (
      <div>
        <div
          className={`border-2 mt-4 w-[100%] h-[150px] relative py-4 px-5 flex text-center items-center justify-center cursor-pointer border-[#8EBBB1] rounded-2xl flex-col ${
            isLoading ? "cursor-not-allowed" : ""
          }`}
          onClick={!isLoading ? handleFileInputChange : undefined} // Disable click when loading
          ref={ref}
        >
          <input
            type="file"
            id="fileInput"
            name={name}
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
            disabled={isLoading} // Disable input when loading
          />

          {isLoading ? (
            <div className="flex items-center justify-center">
              <FaSpinner className="animate-spin w-8 h-8 text-[#0A6C55]" />{" "}
              {/* Loading spinner */}
            </div>
          ) : previewImage ? (
            <>
              <div className="relative flex items-center justify-center">
                <img
                  src={previewImage}
                  className="w-[80px] h-[60px] md:w-[120px] md:h-[90px] xl:w-[140px] xl:h-[110px]"
                  alt="Uploaded"
                />
                <button
                  className="absolute -top-2 -right-2 flex items-center justify-center text-[#0A6C55]"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveFile();
                  }}
                >
                  <IoMdCloseCircle className="w-5 h-5" />
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center justify-center">
                <img
                  src={image}
                  className="w-[70px] h-[70px] md:w-[90px] md:h-[90px] xl:w-[110px] xl:h-[110px]"
                  alt="Upload"
                />
              </div>
              <span className="text-h7 font-semibold text-[#042D24]">
                {text}
              </span>
            </>
          )}
        </div>
        {/* Error message */}
        {errorMessage && (
          <div className="font-normal z-50 text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[1px]">
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

export default UploadImageInput;
