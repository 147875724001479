import { z } from "zod";

export const guaranterUpdateSchema = z.object({
  fullName: z
    .string()
    .min(3, "Must be at least 3 characters long")
    .nonempty("Required.")
    .regex(/^[A-Za-z\u0D80-\u0DFF\s]+$/, "Only letters are allowed"),
  address: z.string().nonempty("Required."),
  nic: z
    .string()
    .min(10, "Must be at least 10 characters long")
    .max(12, "Must be at most 12 characters long")
    .nonempty("Required."),
  guarantorPhoneNumber: z
    .string()
    .min(10, "Must be a valid 10-digit number")
    .max(10, "Must be a valid 10-digit number")
    .nonempty("Required."),
  gurantorImageURL: z.string().nonempty("Required."),
  frontImageURL: z.string().nonempty("Required."),
  backImageURL: z.string().nonempty("Required."),
  electricityBillOrWaterImageURL: z.string().nonempty("Required."),
});
