import axios from "axios";
import { apiOptionJson, BASE_URL } from "./base-url";
import { customerRegisterTypes, CustomerUpdateTypes } from "../types";

interface Guarantor {
  fullName?: string;
  address?: string;
  nic?: string;
  guarantorPhoneNumber?: string;
  verificationMethod?: string;
  frontImageURL?: string;
  backImageURL?: string;
  electricityBillOrWaterImageURL?: string;
  signatureImageURL?: string;
}

interface Collateral {
  collateralName: string;
  netValue: string;
  description: string;
  imgUrl: string[];
}

interface Payload {
  fullName: string;
  email: string;
  whatsAppNumber: string;
  phoneNumber: string;
  optionalContactNumber: string;
  address: string;
  nic: string;
  policeStation: string;
  gramaDivision: string;
  provincialOffice: string;
  verificationMethod: string;
  frontImageURL: string;
  backImageURL: string;
  customerImageURL: string;
  electricityBillImageURL: string;
  companyName: string;
  occupation: string;
  companyAddress: string;
  netMonthlySalary: string;
  signatureImageURL: string;
  guarantors: Guarantor[];
  loanAmount: number;
  loanDuration: string;
  repaymentFrequency: string;
  collaterals?: Collateral[]; // Optional collaterals property
}

interface customerValidateTypes {
  customerEmail: string;
  customerNIC: string;
  guarantorNIC: string;
}

// get all customers
export const allCustomers = async (token: string, userId: number) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v4/customers/user/${userId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// register customer
export const customerRegistration = async (
  requestBody: customerRegisterTypes,
  token: string
) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/api/v4/customers/save`,
      requestBody,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

// register customer
export const customerRegister = async (data: Payload, token: string) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/api/v4/customers/save`,

      data, // Pass the data directly as the payload
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

// get customers by collector ID
export const getCustomerByCollectorId = async (
  userId: number,
  token: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/collectors/${userId}/customers`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get selected customers for review
export const getCustomerforLoanReview = async (
  customerId: number,
  token: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v4/customers/${customerId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// update customer details
export const updateCustomer = async (
  token: string,
  customerId: number,
  updatedData: CustomerUpdateTypes
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/api/v4/customers/${customerId}`,
      updatedData,
      {
        ...apiOptionJson.headers,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

//validate before register customer
export const customerValidate = async (
  data: customerValidateTypes,
  token: string
) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/api/v4/customers/validate`,
      data,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

// get customer details by customer nic
export const getCustomerByNic = async (token: string, nic: string) => {
  try {
    const resposne = await axios.get(
      `${BASE_URL}/api/v4/customers/by-nic?nic=${nic}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resposne.data;
  } catch (error) {
    return error;
  }
};
