import React, { useEffect, useState } from "react";
import C_CustomerUpdateOverviewPage from "../customer-update-overview/C_CustomerUpdateOverviewPage";
import { Button, Main, PrimaryModal, Title } from "../../common";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import AssignResponsModalManager from "../../common/modals/AssignResponsModalManager";
import {
  approvedLoanByManagerId,
  approvedLoanByManagerWithAssignCollector,
  rejectLoan,
} from "../../../api/loan";
import RejectLoanModal from "../../common/modals/RejectLoanModal";
import { useNavigate } from "react-router-dom";
import { getCustomerforLoanReview } from "../../../api/customer";
import toast from "react-hot-toast";

interface A_CustomerOverviewPageProps {
  customerId?: number;
  saveLoanId: string;
  setSaveLoanId: React.Dispatch<React.SetStateAction<string>>;
  managerAppUserId: number;
  userDetails: {
    collectorAppUserId: number;
    managerAppUserId: { userId: number };
  };
}

const M_CustomerOverviewPage: React.FC<A_CustomerOverviewPageProps> = ({
  customerId,
  saveLoanId,
  userDetails,
  setSaveLoanId,
}) => {
  const navigate = useNavigate();
  const [_loanId, setLoanId] = useState<number | null>(null);

  const [isApproveModalVisible, setIsApproveModalVisible] =
    React.useState<boolean>(false);
  const [isRejectModalVisible, setIsRejectModalVisible] =
    React.useState<boolean>(false);
  const [isAddResponseModalVisible, setIsAddResponseModalVisible] =
    React.useState<boolean>(false);

  useEffect(() => {
    const fetchCustomerData = async () => {
      const token = localStorage.getItem("token") || "";
      if (customerId) {
        try {
          const response = await getCustomerforLoanReview(customerId, token);
          if (response.success && response.data?.loan.length > 0) {
            const customerLoan = response.data.loan[0];
            setLoanId(customerLoan.loanId);
            setSaveLoanId(customerLoan.loanId.toString());
          } else {
            toast.error("Loan data could not be retrieved.");
          }
        } catch (error) {
          toast.error("Failed to fetch customer data.");
          console.error(error);
        }
      }
    };
    fetchCustomerData();
  }, [customerId, saveLoanId]);

  const handleCloseApproveModal = () => {
    setIsApproveModalVisible(false);
  };

  const handleShowSuccessModal = () => {
    setIsApproveModalVisible(true);
  };

  const handleOpenRejectModalModal = () => {
    setIsRejectModalVisible(true);
  };

  const handleCloseRejectModalModal = () => {
    setIsRejectModalVisible(false);
  };

  const handleCloseResponseModalModal = () => {
    setIsAddResponseModalVisible(false);
  };

  const handleShowResponseModalModal = () => {
    setIsAddResponseModalVisible(true);
    setIsApproveModalVisible(false);
  };

  const handleApproveLoan = async (selectedCollectorId: number) => {
    const token = localStorage.getItem("token") || "";
    const loanId = saveLoanId ? parseInt(saveLoanId) : 0;
    if (isNaN(loanId)) {
      return;
    }

    const usermanagerId = userDetails.managerAppUserId;
    const usercollectorId = userDetails.collectorAppUserId;

    if (usermanagerId.userId > 0 && usercollectorId === 0) {
      try {
        let response;

        if (selectedCollectorId > 0) {
          response = await approvedLoanByManagerWithAssignCollector(
            loanId,
            usermanagerId.userId,
            selectedCollectorId,
            token
          );
        } else {
          response = await approvedLoanByManagerId(
            loanId,
            usermanagerId.userId,
            token
          );
        }

        if (response.success) {
          toast.success("Successfully approved");
          navigate("/dashboard");
        } else {
          console.log(response.response.data.message);
          toast.error(response.response.data.message);
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    } else {
      ApproveLoan();
    }
  };
  const handleRejectLoan = async (content: string) => {
    const token = localStorage.getItem("token") || "";
    const loanId = parseInt(saveLoanId);
    const userId = Number(localStorage.getItem("userId")) || 0;

    const reason = {
      reason: content,
      userId: userId,
    };

    try {
      const response = await rejectLoan(loanId, reason, token);

      if (response.success) {
        toast.success("Successfully rejected the loan");
        navigate("/dashboard");
        console.log(reason);
      } else {
        console.log("reject", response.response.data.message);
        toast.error(response.response.data.message);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const ApproveLoan = async () => {
    try {
      const token = localStorage.getItem("token") || "";
      const loanId = parseInt(saveLoanId);
      const usermanagerId = userDetails.managerAppUserId.userId;

      const response = await approvedLoanByManagerId(
        loanId,
        usermanagerId,
        token
      );

      if (response.success) {
        console.log("approve sucess");
        toast.success("Successfuly approved");
        navigate("/dashboard");
      } else {
        console.log(response.response.data.message);

        toast.error(response.response.data.message);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  return (
    <div className="w-xl">
      {/* main title  */}
      <Title title={"ණය ගැනුම්කරු පිලිබඳ විස්තර"} />

      <Main>
        <C_CustomerUpdateOverviewPage customerId={customerId || 0} />
        <div className="flex flex-row items-center justify-center gap-5 mt-5 md:gap-14 ms:flex-row ">
          <Button
            text="Approve"
            onClick={handleShowSuccessModal}
            width={"w-28 md:w-40"}
          />
          <Button
            text="Reject"
            onClick={handleOpenRejectModalModal}
            variant="secondary"
            width={"w-28 md:w-40"}
          />
        </div>
      </Main>

      <PrimaryModal
        icon={
          <IoMdCheckmarkCircleOutline className="mx-auto text-center text-sky-700" />
        }
        visible={isApproveModalVisible}
        onClose={() => {}}
        mainTitle="Do you want to approve this loan?"
        content=""
        primaryBtnLabel="Yes"
        primaryOnClick={handleShowResponseModalModal}
        closeIcon={null}
        secondaryBtnLabel="No"
        secondaryOnClick={handleCloseApproveModal}
      />
      <AssignResponsModalManager
        icon={
          <IoMdCheckmarkCircleOutline className="mx-auto text-center text-sky-700" />
        }
        visible={isAddResponseModalVisible}
        onClose={() => {}}
        mainTitle="Add collector"
        content=""
        primaryBtnLabel="Approve"
        collectorId={
          userDetails.collectorAppUserId ? userDetails.collectorAppUserId : 0
        }
        managerId={
          userDetails.managerAppUserId.userId
            ? userDetails.managerAppUserId.userId
            : 0
        }
        primaryOnClick={handleApproveLoan}
        closeIcon={null}
        secondaryBtnLabel="Back"
        secondaryOnClick={handleCloseResponseModalModal}
      />
      <RejectLoanModal
        icon={
          <IoMdCheckmarkCircleOutline className="mx-auto text-center text-sky-700" />
        }
        visible={isRejectModalVisible}
        onClose={() => {}}
        mainTitle="Loan Rejection"
        content=""
        primaryBtnLabel="Reject"
        primaryOnClick={handleRejectLoan}
        closeIcon={null}
        secondaryBtnLabel="Back"
        secondaryOnClick={handleCloseRejectModalModal}
      />
    </div>
  );
};

export default M_CustomerOverviewPage;
