import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import {
  CustomerRegister,
  Dashboard,
  Login,
  UserRegister,
  UserOverview,
  CustomerList,
  EmployeeList,
  CustomerRegisterOverview,
  AddPayment,
  PaymentList,
  ConsoleLog,
  GuaranteeList,
  CollateralList,
  LoanList,
  ScheduleList,
  AnaliticsDasboard,
  ToBeApproveCusOverview,
  ReleaseFunds,
  CustomerUpdateOverview,
  SystemSettings,
  AssignEmployee,
  CalenderShedules,
  ApproveRelease,
} from "../pages";
import RouteWatcher from "./RouteWatcher";
import Blacklist from "../pages/Blacklist";

const MainRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <RouteWatcher>
        <Routes>
          {/* Default route for login */}
          <Route path="/" element={<Login />} />

          {/* Route with MainLayout as the default layout */}
          <Route element={<MainLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="user-register" element={<UserRegister />} />
            <Route path="customer-register" element={<CustomerRegister />} />
            <Route
              path="customer-register-overview"
              element={<CustomerRegisterOverview />}
            />
            <Route path="user-overview/:userId" element={<UserOverview />} />
            <Route path="customer-list" element={<CustomerList />} />
            <Route path="employee-list" element={<EmployeeList />} />
            <Route path="add-payment" element={<AddPayment />} />
            <Route path="payment-list" element={<PaymentList />} />
            <Route path="console-log" element={<ConsoleLog />} />
            <Route path="guarantee-list" element={<GuaranteeList />} />
            <Route path="collateral-list" element={<CollateralList />} />
            <Route path="loan-list" element={<LoanList />} />
            <Route path="schedule-list" element={<ScheduleList />} />
            <Route path="analitics-dashboard" element={<AnaliticsDasboard />} />
            <Route path="release-funds" element={<ReleaseFunds />} />
            <Route
              path="approve-overview/:userId"
              element={<ToBeApproveCusOverview />}
            />
            <Route
              path="customer-update-overview/:userId"
              element={<CustomerUpdateOverview />}
            />
            <Route path="system-settings" element={<SystemSettings />} />
            <Route path="schedule-calender" element={<CalenderShedules />} />
            <Route path="assign-employee" element={<AssignEmployee />} />
            <Route path="blacklist" element={<Blacklist />} />
            <Route path="approve-release" element={<ApproveRelease />} />
          </Route>
        </Routes>
      </RouteWatcher>
    </BrowserRouter>
  );
};

export default MainRouter;
