import React from "react";
import { Main, Title } from "../../common";
import { A_AllCollateralTable } from "../../Tables";

const A_AllCollateralPage: React.FC = () => {
  return (
    <div>
      {/* main container  */}
      <Main>
        {/* title  */}
        <Title title="Collateral List" bgStyle={false} />
        {/* All Collateral Table */}
        <A_AllCollateralTable />
      </Main>
    </div>
  );
};

export default A_AllCollateralPage;
