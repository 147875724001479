import React, { useState } from "react";
import { Step1, Step2, Step3, Step4, Step5 } from "./step";
import { Main, StepButton, Title } from "../../common";

const CustomerRegisterPage: React.FC = () => {
  const [select, setSelect] = useState(1);

  // handle back function
  const handleBackButtonClick = () => {
    if (select > 1) {
      setSelect(select - 1);
    }
  };

  return (
    <div>
      <Title title={"ණය ගැනුම්කරු ලියාපදිංචි කිරීම"} />
      <div className="relative flex items-center justify-between w-[80%] sm:w-[90%] md:w-[70%] mx-auto mt-10 ">
        {/* horizontal line  */}
        <div className="absolute -z-[0] left-0 w-full flex justify-center">
          <div className="w-[100%] h-[1px] bg-primaryColor-200" />
        </div>
        {/* steps */}
        <StepButton
          step={1}
          label="පළමු පිටුව"
          currentStep={select}
          onClick={handleBackButtonClick}
          showBackButton={select > 1}
        />
        <StepButton step={2} currentStep={select} label="දෙවන පිටුව" />
        <StepButton step={3} currentStep={select} label="තෙවන පිටුව" />
        <StepButton step={4} currentStep={select} label="හතරවන පිටුව" />
        <StepButton step={5} currentStep={select} label="පස්වන පිටුව" />
      </div>

      {/* content */}
      {select === 1 ? (
        <Main>
          <Step1 setSelect={setSelect} />
        </Main>
      ) : select === 2 ? (
        <Main>
          <Step2 setSelect={setSelect} />
        </Main>
      ) : select === 3 ? (
        <Main>
          <Step3 setSelect={setSelect} />
        </Main>
      ) : select === 4 ? (
        <Step4 setSelect={setSelect} />
      ) : select === 5 ? (
        <Main>
          <Step5 setSelect={setSelect} />
        </Main>
      ) : null}
    </div>
  );
};

export default CustomerRegisterPage;
