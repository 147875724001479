import React, { useEffect, useState } from "react";
import { Table } from "antd";
import type { TableColumnsType, TableProps } from "antd";
import {
  AssignCollectorManagerModal,
  Button,
  Description,
  Dropdown,
} from "../../../common";
import "./assignemployee.css";
import {
  getCustomersByUnAssignMancoll,
  getUnassignColectorByManagerId,
  getUnassignManagers,
} from "../../../../api/employee";

type TableRowSelection<T extends object = object> =
  TableProps<T>["rowSelection"];

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

interface Item {
  collectorAppUserId: number;
  customerAddress: string;
  customerEmail: string;
  customerFullName: string;
  customerId: number;
  customerPhoneNumber: string;
  managerAppUserId: number;
  nic: string;
}

interface Manager {
  managerId: number;
  appUserId: number;
  username: string;
}

interface collector {
  collectorId: number;
  appUserId: number;
  username: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: " Customer Name",
    dataIndex: "customerName",
    align: "center" as const,
  },
  {
    title: "Customer NIC",
    dataIndex: "customerNIC",
    align: "center" as const,
  },
  {
    title: "Contact Number",
    dataIndex: "customerContact",
    align: "center" as const,
  },
  {
    title: "Address",
    dataIndex: "address",
    align: "center" as const,
  },
  {
    title: "Email Address",
    dataIndex: "emailAddress",
    align: "center" as const,
  },
];

const AssignCollectorManager: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenManagerAssign, setIsOpenManagerAssign] = useState(false);
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [managers, setManagers] = useState<Manager[]>([]);
  const [selectedManager, setSelectedManager] = useState<Manager | null>(null);
  const [selectedManagerId, setSelectedManagerId] = useState(0);
  const [collector, setCollector] = useState<collector[]>([]);
  const [selectedCollector, setSelectedCollector] = useState<collector | null>(
    null
  );
  const [selectedCollectorId, setSelectedCollectorId] = useState<number | null>(
    null
  );

  // Handle open assign manager modal
  const handleOpenAssignManager = () => {
    setIsOpenManagerAssign(true);
  };
  const handleCloseAssignmanager = () => {
    setIsOpenManagerAssign(false);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            return index % 2 === 0;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            return index % 2 !== 0;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  // handle get Customers By UnAssign Managers and  collectors

  const handlegetCustomersByUnAssignMancoll = async () => {
    const token = localStorage.getItem("token") || "";

    try {
      const response = await getCustomersByUnAssignMancoll(token);

      // Filter customers based on selectedManagerId and selectedCollectorId
      const filteredData = response.filter((item: Item) => {
        const matchesManager =
          !selectedManagerId || item.managerAppUserId === selectedManagerId;
        const matchesCollector =
          !selectedCollectorId ||
          item.collectorAppUserId === selectedCollectorId;
        return matchesManager && matchesCollector;
      });

      const mappedData = filteredData.map((item: Item) => ({
        key: item.customerId,
        customerContact: item.customerPhoneNumber,
        emailAddress: item.customerEmail,
        address: item.customerAddress,
        customerNIC: item.nic,
        customerName: item.customerFullName,
      }));

      setTableData(mappedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handlegetCustomersByUnAssignMancoll();
  }, [selectedManagerId, selectedCollectorId]);

  // Fetch unassign managers
  useEffect(() => {
    const getAllUnassignManagers = async () => {
      const token = localStorage.getItem("token") || "";

      try {
        const response = await getUnassignManagers(token);
        setManagers(response);
      } catch (error) {
        console.log(error);
      }
    };

    getAllUnassignManagers();
  }, []);

  // fetch unassign collectors
  useEffect(() => {
    const getAllUnassignCollector = async () => {
      try {
        const managerId = selectedManagerId;
        const token = localStorage.getItem("token") || "";

        const response = await getUnassignColectorByManagerId(managerId, token);
        // Ensure response is an array
        setCollector(Array.isArray(response) ? response : []);
      } catch (error) {
        console.log(error);
        setCollector([]);
      }
    };

    getAllUnassignCollector();
  }, [selectedManagerId]);

  // Handle manager selection
  const handleManagerSelect = (option: string) => {
    if (option === "All") {
      setSelectedManager(null);
      setSelectedManagerId(0);
      setSelectedCollector(null);
      setSelectedCollectorId(null);
      setSelectedRowKeys([]);
    } else {
      const selected = managers.find((manager) => manager.username === option);
      if (selected) {
        setSelectedManager(selected);
        setSelectedManagerId(selected.appUserId);
        setSelectedCollectorId(null);
        setSelectedCollector(null);
        setSelectedRowKeys([]);
      }
    }
  };

  // Handle collector selection
  const handleCollectorSelect = (option: string) => {
    if (option === "All") {
      setSelectedCollector(null); // Clear the selected collector
      setSelectedCollectorId(null); // Reset collector filter
    } else {
      const selected = collector.find(
        (collector) => collector.username === option
      );
      if (selected) {
        setSelectedCollector(selected);
        setSelectedCollectorId(selected.appUserId); // Set the selected collectorId
      }
    }
  };

  // handle cancel assign manager
  const handleCancelCollector = () => {
    setSelectedRowKeys([]);
  };

  const handleUpdateTableData = () => {
    handlegetCustomersByUnAssignMancoll(); // Call this function to refresh the table data
    setSelectedRowKeys([]);
  };

  return (
    <div>
      <div className="gap-5 xxs:flex">
        {/* Select manager */}
        <Dropdown
          label="Unassign Manager"
          placeholder="Select Manager"
          options={[
            "All",
            ...(Array.isArray(managers)
              ? managers.map((manager) => manager.username)
              : []),
          ]}
          selectedOption={selectedManager?.username || ""}
          onOptionSelect={handleManagerSelect}
          fontSize="text-h9"
          fontColor={"text-gray-400"}
          width="w-[100%] xxs:max-w-60"
        />
        {/* Select Collector */}
        <Dropdown
          label="Unassign Collector"
          placeholder="All"
          options={["All", ...collector.map((collector) => collector.username)]}
          selectedOption={selectedCollector?.username || ""}
          onOptionSelect={handleCollectorSelect}
          fontSize="text-h9"
          fontColor={"text-gray-400"}
          width="w-[100%] xxs:max-w-60"
          disabled={!selectedManager}
        />
      </div>

      <Table
        className="font-semibold custom-row-selection"
        scroll={{ x: "max-content" }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData} // Use the filtered table data
        locale={{
          emptyText: (
            <>
              <div className="py-5">
                <Description content={"No customers found "} center />
              </div>
            </>
          ),
        }}
      />

      {/* Assign button */}
      <div className="flex justify-center gap-5 mt-5 sm:justify-end">
        <div className={`${selectedRowKeys.length === 0 ? "hidden" : ""}`}>
          <Button
            text={"Cancel "}
            variant="secondary"
            onClick={handleCancelCollector}
          />
        </div>
        <Button
          text={"Assign"}
          onClick={handleOpenAssignManager}
          disabled={selectedRowKeys.length === 0}
        />
      </div>

      {/* Assign manager modal */}
      <AssignCollectorManagerModal
        visible={isOpenManagerAssign}
        onClose={handleCloseAssignmanager}
        selectedCustomersId={selectedRowKeys}
        onSuccess={handleUpdateTableData}
      />
    </div>
  );
};

export default AssignCollectorManager;
