import React from "react";

interface AssignEmployeeButtonProps {
  label: string;
  value: string;
  currentView: string;
  onClick: () => void;
}

const AssignEmployeeButton: React.FC<AssignEmployeeButtonProps> = ({
  label,
  value,
  currentView,
  onClick,
}) => {
  const isSelected = value === currentView;
  return (
    <button
      onClick={onClick}
      className={`px-4 py-[2px] rounded-lg font-semibold ${
        isSelected
          ? "bg-primaryColor-500 text-white"
          : "bg-transparent text-primaryColor-300"
      }`}
    >
      {label}
    </button>
  );
};

export default AssignEmployeeButton;
