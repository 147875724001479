import axios from "axios";
import { BASE_URL, apiOptionJson } from "./base-url";

export const getLeagalAction = async (
    token: string,
    userId: number,
    
  ) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/legal-actions/${userId}`,
        {
          headers: {
            ...apiOptionJson.headers,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
  export const removeCustomerFromLegalActions = async ( token: string,
    userId: string,) => {
    try {
      const response = await axios.delete(`${BASE_URL}/api/legal-actions/customer/${userId}`, {
        headers: {
            ...apiOptionJson.headers,
            Authorization: `Bearer ${token}`,
          'Accept': '*/*',
        },
        
      });
  
      return {
        data: response.data.data,
        message: response.data.message,
        success: response.data.success,
      };
    } catch (error) {
      console.error("Error removing customer from legal actions:", error);
      return {
        data: null,
        message: "Failed to remove customer from legal actions.",
        success: false,
      };
    }
  };