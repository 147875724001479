import React, { useEffect, useState } from "react";
import { Table, Typography, Form, Empty, Spin } from "antd";
import { getAllLoans, relaseFunds } from "../../../api/loan";
import type { TableProps } from "antd";
import toast from "react-hot-toast";
import { Title } from "../../common";
import {
  CashReleaseCusOverview,
  CustomerPassword,
  QrGenerateRelease,
} from "../../modal";
import ReleasedConformationModal from "../../common/modals/ReleasedConformationModal";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../../../lib/configs/firebaseConfig";
import dayjs, { Dayjs } from "dayjs";

interface Item {
  loanId: number;
  customerId: number;
  loanReleaseStatus: boolean;
  remainingAmount: number;
  amountCurrentlyPaid: number;
  loanInstallments: number;
  loanAmount: number;
  id: number;
  loanState: string;
  customerName: string;
  collectorName: string;
  paymentDate: string;
  dueAmount: number;
  paidAmount: number;
  balanceAmount: number;
  startDate: string;
  repaymentFrequency: string;
  customerAddress: string;
  loanDuration: string;
  customerImageURL: string;
  customerNIC: string;
  collectorAppUserId: number;
  managerAppUserId: number;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        />
      ) : (
        children
      )}
    </td>
  );
};

// Helper to upload a file to Firebase and return its download URL
const uploadImageToFirebase = async (imageUrl: string, imagePath: string) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const storageRef = ref(storage, imagePath);
    await uploadBytes(storageRef, blob);
    const downloadUrl = await getDownloadURL(storageRef);
    return downloadUrl;
  } catch (error) {
    console.error("Image upload failed:", error);
    throw error;
  }
};

// Helper to delete an image from Firebase
const deleteImageFromFirebase = async (imagePath: string) => {
  const storageRef = ref(storage, imagePath);
  try {
    await deleteObject(storageRef);
  } catch (error) {
    console.error(`Failed to delete image: ${imagePath}`, error);
  }
};

const A_ReleaseFundsTable = () => {
  const [form] = Form.useForm();
  const [customersData, setCustomersData] = useState<Item[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [releaseLoading, setReleaseLoading] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [overviewVisible, setOverviewVisible] = useState<boolean>(false);
  const [passwordModalOpen, setPassowrdModalOpen] = useState<boolean>(false);
  const [selectedLoanId, setSelectedLoanId] = useState<number | null>(null);
  const [SelectedCustomerId, setSelectedCustomerId] = useState<number | null>(
    null
  );
  const [assignCollectorId, setassignCollectorId] = useState<number | null>(
    null
  );
  const [assignManagerId, setassignManagerId] = useState<number | null>(null);
  const [qrModalVisible, setQrModalVisible] = useState<boolean>(false);
  const [yesLoading, setYesLoading] = useState(false);
  const [yesDisabled, setYesDisabled] = useState(false);
  const [customerPassword, setCustomerPassword] = useState<string>("");
  const [isSuccessRelease, setIsSuccessRelease] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

  // Function to handle date changes, keeping selectedDate as a Dayjs object
  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      // Get the current time with microseconds
      const currentTimeWithMicroseconds = dayjs().format("HH:mm:ss.SSSSSS");

      // Format the updatedDate with the desired format
      const updatedDate = dayjs(
        newDate.format("YYYY-MM-DD") + "T" + currentTimeWithMicroseconds
      );

      setSelectedDate(updatedDate); // Keep updatedDate as a Dayjs object
    }
  };

  // handle close wr modal and open passowrd modal
  const handleCloseQrOpenPassword = () => {
    setQrModalVisible(false);
    setPassowrdModalOpen(true);
  };

  const handleClosePasswordModal = () => {
    setPassowrdModalOpen(false);
    localStorage.removeItem("password");
  };

  const fetchData = async () => {
    const token = localStorage.getItem("token") || "";
    const Id = localStorage.getItem("userId") || "";
    const userId = parseInt(Id);
    setLoading(true);
    try {
      const response = await getAllLoans(userId, token);

      const sortedData = response.data.sort(
        (a: Item, b: Item) => b.loanId - a.loanId
      );
      setCustomersData(sortedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // handle release funds with loading and disabling button
  const handleReleaseFunds = async () => {
    setYesLoading(true);
    setYesDisabled(true);

    const customerSignImgUrl = localStorage.getItem("customerSignature");
    const employeeSignatureurl = localStorage.getItem("employeeSignature");
    const customerPassord = localStorage.getItem("password");
    const token = localStorage.getItem("token") || "";
    const managerIdString = localStorage.getItem("userId");

    setCustomerPassword(customerPassord || "");

    if (!managerIdString) {
      console.error("Manager ID is not found in localStorage");
      return;
    }

    const managerId = Number(managerIdString);
    if (isNaN(managerId)) {
      console.error("Manager ID is not a valid number");
      return;
    }

    if (selectedLoanId === null) {
      console.error("No loan selected for release");
      return;
    }

    if (!customerSignImgUrl || !employeeSignatureurl) {
      console.error("Signatures not found in localStorage");
      return;
    }

    setReleaseLoading(true);

    const customerSignaturePath = `signatures/customer/${selectedLoanId}-customer-signature.png`;
    const employeeSignaturePath = `signatures/employee/${selectedLoanId}-employee-signature.png`;

    try {
      // Upload customer signature
      const customerDownloadUrl = await uploadImageToFirebase(
        customerSignImgUrl,
        customerSignaturePath
      );

      // Upload employee signature
      const employeeDownloadUrl = await uploadImageToFirebase(
        employeeSignatureurl,
        employeeSignaturePath
      );

      const customerSignatureImgUrl = customerDownloadUrl;
      const employeeSignatureImgUrl = employeeDownloadUrl;
      const password = customerPassord || "";

      const response = await relaseFunds(
        selectedLoanId,
        managerId,
        token,
        customerSignatureImgUrl,
        employeeSignatureImgUrl,
        password,
        selectedDate.format("YYYY-MM-DDTHH:mm:ss.SSSSSS")
      );
      if (response.success) {
        setIsSuccessRelease(true);
        toast.success(response.message);
        setQrModalVisible(true);
      } else {
        await deleteImageFromFirebase(customerSignaturePath);
        await deleteImageFromFirebase(employeeSignaturePath);
        toast.error(response.message);
      }

      await fetchData();
    } catch (error) {
      console.error("Error during release:", error);

      await deleteImageFromFirebase(customerSignaturePath);
      await deleteImageFromFirebase(employeeSignaturePath);

      toast.error("Failed to release funds.");
    } finally {
      localStorage.removeItem("customerSignature");
      localStorage.removeItem("employeeSignature");
      setYesLoading(false); // Stop loading
      setYesDisabled(false); // Re-enable the button
      setReleaseLoading(false);
      setModalVisible(false); // Close modal
    }
  };

  const showReleaseModal = (
    loanId: number,
    customerId: number,
    collectorId: number,
    managerAppUserId: number
  ) => {
    setSelectedCustomerId(customerId);
    setSelectedLoanId(loanId);
    setOverviewVisible(true);
    setassignCollectorId(collectorId);
    setassignManagerId(managerAppUserId);
  };

  const handleOverviewClose = () => {
    setOverviewVisible(false);
    setSelectedLoanId(null);
  };

  const handlePrimaryModalClose = () => {
    setModalVisible(false);
  };

  const handleQRModalClose = () => {
    setQrModalVisible(false);
    setPassowrdModalOpen(true);
    setSelectedLoanId(null);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "customerId",
      fixed: "left" as const,
      align: "center" as const,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      align: "center" as const,
    },
    {
      title: "Collector Name",
      dataIndex: "collectorName",
      align: "center" as const,
    },
    {
      title: "Release Funds Status",
      dataIndex: "loanReleaseStatus",
      align: "center" as const,
      render: (text: boolean) => {
        const displayText = text ? "RELEASED" : "PENDING";
        const color = text ? "green" : "gray";
        return <span style={{ color, fontWeight: "bold" }}>{displayText}</span>;
      },
    },
    {
      title: "Loan Amount (Rs.)",
      dataIndex: "loanAmount",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Loan Status",
      dataIndex: "loanState",
      align: "center" as const,
    },
    {
      title: "Payment Plan",
      dataIndex: "repaymentFrequency",
      align: "center" as const,
    },
    {
      title: "Loan Duration",
      dataIndex: "loanDuration",
      align: "center" as const,
    },
    {
      title: "Loan Installments (Rs.)",
      dataIndex: "loanInstallments",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Release",
      dataIndex: "paymentId",
      render: (_: number, record: Item) => (
        <div className="flex justify-center">
          <Typography.Link>
            <div
              className="flex justify-center w-20 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
              onClick={() =>
                showReleaseModal(
                  record.loanId,
                  record.customerId,
                  record.collectorAppUserId,
                  record.managerAppUserId
                )
              }
            >
              {releaseLoading && selectedLoanId === record.loanId ? (
                <Spin />
              ) : (
                "Release"
              )}
            </div>
          </Typography.Link>
        </div>
      ),
      align: "center" as const,
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "netValue" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  const filteredData = (customersData || []).filter(
    (item) => !item.loanReleaseStatus && item.loanState === "APPROVED"
  );

  return (
    <div className={` mt-4`}>
      {/* table title */}
      <Title title={"To be Release Funds"} bgStyle={false} />
      <Form form={form} component={false}>
        <Table
          scroll={{ x: "max-content" }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData}
          columns={mergedColumns}
          rowClassName="editable-row"
          className="font-semibold"
          locale={{
            emptyText: <Empty description={<span>No data available</span>} />,
          }}
          loading={loading}
        />
      </Form>

      {/* cash release overview  */}
      <CashReleaseCusOverview
        visible={overviewVisible}
        onClose={handleOverviewClose}
        primaryBtnLabel="Release"
        primaryOnClick={() => {
          setOverviewVisible(false);
          setModalVisible(true);
          setIsSuccessRelease(false);
        }}
        secondaryOnClick={handleOverviewClose}
        userDetails={SelectedCustomerId || 0}
      />

      {/* confirm message  */}
      <ReleasedConformationModal
        visible={modalVisible}
        onClose={handlePrimaryModalClose}
        mainTitle="Confirm Release"
        content="Are you sure you want to release funds for this loan?"
        primaryBtnLabel="Yes"
        secondaryBtnLabel="No"
        primaryOnClick={handleReleaseFunds}
        secondaryOnClick={handlePrimaryModalClose}
        collectorId={assignCollectorId ? assignCollectorId : 0}
        managerId={assignManagerId ? assignManagerId : 0}
        loanId={selectedLoanId ? selectedLoanId : 0}
        yesDisabled={yesDisabled}
        yesLoading={yesLoading}
        isSuccessRelease={isSuccessRelease}
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
      />

      {/* qr generate */}
      <QrGenerateRelease
        visible={qrModalVisible}
        onClose={handleQRModalClose}
        customerId={selectedLoanId?.toString() || ""}
        customerName={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.customerName || ""
        }
        customerAddress={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.customerAddress || ""
        } // Pass the actual address here
        loanStartDate={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.startDate || ""
        }
        loneAmount={
          customersData
            .find((item) => item.loanId === selectedLoanId)
            ?.loanAmount.toString() || ""
        }
        numberOfInstallment={
          customersData
            .find((item) => item.loanId === selectedLoanId)
            ?.loanDuration.toString() || ""
        }
        paymentPlan={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.repaymentFrequency || ""
        }
        installmentAmount={
          customersData
            .find((item) => item.loanId === selectedLoanId)
            ?.loanInstallments.toString() || ""
        }
        imgUrl={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.customerImageURL || ""
        }
        customerNIC={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.customerNIC || ""
        }
        closeModal={handleCloseQrOpenPassword}
      />

      {/* customer password modal  */}
      <CustomerPassword
        visible={passwordModalOpen}
        onClose={handleClosePasswordModal}
        customerNIC={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.customerNIC || ""
        }
        customerUserNameValue={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.customerNIC || ""
        }
        closeModal={handleClosePasswordModal}
        customerPassword={customerPassword}
      />
    </div>
  );
};

export default A_ReleaseFundsTable;
