import React from "react";

interface TitleProps {
  title: string;
  textColor?: string;
  fontWeight?: string;
  fontSize?: string;
  fontFamily?: string;
  bgStyle?: boolean;
  className?: string;
}

const Title: React.FC<TitleProps> = ({
  title,
  textColor = "text-primaryColor-900",
  fontWeight = "font-bold",
  fontSize = " text-xl lg:text-2xl",
  fontFamily = "font-poppins",
  bgStyle = true,
  className,
}) => {
  return (
    <div>
      <div
        className={` mx-auto rounded-xl transition-all duration-300 ${
          bgStyle
            ? "w-[95%] mt-10 bg-white px-6 md:px-12 shadow-3xl py-3 lg:py-5"
            : " pb-3"
        }`}
      >
        <div
          className={`${fontFamily} ${fontWeight} ${fontSize} ${textColor} ${className}`}
        >
          {title}
        </div>
      </div>
    </div>
  );
};

export default Title;
