import React, { useEffect, useState } from "react";
import { Button, Description, Input, PrimaryTitle } from "../../common";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FiLock } from "react-icons/fi";
import { ZodError } from "zod";
import { passwordSchema } from "../../../lib/validation/forgetpassword-val";
import { resetPassword } from "../../../api/auth"; // Assuming this is the API function
import toast from "react-hot-toast";

interface Step3Props {
  setSelect: React.Dispatch<React.SetStateAction<number>>;
  onShowSuccessModal: () => void;
  onCloseModal: () => void;
  contactInfo: string; // Add contactInfo as a prop
}

interface PasswordErrors {
  newPassword?: string;
  confirmPassword?: string;
}

const Step3: React.FC<Step3Props> = ({
  onShowSuccessModal,
  contactInfo, // Receive contactInfo as a prop
}) => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errors, setErrors] = useState<PasswordErrors>({});
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [isFirstClick, setIsFirstClick] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const validatePasswords = (): boolean => {
    try {
      passwordSchema.parse({ newPassword, confirmPassword });
      setErrors({});
      return true;
    } catch (e) {
      if (e instanceof ZodError) {
        const errorMessages: PasswordErrors = e.errors.reduce((acc, curr) => {
          const field = curr.path[0] as keyof PasswordErrors;
          acc[field] = curr.message;
          return acc;
        }, {} as PasswordErrors);
        setErrors(errorMessages);
        return false;
      }
      return false;
    }
  };

  useEffect(() => {
    if (isFirstClick) {
      validatePasswords();
    }
  }, [newPassword, confirmPassword]);

  const handleResetPassword = async () => {
    setIsFirstClick(true);
    setSubmitted(true);
    const isValid = validatePasswords();

    if (isValid) {
      try {
        setLoading(true);
        const response = await resetPassword(contactInfo, newPassword);
        if (response.success) {
          onShowSuccessModal();
        } else {
          toast.error(response.response.data.message);
        }
      } catch (error) {
        setErrors({
          newPassword: "Failed to reset password. Please try again.",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "new-password") {
      setNewPassword(value);
    } else if (name === "confirm-password") {
      setConfirmPassword(value);
    }

    if (submitted) {
      validatePasswords();
    }
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleResetPassword();
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center p-4 py-5 text-center"
      onKeyDown={handleKeyDown}
    >
      <PrimaryTitle
        text="New Password"
        center
        fontColor="primaryColor-500"
        fontWeight="font-semibold"
      />
      <div className="space-y-3">
        <Description fontWeight="font-bold" content="Account Verified" center />
        <Description
          fontWeight="font-light"
          content="Set your new password"
          center
        />
        <div className="w-full">
          <Input
            frontIcon={<FiLock />}
            backIcon={
              showNewPassword ? (
                <AiOutlineEyeInvisible onClick={toggleNewPasswordVisibility} />
              ) : (
                <AiOutlineEye onClick={toggleNewPasswordVisibility} />
              )
            }
            id="new-password"
            name="new-password"
            type={showNewPassword ? "text" : "password"}
            placeholder="New Password"
            width="w-[300px] sm:w-[450px]"
            value={newPassword}
            onChange={handleChange}
            errorMessage={errors.newPassword}
          />
        </div>
        <div className="w-full">
          <Input
            frontIcon={<FiLock />}
            backIcon={
              showConfirmPassword ? (
                <AiOutlineEyeInvisible
                  onClick={toggleConfirmPasswordVisibility}
                />
              ) : (
                <AiOutlineEye onClick={toggleConfirmPasswordVisibility} />
              )
            }
            id="confirm-password"
            name="confirm-password"
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password"
            width="w-[300px] sm:w-[450px]"
            value={confirmPassword}
            onChange={handleChange}
            errorMessage={errors.confirmPassword}
          />
        </div>
      </div>
      <Button
        text="Reset Password"
        bgColor="bg-primaryColor-500"
        onClick={handleResetPassword}
        disabled={loading}
        isLoading={loading}
      />
    </div>
  );
};

export default Step3;
