import React from "react";
import { Main, Title } from "../../common";
import { C_AllCustomersTable } from "../../Tables";

interface C_CustomerListPageProp {
  status: string;
}

const C_CustomerListPage: React.FC<C_CustomerListPageProp> = ({ status }) => {
  return (
    <div>
      {/* title  */}
      {/* <Title title={"All Customers"} /> */}

      <Main>
        <Title title={"All Customers"} bgStyle={false} />
        {/* customer list table  */}
        <C_AllCustomersTable status={status} />
      </Main>
    </div>
  );
};

export default C_CustomerListPage;
