// src/lib/configs/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBRSrCOmnyYKmnE3DBLKR_3kj7uzmCZxdI",
  authDomain: "credimanage-2db87.firebaseapp.com",
  projectId: "credimanage-2db87",
  storageBucket: "credimanage-2db87.appspot.com",
  messagingSenderId: "790285741513",
  appId: "1:790285741513:web:112883ae142c1291ea0c18",
  measurementId: "G-Q5JLTN828B",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app); // Initialize Auth service

export { storage, auth };
