import React from "react";
import { Modal } from "antd";
import PrimaryTitle from "../text/PrimaryTitle";
import Description from "../text/Description";
import Button from "../buttons/Button";

const PrimaryModal: React.FC<{
  visible: boolean;
  icon?: React.ReactNode;
  onClose?: () => void;
  mainTitle: string;
  content: string;
  primaryBtnLabel?: string;
  secondaryBtnLabel?: string;
  primaryOnClick?: () => void;
  secondaryOnClick?: () => void;
  closeIcon?: React.ReactNode;
}> = ({
  visible,
  onClose,
  icon,
  mainTitle,
  content,
  primaryBtnLabel = "primary button",
  secondaryBtnLabel,
  primaryOnClick,
  secondaryOnClick,
  closeIcon,
}) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="custom-modal"
    >
      <div className="space-y-5 font-poppins">
        {/* icon  */}
        <div className="text-h2">{icon}</div>

        {/* main title  */}
        <div className="flex justify-center text-center">
          <PrimaryTitle
            text={mainTitle}
            fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
            center
          />
        </div>

        {/* sub title  */}
        <div className="text-center ">
          <Description content={content} center />
        </div>

        <div className="justify-center gap-4 xxs:flex ">
          {/*primary button  */}{" "}
          <div className="flex justify-center">
            <Button
              text={primaryBtnLabel}
              width="min-w-40"
              onClick={primaryOnClick}
            />
          </div>
          {/* secondary button  */}
          {secondaryBtnLabel && (
            <div className="flex justify-center max-xxs:mt-3">
              <Button
                text={secondaryBtnLabel}
                variant="secondary"
                width="min-w-40"
                onClick={secondaryOnClick}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PrimaryModal;
