import { useState } from "react";
import { z } from "zod";
import { CustomerUpdateTypes } from "../../../../../types";
import { customerUpdateSchema } from "../../../../../lib/validation/customer-update-val";

interface UseCustomerDetailsProps {
  fullName: CustomerUpdateTypes["fullName"];
  address: CustomerUpdateTypes["address"];
  nic: CustomerUpdateTypes["nic"];
  policeStation: CustomerUpdateTypes["policeStation"];
  gramaDivision: CustomerUpdateTypes["gramaDivision"];
  provincialOffice: CustomerUpdateTypes["provincialOffice"];
  companyName: CustomerUpdateTypes["companyName"];
  occupation: CustomerUpdateTypes["occupation"];
  companyAddress: CustomerUpdateTypes["companyAddress"];
  email: CustomerUpdateTypes["email"];
  whatsAppNumber: CustomerUpdateTypes["whatsAppNumber"];
  phoneNumber: CustomerUpdateTypes["phoneNumber"];
  optionalContactNumber: CustomerUpdateTypes["optionalContactNumber"];
  netMonthlySalary: CustomerUpdateTypes["netMonthlySalary"];
  frontImageURL: CustomerUpdateTypes["frontImageURL"];
  backImageURL: CustomerUpdateTypes["backImageURL"];
  customerImageURL: CustomerUpdateTypes["customerImageURL"];
  electricityBillImageURL: CustomerUpdateTypes["electricityBillImageURL"];
  savedValues: Partial<CustomerUpdateTypes>;
  setSavedValues: React.Dispatch<
    React.SetStateAction<Partial<CustomerUpdateTypes>>
  >;
}

export const useCustomerDetailsHandlers = ({
  fullName,
  address,
  nic,
  policeStation,
  gramaDivision,
  provincialOffice,
  companyName,
  occupation,
  companyAddress,
  email,
  whatsAppNumber,
  phoneNumber,
  optionalContactNumber,
  netMonthlySalary,
  savedValues,
  frontImageURL,
  backImageURL,
  customerImageURL,
  electricityBillImageURL,
  setSavedValues,
}: UseCustomerDetailsProps) => {
  const [inputs, setInputs] = useState({
    fullName: { value: fullName, disabled: true, error: "" },
    address: { value: address, disabled: true, error: "" },
    nic: { value: nic, disabled: true, error: "" },
    policeStation: { value: policeStation, disabled: true, error: "" },
    gramaDivision: { value: gramaDivision, disabled: true, error: "" },
    provincialOffice: { value: provincialOffice, disabled: true, error: "" },
    companyName: { value: companyName, disabled: true, error: "" },
    occupation: { value: occupation, disabled: true, error: "" },
    companyAddress: { value: companyAddress, disabled: true, error: "" },
    email: { value: email, disabled: true, error: "" },
    whatsAppNumber: { value: whatsAppNumber, disabled: true, error: "" },
    phoneNumber: { value: phoneNumber, disabled: true, error: "" },
    optionalContactNumber: {
      value: optionalContactNumber,
      disabled: true,
      error: "",
    },
    netMonthlySalary: { value: netMonthlySalary, disabled: true, error: "" },
    frontImageURL: { value: frontImageURL || "", disabled: true, error: "" },
    backImageURL: { value: backImageURL || "", disabled: true, error: "" },
    customerImageURL: {
      value: customerImageURL || "",
      disabled: true,
      error: "",
    },
    electricityBillImageURL: {
      value: electricityBillImageURL || "",
      disabled: true,
      error: "",
    },
  });

  const [currentEditingField, setCurrentEditingField] = useState<
    keyof typeof inputs | null
  >(null);

  const validateInput = (key: keyof typeof inputs, value: string) => {
    const fieldSchema = customerUpdateSchema.shape[key];

    try {
      fieldSchema.parse(value);
      return "";
    } catch (error) {
      return (error as z.ZodError).errors[0]?.message || "Invalid value";
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof typeof inputs
  ) => {
    const value = e.target.value;
    const error = validateInput(key, value);

    setInputs({
      ...inputs,
      [key]: { value, disabled: inputs[key].disabled, error },
    });
  };

  const handleEditClick = (key: keyof typeof inputs) => {
    if (currentEditingField && currentEditingField !== key) {
      handleCancel(currentEditingField);
    }

    setCurrentEditingField(key);

    setInputs((prevInputs) =>
      Object.keys(prevInputs).reduce(
        (acc, currentKey) => {
          acc[currentKey as keyof typeof inputs] = {
            ...prevInputs[currentKey as keyof typeof inputs],
            disabled: currentKey !== key,
          };
          return acc;
        },
        {} as typeof inputs
      )
    );
  };

  const handleSave = (key: keyof typeof inputs) => {
    const error = validateInput(key, inputs[key].value);

    if (!error) {
      setSavedValues({
        ...savedValues,
        [key]: inputs[key].value,
      });
      setCurrentEditingField(null);
      setInputs((prevInputs) =>
        Object.keys(prevInputs).reduce(
          (acc, currentKey) => {
            acc[currentKey as keyof typeof inputs] = {
              ...prevInputs[currentKey as keyof typeof inputs],
              disabled: true,
            };
            return acc;
          },
          {} as typeof inputs
        )
      );
    } else {
      setInputs({
        ...inputs,
        [key]: { ...inputs[key], error },
      });
    }
  };

  const handleCancel = (key: keyof typeof inputs) => {
    setInputs({
      ...inputs,
      [key]: { value: savedValues[key] || "", disabled: true, error: "" },
    });
    setCurrentEditingField(null);
  };

  return {
    inputs,
    handleInputChange,
    handleEditClick,
    handleSave,
    handleCancel,
  };
};
