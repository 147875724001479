import React from "react";
import { Main, Title } from "../../common";
import { C_AllCollateralTable } from "../../Tables";

const C_AllCollateralPage: React.FC = () => {
  return (
    <div>
      {/* main container  */}
      <Main>
        {/* title  */}
        <Title title="Collateral List" bgStyle={false} />
        {/* All Collateral Table */}
        <C_AllCollateralTable />
      </Main>
    </div>
  );
};

export default C_AllCollateralPage;
