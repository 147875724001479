import axios from "axios";
import { apiOptionJson, BASE_URL } from "./base-url";
import { CollateralRequestBodyProps } from "../types";

interface requestBodyProps {
  customerId: number;
  loanId: number;
  signatureImageUrl: string;
}

// get all Guarantee List
export const getAllCollateral = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v4/collaterals`, {
      headers: {
        ...apiOptionJson.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// collateral release function
export const collateralRelease = async (
  token: string,
  requestBody: requestBodyProps
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/api/v4/collaterals/release`,
      requestBody,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// update collateral details
export const updateCollateralDetails = async (
  token: string,
  collateralId: number,
  collateralDetailsBody: CollateralRequestBodyProps
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/api/v4/collaterals/${collateralId}`,
      collateralDetailsBody,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
// get customers by collector ID
export const getCollateralsByCollectorId = async (
  collectorId: number,
  token: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/collectors/${collectorId}/collaterals`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching collaterals:", error);
    throw error;
  }
};

export const getCollateralsByManagerId = async (
  appUserId: number,
  token: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v4/collaterals/by-role/${appUserId}`, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching collaterals:", error);
    throw error;
  }
};
