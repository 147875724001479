import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Empty, Form, Table, Typography } from "antd";
import { Description, Dropdown, Input } from "../../common";
import { getAllLoans } from "../../../api/loan";
import { getCollectors } from "../../../api/employee";
import { useNavigate } from "react-router-dom";

interface Item {
  remainingAmount: number;
  customerId: string;
  amountCurrentlyPaid: number;
  loanInstallments: number;
  loanAmount: number;
  id: number;
  loanState: string;
  customerName: string;
  collectorName: string;
  paymentDate: string;
  dueAmount: number;
  paidAmount: number;
  balanceAmount: number;
  repaymentFrequency: string;
  loanId: number;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        ></Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const M_AllLoansTable = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [customersData, setCustomersData] = useState<Item[]>([]);
  const [selectedCollateralStatus, setSelectedCollateralStatus] =
    useState<string>("All");
  const [searchText, setSearchText] = useState<string>("");
  const [allCollectors, setAllCollectors] = useState<
    { collectorUserName: string }[]
  >([]);
  const [selectedCollector, setSelectedCollector] = useState<string>("All");
  const [filterPayementPlan, setfilterPayementPlan] = useState<string>("All");

  // navigate edit customer overview
  const handleNavigate = (customerId: string) => {
    navigate(`/customer-update-overview/${customerId}`);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "loanId",
      fixed: "left" as const,
      align: "center" as const,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      align: "center" as const,
    },
    {
      title: "Collector Name",
      dataIndex: "collectorName",
      align: "center" as const,
    },
    {
      title: "Loan Amount (Rs.)",
      dataIndex: "loanAmount",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Currently Paid (Rs.)",
      dataIndex: "amountCurrentlyPaid",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Loan Installments (Rs.)",
      dataIndex: "loanInstallments",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Remaining Amount (Rs.)",
      dataIndex: "remainingAmount",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Payment Plan",
      dataIndex: "repaymentFrequency",
      align: "center" as const,
    },
    {
      title: "Loan Status",
      dataIndex: "loanState",
      align: "center" as const,
      render: (text: string) => {
        let color = "";
        if (text === "PENDING") {
          color = "gray";
        } else if (text === "APPROVED") {
          color = "green";
        } else if (text === "COMPLETE") {
          color = "blue";
        } else if (text === "REJECTED") {
          color = "red";
        }
        return <span style={{ color, fontWeight: "bold" }}>{text}</span>;
      },
    },
    {
      title: "View",
      dataIndex: "paymentId",
      render: (_: number, record: Item) => (
        <div className="flex justify-center">
          <Typography.Link>
            <div
              onClick={() => handleNavigate(record.customerId)}
              className="flex justify-center w-20 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
            >
              View
            </div>
          </Typography.Link>
        </div>
      ),
      align: "center" as const,
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "netValue" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  const handleCollectorSelect = (option: string) => {
    setSelectedCollector(option);
  };

  // Get all collectors
  useEffect(() => {
    const token = localStorage.getItem("token") || "";

    const fetchCollectors = async () => {
      try {
        const response = await getCollectors(token);
        setAllCollectors([{ collectorUserName: "All" }, ...response]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCollectors();
  }, []);

  // Fetch all loans data
  useEffect(() => {
    const getLoansData = async () => {
      const token = localStorage.getItem("token") || "";
      const Id = localStorage.getItem("userId") || "";
      const userId = parseInt(Id);

      try {
        const response = await getAllLoans(userId, token);

        // Sort the loans by loanId in descending order
        const sortedData = response.data.sort(
          (a: Item, b: Item) => b.loanId - a.loanId
        );

        setCustomersData(sortedData);
      } catch (error) {
        console.log(error);
      }
    };

    getLoansData();
  }, []);

  const loanStatus = ["All", "PENDING", "APPROVED", "COMPLETE", "REJECTED"];
  const paymentPlan = ["All", "DAILY", "WEEKLY", "MONTHLY"];

  // Filter data based on selected collateral status, collector, and search text
  const filteredData = customersData
    .filter(
      (item) =>
        (selectedCollateralStatus === "All" ||
          item.loanState === selectedCollateralStatus) &&
        (selectedCollector === "All" ||
          item.collectorName === selectedCollector) &&
        (filterPayementPlan === "All" ||
          item.repaymentFrequency === filterPayementPlan)
    )
    .filter((item) => {
      const searchValue = searchText.toLowerCase();
      return (
        (item.customerName?.toLowerCase() || "").includes(searchValue) ||
        (item.collectorName?.toLowerCase() || "").includes(searchValue)
      );
    });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  // Calculate totals for filtered data
  const filteredTotalDueAmount = filteredData.reduce(
    (sum, item) => sum + item.loanAmount,
    0
  );
  const filteredTotalPaidAmount = filteredData.reduce(
    (sum, item) => sum + item.amountCurrentlyPaid,
    0
  );
  const filteredTotalloanInstallments = filteredData.reduce(
    (sum, item) => sum + item.loanInstallments,
    0
  );
  const filteredTotalRemainingAmount = filteredData.reduce(
    (sum, item) => sum + item.remainingAmount,
    0
  );

  // Separate calculations for approved and completed loans
  const approvedAndCompletedData = filteredData.filter(
    (item) => item.loanState === "APPROVED" || item.loanState === "COMPLETE"
  );

  const approvedAndCompletedDueAmount = approvedAndCompletedData.reduce(
    (sum, item) => sum + item.loanAmount,
    0
  );
  const approvedAndCompletedPaidAmount = approvedAndCompletedData.reduce(
    (sum, item) => sum + item.amountCurrentlyPaid,
    0
  );
  const approvedAndCompletedLoanInstallments = approvedAndCompletedData.reduce(
    (sum, item) => sum + item.loanInstallments,
    0
  );
  const approvedAndCompletedRemainingAmount = approvedAndCompletedData.reduce(
    (sum, item) => sum + item.remainingAmount,
    0
  );

  // Footer component for totals
  const footer = () => {
    const isAllSelected =
      selectedCollateralStatus === "REJECTED" ||
      selectedCollateralStatus === "PENDING" ||
      selectedCollateralStatus === "APPROVED" ||
      selectedCollateralStatus === "COMPLETE";

    // Get approved and completed amounts total
    const isApprovedOrCompleteSelected = selectedCollateralStatus === "All";

    return (
      <div className="space-y-1">
        {isAllSelected && (
          <div>
            {/* Due Amount Total */}
            <div className="xxs:flex w-[100%] max-w-96 justify-between">
              <div className="xxs:w-44">
                <Description
                  content="Due Amount Total "
                  fontWeight="font-bold"
                />{" "}
                <Description
                  content={`(${selectedCollateralStatus})`}
                  fontSize="text-h9"
                  fontColor={"text-gray-400"}
                />
              </div>
              <Description
                content={`Rs. ${filteredTotalDueAmount.toFixed(2)}`}
              />
            </div>
            {/* Paid Amount total */}
            <div className="xxs:flex w-[100%] max-w-96 justify-between">
              <div className="xxs:w-44">
                <Description
                  content="Paid Amount Total "
                  fontWeight="font-bold"
                />{" "}
                <Description
                  content={`(${selectedCollateralStatus})`}
                  fontSize="text-h9"
                  fontColor={"text-gray-400"}
                />
              </div>
              <Description
                content={`Rs. ${filteredTotalPaidAmount.toFixed(2)}`}
              />
            </div>
            {/* Loan Installments total */}
            <div className="xxs:flex w-[100%] max-w-96 justify-between">
              <div className="xxs:w-44">
                <Description
                  content="Loan Installments Total "
                  fontWeight="font-bold"
                />{" "}
                <Description
                  content={`(${selectedCollateralStatus})`}
                  fontSize="text-h9"
                  fontColor={"text-gray-400"}
                />
              </div>
              <Description
                content={`Rs. ${filteredTotalloanInstallments.toFixed(2)}`}
              />
            </div>
            {/* Balance Amount total */}
            <div className="xxs:flex w-[100%] max-w-96 justify-between">
              <div className="xxs:w-44">
                <Description
                  content="Balance Amount Total "
                  fontWeight="font-bold"
                />{" "}
                <Description
                  content={`(${selectedCollateralStatus})`}
                  fontSize="text-h9"
                  fontColor={"text-gray-400"}
                />
              </div>
              <Description
                content={`Rs. ${filteredTotalRemainingAmount.toFixed(2)}`}
              />
            </div>
          </div>
        )}
        {/* Get approved and completed amounts */}
        {/* Due Amount Total */}
        {isApprovedOrCompleteSelected && (
          <div>
            <div className="xxs:flex w-[100%] max-w-96 justify-between">
              <div className="xxs:w-44">
                <Description
                  content="Due Amount Total "
                  fontWeight="font-bold"
                />{" "}
                <Description
                  content=" (APPROVED, COMPLETE ) "
                  fontSize="text-h9"
                  fontColor={"text-gray-400"}
                />
              </div>
              <Description
                content={`Rs. ${approvedAndCompletedDueAmount.toFixed(2)}`}
              />
            </div>
            {/* Paid Amount total */}
            <div className="xxs:flex w-[100%] max-w-96 justify-between">
              <div className="xxs:w-44">
                <Description
                  content="Paid Amount Total "
                  fontWeight="font-bold"
                />{" "}
                <Description
                  content=" (APPROVED, COMPLETE ) "
                  fontSize="text-h9"
                  fontColor={"text-gray-400"}
                />
              </div>
              <Description
                content={`Rs. ${approvedAndCompletedPaidAmount.toFixed(2)}`}
              />
            </div>
            {/* Loan Installments total */}
            <div className="xxs:flex w-[100%] max-w-96 justify-between">
              <div className="xxs:w-44">
                <Description
                  content="Loan Installments Total "
                  fontWeight="font-bold"
                />
                <Description
                  content=" (APPROVED, COMPLETE ) "
                  fontSize="text-h9"
                  fontColor={"text-gray-400"}
                />
              </div>
              <Description
                content={`Rs. ${approvedAndCompletedLoanInstallments.toFixed(
                  2
                )}`}
              />
            </div>
            {/* Balance Amount total */}
            <div className="xxs:flex w-[100%] max-w-96 justify-between">
              <div className="xxs:w-44">
                <Description
                  content="Balance Amount Total "
                  fontWeight="font-bold"
                />{" "}
                <Description
                  content=" (APPROVED, COMPLETE ) "
                  fontSize="text-h9"
                  fontColor={"text-gray-400"}
                />
              </div>
              <Description
                content={`Rs. ${approvedAndCompletedRemainingAmount.toFixed(
                  2
                )}`}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  // Options for the collector dropdown
  const collectors = allCollectors.map(
    (response) => response.collectorUserName
  );

  return (
    <div>
      {/* mobile view filters  */}
      <div className="md:hidden">
        <div className="grid xxs:gap-3 xxs:grid-cols-2 ">
          {/* Filter collateral by status */}
          <Dropdown
            label="Loan Status"
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            options={loanStatus}
            selectedOption={selectedCollateralStatus}
            onOptionSelect={(option: string) =>
              setSelectedCollateralStatus(option)
            }
            placeholder="Collateral Status"
          />
          {/* Filter by collector */}
          <Dropdown
            label="Collector"
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            options={collectors}
            selectedOption={selectedCollector}
            onOptionSelect={handleCollectorSelect}
            placeholder="Collector"
          />
          <Dropdown
            label="Payment Plan"
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            options={paymentPlan}
            selectedOption={filterPayementPlan}
            onOptionSelect={setfilterPayementPlan}
            placeholder="Payment Plan"
          />
        </div>
        <Input
          id={"filterPayments"}
          name={"filterPayments"}
          placeholder="Search"
          value={searchText}
          onChange={handleSearchChange}
          width="w-[100%] xxs:max-w-60 "
        />
      </div>
      {/* web view filters  */}
      <div className="flex items-center justify-between gap-2 max-md:hidden">
        <div className="flex w-2/3 gap-2">
          {/* Filter collateral by status */}
          <Dropdown
            label="Loan Status"
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            options={loanStatus}
            selectedOption={selectedCollateralStatus}
            onOptionSelect={(option: string) =>
              setSelectedCollateralStatus(option)
            }
            placeholder="Collateral Status"
            width="w-[100%] xxs:max-w-48"
          />
          {/* Filter by collector */}
          <Dropdown
            label="Collector"
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            options={collectors}
            selectedOption={selectedCollector}
            onOptionSelect={handleCollectorSelect}
            placeholder="Collector"
            width="w-[100%] md:max-w-48"
          />
          <Dropdown
            label="Payment Plan"
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            options={paymentPlan}
            selectedOption={filterPayementPlan}
            onOptionSelect={setfilterPayementPlan}
            placeholder="Payment Plan"
            width="w-[100%] md:max-w-48"
          />
        </div>
        {/* Search filter */}
        <div>
          <Input
            id={"filterPayments"}
            name={"filterPayments"}
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            width="w-[100%] xxs:max-w-60 mt-4"
          />
        </div>
      </div>
      {/* Table */}
      <Form form={form} component={false}>
        <Table
          scroll={{ x: "max-content" }} // Ensure the table scrolls horizontally if needed
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData}
          columns={mergedColumns}
          rowClassName="editable-row"
          className="font-semibold"
          footer={footer}
          locale={{
            emptyText: <Empty description={<span>No data available</span>} />,
          }}
        />
      </Form>
    </div>
  );
};

export default M_AllLoansTable;
