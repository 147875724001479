import React from "react";
import { Main, Title } from "../../common";
import { C_PaymentListTable } from "../../Tables";

const C_PaymentListPage: React.FC = () => {
  return (
    <div>
      {/* collector payment list  */}
      <Main>
        {/* title  */}
        <Title title="All Payments" bgStyle={false} />
        {/* table  */}
        <C_PaymentListTable />
      </Main>
    </div>
  );
};

export default C_PaymentListPage;
