import { z } from "zod";

export const contactInfoSchema = z.object({
  contactInfo: z
    .string()
    .min(1, "Email or phone number is required")
    .refine((value) => /\S+@\S+\.\S+/.test(value) || /^\d{10}$/.test(value), {
      message: "Must be a valid email address or a 10-digit phone number",
    }),
});

export const otpSchema = z.object({
  otp: z
    .string()
    .min(1, "OTP is required")
    .length(6, "Please enter a valid 6-digit OTP.")
    .regex(/^\d{6}$/, "Please enter a valid 6-digit OTP."),
});

export const passwordSchema = z
  .object({
    newPassword: z
      .string()
      .min(8, "Password must be at least 8 characters long")
      .max(16, "Password must be at most 16 characters long")
      .refine((value) => /[A-Z]/.test(value), {
        message: "Password must contain at least one uppercase letter",
      })
      .refine((value) => /[a-z]/.test(value), {
        message: "Password must contain at least one lowercase letter",
      })
      .refine((value) => /[!@#$%^&*(),.?":{}|<>]/.test(value), {
        message: "Password must contain at least one symbol",
      })
      .refine((value) => value.length > 0, {
        message: "Password is required",
      }),
    confirmPassword: z.string().refine((value) => value.length > 0, {
      message: "Confirm Password is required",
    }),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords do not match. Please try again.",
    path: ["confirmPassword"],
  });
