import React, { useEffect, useState } from "react";
import { Description, Main, RadioButton, Title } from "../../common";
import {
  CustomerDetails,
  CollateralDetails,
  Registation,
  SecondGuranter,
  FirstGuranter,
  LoanDetails,
} from "./sections/inex";

import SignSection from "./sections/SignSection";
import { CiEdit } from "react-icons/ci";
import { MdDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

const CustomerRegisterOverviewPage: React.FC = () => {
  const [hasCustomerData, setHasCustomerData] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("PDno");
  const [isRadioEditMode, setIsRadioEditMode] = useState(false);
  const [isRadioEditMode2, setIsRadioEditMode2] = useState(false);
  const [selectOptionalSecondGuarantor, setSelectedSecondGuranterOption] =
    useState<string>("SGno");
  const [isCollateralOnly, setIsCollateralOnly] = useState(
    localStorage.getItem("collateralOnly") === "true"
  );

  useEffect(() => {
    if (localStorage.getItem("collateralOnly") === "true") {
      setIsCollateralOnly(true);
    } else {
      setIsCollateralOnly(false);
    }
  }, [isCollateralOnly]);

  useEffect(() => {
    const customerData = localStorage.getItem("customerRegisterFormData1");
    const collateralData = localStorage.getItem("customerRegisterFormData5");
    const guarantorData = localStorage.getItem("customerRegisterFormData4");

    if (customerData) {
      try {
        const parsedData = JSON.parse(customerData);
        const customerNameExists = !!parsedData.fullName;
        setHasCustomerData(customerNameExists);
      } catch (error) {
        console.error("Error parsing customer data:", error);
      }
    }

    if (collateralData) {
      try {
        const parsedData = JSON.parse(collateralData);
        setSelectedOption(parsedData.selectOptionalguarantorsProduct || "PDno");
      } catch (error) {
        console.error("Error parsing collateral data:", error);
      }
    }

    if (guarantorData) {
      try {
        const parsedData = JSON.parse(guarantorData);
        setSelectedSecondGuranterOption(
          parsedData.selectOptionalSecondGuarantor || "SGno"
        );
      } catch (error) {
        console.error("Error parsing guarantor data:", error);
      }
    }
  }, []);

  const handleCollateralSelectChange = (option: string) => {
    setSelectedOption(option);
  };

  const handleguarantorSelectChange = (option: string) => {
    setSelectedSecondGuranterOption(option);
  };

  const handleRadioEditClick = () => {
    setIsRadioEditMode(true);
  };

  const handleRadioEditClick2 = () => {
    setIsRadioEditMode2(true);
  };

  const handleRadioSaveClick = () => {
    // Retrieve current data from local storage
    const collateralData = localStorage.getItem("customerRegisterFormData5");
    let updatedCollateralData = {};

    if (collateralData) {
      try {
        const parsedData = JSON.parse(collateralData);
        // Update the relevant parts
        updatedCollateralData = {
          ...parsedData,
          selectOptionalguarantorsProduct: selectedOption,
        };
      } catch (error) {
        console.error("Error parsing collateral data for save:", error);
      }
    } else {
      // If no data exists, initialize with selectedOption
      updatedCollateralData = {
        selectOptionalguarantorsProduct: selectedOption,
      };
    }

    // Save updated data for collateral back to local storage
    localStorage.setItem(
      "customerRegisterFormData5",
      JSON.stringify(updatedCollateralData)
    );

    // Now update customerRegisterFormData4 with selectOptionalSecondGuarantor
    const guarantorData = localStorage.getItem("customerRegisterFormData4");
    let updatedGuarantorData = {};

    if (guarantorData) {
      try {
        const parsedData = JSON.parse(guarantorData);
        // Update the relevant parts
        updatedGuarantorData = {
          ...parsedData,
          selectOptionalSecondGuarantor: selectOptionalSecondGuarantor,
        };
      } catch (error) {
        console.error("Error parsing guarantor data for save:", error);
      }
    } else {
      // If no data exists, initialize with selectOptionalSecondGuarantor
      updatedGuarantorData = {
        selectOptionalSecondGuarantor: selectOptionalSecondGuarantor,
      };
    }

    // Save updated data for guarantor back to local storage
    localStorage.setItem(
      "customerRegisterFormData4",
      JSON.stringify(updatedGuarantorData)
    );

    // Exit edit mode
    setIsRadioEditMode(false);
    setIsRadioEditMode2(false); // Ensure edit mode is also reset
  };

  const handleRadioCancelClick = () => {
    // Reload the data from local storage
    const collateralData = localStorage.getItem("customerRegisterFormData5");
    const guaranterData = localStorage.getItem("customerRegisterFormData4");

    if (collateralData) {
      const parsedData = JSON.parse(collateralData);
      setSelectedOption(parsedData.selectOptionalguarantorsProduct || "PDno");
    }

    if (guaranterData) {
      const parsedDataGuaranter = JSON.parse(guaranterData);
      setSelectedSecondGuranterOption(
        parsedDataGuaranter.selectOptionalSecondGuarantor || "SGno"
      );
    }
    setIsRadioEditMode(false);
    setIsRadioEditMode2(false); // Ensure edit mode is also reset
  };

  return (
    <div>
      {hasCustomerData && (
        <div>
          <Title title={"ණය ගැනුම්කරු ලියාපදිංචි කිරීම"} />

          <Main>
            {/* customer details  */}
            <CustomerDetails />
            {/* loan details  */}
            <LoanDetails />
            {/* first guarantor details  */}{" "}
            {!isCollateralOnly && <FirstGuranter />}
            {/* second guarantor details  */}
            {!isCollateralOnly && (
              <div className={`flex mt-5  `}>
                <Description
                  content="දෙවන ඇපකරුවෙකු අවශ්‍යද?"
                  fontWeight="font-bold"
                />
                {/* edit button  */}
                <div>
                  {!isRadioEditMode2 && (
                    <CiEdit
                      className="mt-[2px] cursor-pointer text-h5 text-primaryColor-500"
                      onClick={handleRadioEditClick2}
                    />
                  )}
                  {isRadioEditMode2 && (
                    <div className="flex gap-5 text-h4">
                      <span
                        className="text-green-600 cursor-pointer"
                        onClick={handleRadioSaveClick}
                      >
                        <MdDone />
                      </span>
                      <span
                        className="text-red-600 cursor-pointer"
                        onClick={handleRadioCancelClick}
                      >
                        <RxCross2 />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {!isCollateralOnly && (
              <div className="flex gap-5 mt-3 lg:gap-10">
                <RadioButton
                  id={"SecondGuarantYes"}
                  label={"ඔව්"}
                  checked={selectOptionalSecondGuarantor === "SGyes"}
                  onChange={() => handleguarantorSelectChange("SGyes")}
                  disabled={!isRadioEditMode2}
                />
                <RadioButton
                  id={"SecondGuarantNo"}
                  label={"නැත"}
                  checked={selectOptionalSecondGuarantor === "SGno"}
                  onChange={() => handleguarantorSelectChange("SGno")}
                  disabled={!isRadioEditMode2}
                />
              </div>
            )}
            {/* Show <SecondGuranter /> only if SGyes is selected */}
            {selectOptionalSecondGuarantor === "SGyes" && <SecondGuranter />}
            <div className="mt-5">
              {!isCollateralOnly && (
                <div className="flex">
                  {/* Description for the question */}
                  <Description
                    content="ඇපයට භාණ්ඩයක්/ භාණ්ඩ තබන්නේද?*"
                    fontWeight="font-bold"
                  />
                  {/* edit button  */}
                  <div>
                    {!isRadioEditMode && (
                      <CiEdit
                        className="mt-[2px] cursor-pointer text-h5 text-primaryColor-500"
                        onClick={handleRadioEditClick}
                      />
                    )}
                    {isRadioEditMode && (
                      <div className="flex gap-5 text-h4">
                        <span
                          className="text-green-600 cursor-pointer"
                          onClick={handleRadioSaveClick}
                        >
                          <MdDone />
                        </span>
                        <span
                          className="text-red-600 cursor-pointer"
                          onClick={handleRadioCancelClick}
                        >
                          <RxCross2 />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Radio buttons for yes/no */}
              {!isCollateralOnly && (
                <div className="flex gap-5 mt-3">
                  <RadioButton
                    id={"ProductOptionYes"}
                    label={"ඔව්"}
                    checked={selectedOption === "PDyes"}
                    onChange={() => handleCollateralSelectChange("PDyes")}
                    disabled={!isRadioEditMode}
                  />
                  <RadioButton
                    id={"ProductOptionNo"}
                    label={"නැත"}
                    checked={selectedOption === "PDno"}
                    onChange={() => handleCollateralSelectChange("PDno")}
                    disabled={!isRadioEditMode}
                  />
                </div>
              )}
            </div>
            {/* Display CollateralDetails based on selectedOption */}
            {selectedOption === "PDyes" ||
              (isCollateralOnly && <CollateralDetails />)}
            {/* customer signature  */}
            <SignSection />
            {/* registration button  */}
            <Registation />
          </Main>
        </div>
      )}
      {/* No data available*/}
      {!hasCustomerData && (
        <Main>
          <div>
            <Description content="No data available" center />
          </div>
        </Main>
      )}
    </div>
  );
};

export default CustomerRegisterOverviewPage;
