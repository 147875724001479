import React from "react";
import { Chart } from "../../../common/";

interface LegendData {
  label: string;
  value: string;
  color: string;
}

interface DoughnutData {
  datasets: {
    data: number[];
    backgroundColor: string[];
    borderWidth: number;
  }[];
  labels: string[];
  totalDue?: number;
}

interface BarData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
  }[];
}

interface A_ChartsSectionProps {
  doughnutData: DoughnutData;
  barData: BarData;
  barDataC: BarData;
  legendData: LegendData[];
  customerLegendData: LegendData[];
  legendDataDoughnut: LegendData[];
}

const A_ChartsSection: React.FC<A_ChartsSectionProps> = ({
  doughnutData,
  barData,
  barDataC,
  legendData,
  customerLegendData,
  legendDataDoughnut,
}) => {
  return (
    <div className="flex flex-wrap justify-center gap-4 p-4">
      <Chart
        type="doughnut"
        title="Payment Plan"
        data={doughnutData}
        options={{
          plugins: {
            legend: { display: false },
            centerText: {},
          },
          cutout: "70%",
        }}
        legendData={legendDataDoughnut}
        centerTextOptions={{
          text: "Total Due",
          amount: `Rs. ${doughnutData.totalDue?.toFixed(2) || 0}`,
        }}
      />
      <Chart
        type="bar"
        title="Application Overview"
        data={barData}
        options={{
          responsive: true,
          scales: {
            x: { beginAtZero: true, stacked: true, grid: { display: false } },
            y: { beginAtZero: true, stacked: true, grid: { display: true } },
          },
          plugins: {
            legend: {
              display: false, 
            },
          },
        }}
        legendData={legendData} 
      />

      <Chart
        type="bar"
        title="Customer Overview"
        data={barDataC}
        options={{
          responsive: true,
          scales: {
            x: { beginAtZero: true, stacked: true, grid: { display: false } },
            y: { beginAtZero: true, stacked: true, grid: { display: true } },
          },
          plugins: {
            legend: {
              display: false, // Disable the legend
            },
          },
        }}
        legendData={customerLegendData}
      />
    </div>
  );
};

export default A_ChartsSection;
