import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import SignaturePad from "../inputs/SignaturePad";

interface SignatureModalProps {
  visible: boolean;
  onClose: () => void;
  onOk: (signatureUrl: string | null) => void;
  signurl?: string;
  onClear?: () => void;
  isClear?: boolean;
}

const SignatureModal: React.FC<SignatureModalProps> = ({
  visible,
  onClose,
  onOk,
  signurl,
  onClear,
  isClear,
}) => {
  const [signatureUrl, setSignatureUrl] = useState<string | null>(null);

  // Handle image generate
  const handleImageGenerate = (url: string) => {
    setSignatureUrl(url);
  };

  // Handle signature OK
  const handleOk = () => {
    onOk(signatureUrl);
  };

  useEffect(() => {
    if (signurl) {
      handleImageGenerate(signurl);
    }
  }, [signurl]);

  return (
    <Modal
      title="Signature Modal"
      open={visible}
      onCancel={onClose}
      footer={null}
      className="full-screen-modal"
      centered
    >
      <div>
        <SignaturePad
          onImageGenerate={handleImageGenerate}
          SignaturePadText="Please sign here"
          onOk={handleOk}
          signurl={signurl}
          onClear={onClear}
          isClear={isClear}
        />
      </div>
    </Modal>
  );
};

export default SignatureModal;
