import React from "react";
import Description from "./Description";

interface LoanAmountDetailsProps {
  content: string;
  number: string;
}

const LoanAmountDetails: React.FC<LoanAmountDetailsProps> = ({
  content,
  number,
}) => {
  return (
    <div>
      <div className="p-4 mx-auto my-3 rounded-lg bg-primaryColor-200">
        {/* title  */}
        <Description content={content} fontWeight="font-bold" center />
        {/* number  */}
        <Description content={number} fontWeight="font-bold" center />
      </div>
    </div>
  );
};

export default LoanAmountDetails;
