import React, { useEffect, useState } from "react";
import { Empty, Table, DatePicker } from "antd";
import type { TableProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { concoleLogs } from "../../../api/admin";

interface RecordType {
  id: number;
  username: string;
  description: string;
  timestamp: number;
}

const columns: TableProps<RecordType>["columns"] = [
  {
    title: "ID",
    dataIndex: "id",
    fixed: "left",
    align: "center",
  },
  {
    title: "User Name",
    dataIndex: "username",
    align: "center",
  },
  {
    title: "Description",
    dataIndex: "description",
    align: "center",
  },
  {
    title: "Time",
    dataIndex: "timestamp",
    align: "center",
    render: (timestamp: number) =>
      dayjs(timestamp).format("YYYY-MM-DD HH:mm:ss"),
  },
];

const A_ConsoleLogsTable = () => {
  const [empty] = useState(false);
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const [consoleData, setConsoleData] = useState<RecordType[]>([]);
  const [filteredData, setFilteredData] = useState<RecordType[]>([]);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  // get console logs
  useEffect(() => {
    const getConsoleLogs = async () => {
      const token = localStorage.getItem("token") || "";

      try {
        const getconsole = await concoleLogs(token);

        if (Array.isArray(getconsole)) {
          setConsoleData(getconsole);
          setFilteredData(getconsole); // Initialize filtered data with all data
        } else {
          setConsoleData([]);
          setFilteredData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getConsoleLogs();
  }, []);

  // Filter data based on selected date
  useEffect(() => {
    if (selectedDate) {
      const filtered = consoleData.filter((record) =>
        dayjs(record.timestamp).isSame(selectedDate, "day")
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(consoleData); // Reset to all data if no date is selected
    }
  }, [selectedDate, consoleData]);

  return (
    <div className="font-semibold duration-500">
      {/* date [icker for filter logs ] */}
      <div className="">
        <DatePicker
          onChange={(date) => setSelectedDate(date)}
          format="YYYY-MM-DD"
          className="mb-5 w-[100%] xxs:max-w-72 py-2 bg-primaryColor-50 hover:bg-primaryColor-50 focus:outline-none focus-within:ring-0 hover:border-1 hover:border-primaryColor-100 focus-within:border-primaryColor-100 focus-within:bg-primaryColor-50"
          popupClassName=" z-10"
        />
      </div>

      {/* table  */}
      <Table
        bordered
        columns={columns}
        scroll={{ x: "max-content" }} // Ensure the table scrolls horizontally if needed
        rowKey="id"
        dataSource={
          empty
            ? []
            : filteredData.slice(
                (currentPage - 1) * pageSize,
                currentPage * pageSize
              )
        }
        ref={tblRef}
        className="font-semibold"
        pagination={{
          current: currentPage,
          pageSize,
          total: filteredData.length,
          onChange: (page, size) => {
            setCurrentPage(page);
            setPageSize(size);
          },
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "40"],
        }}
        locale={{
          emptyText: <Empty description={<span>No data available</span>} />,
        }}
      />
    </div>
  );
};

export default A_ConsoleLogsTable;
