import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import PrimaryTitle from "../text/PrimaryTitle";
import Dropdown from "../inputs/Dropdown";
import Button from "../buttons/Button";
import { getCollectorsByManagerId, getManagers } from "../../../api/employee";
import PrimaryModal from "./PrimaryModal";
import toast from "react-hot-toast";
import { permanentDelete, updateNewAppuser } from "../../../api/auth";
import { useNavigate } from "react-router-dom";

interface Collector {
  collectorId: number;
  appUserId: number;
  username: string;
}

interface Manager {
  managerId: number;
  userId: number;
  username: string;
}

const AssignCollectorAndDeleteuserModal: React.FC<{
  visible: boolean;
  onClose: () => void;
  closeIcon?: React.ReactNode;
  selectedCollectorsId: number;
}> = ({ visible, onClose, closeIcon, selectedCollectorsId }) => {
  const navigate = useNavigate();
  const [collectors, setCollectors] = useState<Collector[]>([]);
  const [selectedCollectorId, setSelectedCollectorId] = useState<number | null>(
    null
  );
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [managers, setManagers] = useState<Manager[]>([]);
  const [selectedManager, setSelectedManager] = useState<Manager | null>(null);
  const [selectedCollector, setSelectedCollector] = useState<number | null>(
    null
  );

  // Fetch active managers
  useEffect(() => {
    const getActiveAllManagers = async () => {
      const token = localStorage.getItem("token") || "";

      try {
        const response = await getManagers(token);
        setManagers(response);
      } catch (error) {
        console.log(error);
      }
    };

    getActiveAllManagers();
  }, []);

  // Handle manager selection
  const handleManagerSelect = (option: string) => {
    const selectedManager = managers.find(
      (manager) => manager.username === option
    );
    if (selectedManager) {
      setSelectedManager(selectedManager);
      setErrorMessage("");
    }
  };

  useEffect(() => {
    const fetchCollectors = async () => {
      const managerId = selectedManager?.userId || 0;

      if (!managerId) return;
      const token = localStorage.getItem("token") || "";
      try {
        const response = await getCollectorsByManagerId(managerId, token);
        if (Array.isArray(response)) {
          setCollectors(response);
        } else {
          console.error("Expected an array from API, but received:");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCollectors();
  }, [selectedManager]);

  const handleCollectorSelect = (option: string) => {
    const selectedCollector = collectors.find(
      (collector) => collector.username === option
    );
    if (selectedCollector) {
      setSelectedCollectorId(selectedCollector.collectorId);
      setSelectedCollector(selectedCollector.appUserId);
      setErrorMessage(""); // Clear any previous error message
    }
  };

  // handle delete manager
  const handleDeleteManger = async () => {
    const token = localStorage.getItem("token") || "";
    const oldAppUserId = selectedCollectorsId || 0;
    const newAppUserId = selectedCollector || 0;

    // update new app user
    try {
      const response = await updateNewAppuser(
        token,
        oldAppUserId,
        newAppUserId
      );

      if (response.success) {
        // delete permenent
        try {
          const res = await permanentDelete(token, oldAppUserId);
          if (res.success) {
            toast.success(res.message);
            navigate("/employee-list", { replace: true });
          } else {
            toast.error(res.message);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenConfirmModal = () => {
    if (!selectedCollectorId) {
      setErrorMessage("Please select a collector.");
      return;
    }
    setIsOpenConfirmModal(true);
  };

  // handle modal close
  const handleModalClose = () => {
    setSelectedManager(null);
    setSelectedCollectorId(null);
    setErrorMessage("");
    onClose();
  };

  return (
    <>
      <Modal
        open={visible}
        onCancel={handleModalClose}
        centered
        footer={null}
        closeIcon={closeIcon}
        className="custom-modal"
      >
        <div className="space-y-4">
          <PrimaryTitle
            text={"Delete Collector"}
            fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
            center
          />

          {/* Select manager */}
          <Dropdown
            label="Select Manager"
            placeholder="Select Manager"
            options={managers.map((manager) => manager.username)}
            selectedOption={selectedManager?.username || ""}
            onOptionSelect={handleManagerSelect}
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            width="w-[100%]"
            errorMessage={errorMessage}
          />

          {/* Select Collector */}
          <Dropdown
            label="Select Collector"
            placeholder="Select Collector"
            options={collectors.map((collector) => collector.username)}
            selectedOption={
              collectors.find(
                (collector) => collector.collectorId === selectedCollectorId
              )?.username || ""
            }
            onOptionSelect={handleCollectorSelect}
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            width="w-[100%]"
            errorMessage={errorMessage}
            disabled={selectedManager?.userId === undefined ? true : false}
          />
          <div className="flex justify-center">
            <Button
              text={"Delete"}
              onClick={handleOpenConfirmModal}
              disabled={selectedCollectorId ? false : true}
            />
          </div>
        </div>
      </Modal>

      <PrimaryModal
        visible={isOpenConfirmModal}
        mainTitle={"Delete Collector "}
        content={"Are you sure you want to Delete collector?"}
        primaryBtnLabel="Yes"
        secondaryBtnLabel="No"
        primaryOnClick={handleDeleteManger}
        secondaryOnClick={() => setIsOpenConfirmModal(false)}
        onClose={() => setIsOpenConfirmModal(false)}
      />
    </>
  );
};

export default AssignCollectorAndDeleteuserModal;
