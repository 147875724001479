import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Empty, Form, Table, Typography } from "antd";
import { getCollateralsByCollectorId } from "../../../api/collateral";
import { Description, Dropdown, Input } from "../../common";
import { useNavigate } from "react-router-dom";
import CollateralImageOverviewModal from "../../common/modals/CollateralImageOverviewModal";

interface Item {
  imgUrl: string | undefined;
  status: string;
  description: string;
  customerId: number;
  id: number;
  collateralId: string;
  collateralName: string;
  netValue: number;
  collDescription: string;
  collStatus: string;
  collImgUrl?: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        ></Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const C_AllCollateralTable = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<number | null>(null);
  const [collateralData, setCollateralData] = useState<Item[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>("All"); // State for selected status
  const [searchText, setSearchText] = useState<string>("");
  const [isOpenImageModal, setIsOpenImageModal] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>("");
  const navigateCustomerOveview = (customerId: number) => {
    navigate(`/customer-update-overview/${customerId}`);
  };

  const handleOpenImageModal = (imgUrl: string | undefined) => {
    setIsOpenImageModal(true);
    setImageUrl(imgUrl);
  };
  const handleCloseImageModal = () => {
    setImageUrl("");
    setIsOpenImageModal(false);
    setImageUrl("");
  };
  const isEditing = (record: Item) => record.id === editingKey;

  const cancel = () => {
    setEditingKey(null);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "collateralId",
      editable: true,
      fixed: "left" as const,
      align: "center" as const,
    },
    {
      title: "Collateral Name",
      dataIndex: "collateralName",
      editable: true,
      align: "center" as const,
    },
    {
      title: "Net Value",
      dataIndex: "netValue",
      render: (amount: number) => `${amount.toFixed(2)}`,
      editable: true,
      align: "center" as const,
    },
    {
      title: "Description",
      dataIndex: "description",
      editable: true,
      align: "center" as const,
    },
    {
      title: "Collateral Image",
      dataIndex: "imgUrl",
      align: "center" as const,
      render: (_: number, record: Item) => {
        return (
          <div className="flex justify-center">
            <Typography.Link>
              <div
                className="flex justify-center w-40 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
                onClick={() => handleOpenImageModal(record.imgUrl)}
              >
                View
              </div>
            </Typography.Link>
          </div>
        );
      },
    },
    {
      title: "Collateral Status",
      dataIndex: "status",
      align: "center" as const,
      editable: true,
      render: (text: string) => {
        let color = "";
        if (text === "RELEASED") {
          color = "gray";
        } else if (text === "VERIFIED") {
          color = "green";
        }
        return <span style={{ color, fontWeight: "bold" }}>{text}</span>;
      },
    },
    {
      title: "View",
      dataIndex: "customerId",
      render: (_: number, record: Item) => (
        <div className="flex justify-center">
          <Typography.Link>
            <div
              className="flex justify-center w-20 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
              onClick={() => navigateCustomerOveview(record.customerId)}
            >
              View
            </div>
          </Typography.Link>
        </div>
      ),
      align: "center" as const,
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "netValue" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  // get all Collateral details
  useEffect(() => {
    const getCollateralData = async () => {
      const token = localStorage.getItem("token") || "";
      const userId = Number(localStorage.getItem("userId")) || 0;

      if (!userId) {
        console.error("Collector ID is missing");
        return;
      }

      try {
        const response = await getCollateralsByCollectorId(userId, token);
        setCollateralData(response.data);
        console.log("wwwg", response.data);
      } catch (error) {
        console.error("Error fetching collateral data", error);
      }
    };

    getCollateralData();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  // Collateral status options
  const collateralStatus = ["All", "VERIFIED", "RELEASED"];

  // Filter data based on selected status
  const filteredData = Array.isArray(collateralData)
    ? collateralData
        .filter(
          (collateral) =>
            selectedStatus === "All" || collateral.status === selectedStatus
        )
        .filter((collateral) => {
          const searchValue = searchText.toLowerCase();
          const collateralName = collateral.collateralName.toLowerCase();
          const collDescription = collateral.description?.toLowerCase() || "";
          return (
            collateralName.includes(searchValue) ||
            collDescription.includes(searchValue)
          );
        })
    : [];

  // Calculate totals for net value
  const totalNetValue = filteredData.reduce(
    (sum, item) => sum + item.netValue,
    0
  );

  // Footer component for totals
  const footer = () => {
    return (
      <div className="space-y-1">
        {/* Net Value Total */}
        <div className="xxs:flex w-[100%] max-w-96 justify-between">
          <div className="xxs:w-44">
            <Description content="Net Value Total" fontWeight="font-bold" />
          </div>
          <Description content={`Rs. ${totalNetValue.toFixed(2)}`} />
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* Filter collateral by status */}
      <div className="justify-between gap-2 xxs:flex">
        <Dropdown
          label="Collateral Status"
          fontSize="text-h9"
          fontColor={"text-gray-400"}
          options={collateralStatus}
          selectedOption={selectedStatus}
          onOptionSelect={(option: string) => setSelectedStatus(option)}
          placeholder="Collateral Status"
          width="w-[100%] xxs:max-w-60"
        />

        {/* filter guarantors */}
        <Input
          id={"filterGuarantee"}
          name={"filterGuarantee"}
          placeholder="Search"
          value={searchText}
          onChange={handleSearchChange}
          width="w-[100%] xxs:max-w-60 xxs:mt-[15px]"
        />
      </div>

      {/* Table */}
      <Form form={form} component={false}>
        <Table
          scroll={{ x: "max-content" }} // Ensure the table scrolls horizontally if needed
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData} // Use filtered data
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
          className="font-semibold"
          footer={footer}
          locale={{
            emptyText: <Empty description={<span>No data available</span>} />,
          }}
        />
      </Form>

      <CollateralImageOverviewModal
        isOpen={isOpenImageModal}
        onClose={handleCloseImageModal}
        imageUrl={imageUrl}
        disableUpload={true}
      />
    </div>
  );
};

export default C_AllCollateralTable;
