import { useState } from "react";
import { z } from "zod";
import { guaranterUpdateTypes } from "../../../../../types";
import { guaranterUpdateSchema } from "../../../../../lib/validation/guaranter-update-val";

interface UseGuaranterDetailsProps {
  fullName: guaranterUpdateTypes["fullName"];
  address: guaranterUpdateTypes["address"];
  nic: guaranterUpdateTypes["nic"];
  guarantorPhoneNumber: guaranterUpdateTypes["guarantorPhoneNumber"];
  gurantorImageURL: guaranterUpdateTypes["frontImageURL"];
  frontImageURL: guaranterUpdateTypes["frontImageURL"];
  backImageURL: guaranterUpdateTypes["backImageURL"];
  electricityBillOrWaterImageURL: guaranterUpdateTypes["electricityBillOrWaterImageURL"];
  savedValues: Partial<guaranterUpdateTypes>;
  setSavedValues: React.Dispatch<
    React.SetStateAction<Partial<guaranterUpdateTypes>>
  >;
}

export const useGuaranterDetailsHandlers = ({
  fullName,
  address,
  nic,
  guarantorPhoneNumber,
  gurantorImageURL,
  frontImageURL,
  backImageURL,
  electricityBillOrWaterImageURL,
  savedValues,
  setSavedValues,
}: UseGuaranterDetailsProps) => {
  const [inputs, setInputs] = useState({
    fullName: { value: fullName, disabled: true, error: "" },
    address: { value: address, disabled: true, error: "" },
    nic: { value: nic, disabled: true, error: "" },
    guarantorPhoneNumber: {
      value: guarantorPhoneNumber,
      disabled: true,
      error: "",
    },
    gurantorImageURL: { value: gurantorImageURL, disabled: true, error: "" },
    frontImageURL: { value: frontImageURL, disabled: true, error: "" },
    backImageURL: { value: backImageURL, disabled: true, error: "" },
    electricityBillOrWaterImageURL: {
      value: electricityBillOrWaterImageURL,
      disabled: true,
      error: "",
    },
  });

  const [currentEditingField, setCurrentEditingField] = useState<
    keyof typeof inputs | null
  >(null);

  const validateInput = (key: keyof typeof inputs, value: string) => {
    const fieldSchema = guaranterUpdateSchema.shape[key];

    try {
      fieldSchema.parse(value);
      return "";
    } catch (error) {
      return (error as z.ZodError).errors[0]?.message || "Invalid value";
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof typeof inputs
  ) => {
    const value = e.target.value;
    const error = validateInput(key, value);

    setInputs({
      ...inputs,
      [key]: { value, disabled: inputs[key].disabled, error },
    });
  };

  const handleEditClick = (key: keyof typeof inputs) => {
    if (currentEditingField && currentEditingField !== key) {
      handleCancel(currentEditingField);
    }

    setCurrentEditingField(key);

    setInputs((prevInputs) =>
      Object.keys(prevInputs).reduce(
        (acc, currentKey) => {
          acc[currentKey as keyof typeof inputs] = {
            ...prevInputs[currentKey as keyof typeof inputs],
            disabled: currentKey !== key,
          };
          return acc;
        },
        {} as typeof inputs
      )
    );
  };

  const handleSave = (key: keyof typeof inputs) => {
    const error = validateInput(key, inputs[key].value);

    if (!error) {
      setSavedValues({
        ...savedValues,
        [key]: inputs[key].value,
      });
      setCurrentEditingField(null);
      setInputs((prevInputs) =>
        Object.keys(prevInputs).reduce(
          (acc, currentKey) => {
            acc[currentKey as keyof typeof inputs] = {
              ...prevInputs[currentKey as keyof typeof inputs],
              disabled: true,
            };
            return acc;
          },
          {} as typeof inputs
        )
      );
    } else {
      setInputs({
        ...inputs,
        [key]: { ...inputs[key], error },
      });
    }
  };

  const handleCancel = (key: keyof typeof inputs) => {
    setInputs({
      ...inputs,
      [key]: { value: savedValues[key] || "", disabled: true, error: "" },
    });
    setCurrentEditingField(null);
  };

  return {
    inputs,
    handleInputChange,
    handleEditClick,
    handleSave,
    handleCancel,
  };
};
