import React from "react";
import { Main, Title } from "../../common";
import { M_AllCustomersTable } from "../../Tables";

const M_CustomerListPage: React.FC = () => {
  return (
    <div>
      {/* title  */}
      {/* <Title title={"All Customers"} /> */}

      <Main>
        {" "}
        <Title title={"All Customers"} bgStyle={false} />
        {/* customer list table  */}
        <M_AllCustomersTable />
      </Main>
    </div>
  );
};

export default M_CustomerListPage;
