import React, { useEffect, useState } from "react";
import {
  CollateralDetails,
  CustomerDetails,
  FirstGuranter,
  LoanDetails,
  SecondGuranter,
  SignSection,
} from "./sections";
import { getCustomerforLoanReview } from "../../../api/customer";
import Loading from "../loading/Loading";
import { CustomerDataNew } from "../../../types";
import { M_LastpaymentsSchedulesTable } from "../../Tables";

const C_CustomerUpdateOverviewPage: React.FC<{ customerId: number }> = ({
  customerId,
}) => {
  const [customerData, setCustomerData] = useState<CustomerDataNew | null>(
    null
  );

  // get customer details by customer id
  const getCustomerDetailsById = async () => {
    const token = localStorage.getItem("token") || "";
    try {
      const response = await getCustomerforLoanReview(customerId, token);
      setCustomerData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomerDetailsById();
  }, [customerId]);

  if (!customerData) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      {/* customer details  */}
      <CustomerDetails
        loanStatus={customerData.loan[0].loanStatus}
        reason={customerData.loan[0].reason}
        fullName={customerData.fullName}
        address={customerData.address}
        NIC={customerData.nic}
        policeStation={customerData.policeStation}
        gramaDivision={customerData.gramaDivision}
        provincialOffice={customerData.provincialOffice}
        verificationMethod={customerData.verificationMethod}
        frontImageURL={customerData.frontImageURL}
        backImageURL={customerData.backImageURL}
        customerImageURL={customerData.customerImageURL}
        electricityBillImageURL={customerData.electricityBillImageURL}
        companyName={customerData.companyName}
        occupation={customerData.occupation}
        companyAddress={customerData.companyAddress}
        email={customerData.email}
        whatsAppNumber={customerData.whatsAppNumber}
        phoneNumber={customerData.phoneNumber}
        optionalContactNumber={customerData.optionalContactNumber}
        passowrd={customerData.password}
        loanReleaseStatus={customerData.loan[0].loanReleaseStatus}
      />

      {/* loan details  */}
      <LoanDetails
        netMonthlySalary={customerData.netMonthlySalary}
        loanAmount={customerData.loan[0].loanAmount}
        repaymentFrequency={customerData.loan[0].repaymentFrequency}
        startDate={customerData.loan[0].startDate}
        endDate={customerData.loan[0].endDate}
        numberOfInstallments={customerData.loan[0].numberOfInstallments}
        interestAmount={customerData.loan[0].interestAmount}
        installmentAmount={customerData.loan[0].installmentAmount}
      />

      {/* payment table  */}
      <div
        className={`${customerData.loan[0].loanStatus === "REJECTED" || customerData.loan[0].loanStatus === "PENDING" ? "hidden" : ""}`}
      >
        <M_LastpaymentsSchedulesTable customerId={customerId} />
      </div>

      {/* first guranter  */}
      {customerData.guarantors.length !== 0 && (
        <FirstGuranter
          fullName={customerData.guarantors[0].fullName}
          address={customerData.guarantors[0].address}
          nic={customerData.guarantors[0].nic}
          guarantorPhoneNumber={customerData.guarantors[0].guarantorPhoneNumber}
          verificationMethod={customerData.guarantors[0].verificationMethod}
          signatureImageURL={customerData.guarantors[0].signatureImageURL}
          frontImageURL={customerData.guarantors[0].frontImageURL}
          backImageURL={customerData.guarantors[0].backImageURL}
          electricityBillOrWaterImageURL={
            customerData.guarantors[0].electricityBillOrWaterImageURL
          }
          gurantorImageURL={customerData.guarantors[0].gurantorImageURL}
        />
      )}

      {/* second guranter  */}
      {customerData.guarantors.length === 2 && (
        <SecondGuranter
          fullName={customerData.guarantors[1].fullName}
          address={customerData.guarantors[1].address}
          nic={customerData.guarantors[1].nic}
          guarantorPhoneNumber={customerData.guarantors[1].guarantorPhoneNumber}
          verificationMethod={customerData.guarantors[1].verificationMethod}
          signatureImageURL={customerData.guarantors[1].signatureImageURL}
          frontImageURL={customerData.guarantors[1].frontImageURL}
          backImageURL={customerData.guarantors[1].backImageURL}
          electricityBillOrWaterImageURL={
            customerData.guarantors[1].electricityBillOrWaterImageURL
          }
          gurantorImageURL={customerData.guarantors[1].gurantorImageURL}
        />
      )}
      {/* collateral details  */}
      {customerData.collaterals && customerData.collaterals.length > 0 && (
        <CollateralDetails
          collateralName={customerData.collaterals[0].collateralName}
          collDescription={customerData.collaterals[0].description}
          netValue={customerData.collaterals[0].netValue}
          imgUrl={customerData.collaterals[0].imgUrl}
        />
      )}

      {/* sign  */}
      <SignSection signatureImageURL={customerData.signatureImageURL} />
    </div>
  );
};

export default C_CustomerUpdateOverviewPage;
