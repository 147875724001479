import { z } from "zod";

// Define the input schema for the numeric input validation
export const inputSchema = z
  .string()
  .nonempty("Input value is required")
  .refine((val) => !isNaN(Number(val)), {
    message: "Input must be a valid number",
  })
  .refine((val) => Number(val) > 0, {
    message: "Value must be greater than 0",
  })
  .refine((val) => Number(val) <= 10000, {
    message: "Value must be less than or equal to 10,000",
  });
