import React from "react";
import {
  Description,
  Input,
  LoanAmountDetails,
  RadioButton,
} from "../../../../common";
import { CustomerData, Loan } from "../../../../../types";

interface loanDetailsTypes {
  loanAmount: CustomerData["loanAmount"];
  repaymentFrequency: CustomerData["repaymentFrequency"];
  startDate: Loan["startDate"];
  endDate: Loan["endDate"];
  numberOfInstallments: Loan["numberOfInstallments"];
  interestAmount: Loan["interestAmount"];
  installmentAmount: Loan["installmentAmount"];
}

const A_LoanDetails: React.FC<loanDetailsTypes> = ({
  loanAmount,
  repaymentFrequency,
  startDate,
  endDate,
  numberOfInstallments,
  interestAmount,
  installmentAmount,
}) => {
  const getInterestAmount = interestAmount.toFixed(2);
  const getinstallmentAmount = installmentAmount.toFixed(2);

  return (
    <div>
      {/* job  */}
      <Input
        id={"loanAmount"}
        name={"loanAmount"}
        placeholder={"ඉල්ලුම් කරන ණය මුදල (රු) "}
        label="ඉල්ලුම් කරන ණය මුදල (රු)*"
        width="sm:w-[50%]"
        value={loanAmount?.toString()}
        disabled
      />
      {/* select payment plan  */}
      <div>
        <div className="flex">
          <div className="mr-3">
            <Description content="ගෙවීම් සැලසුම*" fontWeight="font-bold" />
          </div>
        </div>

        <div className="flex flex-wrap gap-5 mt-3 lg:gap-10">
          <RadioButton
            id={"dailyInstallments"}
            label={"දින වාරික"}
            checked={repaymentFrequency === "DAILY"}
            disabled
          />
          <RadioButton
            id={"weeklyInstallments"}
            label={"සති වාරික"}
            checked={repaymentFrequency === "WEEKLY"}
            disabled
          />
          <RadioButton
            id={"monthlyInstallments"}
            label={"මාසික වාරික"}
            checked={repaymentFrequency === "MONTHLY"}
            disabled
          />
        </div>
      </div>

      {/* loan Number of installments */}
      <div className="mt-3 ">
        <Input
          id={"numberOfInstallments"}
          name={"numberOfInstallments"}
          placeholder={`ණය ගෙවිය යුතු වාරික ගණන`}
          label={`ණය ගෙවිය යුතු වාරික ගණන`}
          width="sm:w-[50%]"
          value={numberOfInstallments?.toString()}
          disabled
        />
      </div>
      <div className="grid mt-5 md:gap-3 md:grid-cols-2">
        {/* loan Start Date  */}
        <Input
          type="text"
          id={"loanStartDate"}
          name={"loanStartDate"}
          placeholder={"ගෙවීම් ආරම්භක දිනය"}
          label="ගෙවීම් ආරම්භක දිනය*"
          value={new Date(startDate).toLocaleDateString()}
          disabled
        />
        {/* loan end  Date  */}
        <Input
          type="text"
          id={"loanEndDate"}
          name={"loanEndDate"}
          placeholder={"ගෙවීම් අවසාන දිනය"}
          label="ගෙවීම් අවසාන දිනය*"
          value={new Date(endDate).toLocaleDateString()}
          disabled
        />
      </div>
      {/* loan amount details  */}
      <div>
        <div className="grid sm:gap-3 sm:grid-cols-2">
          {/* Number of installments */}
          <LoanAmountDetails
            content={"මුලු ණය මුදල"}
            number={loanAmount?.toString()}
          />
          {/* Total Interest (Rs.) */}
          <LoanAmountDetails
            content={"මුලු පොලිය"}
            number={getInterestAmount?.toString()}
          />
        </div>
        {/* installments Amount (Rs.) */}
        <LoanAmountDetails
          content={"වාරික මුදල (රු)"}
          number={getinstallmentAmount?.toString()}
        />
      </div>
    </div>
  );
};

export default A_LoanDetails;
