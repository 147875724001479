import React, { useEffect, useState } from "react";
import { DatePicker, Modal } from "antd";
import PrimaryTitle from "../text/PrimaryTitle";
import Description from "../text/Description";
import Button from "../buttons/Button";
import {
  approvedLoanWithAssignCollector,
  approvedLoanWithAssignManager,
} from "../../../api/loan";
import { getCollectorsByManagerId } from "../../../api/employee";
import Dropdown from "../inputs/Dropdown";
import { getCurrentUser } from "../../../api/auth";
import SignatureModal from "./SignatureModal";
import { CashReleaseSchema } from "../../../lib/validation/cash-release-val";
import { z } from "zod";
import Input from "../inputs/Input";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Checkbox from "../inputs/Checkbox";
import UploadImageInput from "../inputs/UploadImageInput";
import { removeBackgroundFromImage } from "../../../api/bgremover";
import dayjs, { Dayjs } from "dayjs";

interface Collector {
  collectorId: number;
  username: number;
  contactNumber: string;
}

const ReleasedConformationModal: React.FC<{
  visible: boolean;
  icon?: React.ReactNode;
  onClose?: () => void;
  mainTitle: string;
  collectorId: number;
  managerId: number;
  loanId: number;
  content: string;
  primaryBtnLabel?: string;
  secondaryBtnLabel?: string;
  primaryOnClick?: () => void;
  secondaryOnClick?: () => void;
  closeIcon?: React.ReactNode;
  yesDisabled?: boolean;
  yesLoading?: boolean;
  isSuccessRelease?: boolean;
  selectedDate: Dayjs;
  handleDateChange?: (newDate: Dayjs | null) => void;
}> = ({
  visible,
  onClose,
  icon,
  mainTitle,
  content,
  primaryBtnLabel = "primary button",
  secondaryBtnLabel,
  primaryOnClick,
  secondaryOnClick,
  closeIcon,
  collectorId,
  managerId,
  loanId,
  yesLoading,
  yesDisabled,
  isSuccessRelease,
  handleDateChange,
  selectedDate,
}) => {
  const [collectors, setCollectors] = useState<Collector[]>([]);
  const [selectedCollector, setSelectedCollector] = useState<string | null>(
    null
  );
  const [errors, setErrors] = useState<string>("");
  const [selectedcollectorId, setCollectorId] = useState<number | null>(null);
  const [customerSignUrl, setCustomerSignUrl] = useState<string | null>(null);
  const [employeeSignUrl, setEmployeeSignUrl] = useState<string | null>(null);
  const [isCustomerSignVisible, setIsCustomerSignVisible] = useState(false);
  const [isEmployeeSignVisible, setIsEmployeeSignVisible] = useState(false);
  // Separate error states for each signature
  const [customerSignError, setCustomerSignError] = useState<string | null>(
    null
  );
  const [employeeSignError, setEmployeeSignError] = useState<string | null>(
    null
  );
  const [passwordError, setPassowrdError] = useState<string | null>(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState<string | null>(null);
  const [isClear, setIsClear] = useState(false);
  const [isCustomerUploadMode, setIsCustomerUploadMode] = useState(false);
  const [isEmployeeUploadMode, setIsEmployeeUploadMode] = useState(false);
  const [loadingCustomerImage, setLoadingCustomerImage] = useState(false);
  const [loadingEmployeeImage, setLoadingEmployeeImage] = useState(false);

  // Function to handle opening signature modal
  const handleSignatureModalOpen = () => {
    setIsCustomerSignVisible(true);
  };

  // Function to handle opening signature modal for employee
  const handleEmployeeSignatureModalOpen = () => {
    setIsEmployeeSignVisible(true);
  };

  // Function to handle saving the customer signature URL only to localStorage
  const handleCustomerSignModalOk = (url: string | null) => {
    if (url) {
      setCustomerSignUrl(url);
      setCustomerSignError(null);

      // Save the updated signature URL (base64) to localStorage
      localStorage.setItem("customerSignature", url);
    } else {
      setCustomerSignError("Signature is required.");
    }

    setIsCustomerSignVisible(false);
  };
  // Function to handle saving the employee signature URL to localStorage
  const handleEmployeeSignModalOk = (url: string | null) => {
    if (url) {
      setEmployeeSignUrl(url);
      setEmployeeSignError(null);
      localStorage.setItem("employeeSignature", url);
    } else {
      setEmployeeSignError("Employee Signature is required.");
    }
    setIsEmployeeSignVisible(false);
  };

  // handle customer sign modal open
  const closeCustomerSignModal = () => {
    setIsCustomerSignVisible(false);
  };

  const closeEmployeeSignModal = () => {
    setIsEmployeeSignVisible(false);
  };

  // Function to handle clearing the customer signature URL
  const handleCustomerSignModalClear = () => {
    if (customerSignUrl) {
      setCustomerSignUrl("");
      setCustomerSignError("Signature is required.");
      localStorage.removeItem("customerSignature");
    }
  };

  // Function to handle clearing the employee signature URL
  const handleEmployeeSignModalClear = () => {
    if (employeeSignUrl) {
      setEmployeeSignUrl("");
      setEmployeeSignError("Employee Signature is required.");
      localStorage.removeItem("employeeSignature");
    }
  };

  useEffect(() => {
    // Fetch the saved signature from localStorage when the component mounts
    const savedSignature = localStorage.getItem("customerSignature");
    if (savedSignature) {
      setCustomerSignUrl(savedSignature);
    }

    // Fetch the saved signature from localStorage when the component mounts
    const savedEmployeeSignature = localStorage.getItem("employeeSignature");
    if (savedEmployeeSignature) {
      setEmployeeSignUrl(savedEmployeeSignature);
    }

    // Existing fetchData function to load collectors
    const fetchData = async () => {
      const token = localStorage.getItem("token") || "";
      if (managerId > 0 && collectorId === 0) {
        try {
          const response = await getCollectorsByManagerId(managerId, token);
          if (response) {
            setCollectors(response);
          } else {
            console.log("No response data for collectors");
            setErrors("No response data for collectors");
          }
        } catch (error) {
          console.log(error);
        }
      }
      setErrors("");
    };
    fetchData();
  }, [collectorId, managerId]);

  const handleCollectorSelect = (selectedOption: string) => {
    setSelectedCollector(selectedOption);

    const CollectorId = parseInt(selectedOption.split(". ")[0], 10);
    const selectedCollector = collectors.find(
      (collector) => collector.collectorId === CollectorId
    );

    if (selectedCollector) {
      setCollectorId(selectedCollector.collectorId);
      setErrors("");
    }
  };

  const handlePrimaryClick = async () => {
    let validationData: any = {};

    // Prepare validation data based on the managerId and collectorId conditions
    if (managerId > 0 && collectorId === 0) {
      validationData = {
        selectedCollector: selectedCollector ?? "",
      };
    } else {
      validationData = {
        customerSignUrl: customerSignUrl ?? "",
        employeeSignUrl: employeeSignUrl ?? "",
        password: password ?? "",
      };
    }

    // Check if required fields are empty and set appropriate error messages
    let hasError = false;

    // Collector selection validation (if manager needs to assign collector)
    if (collectorId === 0 && !selectedCollector) {
      setErrors("Please select a collector.");
      hasError = true;
    } else {
      setErrors("");
    }

    // Customer signature validation
    if (!customerSignUrl) {
      setCustomerSignError("Customer signature is required.");
      hasError = true;
    } else {
      setCustomerSignError("");
    }

    // Employee signature validation
    if (!employeeSignUrl) {
      setEmployeeSignError("Employee signature is required.");
      hasError = true;
    } else {
      setEmployeeSignError("");
    }

    // Password validation using Zod schema
    try {
      // Provide fallback to empty string for password validation
      CashReleaseSchema.parse({ password: password || "" }); // Default to empty string if null or undefined

      // If validation passes, clear password error
      setPassowrdError(null);
    } catch (error) {
      if (error instanceof z.ZodError) {
        const passwordError = error.errors.find(
          (issue) => issue.path[0] === "password"
        );
        setPassowrdError(passwordError ? passwordError.message : null);
        hasError = true;
      }
    }

    // Stop further execution if there are any validation errors
    if (hasError) {
      return;
    }

    // If all validations pass, proceed with form submission logic
    try {
      if (collectorId === 0 && selectedcollectorId !== null) {
        const token = localStorage.getItem("token") || "";
        const userId = Number(localStorage.getItem("userId")) || 0;
        const userData = await getCurrentUser(token);

        // Based on user's role, approve the loan
        if (userData.data.role === "ADMIN") {
          const ApproveLoanBody = {
            managerId: managerId,
            collectorId: selectedcollectorId,
          };
          const response = await approvedLoanWithAssignManager(
            loanId,
            ApproveLoanBody,
            token,
            userId
          );

          if (response.success) {
            console.log("");
          } else {
            setErrors("Failed to release the loan.");
          }
        } else if (userData.data.role === "MANAGER") {
          const ApproveLoanBody = {
            managerId: managerId,
            collectorId: selectedcollectorId,
          };
          const response = await approvedLoanWithAssignCollector(
            loanId,
            ApproveLoanBody,
            token,
            userId
          );

          if (response.success) {
            console.log("Loan Release success");
          } else {
            setErrors("Failed to release the loan.");
          }
        }

        setErrors("");
        if (primaryOnClick) {
          primaryOnClick();
        }
      } else {
        setErrors("");
        if (primaryOnClick) {
          primaryOnClick();
        }
      }
    } catch (error) {
      console.error("Error during loan release:", error);
      setErrors("An error occurred while releasing the loan.");
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Save the password in localStorage
    localStorage.setItem("password", newPassword);

    // Validate the password using Zod
    const result = CashReleaseSchema.safeParse({ password: newPassword });

    // Check for password-specific errors
    if (!result.success) {
      const passwordError = result.error.issues.find(
        (issue) => issue.path[0] === "password"
      );
      setPassowrdError(passwordError ? passwordError.message : null);
    } else {
      setPassowrdError(null); // No errors
    }
  };

  const handleClearSign = () => {
    setIsClear(true);
  };

  const clearState = () => {
    handleClearSign();
    setCustomerSignUrl("");
    setEmployeeSignUrl("");
    setSelectedCollector(null);
    setCollectorId(null);
    setErrors("");
    setPassword("");
    setPassowrdError("");

    localStorage.removeItem("customerSignature");
    localStorage.removeItem("employeeSignature");
  };

  useEffect(() => {
    if (isSuccessRelease) {
      clearState();
      handleClearSign();
    }
  }, [isSuccessRelease, isClear]);

  const handleSecondaryClick = () => {
    handleClearSign();
    localStorage.removeItem("customerSignature");
    localStorage.removeItem("employeeSignature");
    localStorage.removeItem("password");

    setCustomerSignError("");
    setEmployeeSignError("");
    setCustomerSignUrl("");
    setEmployeeSignUrl("");
    setPassword("");
    setPassowrdError("");

    clearState();
    if (secondaryOnClick) secondaryOnClick();
  };

  const handleClose = () => {
    handleClearSign();
    setCustomerSignUrl("");
    setEmployeeSignUrl("");
    clearState();
    setCustomerSignError("");
    setEmployeeSignError("");
    setPassword("");
    setPassowrdError("");

    localStorage.removeItem("customerSignature");
    localStorage.removeItem("employeeSignature");
    localStorage.removeItem("password");

    if (onClose) onClose();
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    // Function to clear specific keys from localStorage on refresh
    const handleClearLocalStorage = () => {
      localStorage.removeItem("customerSignature");
      localStorage.removeItem("employeeSignature");
      localStorage.removeItem("password");
    };

    window.addEventListener("beforeunload", handleClearLocalStorage);

    return () => {
      window.removeEventListener("beforeunload", handleClearLocalStorage);
    };
  }, []);

  // Function to handle image selection and uploading
  const handleImageChangeSignatures = async (
    file: File,
    imageField: string,
    loadingSetter: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    try {
      loadingSetter(true);

      const blob = new Blob([file], { type: file.type });

      const processedBlob = await removeBackgroundFromImage(blob);

      const imageUrl = URL.createObjectURL(processedBlob);

      if (imageField === "customerSignatureImageURL") {
        setCustomerSignUrl(imageUrl);
        setCustomerSignError(null); // Clear any errors
        localStorage.setItem("customerSignature", imageUrl);
      } else if (imageField === "employeeSignatureImageURL") {
        setEmployeeSignUrl(imageUrl);
        setEmployeeSignError(null); // Clear any errors
        localStorage.setItem("employeeSignature", imageUrl);
      }
    } catch (error) {
      if (imageField === "customerSignatureImageURL") {
        setCustomerSignError("Failed to upload image. Please try again.");
      } else if (imageField === "employeeSignatureImageURL") {
        setEmployeeSignError("Failed to upload image. Please try again.");
      }
      console.error("Image upload error:", error);
    } finally {
      loadingSetter(false);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={handleClose}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="custom-modal"
    >
      <div className="space-y-5 font-poppins">
        {/* icon  */}
        <div className="text-h2">{icon}</div>
        {/* main title  */}
        <div className="flex justify-center text-center">
          <PrimaryTitle
            text={mainTitle}
            fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
            center
          />
        </div>

        {/* sub title  */}
        <div className="max-xxs:text-center">
          <Description content={content} center />
        </div>
        {/* date picker  */}
        <div>
          <Description content={"Date"} />
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            format="YYYY-MM-DD"
            className=" w-[100%] xxs:max-w-72 py-2 bg-primaryColor-50 hover:bg-primaryColor-50 focus:outline-none focus-within:ring-0 hover:border-1 hover:border-primaryColor-100 focus-within:border-primaryColor-100 focus-within:bg-primaryColor-50"
            popupClassName=" z-10"
            maxDate={dayjs()}
          />
        </div>

        {collectorId === 0 && (
          <div className="flex flex-col justify-center text-center ">
            <div className="flex-col md:flex">
              <Dropdown
                options={collectors.map(
                  (collector) =>
                    `${collector.collectorId}. ${collector.username}`
                )}
                selectedOption={selectedCollector ? selectedCollector : ""}
                onOptionSelect={handleCollectorSelect}
                placeholder="Select Collector"
                label="Please add Collector"
                errorMessage={errors}
              />
            </div>
          </div>
        )}

        {/* customer password  */}
        <div>
          <Input
            id="password"
            name="password"
            label="Customer Password"
            backIcon={
              passwordVisible ? (
                <AiOutlineEyeInvisible
                  className="mt-5 lg:mt-6"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <AiOutlineEye
                  className="mt-5 lg:mt-6"
                  onClick={togglePasswordVisibility}
                />
              )
            }
            type={passwordVisible ? "text" : "password"}
            placeholder="Enter Password"
            errorMessage={passwordError || ""}
            value={password || ""}
            onChange={handlePasswordChange}
          />
        </div>

        {/* Customer Signature or Upload */}
        <div>
          <Description content={"Customer Signature"} />
          <Checkbox
            onChange={(e) => setIsCustomerUploadMode(e.target.checked)}
            checked={isCustomerUploadMode}
            label="Upload Image"
          />
          {isCustomerUploadMode ? (
            <UploadImageInput
              id={"customerSignatureImageURL"}
              name={"customerSignatureImageURL"}
              text={""}
              placeholder={""}
              onFileSelect={(file) => {
                if (file) {
                  // Check if file is not null
                  handleImageChangeSignatures(
                    file,
                    "customerSignatureImageURL",
                    setLoadingCustomerImage
                  );
                }
              }}
              selectImage={customerSignUrl}
              isLoading={loadingCustomerImage}
            />
          ) : (
            <div
              className="h-40 p-2 bg-white border-2 rounded-lg cursor-pointer max-w-[100%] border-primaryColor-200"
              onClick={handleSignatureModalOpen}
            >
              {customerSignUrl ? (
                <img
                  src={customerSignUrl}
                  className="object-contain w-full h-full"
                />
              ) : (
                <div className="flex items-center justify-center h-full underline duration-300 text-primaryColor-200 hover:scale-105">
                  <Description
                    content="Sign here"
                    fontWeight="font-medium"
                    fontColor="text-primaryColor-200"
                    center
                  />
                </div>
              )}
              {customerSignError && (
                <div className="font-normal mt-2 text-left text-red-500 font-poppins text-[12px] md:text-[13px]">
                  {customerSignError}
                </div>
              )}
            </div>
          )}
        </div>

        {/* Employee Signature or Upload */}
        <div>
          <Description content={"Employee Signature"} />
          <Checkbox
            onChange={(e) => setIsEmployeeUploadMode(e.target.checked)}
            checked={isEmployeeUploadMode}
            label="Upload Image"
          />
          {isEmployeeUploadMode ? (
            <UploadImageInput
              id={"employeeSignatureImageURL"}
              name={"employeeSignatureImageURL"}
              text={""}
              placeholder={""}
              onFileSelect={(file) => {
                if (file) {
                  // Check if file is not null
                  handleImageChangeSignatures(
                    file,
                    "employeeSignatureImageURL",
                    setLoadingEmployeeImage
                  );
                }
              }}
              selectImage={employeeSignUrl}
              isLoading={loadingEmployeeImage}
            />
          ) : (
            <div
              className="h-40 p-2 bg-white border-2 rounded-lg cursor-pointer max-w-[100%] border-primaryColor-200"
              onClick={handleEmployeeSignatureModalOpen}
            >
              {employeeSignUrl ? (
                <img
                  src={employeeSignUrl}
                  className="object-contain w-full h-full"
                />
              ) : (
                <div className="flex items-center justify-center h-full underline duration-300 text-primaryColor-200 hover:scale-105">
                  <Description
                    content="Sign here"
                    fontWeight="font-medium"
                    fontColor="text-primaryColor-200"
                    center
                  />
                </div>
              )}
              {employeeSignError && (
                <div className="font-normal mt-2 text-left text-red-500 font-poppins text-[12px] md:text-[13px]">
                  {employeeSignError}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="justify-center gap-4 xxs:flex ">
          {/*primary button  */}{" "}
          <div className="flex justify-center">
            <Button
              text={primaryBtnLabel}
              width="min-w-40"
              onClick={handlePrimaryClick}
              isLoading={yesLoading}
              disabled={yesDisabled}
            />
          </div>
          {/* secondary button  */}
          {secondaryBtnLabel && (
            <div className="flex justify-center max-xxs:mt-3">
              <Button
                text={secondaryBtnLabel}
                variant="secondary"
                width="min-w-40"
                onClick={handleSecondaryClick}
                disabled={yesDisabled}
              />
            </div>
          )}
        </div>
      </div>

      {/*customer Signature modal */}
      <SignatureModal
        visible={isCustomerSignVisible}
        onClose={closeCustomerSignModal}
        onOk={handleCustomerSignModalOk}
        signurl={customerSignUrl || ""}
        onClear={handleCustomerSignModalClear}
        isClear={isClear}
      />

      {/*Employee Signature modal */}
      <SignatureModal
        visible={isEmployeeSignVisible}
        onClose={closeEmployeeSignModal}
        onOk={handleEmployeeSignModalOk}
        onClear={handleEmployeeSignModalClear}
        signurl={employeeSignUrl || ""}
        isClear={isClear}
      />
    </Modal>
  );
};

export default ReleasedConformationModal;
