import React from "react";
import { Button, Main, Title } from "../../common";
import { A_ReleaseFundsTable, M_DashboardTable } from "../../Tables";
import { useNavigate } from "react-router-dom";

const M_Dashboard: React.FC = () => {
  const navigate = useNavigate(); // Hook to navigate between routes

  // buttons labels and onclick data
  const buttonsData = [
    { text: "Customer List", onClick: () => navigate("/customer-list") },
    { text: "View Payment", onClick: () => navigate("/payment-list") },
    { text: "Loan List", onClick: () => navigate("/loan-list") },
    { text: "Add Payment  ", onClick: () => navigate("/add-payment") },
    { text: "Guarantee List", onClick: () => navigate("/guarantee-list") },
    { text: "Collateral List", onClick: () => navigate("/collateral-list") },
  ];

  return (
    <div>
      {/* main container  */}
      <Main>
        {/* main title  */}
        <Title title="Quick Actions" bgStyle={false} />

        {/*quick actions buttons  */}
        <div className="grid gap-5 sm:gap-10 sm:grid-cols-2 lg:grid-cols-3 w-[90%] mx-auto">
          {buttonsData.map((button, index) => (
            <Button
              key={index}
              text={button.text}
              width="w-[100%]"
              onClick={button.onClick}
            />
          ))}
        </div>

        <div className="mt-10">
          {/* table title  */}
          <Title title={"To be Approved Loans"} bgStyle={false} />

          {/* table  */}
          <M_DashboardTable />
        </div>

        <div>
          {/* funds released table  */}
          <A_ReleaseFundsTable />
        </div>
      </Main>
    </div>
  );
};

export default M_Dashboard;
