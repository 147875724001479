import React, { ChangeEvent } from "react";
import Description from "../text/Description";

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string; // Additional classes for customization
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  className = "",
}) => {
  return (
    <label className={`flex w-40 mt-2 items-center space-x-2 ${className}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="relative w-5 h-5 bg-white border-2 border-gray-300 rounded-md appearance-none checked:bg-primaryColor-200 checked:border-primaryColor-300 focus:outline-none custom-checkbox"
      />
      <Description content={label} />
    </label>
  );
};

export default Checkbox;
