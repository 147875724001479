import React from "react";
import { Main, Title } from "../../common";
import { A_ConsoleLogsTable } from "../../Tables";

const ConsoleLogPage: React.FC = () => {
  return (
    <div>
      {/* main container  */}
      <Main>
        {/* title  */}
        <Title title="Console Logs" bgStyle={false} />
        {/* table  */}
        <A_ConsoleLogsTable />
      </Main>
    </div>
  );
};

export default ConsoleLogPage;
