import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import PrimaryTitle from "../text/PrimaryTitle";
import Dropdown from "../inputs/Dropdown";
import Button from "../buttons/Button";
import {
  assignCollectorUnassignCustomers,
  getCollectorsByManagerId,
  getManagers,
} from "../../../api/employee";
import PrimaryModal from "./PrimaryModal";
import toast from "react-hot-toast";

interface Manager {
  managerId: number;
  userId: number;
  username: string;
}

interface Collector {
  collectorId: number;
  userId: number;
  username: string;
}

const AssignCollectorManagerModal: React.FC<{
  visible: boolean;
  onClose: () => void;
  selectedCustomersId: React.Key[];
  onSuccess: () => void;
}> = ({ visible, onClose, selectedCustomersId, onSuccess }) => {
  const [managers, setManagers] = useState<Manager[]>([]);
  const [selectedManager, setSelectedManager] = useState<Manager | null>(null);
  const [collectors, setCollectors] = useState<Collector[]>([]);
  const [selectedCollector, setSelectedCollector] = useState<Collector | null>(
    null
  );
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [selectedCollectorId, setSelectedCollectorId] = useState<number | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  // Fetch active managers
  useEffect(() => {
    const getActiveAllManagers = async () => {
      // Get token
      const token = localStorage.getItem("token") || "";

      try {
        const response = await getManagers(token);
        setManagers(response);
      } catch (error) {
        console.log(error);
      }
    };

    getActiveAllManagers();
  }, []);

  // Fetch active Collectors only when selectedManager is valid
  useEffect(() => {
    const getActiveAllCollectors = async () => {
      if (!selectedManager) {
        console.log("No valid manager selected");
        return;
      }

      const token = localStorage.getItem("token") || "";
      const appUserId = Number(selectedManager.userId);

      try {
        const response = await getCollectorsByManagerId(appUserId, token);
        if (Array.isArray(response)) {
          setCollectors(response);
        } else {
          console.error("Expected an array from API, but received:");
        }
      } catch (error) {
        console.log(error);
      }
    };

    getActiveAllCollectors();
  }, [selectedManager]);

  // Handle manager selection
  const handleManagerSelect = (option: string) => {
    const selected = managers.find((manager) => manager.username === option);
    if (selected) {
      setSelectedManager(selected);
      setSelectedCollector(null);
    }
  };

  // Handle collector selection
  const handleCollectorSelect = (option: string) => {
    const selected = collectors.find(
      (collector) => collector.username === option
    );
    if (selected) {
      setSelectedCollector(selected);
      setSelectedCollectorId(selected.collectorId);
    }
  };

  // Modify the handleAssignNewCollector to use the onSuccess callback
  const handleAssignNewCollector = async () => {
    if (!selectedCollector) {
      setErrorMessage("Please select a collector.");
      return;
    }

    const token = localStorage.getItem("token") || "";
    const assignCollectorRequestBody = {
      customerIds: selectedCustomersId,
      collectorId: selectedCollectorId || 0,
    };

    try {
      const response = await assignCollectorUnassignCustomers(
        token,
        assignCollectorRequestBody
      );

      if (response.success) {
        toast.success(response.message);

        // Close the confirm modal
        setIsOpenConfirmModal(false);

        // Reset selections and close the main modal
        setSelectedCollector(null);
        setSelectedManager(null);
        setErrorMessage("");

        // Delay the onClose to ensure the state is properly updated first
        setTimeout(() => {
          onClose();
        }, 0);

        // Trigger the onSuccess callback
        onSuccess();
      } else {
        toast.error(response.message);
        setSelectedCollector(null);
        setSelectedManager(null);
        // Close the confirm modal on error
        setIsOpenConfirmModal(false);
        setTimeout(() => {
          onClose();
        }, 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenConfirmModal = () => {
    if (!selectedCollectorId) {
      setErrorMessage("Please select a collector.");
      return;
    }
    setIsOpenConfirmModal(true);
  };

  // handle modal close
  const handleModalClose = () => {
    setSelectedCollector(null);
    setSelectedManager(null);
    setErrorMessage("");
    onClose();
  };

  return (
    <Modal
      open={visible}
      onCancel={handleModalClose}
      centered
      footer={null}
      className="custom-modal" // Add your custom class here
    >
      <div className="mt-3 space-y-4 text-center">
        {/* Main title */}
        <PrimaryTitle
          text={"Assign New Manager & Collector"}
          fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
          center
        />

        {/* Select manager */}
        <Dropdown
          label="Select Manager"
          placeholder="Select Manager"
          options={managers.map((manager) => manager.username)}
          selectedOption={selectedManager?.username || ""}
          onOptionSelect={handleManagerSelect}
          fontSize="text-h9"
          fontColor={"text-gray-400"}
          width="w-[100%]"
        />

        {/* Select collector */}
        <Dropdown
          label="Select Collector"
          placeholder="Select Collector"
          options={collectors.map((collector) => collector.username)}
          selectedOption={selectedCollector?.username || ""}
          onOptionSelect={handleCollectorSelect}
          fontSize="text-h9"
          fontColor={"text-gray-400"}
          width="w-[100%]"
          disabled={!selectedManager}
          errorMessage={errorMessage}
        />

        {/* Assign button */}
        <div className="flex justify-center">
          <Button
            text={"Assign"}
            onClick={handleOpenConfirmModal}
            disabled={!selectedCollector}
          />
        </div>
      </div>

      <PrimaryModal
        visible={isOpenConfirmModal}
        mainTitle={"Assign New Manager and Collector"}
        content={"Are you sure you want to assign the new collector?"}
        primaryBtnLabel="Yes"
        secondaryBtnLabel="No"
        primaryOnClick={handleAssignNewCollector}
        secondaryOnClick={() => setIsOpenConfirmModal(false)}
        onClose={() => setIsOpenConfirmModal(false)}
      />
    </Modal>
  );
};

export default AssignCollectorManagerModal;
