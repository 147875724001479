import React from "react";

interface AmountsProps {
  content: number;
  fontFamily?: string;
  fontSize?: string;
  italic?: boolean;
  uppercase?: boolean;
  center?: boolean;
  right?: boolean;
  leading?: string;
  fontColor?:
    | "text-primaryColor-900"
    | "text-primaryColor-200"
    | "text-gray-400"
    | "text-black "
    | string;
  fontWeight?:
    | "font-light"
    | "font-regular"
    | "font-medium"
    | "font-semibold"
    | "font-bold";
  format?: boolean; // New prop to control currency formatting
}

const Amounts: React.FC<AmountsProps> = ({
  content,
  fontColor = "text-primaryColor-900",
  fontFamily = "font-Poppins",
  italic,
  fontSize = "text-h8 lg:text-h7 3xl:text-h4",
  fontWeight = "font-medium",
  uppercase,
  center,
  right,
  leading,
  format = true, // Default to true for formatting
}) => {
  // Format the amount as currency (assuming Sri Lankan Rupee)
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat("en-LK", {
      style: "currency",
      currency: "LKR",
    }).format(amount);
  };

  // Conditionally format the content
  const displayContent = format ? formatCurrency(content) : content.toString();

  return (
    <span
      className={`flex items-center transition-all duration-400 ${fontFamily} ${fontColor} ${fontSize} ${fontWeight} ${leading} 
    ${italic ? "italic" : ""} ${center ? "justify-center" : ""} ${
        right ? "justify-end" : ""
      } ${uppercase ? "uppercase" : ""}`}
    >
      {displayContent}
    </span>
  );
};

export default Amounts;
