import React from "react";
import { Main, Title } from "../../common";
import { M_AllCollateralTable } from "../../Tables";

const M_AllCollateralPage: React.FC = () => {
  return (
    <div>
      {/* main container  */}
      <Main>
        {/* title  */}
        <Title title="Collateral List" bgStyle={false} />
        {/* All Collateral Table */}
        <M_AllCollateralTable />
      </Main>
    </div>
  );
};

export default M_AllCollateralPage;
