import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound: React.FC = () => {
  const navigate = useNavigate(); // Hook to navigate between routes

  // handle navigate login screen
  const navigateLogin = () => {
    navigate("/", { replace: true }); // Navigate to root and replace current entry in history stack
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center ">
        <div className="mt-5 text-2xl font-bold text-red-500 uppercase">
          Page Not Found (404)
        </div>
        <span className="flex">
          Please Check Your User Role & Please
          <span
            className="ml-2 font-semibold cursor-pointer text-primaryColor-300"
            onClick={navigateLogin}
          >
            Sign-In
          </span>
        </span>
      </div>
    </div>
  );
};

export default NotFound;
