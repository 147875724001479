import React, { useState } from "react";
import { Modal } from "antd";
import { format } from "date-fns";

interface Schedule {
  loanId: number;
  customerName: string;
  customerAddress: string;
  customerPhoneNumber: string;
  customerNic: string;
  customerWhatsAppNumber: string;
  numberOfInstallments: number;
  dueDate: string;
  dueAmount: number;
  id?: number; // Optional, as it may or may not be present
  loanAmount: number;
  date?: string;
}

interface ScheduleDetailsModalSmProps {
  visible: boolean;
  icon?: React.ReactNode;
  onClose?: () => void;
  mainTitle?: string;
  selectedDate?: Date | string | null;
  schedules: Schedule[];
  primaryBtnLabel?: string;
  secondaryBtnLabel?: string;
  primaryOnClick?: () => void;
  secondaryOnClick?: () => void;
  closeIcon?: React.ReactNode;
}

const CalanderShedulesCollectionModal: React.FC<
  ScheduleDetailsModalSmProps
> = ({ visible, onClose, schedules, closeIcon, selectedDate }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    if (openIndex === index) {
      setOpenIndex(null); // Close the accordion if it's already open
    } else {
      setOpenIndex(index); // Open the clicked accordion
    }
  };

  const closeModal = () => {
    setOpenIndex(null);
    if (onClose) onClose();
  };

  return (
    <Modal
      open={visible}
      // onOk={onOk}
      onCancel={closeModal}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="custom-modal" // Add your custom class here
    >
      <div className="w-full max-w-md p-4 bg-white rounded-lg">
        <h2 className="mb-4 text-xl font-semibold">
          {selectedDate
            ? format(selectedDate, "MMMM d, yyyy")
            : "Shedule Details"}
        </h2>

        {schedules.length > 0 ? (
          schedules.map((schedule, index) => (
            <div key={index} className="mb-2">
              <button
                className="w-full p-2 text-left bg-[#E7F0EE] rounded-lg"
                onClick={() => toggleAccordion(index)}
              >
                <div className="flex items-center justify-between">
                  <span className="font-semibold">{schedule.customerName}</span>
                  <svg
                    className={`w-4 h-4 transform transition-transform ${
                      openIndex === index ? "rotate-180" : "rotate-0"
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </button>
              {openIndex === index && (
                <div className="p-4 bg-[#E7F0EE] border-t text-primaryColor-900">
                  <div className="flex gap-3">
                    <p className="font-semibold">Address:</p>
                    <p>{schedule.customerAddress}</p>
                  </div>
                  <div className="flex gap-3">
                    <p className="font-semibold">Phone:</p>
                    <p>{schedule.customerPhoneNumber}</p>
                  </div>
                  <div className="flex gap-3">
                    <p className="font-semibold">NIC:</p>
                    <p>{schedule.customerNic}</p>
                  </div>
                  <div className="flex gap-3">
                    <p className="font-semibold">WhatsApp:</p>
                    <p>{schedule.customerWhatsAppNumber}</p>
                  </div>
                  <div className="flex gap-3">
                    <p className="font-semibold">Due Date:</p>
                    <p>{schedule.dueDate}</p>
                  </div>
                  <div className="flex gap-3">
                    <p className="font-semibold">Due Amount:</p>
                    <p>{schedule.dueAmount}</p>
                  </div>
                  <div className="flex gap-3">
                    <p className="font-semibold">Loan Amount:</p>
                    <p>{schedule.loanAmount}</p>
                  </div>
                  <div className="flex gap-3">
                    <p className="font-semibold">Number of Installments:</p>
                    <p>{schedule.numberOfInstallments}</p>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="font-semibold text-center text-primaryColor-900">
            No events scheduled for this day.
          </p>
        )}
      </div>
    </Modal>
  );
};

export default CalanderShedulesCollectionModal;
