import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import PrimaryTitle from "../text/PrimaryTitle";
import Description from "../text/Description";
import Button from "../buttons/Button";
import {
  getCollectorsByManagerId,
  getManagers,
  getUserByUserId,
} from "../../../api/employee";
import Dropdown from "../inputs/Dropdown";

interface Manager {
  managerId: number;
  userId: number;
  username: string;
}

interface Collector {
  collectorId: number;
  username: string;
  contactNumber: string;
}

const AssignResponsModal: React.FC<{
  visible: boolean;
  icon?: React.ReactNode;
  onClose?: () => void;
  mainTitle: string;
  content: string;
  primaryBtnLabel?: string;
  secondaryBtnLabel?: string;
  primaryOnClick?: (managerUserId: number, selectedCollectorId: number) => void;
  secondaryOnClick?: () => void;
  closeIcon?: React.ReactNode;
  managerId: number;
  collectorId: number;
}> = ({
  visible,
  onClose,
  icon,
  mainTitle,
  primaryBtnLabel = "primary button",
  secondaryBtnLabel,
  primaryOnClick,
  secondaryOnClick,
  closeIcon,
  managerId,
  collectorId,
}) => {
  //save name
  const [collector, setCollector] = useState<string>("");
  const [manager, setManager] = useState<string>("");
  //save list
  const [managers, setManagers] = useState<Manager[]>([]);
  const [collectors, setCollectors] = useState<Collector[]>([]);
  //dropdown selector
  const [selectedManager, setSelectedManager] = useState<string | null>(null);
  const [selectedCollector, setSelectedCollector] = useState<string | null>(
    null
  );
  const [errors, setErrors] = useState<string>("");

  const [managerUserId, setManagerUserId] = useState<number | null>(null);
  //get ids
  const [, setManagerId] = useState<number | null>(null);
  const [selectedcollectorId, setCollectorId] = useState<number | null>(null);

  useEffect(() => {
    const getUserDetails = async () => {
      const token = localStorage.getItem("token") || "";
      const usermanagerId = managerId;
      const usercollectorId = collectorId;
      if (usermanagerId > 0) {
        try {
          const responseManager = await getUserByUserId(token, usermanagerId);
          if (responseManager) {
            setManager(responseManager.data.firstName || "");
          } else {
            console.log("No response data for manager");
          }
        } catch (error) {
          console.log(error);
        }

        if (usercollectorId === 0 && usermanagerId > 0) {
          try {
            const response = await getCollectorsByManagerId(
              usermanagerId,
              token
            );

            if (response) {
              console.log("response", response);
              setCollectors(response);
            } else {
              console.log("No response data for managers");
              setErrors("No response data for managers");
            }
          } catch (error) {
            console.log(error);
          }
        }

        if (usercollectorId > 0) {
          try {
            const responseCollector = await getUserByUserId(
              token,
              usercollectorId
            );
            if (responseCollector) {
              setCollector(responseCollector.data.firstName || "");
            } else {
              console.log("No response data for collector");
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    };

    getUserDetails();
  }, [managerId, collectorId]);

  // get manager list when modal is open
  useEffect(() => {
    const usermanagerId = managerId;
    const usercollectorId = collectorId;

    if (usermanagerId === 0 && usercollectorId === 0) {
      const fetchManagers = async () => {
        const token = localStorage.getItem("token") || "";
        try {
          const response = await getManagers(token);

          if (response) {
            setManagers(response);
          } else {
            console.log("No response data for managers");
            setErrors("No response data for managers");
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchManagers();
    }
  }, [managerId, collectorId]);

  //manager select function
  const handleManagerSelect = async (selectedOption: string) => {
    const token = localStorage.getItem("token") || "";
    const managerId = parseInt(selectedOption.split(". ")[0], 10);
    const selectedManager = managers.find(
      (manager) => manager.managerId === managerId
    );
    setSelectedManager(selectedOption);

    if (selectedManager) {
      setManagerId(selectedManager.managerId);
      setManagerUserId(selectedManager.userId);
      setErrors("");
      try {
        const response = await getCollectorsByManagerId(
          selectedManager.userId,
          token
        );
        if (response) {
          setCollectors(response);
        } else {
          console.log("No response data for collectors");
          setErrors("No response data for collectors");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCollectorSelect = (selectedOption: string) => {
    setSelectedCollector(selectedOption);
    setCollectorId(0);

    const CollectorId = parseInt(selectedOption.split(". ")[0], 10);
    const selectedCollector = collectors.find(
      (collector) => collector.collectorId === CollectorId
    );

    if (selectedCollector) {
      setCollectorId(selectedCollector.collectorId);
      setErrors("");
    }
  };

  const handlePrimaryClick = () => {
    const usermanagerId = managerId;
    const usercollectorId = collectorId;

    if (usermanagerId === 0 && usercollectorId === 0) {
      if (primaryOnClick && managerUserId !== null) {
        primaryOnClick(
          managerUserId,
          selectedcollectorId ? selectedcollectorId : 0
        );
      } else {
        setErrors("Please select a manager  ");
      }
    } else if (usermanagerId > 0 && usercollectorId === 0) {
      if (primaryOnClick && selectedcollectorId !== null) {
        primaryOnClick(usermanagerId, selectedcollectorId);
      } else {
        if (primaryOnClick) {
          primaryOnClick(usermanagerId, 0);
        }
      }
    } else {
      if (primaryOnClick) {
        primaryOnClick(0, 0);
      }
    }
  };

  const clearDropdowns = () => {
    setSelectedManager(null);
    setSelectedCollector(null);
    setCollectors([]);
    setErrors("");
  };

  const handleSecondaryClick = () => {
    clearDropdowns();
    if (secondaryOnClick) secondaryOnClick();
  };

  const handleClose = () => {
    clearDropdowns();
    if (onClose) onClose();
  };

  return (
    <Modal
      open={visible}
      onCancel={handleClose}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="custom-modal"
    >
      <div className="space-y-5 font-poppins">
        {/* icon  */}
        <div className="text-h2">{icon}</div>

        {/* main title  */}
        <div className="flex justify-center text-center">
          <PrimaryTitle
            text={mainTitle}
            fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
            center
          />
        </div>

        {managerId > 0 && collectorId > 0 && (
          <div className="flex justify-center gap-5 text-center md:gap-8">
            <div className="max-xxs:text-center">
              <Description content={`Manager : ${manager}`} center />
            </div>

            <div className="max-xxs:text-center">
              <Description content={`Collector : ${collector}`} center />
            </div>
          </div>
        )}

        {managerId === 0 && collectorId === 0 && (
          <div className="">
            <div className="">
              <Dropdown
                options={managers.map(
                  (manager) => `${manager.managerId}. ${manager.username}`
                )}
                selectedOption={selectedManager ? selectedManager : ""}
                onOptionSelect={handleManagerSelect}
                placeholder="Select Manager"
                label="Add Manager"
                errorMessage={errors}
              />
            </div>
            <div className={` marker: ${errors ? "-mt-5" : ""}`}>
              <Dropdown
                options={[
                  "None",
                  ...collectors.map(
                    (collector) =>
                      `${collector.collectorId}. ${collector.username}`
                  ),
                ]}
                selectedOption={selectedCollector || "None"}
                onOptionSelect={handleCollectorSelect}
                placeholder="Select Collector"
                label="Add Collector"
                disabled={!selectedManager} // Disable if no manager is selected
              />
            </div>
          </div>
        )}

        {managerId > 0 && collectorId === 0 && (
          <div className="gap-5 text-center ">
            <div className="max-xxs:text-center">
              <Description content={`Manager : ${manager}`} center />
            </div>
            <div>
              <Dropdown
                options={[
                  "None",
                  ...collectors.map(
                    (collector) =>
                      `${collector.collectorId}. ${collector.username}`
                  ),
                ]}
                selectedOption={selectedCollector ? selectedCollector : ""}
                onOptionSelect={handleCollectorSelect}
                placeholder="Select Collector"
                label="Add Collector"
                errorMessage={errors}
              />
            </div>
          </div>
        )}

        <div className="justify-center gap-4 xxs:flex ">
          {/*primary button  */}
          <div className="flex justify-center">
            <Button
              text={primaryBtnLabel}
              width="min-w-40"
              onClick={handlePrimaryClick}
            />
          </div>
          {/* secondary button  */}
          {secondaryBtnLabel && (
            <div className="flex justify-center max-xxs:mt-3">
              <Button
                text={secondaryBtnLabel}
                variant="secondary"
                width="min-w-40"
                onClick={handleSecondaryClick}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AssignResponsModal;
