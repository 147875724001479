import React from "react";
import { A_ReleaseFundsTable } from "../../Tables";

const A_ReleaseFundsPage: React.FC = () => {
  return (
    <div>
      {/* release funds table  */}
      <A_ReleaseFundsTable />
    </div>
  );
};

export default A_ReleaseFundsPage;
