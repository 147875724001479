import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Empty, Form, Table } from "antd";
import { getCustomersForAnalytics } from "../../../api/employee";

interface Item {
  customerId: number;
  fullName: string;
  address: string;
  startDate: string;
  loanStatus: string;
  collectorUsername: string;
  loanAmount: number;
  totalInterest: number;
  customerNic: string;
  paidAmount: number;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        ></Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const C_CollectorPerfoTable = () => {
  const [form] = Form.useForm();
  const [customersData, setCustomersData] = useState<Item[]>([]);
  const columns = [
    {
      title: "Customer",
      dataIndex: "fullName",
      width: 100,
      align: "center" as const,
      render: (text: string) => <span className="text-gray-700">{text}</span>,
    },
    {
      title: "Customer NIC",
      dataIndex: "customerNic",
      width: 100,
      align: "center" as const,
      render: (text: string) => <span className="text-gray-700">{text}</span>,
    },
    {
      title: "Loan Amount",
      dataIndex: "loanAmount",
      width: 100,
      align: "center" as const,
      render: (text: string) => <span className="text-gray-700">{text}</span>,
    },
    {
      title: "paid Amount",
      dataIndex: "paidAmount",
      width: 100,
      align: "center" as const,
      render: (text: string) => <span className="text-gray-700">{text}</span>,
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "number" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  // Fetch all collectors data
  useEffect(() => {
    const getEmployeeData = async () => {
      const token = localStorage.getItem("token") || "";
      const appUserId = Number(localStorage.getItem("userId"));

      try {
        const response = await getCustomersForAnalytics(token, appUserId);

        if (response && response.data && Array.isArray(response.data)) {
          const formattedData = response.data.map((item: Item) => ({
            ...item,
            key: item.customerId,
          }));
          setCustomersData(formattedData);
        } else {
          console.error("Unexpected data format");
          setCustomersData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getEmployeeData();
  }, []);

  return (
    <div className=" bg-primaryColor-50">
      {/* Table */}
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          columns={mergedColumns}
          dataSource={customersData}
          rowClassName="editable-row"
          className="w-full font-semibold"
          locale={{
            emptyText: <Empty description={<span>No data available</span>} />,
          }}
          pagination={false}
          scroll={{ y: 240, x: "max-content" }}
        />
      </Form>
    </div>
  );
};

export default C_CollectorPerfoTable;
