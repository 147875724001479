import React, { useEffect, useState } from "react";
import { Table } from "antd";
import type { TableColumnsType, TableProps } from "antd";
import {
  AssignManagerModal,
  Button,
  Description,
  Dropdown,
} from "../../../common";
import "./assignemployee.css";
import {
  getCollectorsunassignManager,
  getUnassignManagers,
} from "../../../../api/employee";

type TableRowSelection<T extends object = object> =
  TableProps<T>["rowSelection"];

interface DataType {
  supervisAppuserId: number;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

interface Item {
  username: string;
  email: string;
  userId: number;
  firstName: string;
  lastName: string;
  contactNumber: number;
  supervisAppuserId: number;
}

interface Manager {
  managerId: number;
  userId: number;
  username: string;
  appUserId: number;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Name",
    dataIndex: "name",
    align: "center" as const,
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    align: "center" as const,
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    align: "center" as const,
  },
  {
    title: "NIC",
    dataIndex: "age",
    align: "center" as const,
  },
  {
    title: "Email Address",
    dataIndex: "emailAddress",
    align: "center" as const,
  },
];

const AssignManager: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenManagerAssign, setIsOpenManagerAssign] = useState(false);
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [managers, setManagers] = useState<Manager[]>([]);
  const [selectedManager, setSelectedManager] = useState<Manager | null>(null);
  const [filteredData, setFilteredData] = useState<DataType[]>([]);

  // Handle open assign manager modal
  const handleOpenAssignManager = () => {
    setIsOpenManagerAssign(true);
  };
  const handleCloseAssignmanager = () => {
    setIsOpenManagerAssign(false);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            return index % 2 === 0;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            return index % 2 !== 0;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  // Get collectors by unassigned managers
  const fetchData = async () => {
    // Get token
    const token = localStorage.getItem("token") || "";

    try {
      const response = await getCollectorsunassignManager(token);

      // Map the response data to match the DataType structure
      const mappedData = response.map((item: Item) => ({
        key: item.userId,
        name: item.username,
        age: item.contactNumber,
        emailAddress: item.email,
        firstName: item.firstName,
        lastName: item.lastName,
        supervisAppuserId: item.supervisAppuserId,
      }));

      setTableData(mappedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // get unassign managers
  useEffect(() => {
    const getAllUnassignManagers = async () => {
      const token = localStorage.getItem("token") || "";

      try {
        const response = await getUnassignManagers(token);
        setManagers(response);
      } catch (error) {
        console.log(error);
      }
    };
    getAllUnassignManagers();
  }, []);

  // Handle manager selection
  const handleManagerSelect = (option: string) => {
    if (option === "All") {
      setSelectedManager(null); // Clear the selected manager
      setFilteredData(tableData); // Show all data
    } else {
      const selected = managers.find((manager) => manager.username === option);
      if (selected) {
        setSelectedManager(selected);
        setFilteredData(
          tableData.filter(
            (data) => data.supervisAppuserId === selected.appUserId
          )
        ); // Filter data
      }
    }
  };

  useEffect(() => {
    setFilteredData(tableData);
  }, [tableData]);

  const handleUpdateTableData = () => {
    fetchData(); // Refresh data from API
    setSelectedRowKeys([]);
  };

  // handle cancel assign manager
  const handleCancelManager = () => {
    setSelectedRowKeys([]);
  };

  return (
    <div>
      <div className="gap-5 xxs:flex">
        {/* Select manager */}
        <Dropdown
          label="Unassign Manager"
          placeholder="All"
          options={[
            "All",
            ...(Array.isArray(managers)
              ? managers.map((manager) => manager.username)
              : []),
          ]}
          selectedOption={selectedManager?.username || ""}
          onOptionSelect={handleManagerSelect}
          fontSize="text-h9"
          fontColor={"text-gray-400"}
          width="w-[100%] xxs:max-w-60"
        />
      </div>

      {/* Table */}
      <Table
        className="font-semibold custom-row-selection" // Apply the custom class here
        scroll={{ x: "max-content" }} // Ensure the table scrolls horizontally if needed
        rowSelection={rowSelection}
        columns={columns}
        dataSource={filteredData} // Use filtered data here
        locale={{
          emptyText: (
            <>
              <div className="py-5">
                <Description content={"No collectors found "} center />
              </div>
            </>
          ),
        }}
      />

      {/* Assign button */}
      <div className="flex justify-center gap-5 sm:justify-end">
        <div className={`${selectedRowKeys.length === 0 ? "hidden" : ""}`}>
          <Button
            text={"Cancel "}
            variant="secondary"
            onClick={handleCancelManager}
          />
        </div>

        <Button
          text={"Assign"}
          onClick={handleOpenAssignManager}
          disabled={selectedRowKeys.length === 0}
        />
      </div>

      {/* Assign manager modal */}
      <AssignManagerModal
        visible={isOpenManagerAssign}
        onClose={handleCloseAssignmanager}
        closeIcon={undefined}
        selectedCustomersId={selectedRowKeys} // Pass selectedRowKeys here
        onSuccess={handleUpdateTableData} // Add callback for data refresh
      />
    </div>
  );
};

export default AssignManager;
