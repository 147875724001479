import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../components/layout";

// Defining MainLayout as a functional component
const MainLayout: React.FC = () => {
  return (
    <>
      {/* Rendering Header component */}
      <Header />
      {/* Rendering nested routes components */}
      <Outlet />
    </>
  );
};

// Exporting MainLayout component as default
export default MainLayout;
