import React, { useState, useEffect } from "react";
import { Button, Description, OtpInput, PrimaryTitle } from "../../common";
import { z } from "zod";
import { otpSchema } from "../../../lib/validation/forgetpassword-val";
import { verifyOtp, getOtpByEmail } from "../../../api/auth"; // Assuming there's a getOtpByEmail API function

interface Step2Props {
  setSelect: React.Dispatch<React.SetStateAction<number>>;
  contactInfo: string;
}

const Step2: React.FC<Step2Props> = ({ setSelect, contactInfo }) => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(60); // 60 seconds countdown timer
  const [resendDisabled, setResendDisabled] = useState(true);

  useEffect(() => {
    let countdown: NodeJS.Timeout | null = null;

    if (resendDisabled && timer > 0) {
      countdown = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendDisabled(false); // Enable the resend button after countdown
    }

    return () => {
      if (countdown) clearTimeout(countdown);
    };
  }, [timer, resendDisabled]);

  const handleChange = (value: string) => {
    setOtp(value);

    if (submitted) {
      try {
        otpSchema.parse({ otp: value });
        setError("");
      } catch (e) {
        if (e instanceof z.ZodError) {
          setError(e.errors[0]?.message || "Invalid input");
        }
      }
    }
  };

  const handleResend = async () => {
    try {
      setLoading(true);
      setError("");
      await getOtpByEmail(contactInfo); // Call API to resend OTP
      setResendDisabled(true); // Disable resend button after click
      setTimer(60); // Reset timer to 60 seconds
    } catch (error) {
      console.error(error);
      setError("Failed to resend OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setSubmitted(true);

    try {
      otpSchema.parse({ otp });
      setError(""); // Clear any existing errors
      setLoading(true); // Start loading

      const response = await verifyOtp(otp, contactInfo);

      if (response.success) {
        setSelect(3);
      } else {
        setError(
          response.response.data.message ||
            "Failed to verify OTP. Please try again."
        );
      }
    } catch (e) {
      if (e instanceof z.ZodError) {
        setError(e.errors[0]?.message || "Invalid input");
      } else {
        setError("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleVerifyOtp();
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center p-4 py-5 text-center"
      onKeyDown={handleKeyDown}
    >
      <PrimaryTitle
        text="Please verify your account"
        center
        fontColor="primaryColor-500"
        fontWeight="font-semibold"
      />
      <div className="space-y-2">
        <Description
          fontWeight="font-medium"
          content={`Please enter the verification code you received at ${contactInfo}`}
          center
        />
        <OtpInput
          otpLength={6}
          value={otp}
          onChange={handleChange}
          errorMessage={error}
        />
        <p className="font-normal text-center text-primaryColor-500">
          Didn’t receive the Verification Code?{" "}
          <button
            className="text-base font-bold text-primaryColor-500 font-poppins focus:outline-none"
            onClick={handleResend}
            disabled={resendDisabled || loading}
          >
            {resendDisabled ? `Resend in ${timer}s` : "Resend"}{" "}
            {/* Display timer or resend text */}
          </button>
        </p>
      </div>
      <div className="flex justify-center mt-6">
        <Button
          onClick={handleVerifyOtp}
          text="Next"
          bgColor="bg-primaryColor-500"
          disabled={loading}
          isLoading={loading}
        />
      </div>
    </div>
  );
};

export default Step2;
