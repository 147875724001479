import React from "react";
import { Main, Title } from "../../common";
import { A_PaymentListTable } from "../../Tables";

const A_PaymentListPage: React.FC = () => {
  return (
    <div>
      {/* main container  */}
      <Main>
        {/* title  */}
        <Title title="All Payments" bgStyle={false} />
        {/* table  */}
        <A_PaymentListTable />
      </Main>
    </div>
  );
};

export default A_PaymentListPage;
