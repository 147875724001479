import React from "react";
import { Main, Title } from "../../common";
import { A_ScheduleListTable } from "../../Tables";

const A_ScheduleListPage: React.FC = () => {
  return (
    <div>
      <Main>
        <Title title={"Schedule List"} bgStyle={false} />
        {/* <main title  */}
        <A_ScheduleListTable />
      </Main>
    </div>
  );
};

export default A_ScheduleListPage;
