import { z } from "zod";

// Define the Zod schema for the customer data
export const customerSchema = z.object({
  loanReason: z
    .string()
    .min(3, "හේතුව සඳහා අවම අකුරු 3ක්වත් තිබිය යුතුය")
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .nonempty("අවශ්යයි"),
  fullName: z
    .string()
    .min(3, "නමෙහි අවම අකුරු 3ක්වත් තිබිය යුතුය")
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .regex(
      /^[A-Za-z\u0D80-\u0DFF\s]+$/,
      "නමෙහි විශේෂ අකුරු සහ සංඛ්‍යා නොවිය යුතුය"
    )
    .nonempty("අවශ්යයි"),
  address: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .min(1, "අවශ්‍යයි"),
  nic: z
    .string()
    .min(10, " අවම වශයෙන් අක්ෂර 10 ක් විය යුතුය")
    .max(12, "අක්ෂර 12 ඉක්මවිය නොහැක"),
  policeStation: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .min(1, "අවශ්‍යයි"),
  gramaDivision: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .min(1, "අවශ්‍යයි"),
  provincialOffice: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .min(1, "අවශ්‍යයි"),
  verificationMethod: z.string().min(1, "අවශ්‍යයි"),
  phoneNumber: z
    .string()
    .regex(/^\d+$/, "දුරකථන අංකය ඉලක්කම් විය යුතුය")
    .min(10, "දුරකථන අංකය අවම වශයෙන් ඉලක්කම් 10ක් විය යුතුය")
    .max(10, "දුරකථන අංකය වැඩිම ඉලක්කම් 10ක් විය යුතුය"),
  email: z
    .string()
    .email("වලංගු නොවන ඊමේල් ලිපිනයකි")
    .nonempty("අවශ්යයි")
    .max(254, "උපරිම අකුරු 254ක් විය යුතුය")
    .refine((value) => /\S+@\S+\.\S+/.test(value), {
      message: "වලංගු නොවන ඊමේල් ආකෘතියකි",
    }),
  optionalContactNumber: z
    .string()
    .refine((value) => value === "" || value.length === 10, {
      message: "දුරකථන අංකය ඉලක්කම් 10ක් විය යුතුය",
    })
    .refine((value) => value === "" || /^\d+$/.test(value), {
      message: "සංඛ්‍යා පමණක් තිබිය යුතුය",
    }),
  whatsAppNumber: z
    .string()
    .regex(/^\d+$/, "දුරකථන අංකය ඉලක්කම් විය යුතුය")
    .min(10, "දුරකථන අංකය අවම වශයෙන් ඉලක්කම් 10ක් විය යුතුය")
    .max(10, "දුරකථන අංකය වැඩිම ඉලක්කම් 10ක් විය යුතුය"),
  companyName: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .min(1, "අවශ්‍යයි"),
  occupation: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .min(1, "අවශ්‍යයි"),
  companyAddress: z
    .string()
    .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
    .min(1, "අවශ්‍යයි"),
  frontImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  backImageURL: z.any().refine((value) => value !== "", {
    message: "අවශ්යයි",
  }),
  customerImageURL: z.any().refine((value) => value !== "", {
    message: "ණය ගැනුම්කරුගේ ඡායාරූපය අවශ්යයි",
  }),
  electricityBillImageURL: z.any().refine((value) => value !== "", {
    message: "බිල්පතෙහි ඡායාරූපය අවශ්යයි",
  }),
});
