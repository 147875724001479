import axios from "axios";
import { BASE_URL } from "./base-url";

interface calculateIntrestTypes {
  repaymentFrequency: string;
  loanAmount: number;
  duration: number;
}

// calculation loan interest and installments
export const calculateInterest = async (requestBody: calculateIntrestTypes) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/calculation/get-data`,
      requestBody
    );

    return response.data;
  } catch (error) {
    console.error("Error data", error);
    throw error;
  }
};
