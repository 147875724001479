import React from "react";
import { Modal } from "antd";
import { C_CustomerUpdateOverviewPage } from "../page";
import { Button } from "../common";

interface BlacklistOverviewProps {
  visible: boolean;
  icon?: React.ReactNode;
  onClose?: () => void;
  closeIcon?: React.ReactNode;
  primaryBtnLabel: string;
  secondaryBtnLabel?: string;
  userDetails?: string;
  primaryOnClick?: () => void;
  secondaryOnClick?: () => void;
  customerId?: number | null;
}

const BlacklistOverview: React.FC<BlacklistOverviewProps> = ({
  visible,
  onClose,
  closeIcon,
  primaryBtnLabel,
  primaryOnClick,
  secondaryOnClick,
  secondaryBtnLabel,
  customerId,
}) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="custom-modal"
      width="max-w-xl"
    >
      <div className="w-xl">
        <C_CustomerUpdateOverviewPage customerId={customerId || 0} />
        <div className="flex justify-center gap-4 mt-4">
          {secondaryBtnLabel && (
            <Button text={secondaryBtnLabel} onClick={secondaryOnClick} />
          )}
          <Button text={primaryBtnLabel} onClick={primaryOnClick} />
        </div>
      </div>
    </Modal>
  );
};

export default BlacklistOverview;
