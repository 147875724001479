import React from "react";

interface ImageChipProps {
  id: string;
  imageName: string;
  onClick?: () => void;
  fontSize?: string;
  fontWeight?: "font-thin" | "font-normal" | "font-semibold" | "font-bold";
  errorMessage?: string;
}

const ImageChip: React.FC<ImageChipProps> = ({
  id,
  imageName,
  onClick,
  fontSize = "text-base",
  fontWeight = "font-normal",
  errorMessage,
}) => {
  return (
    <div>
      <button
        id={id}
        onClick={onClick}
        className={` py-[6px] ${fontSize} ${fontWeight}  rounded-lg flex items-center justify-center`}
      >
        <div className="flex items-center px-4 py-1 rounded-full bg-primaryColor-50">
          <img
            src="/images/imageUpload.png"
            alt="icon"
            className="w-5 h-5 mr-2 md:w-6 md:h-6 lg:w-7 lg:h-7"
          />
          <span className="text-sm md:text-md xl:text-lg">{imageName}</span>
        </div>
      </button>
      {/* Error message */}
      {errorMessage && (
        <div className=" font-normal z-50  text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[3px]">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default ImageChip;
