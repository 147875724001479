import { useState } from "react";
import { z } from "zod";
import { CollateralUpdateProps } from "../../../../../types";
import { collateralUpdateSchema } from "../../../../../lib/validation/collateral-update-val";

interface UseCollateralDetailsProps {
  collateralName: CollateralUpdateProps["collateralName"];
  netValue: CollateralUpdateProps["netValue"];
  collDescription: CollateralUpdateProps["collDescription"];
  collImgUrl: CollateralUpdateProps["collImgUrl"];
  savedValues: Partial<CollateralUpdateProps>;
  setSavedValues: React.Dispatch<
    React.SetStateAction<Partial<CollateralUpdateProps>>
  >;
}

export const useColletaralDetailsHandlers = ({
  collateralName,
  netValue,
  collDescription,
  collImgUrl,
  savedValues,
  setSavedValues,
}: UseCollateralDetailsProps) => {
  const [inputs, setInputs] = useState({
    collateralName: { value: collateralName, disabled: true, error: "" },
    netValue: { value: netValue, disabled: true, error: "" },
    collDescription: { value: collDescription, disabled: true, error: "" },
    collImgUrl: { value: collImgUrl, disabled: true, error: "" },
  });

  const [currentEditingField, setCurrentEditingField] = useState<
    keyof typeof inputs | null
  >(null);

  const validateInput = (key: keyof typeof inputs, value: string) => {
    const fieldSchema = collateralUpdateSchema.shape[key];

    try {
      fieldSchema.parse(value);
      return "";
    } catch (error) {
      return (error as z.ZodError).errors[0]?.message || "Invalid value";
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof typeof inputs
  ) => {
    const value = e.target.value;
    const error = validateInput(key, value);

    setInputs({
      ...inputs,
      [key]: { value, disabled: inputs[key].disabled, error },
    });
  };

  const handleEditClick = (key: keyof typeof inputs) => {
    if (currentEditingField && currentEditingField !== key) {
      handleCancel(currentEditingField);
    }

    setCurrentEditingField(key);

    setInputs((prevInputs) =>
      Object.keys(prevInputs).reduce(
        (acc, currentKey) => {
          acc[currentKey as keyof typeof inputs] = {
            ...prevInputs[currentKey as keyof typeof inputs],
            disabled: currentKey !== key,
          };
          return acc;
        },
        {} as typeof inputs
      )
    );
  };

  const handleSave = (key: keyof typeof inputs) => {
    const error = validateInput(key, inputs[key].value);

    if (!error) {
      setSavedValues({
        ...savedValues,
        [key]: inputs[key].value,
      });
      setCurrentEditingField(null);
      setInputs((prevInputs) =>
        Object.keys(prevInputs).reduce(
          (acc, currentKey) => {
            acc[currentKey as keyof typeof inputs] = {
              ...prevInputs[currentKey as keyof typeof inputs],
              disabled: true,
            };
            return acc;
          },
          {} as typeof inputs
        )
      );
    } else {
      setInputs({
        ...inputs,
        [key]: { ...inputs[key], error },
      });
    }
  };

  const handleCancel = (key: keyof typeof inputs) => {
    setInputs({
      ...inputs,
      [key]: { value: savedValues[key] || "", disabled: true, error: "" },
    });
    setCurrentEditingField(null);
  };

  return {
    inputs,
    handleInputChange,
    handleEditClick,
    handleSave,
    handleCancel,
  };
};
