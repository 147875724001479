import React from "react";
import { Button, Main, Title } from "../../common";
import { useNavigate } from "react-router-dom";
import { A_EmployeeListTable } from "../../Tables";

const EmployeeListPage: React.FC = () => {
  const navigate = useNavigate();

  // handle navigate user registration
  const handleNavigateUserRegister = () => {
    navigate("/user-register");
  };

  return (
    <div>
      {/* main container  */}
      <Main>
        {/* title  */}
        <Title title="All Employee" bgStyle={false} />
        {/* table  */}
        <A_EmployeeListTable />
        <Button text={"Add Employee"} onClick={handleNavigateUserRegister} />
      </Main>
    </div>
  );
};

export default EmployeeListPage;
