import { z } from "zod";

// Define the Zod schema for the collateral data
export const CollateralDetailsSchema = (isRequired: boolean) =>
  z.object({
    collateralName: isRequired
      ? z
          .string()
          .nonempty("භාණ්ඩය අවශ්‍ය වේ")
          .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
      : z.string().optional(),
    collDescription: z
      .string()
      .max(255, "උපරිම අකුරු 255ක් විය යුතුය")
      .optional(),
    collNetValue: isRequired
      ? z
          .string()
          .nonempty("දළ වටිනාකම අවශ්‍ය වේ")
          .regex(
            /^\d+(\.\d{1,2})?$/,
            "සංඛ්‍යා පමණක් තිබිය යුතුය (තැබිය හැකි උපරිමය දශම ස්ථාන ගණන 2කි)"
          )
          .refine((value) => parseFloat(value) > 500, {
            message: "ණය මුදලේ වටිනාකම 500 ට වඩා වැඩි විය යුතුය.",
          })
      : z.string().optional(),
    collImgUrls: z
      .array(z.string().url("සෑම රූපයක්ම වලංගු URL එකක් විය යුතුය"))
      .min(1, "අවම වශයෙන් එක් පින්තූරයක් උඩුගත කළ යුතුය")
      .max(5, "උපරිම පින්තූර 5ක් උඩුගත කළ හැක"),
  });
