import axios from "axios";
import { BASE_URL, apiOptionJson } from "./base-url";

// get all notification
export const getNotifications = async (token: string, userId: number) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v7/notifications?userId=${userId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get all read notifications
export const getReadNotifications = async (token: string, userId: number) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v7/notifications/read?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// update is read
export const isReadNotification = async (id: number, token: string) => {
  try {
    const reposne = await axios.post(
      `${BASE_URL}/api/v7/notifications/${id}/read`,
      {},
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return reposne.data;
  } catch (error) {
    console.error("Error marking notification as read:", error);
    return error;
  }
};

export const markAllNotificationsAsRead = async (
  userId: number,
  token: string
) => {
  try {
    const reposne = await axios.post(
      `${BASE_URL}/api/v7/notifications/markAllRead/${userId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return reposne.data;
  } catch (error) {
    console.error("Error marking notification as read:", error);
    return error;
  }
};
