import React from "react";

interface DescriptionProps {
  content: string;
  fontFamily?: string;
  fontSize?: string;
  italic?: boolean;
  uppercase?: boolean;
  center?: boolean;
  right?: boolean;
  leading?: string;

  fontColor?:
    | "text-primaryColor-900"
    | "text-primaryColor-200"
    | "text-gray-400"
    | "text-black "
    | string;
  fontWeight?:
    | "font-light"
    | "font-regular"
    | "font-medium"
    | "font-semibold"
    | "font-bold";
}

const Description: React.FC<DescriptionProps> = ({
  content,
  fontColor = "text-primaryColor-900",
  fontFamily = "font-Poppins",
  italic,
  fontSize = "text-h8 lg:text-h7 3xl:text-h4",
  fontWeight = "font-medium",
  uppercase,
  center,
  right,
  leading,
}) => {
  const lines = content.split("\n");
  const isMultiLine = lines.length > 1;

  return (
    <span
      className={`${
        isMultiLine ? "" : "flex"
      } items-center transition-all duration-400 ${fontFamily} ${fontColor} ${fontSize} ${fontWeight} ${leading} 
    ${italic ? "italic" : ""} ${center ? "justify-center" : ""} ${
        right ? "justify-end" : ""
      } ${uppercase ? "uppercase" : ""}`}
    >
      {lines.map((line, index) => (
        <span key={index}>
          {line}
          {index < lines.length - 1 && <br />}
        </span>
      ))}
    </span>
  );
};

export default Description;
